import React from "react";
import { useState } from "react";

import { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";


import withReactContent from "sweetalert2-react-content";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import ModalAgregarDepartamento from "./ModalAggDepartament";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditarDepartamento from "./ModalEditDepartament";
import { paginationProps } from "../utils/util";
import SearchInput from "../Components/SearchInput";
import PreloaderTables from "../PreloaderTables/PreloaderTables";

const Departamentos = () => {

    const initialListarDatos = {
        id: "0",
        name: ""
    }

    const initDataFilter = {
        search: ''
    }

    const [ListarDatos, setListar] = useState();
    const [datosServidor, setDatosservidor] = useState([])
    const [ShowModalAgregarDepartament, setShowModalAgregarDepartament] = React.useState(false);
    const [ShowModalEditar, setShowModalEditar] = React.useState(false);
    const [EditarDepartamentoId, setEditarDepartamentoId] = React.useState();
    const [data, setData] = useState([initialListarDatos]);
    const [loaded, setLoaded] = useState(false);
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [dataSearch, setDataSearch] = useState([]);
    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');

    useEffect((props) => {
        getInfo();
    }, []);

    async function getInfo() {
        const url = `${process.env.REACT_APP_API_URI}user/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };
        try {
            const resp = await axios.get(url, config);
            console.log(resp.data)
            setDatosservidor(resp.data);

        } catch (err) {
            console.error(err);
        }
    };

    const [showModal, setShowModal] = React.useState(false);

    const datosFormulario = {
        Departamentos: "",

    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);

    const [alerta, setAlerta] = useState([initialStateInput]);

    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name)
        console.log(value)
        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const handleLoginsession = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [

            { nombre: "departamento", value: formulario.departamento },


        ];

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length >= 1) {
            console.log("Enviar al servidor");
        };

    };

    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'Departamento': {
                    if (valorInput.value === '' || valorInput.value === null) {
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });
                    } else {
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        })

        return errors;
    }

    console.log(alerta)

    useEffect(() => {
        function ListarDepartamento() {
            const urlDepartament = `${process.env.REACT_APP_API_URI}state/index`;
            console.log(urlDepartament);

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            };

            if (Cookies.get('Bearer') !== undefined) {
                config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
            } else {
                window.location = "/"
            }



            axios.get(urlDepartament, config)
                .then(response => {
                    console.log(response, "Listar");

                    setListar(response.data);
                    setData(response.data);
                    setDataSearch(response.data);
                    setLoaded(true)
                })
        }
        ListarDepartamento();

        console.log(ListarDatos, "Hola")

    }, [])

    const buttonEditar = (estadomodal, IdDepartamento) => {
        console.log(estadomodal, IdDepartamento);
        setEditarDepartamentoId(IdDepartamento)
        setShowModalEditar(estadomodal)
    }

    const columns = [
        {
            name: '#',
            width: "25%",
            cell: (row, index) => <b>
                {loaded && (index + 1)}
            </b>
        },
        {
            name: 'Nombre',
            width: "25%",
            cell: (row) => <div>{loaded && row.name}</div>
        },

        {
            name: 'Estado',
            width: "25%",
            cell: (row) =>


                <div className="flex items-center">
                    {(row.status == 1) ? (
                        <>
                            <div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</>
                    ) : (
                        <>
                            <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div> Inactivo
                        </>
                    )}

                </div>
        },

        {
            name: 'Editar',
            width: "25%",
            cell: (row) => <div> <button onClick={() => buttonEditar(true, row.id)} type="button" data-modal-toggle="user-modal" className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center">
                <FontAwesomeIcon icon={faPenToSquare} />

            </button></div>
        },

    ];

    const tableData = {
        columns,
        data
    };

    const searchFilter = (termSearch, nameInput, dataArray) => {
        console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = data.filter((item) => {
                    if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                setDataSearch(srch);
                break;
            default:
                break;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        setDataFilter({...dataFilter, [e.target.name]: e.target.value});
    }

    return (
        <div className=''>
            <div className='ContainerOpenClass'>
                <div className=" p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="sm:flex">
                            <h1 className="text-xl sm:text-2xl font-semibold text-white">Departamentos</h1>

                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                                <button type="button" data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                    onClick={() => setShowModal(true)}>
                                    <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Agregar Departamento
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-4 bg-blue-950">
                    <SearchInput dataFilterSearch={ dataFilter.search } changeData={ changeData }/>
                    <div className="px-2">
                        {
                            loaded
                            ?
                            <DataTable
                                columns={columns}
                                data={ dataSearch }
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                responsive
                                paginationComponentOptions={paginationProps}
                                noDataComponent={<div className="my-5">No hay registros</div>}
                            />
                            :
                            <PreloaderTables/>
                        }
                    </div>
                </div>
            </div>
            {showModal ? (<ModalAgregarDepartamento setShowModalAgregar={setShowModal}></ModalAgregarDepartamento>) : null}
            {ShowModalEditar ? (<EditarDepartamento setShowModalEditar={setShowModalEditar} EditarDepartamentoId={EditarDepartamentoId}></EditarDepartamento>) : null}
        </div>
    )
}

export default Departamentos;
