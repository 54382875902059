import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import withReactContent from "sweetalert2-react-content";
import DataTableExtensions from "react-data-table-component-extensions";

const ModalAggMunicipio = (props) => {



    const datosFormulario = {
        Municipio: "",
        Departamento: ""
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);

    console.log(formulario);
    const MySwal = withReactContent(Swal);
    const tokenAPP = JSON.parse(Cookies.get('Bearer')).token;
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [departamentos, setDepartamentos] = useState(undefined);

    const ManejarEventoDeInputs = (event) => {
        console.log(event);
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name)
        console.log(value)

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const handleLoginsession = (e) => {
        e.preventDefault();

        let verificarInputs = [
            { nombre: "Municipio", value: formulario.nombre },
            { nombre: "Departamento", value: formulario.nombre }
        ];
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados);

        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        console.log('Total de validaciones', totalValidaciones.length);

        if (totalValidaciones.length >= 2) {

            RegistroMunicipio();
        };


    };

    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'Municipio': {
                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }

                case 'Departamento': {
                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }


                default: {


                    break;
                }
            }

        })

        return errors;
    }



    const RegistroMunicipio = async () => {


        const url = `${process.env.REACT_APP_API_URI}city/register/`;
        console.log(url);
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        console.log("config ==>", config);
        // console.log("Form: ", formulario);
        
        const newdata = {
            
            name: formulario.Municipio,
            state: formulario.type

        }
        console.log("Data de envio: ", newdata);
        // console.log(url, login, config)
        
        axios.post(url, newdata, config)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setFormulario(datosFormulario)

                    setTimeout(() => {
                        props.setShowModalAgregar(false)
                    }, "4000")
                    setTimeout(() => {
                        window.location.replace('/Municipios');
                    }, "4500")

                }

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });

            });
    };

    const consultarDepartamentos = async () => {
        const url = `${process.env.REACT_APP_API_URI}state/filter/1`; // Siempre consultando El Salvador
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
        .then(response => {
            console.log(response.data);
            setDepartamentos(response.data);
        })
        .catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        consultarDepartamentos();
    }, []);

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="w-[127%] border-0 rounded-lg shadow-lg relative w-full flex flex-col  bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-950">
                                Agregar Municipio
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.setShowModalAgregar(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full max-w-sm" onSubmit={handleLoginsession}>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-blue-950 font-bold  mb-1 md:mb-0 pr-4">
                                                Municipio
                                            </label>
                                        </div>

                                        <div className="md:w-2/3">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="Municipio" id="Municipio" type="text" value={formulario.Municipio} onChange={ManejarEventoDeInputs} />

                                            {
                                                alerta.filter(input => input.valorInput == "municipio" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center ">
                                        <div className="md:w-1/3">
                                            <label className="block text-blue-950 font-bold  mb-1 md:mb-0 pr-4">
                                                Departamento
                                            </label>


                                        </div>
                                        <div className="md:w-2/3 m-2 ">
                                            <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="type" id="type" value={formulario.type}
                                                onChange={ ManejarEventoDeInputs }
                                            >
                                                { departamentos && (
                                                    <>
                                                        <option>Seleccione un Departamento</option>
                                                        {
                                                            departamentos.map((item, index)=>
                                                                <option 
                                                                    key={ index }
                                                                    value={item.id}
                                                                >{item.name}</option>
                                                            )
                                                        }
                                                    </>
                                                ) }
                                            </select>

                                            {
                                                alerta.filter(input => input.valorInput == "Departamento" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }


                                        </div>
                                    </div>










                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b justify-center">

                                        <button
                                            className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                            type="submit" 
                                            

                                        >
                                            Agregar
                                        </button>

                                        <button
                                            className="bg-red-500 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                            type="button"
                                            onClick={() => props.setShowModalAgregar(false)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>


                                </form>
                            </div>
                        </div>
                        {/*footer*/}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )

}

export default ModalAggMunicipio;