import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { downloadFileRequest } from './utils/util';

const DownloadFileButton = (props) => {
    const MySwal = withReactContent(Swal);

    const handleDownload = async () => {
        let response = await downloadFileRequest(
            `student/download-${props.origin}${ props.id ? `/${ props.id }` : '' }`
        );

        if (response.type === "error") {
            let errorMessage = response.message ? response.message : "Error al descargar el archivo";

            MySwal.fire(
                'Error',
                errorMessage,
                'error'
            );

            return;
        }

        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${ props.filename }`);

        document.body.appendChild(link);

        link.click();
    };

    return (
        <button
            className="btn-file btn-download"
            type="button"
            onClick={ handleDownload }
        >
            <FontAwesomeIcon className="btn-icon" icon={ faDownload } />            
        </button>
    );
};

export default DownloadFileButton;
