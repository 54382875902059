import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import { getOrDeleteRequest } from "../utils/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import CampusRSelect from "../Components/CampusRSelect";
import KnowledgesRSelect from "../Components/KnowledegesRSelect";
import SearchInput from "../Components/SearchInput";
import Preloader from "../Preloader/Preloader";

const Filters = forwardRef((props, ref) => {

    const initParamFilters = {
        gender: null,
        status: null,
        state: null,
        city: null,
        district: null,
        knowledge: [],
        program: null,
        campus: null,
        program_status: null,
        language: null,
        status_order: null,
        name_order: 'ASC',
        internship_status: null
    }

    const [dataDepartments, setDataDepartments] = useState([]);
    const [dataMunicipalities, setDataMunicipalities] = useState([]);
    const [dataDistrict, setDataDistrict] = useState([]);
    const [onKnowledges, setOnKnowledges] = useState([]);
    const [dataLanguages, setDataLanguages] = useState([]);
    const [dataProgramTraining, setDataProgramTraining] = useState([]);
    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');
    const [textFilters, setTextFilters] = useState(false);
    const [paramFilters, setParamFilters] = useState(initParamFilters);
    const [statusProgram, setStatusProgram] = useState(false);

    useEffect(() => {
        getDepartments();
        getLanguages();
        getProgramTranining();
        if (sessionStorage.getItem('MyFilters') !== null && sessionStorage.getItem('MyFiltersIn') !== null) {
            const filterSelect = JSON.parse(sessionStorage.getItem('MyFilters'));
            const ft = JSON.parse(sessionStorage.getItem('MyFilters'));
            const fti = JSON.parse(sessionStorage.getItem('MyFiltersIn'));
            filterSelect.program !== null && setStatusProgram(true);
            props.setDataFilter(fti);
            setParamFilters(ft);
            verifyObj(ft) && getfiltersStudents(ft);
        }
    }, []);

    const getDepartments = async () => {
        const response = await getOrDeleteRequest(
            "state/index",
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            setDataDepartments([]);
            console.log(response);
        } else {
            setDataDepartments(setFilterDataReactSelect(response, 'state'));
        }
    }

    const getMunicipalities = async (idState) => {
        const response = await getOrDeleteRequest(
            `city/filter/${idState}`,
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataMunicipalities(setFilterDataReactSelect(response, 'city'));
        }
    }

    const getdistrict = async (idMunicipality) => {
        const response = await getOrDeleteRequest(
            `district/filter/${idMunicipality}`,
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataDistrict(setFilterDataReactSelect(response, 'district'));
        }
    }

    const getLanguages = async () => {
        const response = await getOrDeleteRequest(
            'language/index',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataLanguages(setFilterDataReactSelect(response, 'language'));
        }
    }

    const getProgramTranining = async () => {
        const response = await getOrDeleteRequest(
            'program-schedule/filter',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataProgramTraining(setFilterDataReactSelect3(response, 'program'));
        }
    }

    const getfiltersStudents = async (ft) => {
        props.setLoaded(false);
        const endPoint = `student/index?internal=1&` +
            `${ft.gender !== null ? 'gender=' + ft.gender + '&' : ''}` +
            `${ft.status !== null ? 'status=' + ft.status + '&' : ''}` +
            `${ft.state !== null ? 'state=' + ft.state + '&' : ''}` +
            `${ft.city !== null ? 'city=' + ft.city + '&' : ''}` +
            `${ft.district !== null ? 'district=' + ft.district + '&' : ''}` +
            `${ft.knowledge !== null && ft.knowledge.length > 0 ? 'knowledge=' + ft.knowledge + '&' : ''}` +
            `${ft.program !== null ? 'program=' + ft.program + '&' : ''}` +
            `${ft.campus !== null ? 'campus=' + ft.campus + '&' : ''}` +
            `${ft.program_status !== null ? 'program_status=' + ft.program_status + '&' : ''}` +
            `${ft.status_order !== null ? 'status_order=' + ft.status_order + '&' : ''}` +
            `${ft.internship_status !== null ? 'internship_status=' + ft.internship_status + '&' : ''}` +
            `${ft.name_order ? 'name_order=' + ft.name_order + '&' : ''}` +
            `${ft.language !== null ? 'language=' + ft.language + '&' : ''}`;
        const response = await getOrDeleteRequest(
            endPoint.slice(0, -1),
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            props.setLoaded(true);
            return;
        }
        if (response.type === "error") {
            console.log(response, 'error');
            props.setDataSearch([]);
            props.setLoaded(true);
            return;
        }
        props.setDataSearch(response);
        props.setLoaded(true);
    }

    const setFilterDataReactSelect = (dataFilter, typeData) => {
        let options = [];
        dataFilter.map((item) => {
            options.push({ value: item.id, label: item.name, name: typeData });
        });
        return options;
    }

    const setFilterDataReactSelect2 = (dataFilter, typeData) => {
        let options = [];
        dataFilter.map((item) => {
            options.push({ value: item.id, label: item.campus_name, name: typeData });
        });
        return options;
    }

    const setFilterDataReactSelect3 = (dataFilter, typeData) => {
        let options = [];
        dataFilter.map((item) => {
            options.push({ value: item.value, label: item.label, name: typeData });
        });
        return options;
    }

    const addElement = (dataArray) => {
        const arrayIds = dataArray.map(item => {
            return item.value;
        });
        return arrayIds;
    }

    const searchFilter = (termSearch, nameInput, dataArray) => {
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = props.data.filter((item) => {
                    if (item.names.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.lastnames.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.email.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                props.setDataSearch(srch);
                break;
            case 'gender':
                props.dataFilter.gender = termSearch;
                paramFilters.gender = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'status':
                props.dataFilter.status = termSearch;
                paramFilters.status = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'state':
                props.dataFilter.state = termSearch;
                paramFilters.state = termSearch;
                if (props.dataFilter.city !== null || props.dataFilter.district !== null) {
                    props.dataFilter.city = '';
                    props.dataFilter.district = '';
                    paramFilters.city = '';
                    paramFilters.district = '';
                    setDataDistrict([]);
                    setDataMunicipalities([]);
                }
                getfiltersStudents(paramFilters);
                getMunicipalities(termSearch);
                break;
            case 'city':
                props.dataFilter.city = termSearch;
                paramFilters.city = termSearch;
                getfiltersStudents(paramFilters);
                getdistrict(termSearch);
                break;
            case 'district':
                props.dataFilter.district = termSearch;
                paramFilters.district = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'knowledge':
                props.dataFilter.knowledge = dataArray;
                paramFilters.knowledge = dataArray;
                if (dataArray.length > 0) {
                    paramFilters.knowledge = addElement(dataArray);
                } else {
                    paramFilters.knowledge = [];
                    props.dataFilter.knowledge = [];
                }
                getfiltersStudents(paramFilters);
                break;
            case 'program':
                props.dataFilter.program = termSearch;
                paramFilters.program = termSearch;
                getfiltersStudents(paramFilters);
                getStatusProgram();
                break;
            case 'campus':
                props.dataFilter.campus = termSearch;
                paramFilters.campus = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'language':
                props.dataFilter.language = termSearch;
                paramFilters.language = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'program_status':
                props.dataFilter.program_status = termSearch;
                paramFilters.program_status = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'internship_status':
                props.dataFilter.internship_status = termSearch;
                paramFilters.internship_status = termSearch;
                getfiltersStudents(paramFilters);
                break;
            case 'status_order':
                props.dataFilter.status_order = termSearch;
                paramFilters.status_order = termSearch;
                getfiltersStudents(paramFilters);
                break;
            default:
                break;
        }
    }

    const getStatusProgram = () => {
        if (props.dataFilter.program != null) {
            setStatusProgram(true);
            props.dataFilter.program_status = 0;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        props.setDataFilter({ ...props.dataFilter, [e.target.name]: e.target.value });
        searchFilter(e.target.value, e.target.name);
        setParamFilters({ ...paramFilters, [e.target.name]: e.target.value });
        sessionStorage.setItem('MyFilters', JSON.stringify(paramFilters));
        props.setMessageFilter(true);
    }

    const changeDataRSelect = (e) => {
        searchFilter(e.value, e.name, '');
        setParamFilters({ ...paramFilters, [e.name]: e.value });
        props.setDataFilter({ ...props.dataFilter, [e.name]: e });
        sessionStorage.setItem('MyFilters', JSON.stringify(paramFilters));
        props.setMessageFilter(true);
    }

    const changeDataRSelectMulti = (e) => {
        if (e.length > 0) {
            searchFilter('', e[0].name, e);
            setParamFilters({ ...paramFilters, [e[0].name]: addElement(e) });
            props.setDataFilter({ ...props.dataFilter, [e.name]: e });
        } else {
            props.dataFilter.knowledge = [];
            paramFilters.knowledge = [];
            getfiltersStudents(paramFilters);
        }
        sessionStorage.setItem('MyFilters', JSON.stringify(paramFilters));
        props.setMessageFilter(true);
    }

    const resetFilter = () => {
        props.setDataFilter(props.initDataFilter);
        setParamFilters(initParamFilters);
        setStatusProgram(false);
        sessionStorage.removeItem("MyFilters");
        sessionStorage.removeItem("MyFiltersIn");
        props.getInfo();
        props.setMessageFilter(false);
    }

    function verifyObj(obj) {
        props.setMessageFilter(true);
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (value !== null && !emptyArray(value) && value !== "ASC") {
                    return true;
                }
            }
        }
        return false;
    }

    function emptyArray(value) {
        return Array.isArray(value) && value.length === 0;
    }

    return (<>
        <div className="px-4">
            <form className="text-sm font-medium relative">
                {
                    props.disabled !== undefined && props.disabled === true && textFilters === true
                    &&
                    <>
                        <div className="absolute w-full h-full px-2">
                            <div className="flex justify-center relative w-full h-full">
                                <div className="absolute w-full h-full bg-black opacity-80 z-10"></div>
                            </div>
                        </div>
                        <Preloader />
                    </>
                }
                <div className="w-full flex flex-wrap">
                    <SearchInput dataFilterSearch={props.dataFilter.search} changeData={changeData} disabled={props.disabled !== undefined ? props.disabled : false} />
                    <div className="w-full md:w-1/3 px-2">
                        <div className="mb-6">
                            <label htmlFor="gender" className="block mb-2 text-white">Género</label>
                            <select
                                name="gender"
                                value={props.dataFilter.gender}
                                className="text-sm rounded block w-full px-3 py-3 text-gray-500 bg-white"
                                id="gender"
                                onChange={changeData}
                                disabled={props.disabled !== undefined ? props.disabled : false}
                            >
                                <option disabled value='default'>Seleccione una opción </option>
                                <option value={1}>Masculino</option>
                                <option value={2}>Femenino</option>
                                <option value={3}>Prefiero no decirlo</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3 px-2">
                        <div className="mb-6">
                            <label htmlFor="status" className="block mb-2 text-white">Estado</label>
                            <select
                                name="status"
                                value={props.dataFilter.status}
                                className="text-sm rounded block w-full px-3 py-3 text-gray-500 bg-white"
                                id="status"
                                onChange={changeData}
                                disabled={props.disabled !== undefined ? props.disabled : false}
                            >
                                <option disabled value={'default'}>Seleccione una opción </option>
                                <option value={1}>Activo </option>
                                <option value={2}>Inactivo </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="relative">
                    <div className="absolute right-6 top-0 text-red-400 text-sm hover:underline cursor-pointer" onClick={resetFilter} >
                        <FontAwesomeIcon icon={faTrash} /> Quitar filtros
                    </div>
                    <details className="">
                        <summary className="w-1/2 md:w-1/5 lg:w-[12%] pb-2 px-2 flex justify-evenly items-center font-medium cursor-pointer list-none" onClick={() => { setTextFilters(!textFilters) }} >
                            {textFilters
                                ? <div className="text-sm hover:underline text-yellow-500"> <FontAwesomeIcon icon={faCircleMinus} /> Menos filtros</div>
                                : <div className="text-sm hover:underline text-yellow-500"> <FontAwesomeIcon icon={faCirclePlus} /> Más filtros</div>}
                        </summary>
                        <div className="group-open:animate-fadeIn">
                            <div className="w-full flex flex-wrap">
                                {/* <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="state" className="block mb-2 text-white">Departamentos</label>
                                        <Select
                                            name="state"
                                            id="state"
                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                            value={props.dataFilter.state}
                                            options={dataDepartments}
                                            placeholder={"Selecciona una opción"}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            onChange={changeDataRSelect}
                                            isDisabled={props.disabled !== undefined ? props.disabled : false}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="city" className="block mb-2 text-white">Municipios</label>
                                        <Select
                                            name="city"
                                            id="city"
                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                            value={props.dataFilter.city}
                                            options={dataMunicipalities}
                                            placeholder={"Selecciona una opción"}
                                            noOptionsMessage={() => 'Seleccione un departamento'}
                                            onChange={changeDataRSelect}
                                            isDisabled={props.disabled !== undefined ? props.disabled : false}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="district" className="block mb-2 text-white">Distritos</label>
                                        <Select
                                            name="district"
                                            id="district"
                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                            value={props.dataFilter.district}
                                            options={dataDistrict}
                                            placeholder={"Selecciona una opción"}
                                            noOptionsMessage={() => 'Seleccione un municipio'}
                                            onChange={changeDataRSelect}
                                            isDisabled={props.disabled !== undefined ? props.disabled : false}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div className="w-full flex flex-wrap">
                                <KnowledgesRSelect
                                    useState={useState}
                                    useEffect={useEffect}
                                    select={Select}
                                    getOrDeleteRequest={getOrDeleteRequest}
                                    dataFilterKnowledge={props.dataFilter.knowledge}
                                    setFilterDataReactSelect={setFilterDataReactSelect}
                                    changeDataRSelectMulti={changeDataRSelectMulti}
                                    isDisabled={props.disabled !== undefined ? props.disabled : false}
                                />
                            </div>
                            <div className="w-full flex flex-wrap">
                                <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="language" className="block mb-2 text-white">Idiomas</label>
                                        <Select
                                            name="language"
                                            id="language"
                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                            value={props.dataFilter.language}
                                            options={dataLanguages}
                                            placeholder={"Selecciona una opción"}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            onChange={changeDataRSelect}
                                            isDisabled={props.disabled !== undefined ? props.disabled : false}
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="program" className="block mb-2 text-white">Programa de formación</label>
                                        <Select
                                            name="program"
                                            id="program"
                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                            value={props.dataFilter.program}
                                            options={dataProgramTraining}
                                            placeholder={"Selecciona una opción"}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            onChange={changeDataRSelect}
                                            isDisabled={props.disabled !== undefined ? props.disabled : false}
                                        />
                                    </div>
                                </div>
                                <CampusRSelect
                                    useState={useState}
                                    useEffect={useEffect}
                                    select={Select}
                                    getOrDeleteRequest={getOrDeleteRequest}
                                    dataFilterCampus={props.dataFilter.campus}
                                    setFilterDataReactSelect2={setFilterDataReactSelect2}
                                    changeDataRSelect={changeDataRSelect}
                                    isDisabled={props.disabled !== undefined ? props.disabled : false}
                                />
                            </div>
                            <div className="w-full flex flex-wrap">
                                <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="program_status" className="block mb-2 text-white">Estado de la Formación</label>
                                        <select
                                            name="program_status"
                                            value={props.dataFilter.program_status}
                                            className="text-sm rounded block w-full px-3 py-3 text-gray-500 bg-white"
                                            id="program_status"
                                            onChange={changeData}
                                            disabled={props.disabled !== undefined ? props.disabled : false}
                                        >
                                            {statusProgram
                                                ?
                                                <>
                                                    <option disabled value={0}>Seleccione una opción </option>
                                                    <option value={1}>Aplicado </option>
                                                    <option value={2}>Preseleccionado </option>
                                                    <option value={3}>Seleccionado </option>
                                                    <option value={4}>No completado </option>
                                                    <option value={5}>Completado </option>
                                                    <option value={6}>En proceso </option>
                                                    <option value={7}>No iniciado </option>
                                                </>
                                                :
                                                <option disabled value={'default'}>Seleccione un programa </option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="internship_status" className="block mb-2 text-white">En pasantía</label>
                                        <select
                                            name="internship_status"
                                            value={props.dataFilter.internship_status}
                                            className="text-sm rounded block w-full px-3 py-3 text-gray-500 bg-white"
                                            id="internship_status"
                                            onChange={changeData}
                                            disabled={props.disabled !== undefined ? props.disabled : false}
                                        >
                                            <option disabled value={'default'}>Seleccione una opción </option>
                                            <option value={1}>Sí </option>
                                            <option value={2}>No </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/3 px-2">
                                    <div className="mb-6">
                                        <label htmlFor="status_order" className="block mb-2 text-white">En Formación</label>
                                        <select
                                            name="status_order"
                                            value={props.dataFilter.status_order}
                                            className="text-sm rounded block w-full px-3 py-3 text-gray-500 bg-white"
                                            id="status_order"
                                            onChange={changeData}
                                            disabled={props.disabled !== undefined ? props.disabled : false}
                                        >
                                            <option disabled value={'default'}>Seleccione una opción </option>
                                            <option value={1}>Sí </option>
                                            <option value={0}>No </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </details>
                </div>
            </form>
        </div>
    </>)
});

export default Filters;
