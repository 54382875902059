// import { useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

// import Alumno from "./../../assets/eliezer_cuadrada.png";
// import Discord from "./icons/discord.png"
// import Phone from "./icons/telefono.png"
// import Mail from "./icons/correo.png"
// import Linkedin from "./icons/Linkedin.png"
// import Whatsapp from "./icons/whatsapp.png"
// import Twitter from "./icons/twitter.png"
// import Website from "./icons/website.png"
// import Telegram from "./icons/telegram.png"
// import Facebook from "./icons/facebook.png"
// import Github from "./icons/github.png"
import RobotoRegular from "./Fonts/Roboto-Regular.ttf"
import RobotoBold from "./Fonts/Roboto-Bold.ttf"
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import SectionProfile from '../../layouts/SectionProfile';
import PreloaderComponentSectionProfile from '../Estudiantes/Preloaders/PreloaderComponentSectionProfile';

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular
      },
      {
        src: RobotoBold,
        fontWeight: 'bold'
      },
    ]
  })

const EstructuraCVPDF = ({
    data,
    id,
}) => {

    // const [infoCV, setInfoCV] = useState();
    const [loaded, setLoaded] = useState(false);

    const [experiences, setExperiences] = useState([]);
    const [general, setGeneral] = useState({
        about_me: "",
        age: 0,
        birth_date: "",
        city: "",
        country: "",
        direction: "",
        dui: "",
        email: "",
        lastnames: "",
        names: "",
        phone: "",
        state: "",
    });
    const [knowledge, setKnowledge] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [otherStudies, setOtherStudies] = useState([]);
    const [studies, setStudies] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "black",
            fontSize: 12,
            lineHeight: 1.5,
            padding: 15,
            position: "relative",
            width: "200px", 
            height: "200px", 
            fontFamily: 'Roboto',
            overflow: "hidden",
            paddingBottom: 55,
        },
        container: {
            // flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            // marginBottom: 0,
            // padding: 0,
        },
        section: {
            margin: 15,
            padding: 15,
        },
        date: {
            margin: 15,
            marginTop: 50,
            padding: 15,
            textAlign: "right",
        },
        destinatario: {
            margin: 15,
            marginTop: 20,
            padding: 15,
        },
        parrafo: {
            margin: 15,
            marginTop: 3,
            padding: 15,
            textAlign: "justify"
        },
        atentamente: {
            margin: 15,
            marginTop: 50,
            padding: 15,
        },
        viewer: {
            width: "100%", 
            height: window.innerHeight - 170,            
        },
        columnas:{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            backgroundColor: "#004A98",
            borderTopLeftRadius: 50,
            zIndex: 1,
            height: "100%",
        },
        columnLeft:{
            width: "40%",
            height: "100%",
            display: "flex",
            marginTop: 40,
            paddingBottom: 40,
            backgroundColor: "#004A98",
            borderTopLeftRadius: 50,
            borderBottomRightRadius: 40,
        },
        columnRight:{
            width: "60%",
            height: "100%",
            padding: 40,
            backgroundColor: "white",
            marginTop: 30,
            borderTopLeftRadius: 50, 
        },
        nombreBeneficiario:{
            fontSize: loaded && general.names.length > 14 || loaded && general.lastnames.length > 14 ? 20 : 30,
            textTransform: "uppercase",
            textAlign: "left",
            fontWeight: "bold",
        },
        gradoAcademico:{
            fontSize: 14,
            // fontWeight: "normal",
        },
        imgRounded:{
            width: 200,
            height: 200,
            overflow: "hidden",
            position: "relative",
            borderRadius: "50%", 
            border: "5px solid #FFEC00",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFEC00",
        },
        wave:{
            width: "100px",
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: -55,
            zIndex: 100,
            backgroundColor: "white",
            borderBottomRightRadius: 50,
        },
        waveBlanca:{
            width: "100px",
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            top: -40,
            left: 38,
            zIndex: 100,
            backgroundColor: "white",
        },
        waveAzul:{
            width: "110px",
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            top: 0,
            left: 40,
            zIndex: 100,
            backgroundColor: "#004A98",
            borderTopLeftRadius: 40,
        },
        titulosAmarillos:{
            color: "#FFEC00",   
            fontSize: 10,
            textTransform: "uppercase",
            marginTop: 15,
            fontWeight: "bold",
            textAlign: "left",
        },
        titulosAzulesSubrayados:{
            color: "#004A98",   
            fontSize: 10,
            textDecoration: "underline",
            textDecorationColor: "#FFEC00",
            textTransform: "uppercase",
            marginTop: 15,
            fontWeight: "bold",
            textAlign: "left",
        },
        parrafosBlancos:{
            color: "#fff",   
            fontSize: 9,
            marginTop: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        viewInfoEstudianteLeft:{
            padding: 20,
        },
        burbuja:{
            width: "auto",
            minWidth: "30px",
            marginBottom: "2px",
            marginTop: "2px",
            marginRight: "2px",
            paddingHorizontal: "10px",
            paddingVertical: "6px",
            backgroundColor: "#004A98",
            color: "#fff",
            borderRadius: 5,
            textAlign: "center",
            fontSize: 9,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        contenedorBurbujas:{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
        },
    });

    console.log(window.innerHeight);
    //Obtener dia, mes y año con el formato 00/00/0000
    let date = new Date();
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    // let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    // Obtenemos el mes en español
    let month = date.toLocaleString('es-ES', { month: 'long' });
    month = month.charAt(0).toUpperCase() + month.slice(1);

    let year = date.getFullYear();

    console.log(data, "Data del beneficiario")

    //Cambiar nombre del archivo PDF a descargar
    // let fileName = `Carta de Recomendación - ${props.data.names + " " + props.data.lastnames}.pdf`;

    useEffect(() => {
        getInfoCV(id);
        if(data.photo === null){
            // Alerta sweet alert
            Swal.fire({
                title: "¡Error!",
                text: "Para poder generar el PDF es necesario que el beneficiario tenga una foto de perfil, se le redirigirá a la sección de editar beneficiario para que pueda agregar una foto de perfil.",
                icon: "error",
                button: "Aceptar",
            }).then((result) => {
                if (result.value) {
                    window.location.href = `/EditarBeneficiario/${data.id}`;
                }
                window.location.href = `/EditarBeneficiario/${data.id}`;
            });
        }
    }, [data]);

    async function getInfoCV(e) {
        console.log(e, "ID del beneficiario")
        setLoaded(false);
        const url = `${process.env.REACT_APP_API_URI}student/resume/${e}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            console.log(resp.data.data, "Respuesta de info CV")
            setExperiences(resp.data.data.experiences);
            setGeneral(resp.data.data.general);
            setKnowledge(resp.data.data.knowledges);
            setLanguages(resp.data.data.languages);
            setOtherStudies(resp.data.data.other_studies);
            setStudies(resp.data.data.studies);
            setLoaded(true);
        } catch (err) {
            console.error(err);
        }
    };

    const knokledgeSeparados = knowledge.map(objeto => objeto.name).join(', ');

    return (
        <>
            <SectionProfile
                title="CV"
                button={
                    <button onClick={() => {
                        setShowModal(true);
                    }} className="btn-card-header">
                        ¿Como se conforma el CV?
                    </button>
                }
            >
                {loaded
                ?
                <PDFViewer 
                    style={styles.viewer}
                >
                    <Document>
                        <Page size="letter" style={styles.page}>
                            <View style={styles.columnas}>
                                <View style={styles.columnLeft}>
                                    <View style={{justifyContent: "center", alignItems: "center", position: "relative"}}>
                                        <View style={styles.imgRounded}>
                                            <Image src={`data:${data.photo_type};base64,${data.photo}`} style={{
                                                //Poner imagen dentro de un circulo sin perder la calidad
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "50%",
                                                overflow: "hidden",
                                                objectPosition: "center top",
                                                objectFit: "cover",
                                            }} />
                                        </View>
                                    </View>
                                    <View style={styles.viewInfoEstudianteLeft}>
                                        <Text style={styles.titulosAmarillos}>
                                            Acerca de mi
                                        </Text>
                                        <Text style={styles.parrafosBlancos}>
                                            { loaded && general.about_me === null ? "No proporcionado" : general.about_me }
                                        </Text>




                                        {/* Si */}
                                        <Text style={styles.titulosAmarillos}>
                                            Información personal
                                        </Text>

                                        {/* No */}
                                        {/* <Text style={styles.parrafosBlancos}>
                                            ESTADO CIVIL: Soltero
                                        </Text> */}



                                        {/* Si */}
                                        <Text style={styles.parrafosBlancos}>
                                            {
                                                loaded 
                                                &&
                                                `DUI: ${general.dui === null ? "No proporcionado" : general.dui}`
                                            }
                                        </Text>
                                        <Text style={styles.parrafosBlancos}>
                                            FECHA DE NACIMIENTO:
                                        </Text>
                                        <Text style={styles.parrafosBlancos}>
                                            {
                                                loaded 
                                                &&
                                                general.birth_date !== null ? general.birth_date.split("-").reverse().join("/") : "No proporcionado"
                                            }
                                        </Text>
                                        {
                                            loaded && general.city !== null && general.state !== null
                                            &&
                                            <>
                                                <Text style={styles.parrafosBlancos}>
                                                    RESIDENCIA:
                                                </Text>
                                                <Text style={styles.parrafosBlancos}>
                                                    {
                                                        loaded 
                                                        &&
                                                        `${general.city}, ${general.state}`
                                                    }
                                                </Text>
                                            </>
                                        }
                                        {/* No */}
                                        {/* <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Phone} style={{width: "15px", marginRight: 7}} />
                                            <Text style={styles.parrafosBlancos}>
                                                (503) 7373 9393
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Mail} style={{width: "15px", marginRight: 7}} />
                                            <Link src='mailto:lopez.victor@gmail.com' style={styles.parrafosBlancos}>
                                                lopez.victor@gmail.com
                                            </Link>
                                        </View> */}
                                        {
                                            loaded && languages.length > 0
                                            &&
                                            <>
                                                <Text style={styles.titulosAmarillos}>
                                                    Idiomas
                                                </Text>
                                                {
                                                    languages.map((item, index) => {
                                                        return <Text key={index} style={{color: "white", fontSize: 9}}>
                                                            {item.name}
                                                        </Text>
                                                    })
                                                } 
                                            </>
                                        }
                                        {/* <Text style={styles.titulosAmarillos}>
                                            REDES
                                        </Text>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Whatsapp} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                            <Link src='https://wa.me/73201382' style={styles.parrafosBlancos}>
                                                7320 1382
                                            </Link>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Telegram} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                            <Text style={styles.parrafosBlancos}>
                                                @victorlopez13
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Github} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                            <Text style={styles.parrafosBlancos}>
                                                @victorlopez13
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Facebook} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                            <Text style={styles.parrafosBlancos}>
                                                Victor Lopez
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Twitter} style={{width: "13px", marginRight: 7}} />
                                            <Text style={styles.parrafosBlancos}>
                                                @victorlopez13
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Discord} style={{width: "15px", marginRight: 7}} />
                                            <Text style={styles.parrafosBlancos}>
                                                @victorlopez13
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Linkedin} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                            <Text style={styles.parrafosBlancos}>
                                                @victorlopez13
                                            </Text>
                                        </View>
                                        <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Image src={Website} style={{width: "15px", marginRight: 7, borderRadius: "50%"}} />
                                            <Text style={styles.parrafosBlancos}>
                                                @victorlopez13
                                            </Text>
                                        </View> */}

                                        {/* Si */}
                                        {
                                            <>
                                                <Text style={styles.titulosAmarillos}>
                                                    APTITUDES
                                                </Text>
                                                {
                                                    // data.soft_skills.map((item, index) => {
                                                    //     return(
                                                    <>
                                                        <Text style={{color: "white", fontSize: 9}}>
                                                            Trabajo en equipo
                                                        </Text>
                                                        <Text style={{color: "white", fontSize: 9}}>
                                                            Resolucion de problemas
                                                        </Text>
                                                        <Text style={{color: "white", fontSize: 9}}>
                                                            Manejo del tiempo 
                                                        </Text>
                                                        <Text style={{color: "white", fontSize: 9}}>
                                                            Manejo de estres
                                                        </Text>
                                                    </>
                                                    //     )
                                                    // })
                                                }
                                            </>
                                        } 
                                    </View>
                                </View>
                                <View style={styles.columnRight}>

                                    {/* Si */}
                                    <Text style={styles.nombreBeneficiario}>
                                        {general.names}
                                    </Text>
                                    <Text style={styles.nombreBeneficiario}>
                                        {general.lastnames}
                                    </Text>

                                    {/* No */}
                                    {/* <Text style={styles.gradoAcademico}>
                                        Técnico en Ingeniería en Computación
                                    </Text> */}

                                    {/* Si */}
                                    {
                                        studies.length > 0
                                        &&
                                        <>
                                            <Text style={styles.titulosAzulesSubrayados}>
                                                Formación
                                            </Text>
                                            {studies.map((item, index) => {
                                                // Validamos que solo se muestren los primeros 2 estudios
                                                if(index > 1){
                                                    return;
                                                }

                                                return(
                                                    <>
                                                        <Text style={{
                                                            fontWeight: "bold",
                                                            fontSize: 9,
                                                            marginTop: 5,
                                                        }}>
                                                            {/* {item.study_degree} */}
                                                            {item.institution}
                                                        </Text>
                                                        <Text style={{
                                                            fontSize: 9,
                                                        }}>
                                                            {item.study_degree}
                                                            {/* {item.institution} */}
                                                        </Text>
                                                        <Text style={{
                                                            fontSize: 9,
                                                        }}>
                                                            {item.full_emision_year}
                                                        </Text>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                    {
                                        experiences.length > 0
                                        &&
                                        <>
                                            <Text style={styles.titulosAzulesSubrayados}>
                                                Experiencia
                                            </Text>
                                            {experiences.map((item, index) => {
                                                // Validamos que solo se muestren los primeros 3 trabajos
                                                if (index > 2) {
                                                    return;
                                                }

                                                return(
                                                    <>
                                                        <Text style={{
                                                            fontWeight: "bold",
                                                            fontSize: 9,
                                                            marginTop: 5,
                                                        }}>
                                                            {item.cargo}
                                                        </Text>
                                                        <Text style={{
                                                            fontSize: 9,
                                                        }}>
                                                            {item.nombre_empresa}
                                                        </Text>
                                                        <Text style={{
                                                            fontSize: 9,
                                                        }}>
                                                            {item.full_start_date} - {item.full_end_date}
                                                        </Text>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                    {
                                        otherStudies.length > 0 
                                        &&
                                        <>
                                            <Text style={styles.titulosAzulesSubrayados}>
                                                Otros estudios
                                            </Text>
                                            {otherStudies.map((item, index) => {
                                                // Validamos que solo se muestren los primeros 3 estudios
                                                if (index > 2) {
                                                    return;
                                                }

                                                return(
                                                    <>
                                                        <Text style={{
                                                            fontWeight: "bold",
                                                            fontSize: 9,
                                                            marginTop: 5,
                                                        }}>
                                                            {item.study_degree}
                                                        </Text>
                                                        <Text style={{
                                                            fontSize: 9,
                                                        }}>
                                                            {item.institution}
                                                        </Text>
                                                        <Text style={{
                                                            fontSize: 9,
                                                        }}>
                                                            {item.full_emision_year}
                                                        </Text>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }   
                                    {
                                        knowledge.length > 0
                                        &&
                                        <>
                                            <Text style={styles.titulosAzulesSubrayados}>
                                                Conocimientos
                                            </Text>
                                            <View style={{flexDirection: "column", fontSize: 9}}>
                                                <View style={{ flexDirection: "row", marginBottom: 2 }}>
                                                    <Text>{knokledgeSeparados}.</Text>
                                                </View>
                                            </View>  
                                        </>
                                    } 
                                </View>
                                {/* <View style={styles.wave}></View>
                                <View style={styles.waveBlanca}></View>
                                <View style={styles.waveAzul}></View> */}
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
                :
                <PreloaderComponentSectionProfile filas={9} filas_sm={5} filas_md={5} />}
            </SectionProfile>
            {showModal ? (
              <>
                  <div
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-8"
                  >
                      <div className="relative w-full my-6 mx-auto max-w-3xl">
                          {/*content*/}
                          <div className="w-full border-0 rounded-lg shadow-lg relative flex flex-col  bg-white outline-none focus:outline-none">
                              {/*header*/}
                              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                  <h3 className="text-3xl font-semibold text-blue-950">
                                  ¿Como se conforma el CV?
                                  </h3>
                                  <button
                                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                      onClick={() => setShowModal(false)}
                                  >
                                  </button>
                              </div>
                              {/*body*/}
                              <div className="relative p-6 flex-auto">
                                  <div className="my-4 text-black text-lg leading-relaxed">
                                        <div className="flex flex-wrap">
                                            <div className="w-full md:w-1/2 p-3">
                                                <h3 className='font-bold'>1- Formación:</h3>
                                                <ul>
                                                    <li className='list-disc ml-5'>Ultimas 2 formaciones académicas registradas.</li>
                                                </ul>
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <h3 className='font-bold'>2- Experiencia:</h3>
                                                <ul>
                                                    <li className='list-disc ml-5'>Ultimas 3 experiencias laborales registradas.</li>
                                                </ul>
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <h3 className='font-bold'>3- Otros estudios:</h3>
                                                <ul>
                                                    <li className='list-disc ml-5'>Últimos 3 estudios no formales registrados.</li>
                                                </ul>
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <h3 className='font-bold'>4- Conocimientos:</h3>
                                                <ul>
                                                    <li className='list-disc ml-5'>Todos los conocimientos registrados.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="flex items-center p-6 rounded-b justify-center">
                                            <button
                                                className="text-white bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                type="button"
                                                onClick={() => setShowModal(false)}
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                  </div>
                              </div>
                              {/*footer*/}
                             
                          </div>
                      </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
        </>
      );
}

export default EstructuraCVPDF;