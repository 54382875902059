import React, { ChangeEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { ValidateEmail } from "./validateEmail.ts";

const RecoveryPassword = () => {

  const { token } = useParams();

  const navigate = useNavigate();

  interface alertLogin{
    input?:string,
    message: string | boolean,
    state?:boolean
 }

 const initialStateInput = {
  input: "",
  message: '',
  state: false
}

  const [alert, setAlert] = useState<alertLogin[]>([initialStateInput]);

  interface recoveryPassword{
    password:string,
    password_confirmation:string,
    email:string,
    token:string | undefined
 }

 const initialStateRecoveryPassword = {
  password: "",
  password_confirmation: "",
  email: "",
  token: ""
}

// types
type handleInputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>

  const [RecoveryPassword, SetRecoveryPassword] = useState<recoveryPassword>(initialStateRecoveryPassword)

  const handleInputChange = ({ target: { name, value, id } }: handleInputChange) => {

    //  console.log(name, value);
    SetRecoveryPassword({ ...RecoveryPassword, [name]: value });

 };

  const handleCompaniesSession = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let verifyValues = [
       {
          name: "password",
          value: RecoveryPassword.password
       },
       {
          name: "confirmPassword",
          value: RecoveryPassword.password_confirmation
       },
       {
          name: "gmail",
          value: RecoveryPassword.email
       }
    ];


    const validateInputs = ValidateEmail(verifyValues);

    const state = validateInputs.filter(input => input.state === false).map((s) => { return false });

    //   console.log(validateInputs);
    setAlert(validateInputs);

    // console.log(state.length);
    // console.log("Data", RecoveryPassword);
    if (state.length >= 2) {

       if (RecoveryPassword.password !== RecoveryPassword.password_confirmation) {
          setAlert([{
             input: "password",
             message: 'Las contraseñas no coinciden',
             state: true
          }, {
             input: "confirmPassword",
             message: 'Las contraseñas no coinciden',
             state: true
          }]);
       } else {
          sendData();
       }

    }
 };

 function sendData() {

  const url = `${process.env.REACT_APP_API_URI}auth/reset`;

  let config = {
     headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
     }
  }

  // console.log(RecoveryPassword);
  RecoveryPassword.token = token;
  console.log(RecoveryPassword)
  axios.post(url, RecoveryPassword, config)
     .then(response => {
        console.log(response.data);
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 3500
         }).then(function(){
            SetRecoveryPassword(initialStateRecoveryPassword);
            navigate('/');
         });


     })
     .catch(err => {
        console.log(err.response.data);
        console.log(err);

        Swal.fire({
           position: 'center',
           icon: 'error',
           title: err.response.data.message,
           showConfirmButton: false,
           timer: 3500
        });


     })

  // console.log(url, " " , "no-login", " ", registerFreelancer);

};

  return (

    <section className="text-gray-600 body-font relative bg-white-950">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 ">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-slate-50">Cambiar Contraseña</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-slate-50">Ingrese una contraseña nueva para restablecer su cuenta.</p>
        </div>

        <form className="space-y-4 md:space-y-6 " onSubmit={handleCompaniesSession}>
          <div>
            <label className="block mb-2 text-sm font-medium text-white">Email</label>
            <input type="text" name="email" className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 text-black focus:ring-blue-500 focus:border-blue-500" onChange={handleInputChange} value={RecoveryPassword.email} required />
            {alert.filter(input => input.input == "gmail" && input.state === true).map(message => (
               <span className="text-red-500 text-start">{message.message}</span>
            ))}
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-white"> Nueva Contraseña</label>
            <input type="password" name="password" className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 text-black focus:ring-blue-500 focus:border-blue-500" onChange={handleInputChange} value={RecoveryPassword.password} required />
            {alert.filter(input => input.input == "password" && input.state === true).map(message => (
               <span className="text-red-500 text-start">{message.message}</span>
            ))}
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-white"> Confirmar Contraseña</label>
            <input type="password" name="password_confirmation" placeholder="••••••••" className="bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 text-black focus:border-blue-500" onChange={handleInputChange} value={RecoveryPassword.password_confirmation} required />
            {alert.filter(input => input.input == "confirmPassword" && input.state === true).map(message => (
               <span className="text-red-500 text-start">{message.message}</span>
            ))}
          </div>
          <input type="submit" className="w-full text-white bg-violet-500 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 dark:hover:bg-primary-700 focus:ring-primary-800" value={"Cambiar contraseña"} />
        </form>
      </div>
    </section>
  )
}
export default RecoveryPassword;