import { useEffect, useState, useCallback } from "react";
import { getOrDeleteRequest, validateSession } from "../utils/util";

const ProfileNav = () => {
	const [infoUser, setInfoUser] = useState({});
    const [loaded, setLoaded] = useState(false);

	const getInfoUser = useCallback(async () => {
        let session = validateSession();
        
        let response = await getOrDeleteRequest(
            `auth/profile`, 
            "GET"
        );
        
        if (response.type === "error") {
            return;
        }

        setInfoUser(response.data);
    }, []);

    useEffect(() => {
        if (!loaded) {
            getInfoUser();
            setLoaded(true);
        }
    }, []);

    return (
        <>
            <div className="flex items-center space-x-4">
                <div className="font-medium text-white ml-2 md:ml-0 text-start md:text-end order-2 md:order-1">
                    <div>{infoUser.names} {infoUser.lastnames}</div>
                    <div className="text-sm text-gray-400">Registro académico</div>
                </div>
                <div className="relative inline-flex items-center justify-center order-1 md:order-2">
                    <span className="top-0 right-0 absolute w-3.5 h-3.5 bg-green-400 border-2 border-gray-800 rounded-full"></span>
                    <span className="font-medium flex justify-center items-center w-10 h-10 overflow-hidden rounded-full bg-orange-600 text-white uppercase">
                        {infoUser.names !== undefined && infoUser.names.substring(0, 1)}{infoUser.lastnames !== undefined && infoUser.lastnames.substring(0, 1)}
                    </span>
                </div>
            </div>
        </>
    )
}

export default ProfileNav;
