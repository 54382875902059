import axios from "axios";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Select from 'react-select';
import { differenceInYears, set } from "date-fns";
import ReactInputMask from "react-input-mask";
import BackButton from "../BackButton";
import SectionProfile from "../../layouts/SectionProfile";
import ModalAddConocimiento from "../conocimientos/ModalAddConocimiento";
// import { DesertionLetterLogic } from './DesertionLetterLogic';
import { DesertionLetter } from './DesertionLetter';
import { validateData } from "../utils/validateData";
import ModalCropImage from "./modals/ModalCropImage";
import { showPopup } from "../utils/util";
import { FileUploader } from "react-drag-drop-files";
import { faCloudArrowUp, faFile, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalResizeImage from "./modals/ModalResizeImage";
import { SoftSkillsLogic } from "./SoftSkillsLogic";
import ModalAddSoftSkill from "./modals/ModalAddSoftSkill";
import AddLanguageModal from "../Idiomas/AddLanguageModal";

const AddEstudiante = () => {
    // {  } = DesertionLetterLogic;
    const [showModal, setShowModal] = React.useState(false);
    const [showModalAddConocimientos, setShowModalAddConocimientos] = React.useState(false);
    const [showModalEditar, setShowModalEditar] = React.useState(false);
    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [showModalAddLanguage, setShowModalAddLanguage] = useState(false);

    const initialDatosFormulario = {
        names: "",
        lastnames: '',
        email: '',
        phone: '',
        alternative_phone: "",
        birth_date: '',
        gender: '',
        address: '',
        city_id: '',
        currently_studying: '',
        district: '',
        contact_name: '',
        contact_lastname: '',
        contact_phone: '',
        contact_email: '',
        contact_relationship: '',
        study_level: '',
        currently_working: '',
        family_income: '',
        family_members: '',
        dui: '',
        availability: '',
        nit: '',
        knowledge: [],
        department: 0,
        languages: [],
        soft_skills: [],
        employer: '',
        work_position: '',
        work_area: '',
        work_comment: '',
        document_front: '',
        document_back: '',
        photo: '',
    };

    const initialEstudiante = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const initFileNames = {
        document_front: '',
        document_back: '',
        photo: '',
        urlPhoto: '',
        urlDocumentFront: '',
        urlDocumentBack: '',
    }

    const [formulario, setFormulario] = useState(initialDatosFormulario);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [selectedOptionConocimientos, setSelectedOptionConocimientos] = useState([]);
    const [selectedOptionSoftSkills, setSelectedOptionSoftSkills] = useState([]);
    const [selectedOptionLenguajes, setSelectedOptionLenguajes] = useState([]);
    const [mayorDeEdad, setMayorDeEdad] = useState(false);
    const [showFormContactEmergency, setShowFormContactEmergency] = useState(false);
    const options = [];
    const optionsSoftSkills = [];
    const optionsLanguages = [];

    const [conocimientos, setConocimientos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [distritos, setDistritos] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);
    const [fileNames, setFileNames] = useState(initFileNames);
    const [ModalResizeImageFront, setModalResizeImageFront] = useState(false);
    const [ModalResizeImageBack, setModalResizeImageBack] = useState(false);

    const params = {
        Link: Link
    }

    const { objF, objC } = SoftSkillsLogic(params);

    async function getInfoIdiomas() {
        setLoaded(false)
        const url = `${process.env.REACT_APP_API_URI}language/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setLanguages(resp.data);

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };


    async function getConocimientos() {
        const url = `${process.env.REACT_APP_API_URI}knowledge/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setConocimientos(resp.data)

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    async function getSoftSkills() {
        const url = `${process.env.REACT_APP_API_URI}soft-skill/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setSoftSkills(resp.data)

            console.log(resp.data, "Respuesta")

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    async function getDepartamentos() {
        const url = `${process.env.REACT_APP_API_URI}state/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setDepartamentos(resp.data)

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    async function getDistritos(e) {

        e.preventDefault();

        console.log(e.currentTarget.value)

        const url = `${process.env.REACT_APP_API_URI}district/filter/${e.currentTarget.value}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setDistritos(resp.data)

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };
    async function getMunicipios(e) {

        e.preventDefault();

        console.log(e.currentTarget.value)

        const url = `${process.env.REACT_APP_API_URI}city/filter/${e.currentTarget.value}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setMunicipios(resp.data)

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!loaded) {
            getConocimientos();
            getInfoIdiomas();
            getDepartamentos();
            getSoftSkills();
        }
    }, []);

    let arrayIdesLanguages = [];
    let arrayIdesKnowledges = [];
    let arraySoftSkills = [];

    const ManejarEventoDeInputs = ({ target: { name, value, files } }) => {
        name == 'document_front' || name == 'document_back' || name == 'photo'
        ? 
        addDui(name, files) 
        : 
        setFormulario({ ...formulario, [name]: value });
    };

    const addDui = (name, files) => {
        // console.log(files, "Files")
        // console.log(name, "Name")
        if(files.length !== 0){
            setFormulario({ ...formulario, [name]: files[0] });
            setFileNames({ ...fileNames, [name]: files[0].name });
            console.log(files, "Files")
            return;
        }
        setFormulario({ ...formulario, [name]: ''});
        setFileNames({ ...fileNames, [name]: '' });
        console.log(files, "Files")
        console.log(name, "Name")
    }

    const addEstudiante = (e) => {
        e.preventDefault();

        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        console.log(inputs, "Inputs")

        if (mayorDeEdad) {
            inputs = inputs.filter((input) => input.name !== "nit");
        } else {
            inputs = inputs.filter((input) => input.name !== "dui");
        }

        if (showFormContactEmergency) {
            inputs = inputs.filter((input) => input.name !== "contact_email");
        } else {
            inputs = inputs.filter((input) => input.name !== "contact_name");
            inputs = inputs.filter((input) => input.name !== "contact_lastname");
            inputs = inputs.filter((input) => input.name !== "contact_phone");
            inputs = inputs.filter((input) => input.name !== "contact_relationship");
            inputs = inputs.filter((input) => input.name !== "contact_email");
        }

        console.log(formulario, "Formulario")

        if (formulario.currently_working == 0) {
            inputs = inputs.filter((input) => input.name !== "employer");
            inputs = inputs.filter((input) => input.name !== "work_position");
            inputs = inputs.filter((input) => input.name !== "work_area");
        }

        // if (selectedOptionLenguajes.length > 0) {
        //     inputs = inputs.filter((input) => input.name !== "languages");
        // }

        inputs = inputs.filter((input) => input.name !== "knowledge");
        inputs = inputs.filter((input) => input.name !== "soft_skills");
        inputs = inputs.filter((input) => input.name !== "alternative_phone");
        inputs = inputs.filter((input) => input.name !== "document_front");
        inputs = inputs.filter((input) => input.name !== "document_back");
        inputs = inputs.filter((input) => input.name !== "photo");
        inputs = inputs.filter((input) => input.name !== "gender");
        inputs = inputs.filter((input) => input.name !== "address");
        inputs = inputs.filter((input) => input.name !== "city_id");
        inputs = inputs.filter((input) => input.name !== "district");
        inputs = inputs.filter((input) => input.name !== "department");
        // inputs = inputs.filter((input) => input.name !== "study_level");
        // inputs = inputs.filter((input) => input.name !== "availability");
        // inputs = inputs.filter((input) => input.name !== "currently_studying");
        // inputs = inputs.filter((input) => input.name !== "currently_working");
        // inputs = inputs.filter((input) => input.name !== "family_income");
        // inputs = inputs.filter((input) => input.name !== "family_members");
        // inputs = inputs.filter((input) => input.name !== "employer");
        // inputs = inputs.filter((input) => input.name !== "work_position");
        // inputs = inputs.filter((input) => input.name !== "work_area");
        inputs = inputs.filter((input) => input.name !== "work_salary");
        inputs = inputs.filter((input) => input.name !== "work_comment");
        inputs = inputs.filter((input) => input.name !== "languages");

        console.log("Verificar", inputs)

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = validateData(inputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);

        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.status !== false).map(() => { return false });

        console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length < 1) {
            console.log("Enviar al servidor");
            // selectedOptionConocimientos.map((item) => {
            //     arrayIdesKnowledges.push(item.value)
            // })

            // selectedOptionLenguajes.map((item) => {
            //     arrayIdesLanguages.push(item.value)
            // })

            // selectedOptionSoftSkills.map((item) => {
            //     arraySoftSkills.push(item.value)
            // })
            RegistrarEstudiante();
        } else {
            // Hacemos focus en el primer input que contiene errores
            let firstInputError = datosValidados.filter(input => input.status === true)[0];
            console.log(firstInputError, "Primer input con error");
            let inputError = document.getElementById('add-student-register').querySelector(`[name=${firstInputError.inputName}]`);
            // console.log(inputError, "Input con error");
            if(inputError !== undefined && inputError !== null){
                inputError.focus();
            }
        }
    };

    function RegistrarEstudiante() {

        const url = `${process.env.REACT_APP_API_URI}student/register`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        let dataInsertar = {
            names: formulario.names,
            lastnames: formulario.lastnames,
            email: formulario.email,
            phone: formulario.phone,
            alternative_phone: formulario.alternative_phone,
            birth_date: formulario.birth_date,
            // gender: formulario.gender,
            // address: formulario.address,
            // city: parseInt(formulario.city_id),
            time_availability: formulario.availability,
            // district: formulario.district,
            currently_studying: formulario.currently_studying,
            study_level: formulario.study_level,
            currently_working: formulario.currently_working,
            family_income: formulario.family_income,
            family_members: formulario.family_members,
            // knowledge: arrayIdesKnowledges,
            // languages: arrayIdesLanguages,
            // soft_skills: arraySoftSkills,
            // letter: fileCarta,
            authorization_image: authorization ? 1 : 0,
            work_comment: formulario.work_comment,
        };

        if (showFormContactEmergency) {
            dataInsertar.contact_name = formulario.contact_name;
            dataInsertar.contact_lastname = formulario.contact_lastname;
            dataInsertar.contact_phone = formulario.contact_phone;
            dataInsertar.contact_relationship = formulario.contact_relationship;
        }

        if (formulario.contact_email !== '') {
            dataInsertar.contact_email = formulario.contact_email;
        }

        if (mayorDeEdad) {
            dataInsertar.dui = formulario.dui;
        } else {
            dataInsertar.nit = formulario.nit;
        }

        if (formulario.currently_working == 1) {
            dataInsertar.employer = formulario.employer;
            dataInsertar.work_position = formulario.work_position;
            dataInsertar.work_area = formulario.work_area;
            dataInsertar.work_salary = formulario.work_salary;
        }

        if(formulario.document_front !== ''){
            dataInsertar.document_front = formulario.document_front;
        }

        if(formulario.document_back !== ''){
            dataInsertar.document_back = formulario.document_back;
        }

        if(formulario.photo !== ''){
            dataInsertar.photo = formulario.photo;
        }

        // let sendInsertData = new FormData();

        // for (let key in dataInsertar) {
        //     sendInsertData.append(key, dataInsertar[key]);
        //     console.log(key, ': ', dataInsertar[key]);
        // }

        console.log(dataInsertar, "DataInsertar");
        // return;

        axios.post(url, dataInsertar, config)
            .then(response => {
                console.log(response, "response");
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    // navigate("/Beneficiarios");
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    function isNumeric(input) {
        return !isNaN(input) && !isNaN(parseFloat(input));
    }

    function esMayorDeEdad(fechaNacimiento) {
        const fechaActual = new Date();
        const [anio, mes, dia] = fechaNacimiento.split('-');
        const fechaNacimientoObj = new Date(anio, mes - 1, dia);

        const edad = differenceInYears(fechaActual, fechaNacimientoObj);

        return edad >= 18;
    }

    if (conocimientos.length > 0) {
        conocimientos.map((item) => {
            options.push({ value: item.id, label: item.name })
        })
    }

    if (languages.length > 0) {
        languages.map((item) => {
            optionsLanguages.push({ value: item.id, label: item.name })
        })
    }

    if (softSkills.length > 0) {
        softSkills.map((item) => {
            optionsSoftSkills.push({ value: item.id, label: item.skill_name })
        })
    }

    // Mostrar el nombre del archivo que se subio en el input file
    const [fileNameFoto, setFileNameFoto] = useState('');
    const [fileFoto, setFileFoto] = useState(null);

    const [fileDuiFront, setFileDuiFront] = useState(null);
    const [fileDuiFrontName, setfileDuiFrontName] = useState('');
    const [fileDuiBack, setFileDuiBack] = useState(null);
    const [fileDuiBackName, setfileDuiBackName] = useState('');
    
    const [authorization, setAuthorization] = useState(false);

    const handleFileFoto = (e) => {
        console.log(e.target.files);
        console.log(e.target.files.length);
        if(e.target.files.length != 0){
            setFileFoto(e.target.files[0]);
            setFileNameFoto(e.target.files[0].name);
            return;
        }
        setFileFoto(null);
        setFileNameFoto('');
    }

    const vaciarFileFoto = () => {
        setFileFoto(null);
        setFileNameFoto("");
        setFileNames({ ...fileNames, photo: '', urlPhoto: '' });
        setFormulario({ ...formulario, photo: '' });
    }

    const handleFileFotoDuiFront = (e) => {
        setFileDuiFront(e);
        setfileDuiFrontName(e.name);
        setFormulario({ ...formulario, document_front: e });
        setFileNames({ ...fileNames, document_front: e.name, urlDocumentFront: URL.createObjectURL(e) });
        showPopup(true, setModalResizeImageFront);
    }

    const handleFileFotoDuiBack = (e) => {
        setFileDuiBack(e);
        setfileDuiBackName(e.name);
        setFormulario({ ...formulario, document_back: e });
        setFileNames({ ...fileNames, document_back: e.name, urlDocumentBack: URL.createObjectURL(e) });
        showPopup(true, setModalResizeImageBack);
    }

    const [fileNameCarta, setFileNameCarta] = useState('');
    const [fileCarta, setFileCarta] = useState(null);
    const [fileCartaError, setFileCartaError] = useState(false);

    const handleFileCarta = (e) => {
        setFileCarta(e);
        setFileNameCarta(e.name);
        setFormulario({ ...formulario, letter: e });
        setFileCartaError(false);
    }

    const handleSwitchContactInfo = (e) => {
        if (e.target.checked) {
            setShowFormContactEmergency(true);
        } else {
            setShowFormContactEmergency(false);
        }
    }

    const [showModalCropImage, setShowModalCropImage] = useState(false);

    const fileTypes = ["JPG", "PNG", "JPEG"];
    const fileTypesCarta = ["PDF", "DOCX", "DOC", "ODT", "JPG", "PNG", "JPEG"];

    const handleChange = (e) => {

        console.log(e, "Evento")

        //Verificar si el archivo es una imagen y que la imagen sea mayor a 1000x1000
        if (e.type === "image/jpeg" || e.type === "image/png" || e.type === "image/jpg") {
            //Verificar que el ancho y alto de la imagen sea mayor a 1000x1000 
            const image = new Image();
            image.src = URL.createObjectURL(e);
            image.onload = function () {
                if (this.width >= 1000 && this.height >= 1000) {
                    setFormulario({ ...formulario, photo: e });
                    setFileNames({ ...fileNames, urlPhoto: URL.createObjectURL(e), photo: e.name });
                    showPopup(true, setShowModalCropImage);
                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'La imagen debe ser mayor a 1000x1000',
                        showConfirmButton: false,
                        timer: 3500
                    });

                    setFormulario({ ...formulario, photo: '' });
                    setFileNames({ ...fileNames, photo: '', urlPhoto: '' });
                }
            }
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'El archivo debe ser una imagen',
                showConfirmButton: false,
                timer: 3500
            });
        }

        console.log(formulario, "Formulario")
        console.log(fileNames, "FileNames")
    };

    const vaciarFileFotoFrontal = () => {
        setFileDuiFront(null);
        setfileDuiFrontName("");
        setFormulario({ ...formulario, document_front: '' });
        setFileNames({ ...fileNames, document_front: '', urlDocumentFront: '' });
    }
        
    const vaciarFileFotoTrasera = () => {
        setFileDuiBack(null);
        setfileDuiBackName("");
        setFormulario({ ...formulario, document_back: '' });
        setFileNames({ ...fileNames, document_back: '', urlDocumentBack: '' });
    }

    return (

        <div className=''>

            <div className='ContainerOpenClass'>

                <div className=" p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="flex justify-center">
                            <h1 className="text-xl sm:text-2xl font-semibold text-white">Agregar beneficiario</h1>
                        </div>
                        <form id="add-student-register" onSubmit={addEstudiante} className="flex flex-wrap rounded-lg mt-5" encType="multipart/form-data">
                            <div className="flex items-center w-full md:w-1/4 justify-start md:px-10">
                                <BackButton
                                    history={Cookies.get('prevLocation')}
                                />
                            </div>
                            <div className="w-full text-center p-1">
                                <p className="mb-3 tex-sm text-white">Los campos marcados con un asterisco (<span className="text-red-600">*</span>) son obligatorios.</p>
                            </div>
                            <SectionProfile title="Información personal">
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Nombres<span className="text-red-600">&nbsp;*</span></label>
                                            <input value={formulario.names} type="text" name="names" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" onChange={ManejarEventoDeInputs} />
                                            {
                                                alerta.filter(input => input.inputName == "names" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Apellidos<span className="text-red-600">&nbsp;*</span></label>
                                            <input value={formulario.lastnames} onChange={ManejarEventoDeInputs} type="text" name="lastnames" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                            {
                                                alerta.filter(input => input.inputName == "lastnames" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Fecha de nacimiento<span className="text-red-600">&nbsp;*</span></label>
                                            <input value={formulario.birth_date} type="date" name="birth_date" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" onChange={function (e) {
                                                if (esMayorDeEdad(e.currentTarget.value)) {
                                                    setMayorDeEdad(true)
                                                } else {
                                                    setMayorDeEdad(false)
                                                }
                                                ManejarEventoDeInputs(e)
                                            }} />
                                            {
                                                alerta.filter(input => input.inputName == "birth_date" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="w-full sm:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Género<span className="text-red-600">&nbsp;*</span></label>
                                            <select onChange={ManejarEventoDeInputs} name="gender" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                <option disabled selected value="null">[Seleccione una opción]</option>
                                                <option value="1">Masculino</option>
                                                <option value="2">Femenino</option>
                                                <option value="3">No especificado</option>
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "gender" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div> */}
                                    {
                                        mayorDeEdad == true
                                            ?
                                            <div className="w-full sm:w-1/2 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">DUI<span className="text-red-600">&nbsp;*</span></label>
                                                    <ReactInputMask
                                                        mask={"99999999-9"}
                                                        id="dui"
                                                        className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                        name="dui"
                                                        type="text"
                                                        autoComplete="nope"
                                                        value={formulario.dui} onChange={ManejarEventoDeInputs}
                                                    />
                                                    {
                                                        alerta.filter(input => input.inputName == "dui" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="w-full sm:w-1/2 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">NIT<span className="text-red-600">&nbsp;*</span></label>
                                                    <ReactInputMask
                                                        mask={"9999-999999-999-9"}
                                                        id="nit"
                                                        className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                        name="nit"
                                                        type="text"
                                                        autoComplete="nope"
                                                        value={formulario.nit} onChange={ManejarEventoDeInputs}
                                                    />
                                                    {
                                                        alerta.filter(input => input.inputName == "nit" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div>
                                    }
                                    {/* <div className="w-full p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Dirección<span className="text-red-600">&nbsp;*</span></label>
                                            <input value={formulario.address} onChange={ManejarEventoDeInputs} type="text" name="address" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                            {
                                                alerta.filter(input => input.inputName == "address" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div> */}
                                    {/* <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Departamento<span className="text-red-600">&nbsp;*</span></label>
                                            <select onChange={
                                                (e) => {
                                                    ManejarEventoDeInputs(e);
                                                    getMunicipios(e)
                                                }
                                            } name="department" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                <option disabled selected value="0">[Seleccione una opción]</option>
                                                {
                                                    departamentos.map((item) =>
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "department" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Municipio<span className="text-red-600">&nbsp;*</span></label>
                                            <select
                                                name="city_id"
                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                disabled={municipios.length == 0 ? true : false}
                                                onChange={
                                                    (e) => {
                                                        setFormulario({
                                                            ...formulario,
                                                            city_id: e.target.value
                                                        })
                                                        getDistritos(e)
                                                    }
                                                }
                                            >
                                                <option disabled selected value="0">[Seleccione una opción]</option>
                                                {
                                                    municipios.map((item) =>
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "city_id" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Distrito<span className="text-red-600">&nbsp;*</span></label>
                                            <select
                                                name="district"
                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                disabled={distritos.length == 0 ? true : false}
                                                onChange={ManejarEventoDeInputs}
                                            >
                                                <option disabled selected value="0">[Seleccione una opción]</option>
                                                {
                                                    distritos.map((item, index) =>
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "district" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </SectionProfile>
                            <SectionProfile title="Foto de identificación">
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Foto {fileNames.photo !== "" && <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                () => {
                                                    vaciarFileFoto();
                                                }
                                            } />}</label>
                                            <div class="flex items-center justify-center w-full">
                                            <FileUploader children={
                                                <label for="photo" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                    <div class="flex flex-col items-center justify-center p-5">
                                                        {
                                                            fileNames.photo === ''
                                                                ?
                                                                <>
                                                                    <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faCloudArrowUp} />
                                                                    <p class="mb-2 text-sm text-gray-500 text-center"><span class="font-semibold">Arrastra o haz clic aquí<br />para subir un archivo</span></p>
                                                                    <p class="text-xs text-center text-gray-500">
                                                                        JPG/JPEG o PNG
                                                                        <ul>
                                                                            <li>Peso: 300KB max</li>
                                                                            <li>Dimensiones mínimas: 1000x1000</li>
                                                                        </ul>
                                                                    </p>
                                                                </>
                                                                :
                                                                <p class="mb-2 text-sm text-gray-500 p-5 text-center">
                                                                    <center><img src={fileNames.urlPhoto} className="w-1/2 xl:w-1/4 mb-5" alt="" /></center>
                                                                    <span class="font-semibold">
                                                                        Nombre del archivo:
                                                                        <br />
                                                                        <span className="text-blue-cnx">{
                                                                        fileNames.photo.length > 20
                                                                            ?
                                                                            fileNames.photo.substring(0, 20) + "..."
                                                                            :
                                                                            fileNames.photo
                                                                        }</span>
                                                                    </span>
                                                                </p>
                                                        }
                                                    </div>
                                                </label>
                                            } onTypeError={
                                                () => {
                                                    MySwal.fire({
                                                        position: 'center',
                                                        icon: 'error',
                                                        title: 'El archivo debe ser una imagen',
                                                        showConfirmButton: false,
                                                        timer: 3500
                                                    });
                                                }
                                            } classes="w-full" hoverTitle={"Soltar aquí"} handleChange={handleChange} name="photo" types={fileTypes} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Carta de compromiso {fileNameCarta !== "" && <FontAwesomeIcon className="text-red-500 cursor-pointer" onClick={
                                                () => {
                                                    setFileCarta(null);
                                                    setFileNameCarta("");
                                                    setFileCartaError(false);
                                                    setFormulario({ ...formulario, letter: '' });
                                                }
                                            } icon={faTrash} />}</label>
                                            <div class="flex items-center justify-center w-full">
                                            <FileUploader children={
                                                <label for="letter" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                    <div class="flex flex-col items-center justify-center p-5">
                                                        {
                                                            fileNameCarta === ''
                                                                ?
                                                                <>
                                                                    <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faCloudArrowUp} />
                                                                    <p class="text-sm text-gray-500 text-center"><span class="font-semibold">Arrastra o haz clic aquí<br />para subir un archivo</span></p>
                                                                    <p class="text-xs p-5 text-gray-500">PDF, DOC, DOCX, ODT, JPG, JPEG o PNG (Peso: 5MB max)</p>
                                                                </>
                                                                :
                                                                <>
                                                                    <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faFile} />
                                                                    <p class="mb-2 text-sm text-gray-500 p-5">
                                                                        <span class="font-semibold">
                                                                            Nombre del archivo:
                                                                            <br />
                                                                            <span className="text-blue-cnx">{
                                                                            fileNameCarta.length > 20
                                                                                ?
                                                                                fileNameCarta.substring(0, 20) + "..."
                                                                                :
                                                                                fileNameCarta
                                                                            }</span>
                                                                        </span>
                                                                    </p>
                                                                </>
                                                        }
                                                    </div>
                                                </label>
                                            } onTypeError={
                                                () => {
                                                    MySwal.fire({
                                                        position: 'center',
                                                        icon: 'error',
                                                        title: 'Solo se permiten archivos PDF, DOC, DOCX, ODT, JPG, JPEG o PNG',
                                                        showConfirmButton: false,
                                                        timer: 3500
                                                    });
                                                }
                                            } classes="w-full" hoverTitle={"Soltar aquí"} handleChange={handleFileCarta} name="letter" types={fileTypesCarta} />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </SectionProfile>
                            <SectionProfile title="Información de contacto">
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Correo electrónico<span className="text-red-600">&nbsp;*</span></label>
                                            <input value={formulario.email} onChange={ManejarEventoDeInputs} type="text" name="email" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                            {
                                                alerta.filter(input => input.inputName == "email" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Teléfono<span className="text-red-600">&nbsp;*</span></label>
                                            <ReactInputMask
                                                mask={"9999-9999"}
                                                id="phone"
                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                name="phone"
                                                type="text"
                                                autoComplete="nope"
                                                value={formulario.phone} onChange={ManejarEventoDeInputs}
                                            />
                                            {
                                                alerta.filter(input => input.inputName == "phone" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Teléfono alternativo</label>
                                            <ReactInputMask
                                                mask={"9999-9999"}
                                                id="alternative_phone"
                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                name="alternative_phone"
                                                type="text"
                                                autoComplete="nope"
                                                value={formulario.alternative_phone} onChange={ManejarEventoDeInputs}
                                            />
                                            {
                                                alerta.filter(input => input.inputName == "alternative_phone" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </SectionProfile> 
                            <SectionProfile title="Imagen del documento">
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Frontal {fileNames.document_front !== "" && <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                () => {
                                                    setFileDuiFront(null);
                                                    setfileDuiFrontName("");
                                                    setFormulario({ ...formulario, document_front: '' });
                                                    setFileNames({ ...fileNames, document_front: '', urlDocumentFront: '' });
                                                }
                                            } />}</label>
                                            <div class="flex items-center justify-center w-full">
                                            <FileUploader children={
                                                <label for="document_front" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                    <div class="flex flex-col items-center justify-center p-5">
                                                        {fileNames.document_front === ''
                                                            ? <>
                                                                <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faCloudArrowUp} />
                                                                <p class="mb-2 text-sm text-gray-500 text-center"><span class="font-semibold">Arrastra o haz clic aquí para<br />subir el frontal del documento</span></p>
                                                                <p class="text-xs text-gray-500">
                                                                    JPG/JPEG o PNG
                                                                    <ul>
                                                                        <li>Resolución: 1000x1000</li>
                                                                        <li>Peso: 300KB max</li>
                                                                    </ul>
                                                                </p>
                                                            </>
                                                            : 
                                                            <>
                                                                <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faFile} />
                                                                <p class="mb-2 text-sm text-gray-500 p-5 text-center">
                                                                    {/* <center><img src={fileNames.urlDocumentFront} className="w-1/2 mb-5" alt="" /></center> */}
                                                                    <span class="font-semibold text-center"> Nombre del archivo: <br /> 
                                                                        <span className="text-blue-cnx">{
                                                                            fileNames.document_front.length > 20
                                                                                ?
                                                                                fileNames.document_front.substring(0, 20) + "..."
                                                                                :
                                                                                fileNames.document_front
                                                                        }</span> 
                                                                    </span>
                                                                </p>
                                                            </>
                                                        }
                                                    </div>
                                                </label>
                                            } onTypeError={
                                                () => {
                                                    MySwal.fire({
                                                        position: 'center',
                                                        icon: 'error',
                                                        title: 'El archivo debe ser una imagen',
                                                        showConfirmButton: false,
                                                        timer: 3500
                                                    });
                                                }
                                            } classes="w-full" hoverTitle={"Soltar aquí"} handleChange={handleFileFotoDuiFront} name="document_front" types={fileTypes} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Reverso {fileNames.document_back !== "" && <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                () => {
                                                    setFileDuiBack(null);
                                                    setfileDuiBackName("");
                                                    setFormulario({ ...formulario, document_back: '' });
                                                    setFileNames({ ...fileNames, document_back: '', urlDocumentBack: '' });
                                                }
                                            } />}</label>
                                            <div class="flex items-center justify-center w-full">
                                            <FileUploader children={
                                                <label for="document_back" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                    <div class="flex flex-col items-center justify-center p-5">
                                                        {fileNames.document_back === ''
                                                            ? <>
                                                                <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faCloudArrowUp} />
                                                                <p class="mb-2 text-sm text-gray-500 text-center"><span class="font-semibold">Arrastra o haz clic aquí para<br />subir el reverso del documento</span></p>
                                                                <p class="text-xs text-gray-500">
                                                                    JPG/JPEG o PNG
                                                                    <ul>
                                                                        <li>Resolución: 1000x1000</li>
                                                                        <li>Peso: 300KB max</li>
                                                                    </ul>
                                                                </p>
                                                            </>
                                                            : 
                                                            <>
                                                                <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={faFile} />
                                                                <p class="mb-2 text-sm text-gray-500 p-5 text-center">
                                                                    {/* <center><img src={fileNames.urlDocumentBack} className="w-1/2 mb-5" alt="" /></center> */}
                                                                    <span class="font-semibold"> Nombre del archivo: <br /> <span className="text-blue-cnx">{
                                                                        fileNames.document_back.length > 20
                                                                            ?
                                                                            fileNames.document_back.substring(0, 20) + "..."
                                                                            :
                                                                            fileNames.document_back
                                                                    } </span>
                                                                    </span>
                                                                </p>
                                                            </>
                                                        }
                                                    </div>
                                                    <input
                                                        type="file"
                                                        class="hidden"
                                                        id="document_back"
                                                        name="document_back"
                                                        // value={formulario.document_back}
                                                        onChange={(e) => { ManejarEventoDeInputs(e) }} 
                                                    />
                                                </label>
                                            } onTypeError={
                                                () => {
                                                    MySwal.fire({
                                                        position: 'center',
                                                        icon: 'error',
                                                        title: 'El archivo debe ser una imagen',
                                                        showConfirmButton: false,
                                                        timer: 3500
                                                    });
                                                }
                                            } classes="w-full" hoverTitle={"Soltar aquí"} handleChange={handleFileFotoDuiBack} name="document_back" types={fileTypes} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SectionProfile>
                            <SectionProfile title="Situación economica y laboral actual">
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Último grado académico obtenido<span className="text-red-600">&nbsp;*</span></label>
                                            <select onChange={ManejarEventoDeInputs} name="study_level" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                <option value="1">Educación Básica</option>
                                                <option value="2">Bachillerato</option>
                                                <option value="3">Técnico</option>
                                                <option value="4">Universidad</option>
                                                <option value="5">Maestría</option>
                                                <option value="6">Doctorado</option>
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "study_level" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">¿Estudia actualmente?<span className="text-red-600">&nbsp;*</span></label>
                                            <select onChange={ManejarEventoDeInputs} name="currently_studying" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                <option value="1">Si</option>
                                                <option value="0">No</option>
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "currently_studying" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">¿Disponibilidad de tiempo?<span className="text-red-600">&nbsp;*</span></label>
                                            <select
                                                name="availability"
                                                onChange={ManejarEventoDeInputs}
                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                <option value="1">Completo</option>
                                                <option value="6">Lunes a viernes</option>
                                                <option value="2">Medio tiempo por la mañana</option>
                                                <option value="3">Medio tiempo por la tarde</option>
                                                <option value="4">Sábado y domingo</option>
                                                <option value="5">Sin disponibilidad</option>
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "availability" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">¿Trabaja actualmente?<span className="text-red-600">&nbsp;*</span></label>
                                            <select name="currently_working" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" onChange={(e) => {
                                                setFormulario({
                                                    ...formulario,
                                                    [e.target.name]: e.target.value,
                                                });
                                                ManejarEventoDeInputs(e)
                                            }} >
                                                <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                <option value="1">Si</option>
                                                <option value="0">No</option>
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "currently_working" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>

                                    <div className="w-full md:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Empleador<span className={`text-red-600 ${formulario.currently_working == 1 ? 'inline' : 'hidden'}`}>&nbsp;*</span></label>
                                            <input type="text" name="employer" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true}
                                                onChange={ManejarEventoDeInputs}
                                            />
                                            {
                                                formulario.currently_working == 1 && alerta.filter(input => input.inputName == "employer" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Posición laboral<span className={`text-red-600 ${formulario.currently_working == 1 ? 'inline' : 'hidden'}`}>&nbsp;*</span></label>
                                            <input type="text" name="work_position" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true}
                                                value={formulario.work_position} onChange={ManejarEventoDeInputs}
                                            />
                                            {
                                                formulario.currently_working == 1 && alerta.filter(input => input.inputName == "work_position" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Área laboral<span className={`text-red-600 ${formulario.currently_working == 1 ? 'inline' : 'hidden'}`}>&nbsp;*</span></label>
                                            <input type="text" name="work_area" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true}
                                                value={formulario.work_area} onChange={ManejarEventoDeInputs}
                                            />
                                            {
                                                formulario.currently_working == 1 && alerta.filter(input => input.inputName == "work_area" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Salario</label>
                                            <input type="text" name="work_salary" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true}
                                                value={formulario.work_salary} onChange={
                                                    (e) => {
                                                        // Verificar que el valor ingresado sea un número y que no sea negativo
                                                        if (isNaN(e.target.value) || e.target.value < 0) {
                                                            setFormulario({
                                                                ...formulario,
                                                                [e.target.name]: 1
                                                            })
                                                        } else {
                                                            setFormulario({
                                                                ...formulario,
                                                                [e.target.name]: e.target.value
                                                            })
                                                        }
                                                    }
                                                }
                                            />
                                            {/* {
                                                formulario.currently_working == 1 && alerta.filter(input => input.inputName == "work_salary" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            } */}
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Ingreso familiar<span className="text-red-600">&nbsp;*</span></label>
                                            <select name="family_income" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                onChange={ManejarEventoDeInputs}
                                            >
                                                <option hidden disabled selected value="0">[Seleccione una opción]</option>
                                                <option value="1">Menos de $300</option>
                                                <option value="2">$300 - $500</option>
                                                <option value="3">$500 - $1,000</option>
                                                <option value="4">$1,000 - $1,500</option>
                                                <option value="5">Mas de $1,500</option>
                                            </select>
                                            {
                                                alerta.filter(input => input.inputName == "family_income" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-1/3 p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Miembros familiares<span className="text-red-600">&nbsp;*</span></label>
                                            <input type="text" name="family_members" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                value={formulario.family_members} onChange={
                                                    (e) => {
                                                        // Verificar que el valor ingresado sea un número y que no sea negativo
                                                        if (isNaN(e.target.value) || e.target.value < 0) {
                                                            setFormulario({
                                                                ...formulario,
                                                                [e.target.name]: 1
                                                            })
                                                        } else {
                                                            setFormulario({
                                                                ...formulario,
                                                                [e.target.name]: e.target.value
                                                            })
                                                        }
                                                    }
                                                }
                                            />
                                            {
                                                alerta.filter(input => input.inputName == "family_members" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Observaciones o comentarios adicionales</label>
                                            <textarea name="work_comment" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 h-32 resize-none"
                                                onChange={ManejarEventoDeInputs}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </SectionProfile>
                            <SectionProfile title="Contacto de emergencia" button={
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" onChange={handleSwitchContactInfo} class="sr-only peer"
                                        value={formulario.emergency_contact} name="emergency_contact"
                                    />
                                    <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                    {/* <span class="ml-3 text-sm font-medium text-black">Mostrar</span> */}
                                </label>}>
                                <div className="p-5 w-full flex flex-wrap">
                                    {
                                        showFormContactEmergency
                                            ?
                                            <>
                                                <div className="w-full sm:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Nombres<span className="text-red-600">&nbsp;*</span></label>
                                                        <input type="text" name="contact_name" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            value={formulario.contact_name} onChange={ManejarEventoDeInputs}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "contact_name" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Apellidos<span className="text-red-600">&nbsp;*</span></label>
                                                        <input type="text" name="contact_lastname" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            value={formulario.contact_lastname} onChange={ManejarEventoDeInputs}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "contact_lastname" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Teléfono de contacto<span className="text-red-600">&nbsp;*</span></label>
                                                        <ReactInputMask
                                                            mask={"9999-9999"}
                                                            id="contact_phone"
                                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            name="contact_phone"
                                                            type="text"
                                                            autoComplete="nope"
                                                            value={formulario.contact_phone} onChange={ManejarEventoDeInputs}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "contact_phone" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Correo electrónico</label>
                                                        <input type="text" name="contact_email" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            value={formulario.contact_email} onChange={ManejarEventoDeInputs}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "contact_email" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Relación<span className="text-red-600">&nbsp;*</span></label>
                                                        <input type="text" name="contact_relationship" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            value={formulario.contact_relationship} onChange={ManejarEventoDeInputs}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "contact_relationship" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className="w-full text-center">
                                                <p className="text-sm font-medium text-black">
                                                    <span className="text-red-600">*</span> Para mostrar el formulario de contacto de emergencia, active el interruptor.
                                                </p>
                                            </div>
                                    }
                                </div>
                            </SectionProfile>
                            {/* <SectionProfile title="Idiomas" button={
                                <button
                                    onClick={() => {
                                        setShowModalAddLanguage(true)
                                    }}
                                    type="button"
                                    className="btn-card-header">
                                    Agregar idioma
                                </button>
                            
                            }>
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Idiomas<span className="text-red-600">&nbsp;*</span></label>
                                            <Select
                                                isMulti
                                                value={selectedOptionLenguajes}
                                                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                onChange={setSelectedOptionLenguajes}
                                                options={optionsLanguages}
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                                name="languages"
                                            />
                                            {
                                                alerta.filter(input => input.inputName == "languages" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </SectionProfile>
                            <SectionProfile title="Conocimientos" button={<button
                                onClick={() => {
                                    setShowModalAddConocimientos(true)
                                }}
                                type="button"
                                className="btn-card-header">
                                Agregar conocimiento
                            </button>}>
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Conocimientos</label>
                                            <Select
                                                value={selectedOptionConocimientos}
                                                isMulti
                                                name="knowledge"
                                                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                onChange={setSelectedOptionConocimientos}
                                                options={options}
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                            />
                                            {
                                                alerta.filter(input => input.inputName == "knowledge" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </SectionProfile>
                            {
                                showModalAddConocimientos
                                    ? <ModalAddConocimiento show={setShowModalAddConocimientos} function={getConocimientos} />
                                    :
                                    null
                            }
                            <SectionProfile title="Habilidades blandas" button={
                                <button
                                    onClick={() => {
                                        objF.setShowModalAddSoftSkill(true);
                                        objF.setTypeModal("add");
                                    }}
                                    type="button"
                                    className="btn-card-header">
                                    Agregar habilidad
                                </button>
                            
                            }>
                                <div className="p-5 w-full flex flex-wrap">
                                    <div className="w-full p-1">
                                        <div className="mb-6">
                                            <label className="block mb-2 text-sm font-medium text-black">Habilidades blandas</label>
                                            <Select
                                                value={selectedOptionSoftSkills}
                                                isMulti
                                                name="soft_skills"
                                                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                onChange={setSelectedOptionSoftSkills}
                                                options={optionsSoftSkills}
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                            />
                                            {
                                                alerta.filter(input => input.inputName == "soft_skills" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </SectionProfile> */}
                            <SectionProfile title="Uso de información e imagen">
                                <div className="w-full">
                                    {
                                        authorization
                                        ?
                                        <h2 className="p-5 pb-0">Autorizo a CONEXION para que utilice mi información personal y mi imagen para fines de difusión, publicidad y promoción de sus programas y servicios.</h2>
                                        :
                                        <h2 className="p-5 pb-0">No autorizo a CONEXION para que utilice mi información personal y mi imagen para fines de difusión, publicidad y promoción de sus programas y servicios.</h2>
                                    }
                                </div>
                                <div className="w-full flex justify-center p-5">
                                    <label class="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" onChange={
                                            (e) => {
                                                setAuthorization(e.target.checked)
                                            }
                                        } class="sr-only peer" />
                                        <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                        <span class="ml-3 text-sm font-medium text-black">
                                            {
                                                authorization
                                                ?
                                                <>
                                                    Si
                                                </>
                                                :
                                                <>
                                                    No
                                                </>
                                            }
                                        </span>
                                    </label>
                                </div>
                            </SectionProfile>
                            <div className="w-full text-center">
                                <input
                                    className="bg-blue-cnx hover:bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all cursor-pointer"
                                    type="submit"
                                    value="Registrar"
                                />
                            </div>
                        </form>
                        {
                            showModalCropImage
                            &&
                            <ModalCropImage 
                                img={formulario.photo}
                                setShowModalCropImage={setShowModalCropImage}
                                showModalCropImage={showModalCropImage}
                                funVaciarFoto={vaciarFileFotoFrontal}
                                showPopup={showPopup}
                                setFormulario={setFormulario}
                                setFileNames={setFileNames}
                                formulario={formulario}
                                fileNames={fileNames}
                            />
                        }
                        {
                            ModalResizeImageFront
                            &&
                            <ModalResizeImage
                                img={formulario.document_front}
                                setShowModalResizeImage={setModalResizeImageFront}
                                showModalResizeImage={ModalResizeImageFront}
                                funVaciarFoto={vaciarFileFotoFrontal}
                                showPopup={showPopup}
                                setFormulario={setFormulario}
                                setFileNames={setFileNames}
                                formulario={formulario}
                                fileNames={fileNames}
                                name={"document_front"}
                            />
                        }
                        {
                            ModalResizeImageBack    
                            &&
                            <ModalResizeImage
                                img={formulario.document_back}
                                setShowModalResizeImage={setModalResizeImageBack}
                                showModalResizeImage={ModalResizeImageBack}
                                funVaciarFoto={vaciarFileFotoTrasera}
                                showPopup={showPopup}
                                setFormulario={setFormulario}
                                setFileNames={setFileNames}
                                formulario={formulario}
                                fileNames={fileNames}
                                name={"document_back"}
                            />
                        }
                        { 
                            objC.showModalAddSoftSkill && 
                            <ModalAddSoftSkill
                                showPopup={ objF.showPopup } 
                                setShowModalAddSoftSkill={ objF.setShowModalAddSoftSkill } 
                                typeModal={ objC.typeModal }
                                reloadData={ getSoftSkills }
                            /> 
                        }
                        {/* Modal agregar idiomas */}
                        <AddLanguageModal
                            showModal={showModalAddLanguage}
                            setShowModal={setShowModalAddLanguage}
                            getInfo={getInfoIdiomas}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}
export default AddEstudiante;