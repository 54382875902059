import Cookies from "js-cookie";
import { Routes, Route, useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import menu from "../data/menu";
import AllowedRoute from "../components/AllowedRoute";
import { AccessDenied } from "../components/AllowedRoute";

import Navbar from "../componentes/navbar/navbar";

import Home from "../componentes/home";
import NotFound from "../componentes/errors/NotFound.jsx";
import RecoveryPassword from "../componentes/RecoveryPassword/RecoveryPassword.tsx";

import Dashboard from "../componentes/dashboard/dashboard";
import Modalidades from "../componentes/modalidades/Modalidades";
import Conocimientos from "../componentes/conocimientos/Conocimientos";
import Programas from "../componentes/programas/Programas";
import Estudiantes from "../componentes/Estudiantes/Estudiantes";
import AddEstudiante from "../componentes/Estudiantes/AddEstudiante";
import EditarEstudiante from "../componentes/Estudiantes/EditarEstudiante";
import VerEstudiante from "../componentes/Estudiantes/VerEstudiante";
import SoftSkills from "../componentes/Estudiantes/SoftSkills";
import Departamentos from "../componentes/departamentos/Departamentos";
import Usuarios from "../componentes/usuarios/usuarios";
import Municipios from "../componentes/municipios/Municipios";
import Especialidades from "../componentes/especialidades/Especialidades";
import Idiomas from "../componentes/Idiomas/Idiomas";
import TiposDeProgramas from "../componentes/TiposDeProgramas/TiposDeProgramas";
import Distritos from "../componentes/distritos/Distritos";
import Cooperantes from "../componentes/Cooperantes/Cooperantes";
import CompanyDetail from "../componentes/Cooperantes/CompanyDetail";
import TipoCooperante from "../componentes/tipocooperante/TipoCooperante";
import Paises from "../componentes/paises/Paises";
import Documents from "../componentes/formatFiles/FormatFiles";
// import Pasantias from "../componentes/pasantias/Pasantias";
import Sedes from "../componentes/sedes/Sedes";
import TrainingCatalog from "../componentes/trainingCatalog/TrainingCatalog";
import { Fragment } from "react";
import EstructuraCVPDF from "../componentes/GenerarPDF/EstructuraCVPDF";
import VerProgramaDeFormacion from "../componentes/programas/VerProgramaDeFormacion.jsx";
import MySessions from "../componentes/profile/MySessions.jsx";
import AccessLog from "../componentes/admin/AccessLog.jsx";
import UserHistory from "../componentes/admin/UserHistory.jsx";
import RankingTest from "../componentes/programas/RankingTest.jsx";

export default function RouterApp()  {

    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(location);
    const navigate = useNavigate();
    const [validateRute, setValidateRute] = useState(false);
    const [role, setRole] = useState(() => {
        // Validamos si existe la cookie y si existe el rol
        if (Cookies.get('Bearer') === undefined) {
            // Si no existe la cookie, redireccionamos al login
            navigate('/');
            return;
        }

        // Si existe la cookie, retornamos el rol
        return JSON.parse(Cookies.get('Bearer')).role;
    });

    // const [bandera, setBandera] = useState(true);
    // const [prevLocation, setPrevLocation] = useState(location);

    let page = useParams();

    // Guardar ultima ruta visitada y la actual en cookies y compararlas
    useEffect(() => {
        if (prevLocation !== location) {
            setPrevLocation(location);
            Cookies.set('prevLocation', prevLocation.pathname);
            Cookies.set('location', location.pathname);
        }
    }, [location, prevLocation]);
    

    useEffect(() => {
        // console.log(role);
        setValidateRute(false);

        if(Cookies.get('Bearer') == undefined){
            setValidateRute(false)
        }else{
            setValidateRute(true)
            window.scrollTo(0, 0);
            if(location.pathname == "/"){
                setValidateRute(true)
                navigate('/Dashboard');
                window.scrollTo(0, 0);
            }
        }

        const regex = /^\/reset\/[\dA-Za-z]*$/;

        if(location.pathname !== "/"){
            setValidateRute(false)
            if(!regex.test(location.pathname)){
                setValidateRute(false)
                if(Cookies.get('Bearer') == undefined){
                    setValidateRute(false)
                    navigate('/');
                }else{
                    setValidateRute(true)
                    window.scrollTo(0, 0);
                    if(location.pathname == "/"){
                        setValidateRute(true)
                        navigate('/Dashboard');
                        window.scrollTo(0, 0);
                    }
                }
            }
        }

    }, [page]);

        // const [mostrarMenu, setMostrarMenu] = useState(false);

    return(
        <>
            {validateRute == true && <Navbar role={ setRole } />}
            <div className={`${validateRute == true && "flex overflow-hidden bg-blue-950 pt-16"}`}>
                <div className={`${validateRute == true && "h-full w-full bg-blue-950 relative overflow-y-auto lg:ml-64" }`}>
                    <main className="mt-14 md:mt-0 2xl:mt-5">
                        <Fragment>
                            <Routes>
                                <Route path="/" element={<Home/>} />
                                
                                <Route path="/Dashboard" element={<Dashboard/>} />
                                {/* <Route exact path="/Dashboard" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] }  /> } >
                                </Route> */}
                                
                                <Route exact path="/Especialidades" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Especialidades" element={<Especialidades/>} />
                                </Route>

                                <Route exact path="/Conocimientos" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> } >
                                    <Route path="/Conocimientos" element={<Conocimientos/>} />
                                </Route>

                                <Route exact path="/Idiomas" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> } >
                                    <Route path="/Idiomas" element={<Idiomas/>} />
                                </Route>

                                <Route exact path="/Modalidades" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Modalidades" element={<Modalidades/>} />
                                </Route>

                                <Route exact path="/Beneficiarios" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> } >
                                    <Route path="/Beneficiarios" element={<Estudiantes/>} />
                                </Route>

                                <Route exact path="/VerBeneficiarios/:id" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> } >
                                    <Route path="/VerBeneficiarios/:id" element={<VerEstudiante/>} />
                                </Route>
                                
                                <Route exact path="/AddBeneficiario" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> } >
                                    <Route path="/AddBeneficiario" element={<AddEstudiante/>} />
                                </Route>

                                <Route exact path="/EditarBeneficiario/:id" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> } >
                                    <Route path="/EditarBeneficiario/:id" element={<EditarEstudiante/>} />
                                </Route>

                                <Route exact path="/habilidades-blandas" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> } >
                                    <Route path="/habilidades-blandas" element={<SoftSkills/>} />
                                </Route>
                                
                                <Route exact path="/TipoCooperante" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/TipoCooperante" element={<TipoCooperante/>} />
                                </Route>
                                
                                <Route exact path="/Cooperantes" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> } >
                                    <Route path="/Cooperantes" element={<Cooperantes/>} />
                                </Route>

                                <Route path="/TiposDeFormacion" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/TiposDeFormacion" element={<TiposDeProgramas/>} />
                                </Route>

                                <Route exact path="/ProgramasDeFormacion" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> } >
                                    <Route path="/ProgramasDeFormacion" element={<Programas/>} />
                                </Route>

                                <Route exact path="/VerProgramaDeFormacion/:id" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> } >
                                    <Route path="/VerProgramaDeFormacion/:id" element={<VerProgramaDeFormacion/>} />
                                </Route>

                                <Route exact path="/DetalleDeLCooperante/:id" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> } >
                                    <Route path="/DetalleDeLCooperante/:id" element={<CompanyDetail/>} />
                                </Route>

                                <Route exact path="/CatalagoDeFormacion" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/CatalagoDeFormacion" element={<TrainingCatalog/>} />
                                </Route>

                                <Route exact path="/Sedes" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Sedes" element={<Sedes/>} />
                                </Route>

                                <Route exact path="/Paises" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Paises" element={<Paises/>} />
                                </Route>
                                
                                <Route exact path="/Departamentos" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Departamentos" element={<Departamentos/>} />
                                </Route>

                                <Route exact path="/Municipios" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Municipios" element={<Municipios/>} />
                                </Route>
                                
                                <Route exact path="/Distritos" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> } >
                                    <Route path="/Distritos" element={<Distritos/>} />
                                </Route>

                                 {/* Admin routes */}
                                <Route exact path="/Usuarios" element={ <AllowedRoute role={ role } roles={ [1, 2] } /> }>
                                    <Route path="/Usuarios" element={<Usuarios/>} />
                                </Route>

                                <Route exact path="/AccessLog" element={ <AllowedRoute role={ role } roles={ [1] } /> }>
                                    <Route path="/AccessLog" element={<AccessLog/>} />
                                </Route>

                                <Route exact path="/UserHistory" element={ <AllowedRoute role={ role } roles={ [1] } /> }>
                                    <Route path="/UserHistory/:username" element={<UserHistory/>} />
                                </Route>

                                <Route exac path="/ranking-test/:program/:id" element={ <AllowedRoute role={ role } roles={ [1, 2, 3] } /> }>
                                    <Route path="/ranking-test/:program/:id" element={<RankingTest/>} />
                                </Route>

                                {/* Files */}
                                <Route exact path="/Documentos" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> }>
                                    <Route path="/Documentos" element={<Documents/>} />
                                </Route>

                                {/* Profile */}
                                <Route exact path="/SesionesPrevias" element={ <AllowedRoute role={ role } roles={ [1, 2, 3, 4] } /> }>
                                    <Route path="/SesionesPrevias" element={<MySessions/>} />
                                </Route>

                                {/* Recuperar contraseña */}
                                <Route path="/reset/:token" element={<RecoveryPassword/>}/>
                                <Route path="/EstructuraCVPDF" element={<EstructuraCVPDF/>}/>

                                {/*Not found*/}
                                <Route path="*" element={ <NotFound /> } />
                                <Route path="/access-denied" element={<AccessDenied/>} />
                            </Routes>
                            {/* <ScrollToTop /> */}
                        </Fragment>
                    </main>
                </div>
            </div>
        </>
    );
}
