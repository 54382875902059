import { ModalEditFormatDocumentLogic } from './ModalEditFormatDocumentLogic';
const ModalEditFormatDocument = (props) => {
    const param = {
        setShowModalEditFormatDocument: props.setShowModalEditFormatDocument,
        getFormatFiles: props.getFormatFiles,
        id: props.id
    }
    const { objC, objF } = ModalEditFormatDocumentLogic(param);
    return (
        <>
            <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-7 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-950">
                                Editar formato de documento
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.setShowModalEditFormatDocument(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full" onSubmit={objF.handelSubmit}>
                                    <div className="flex flex-wrap">
                                        <div className="w-full mb-4">
                                            <label htmlFor='name' className="block text-blue-950 font-bold mb-1 md:mb-0">Nombre</label>
                                            <input
                                                type="text"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                id='name'
                                                name="name"
                                                value={objC.dataDocument.name}
                                                onChange={objF.handelChange}
                                            />
                                            {objC.alert.filter(input => input.inputName == "name" && input.status === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.message}</span></div>))}
                                        </div>
                                        <div className="w-full mb-4">
                                            <label htmlFor='description' className="block text-blue-950 font-bold mb-1 md:mb-0">Descripción</label>
                                            <textarea
                                                type="text"
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                                rows={5}
                                                id='description'
                                                name="description"
                                                value={objC.dataDocument.description}
                                                onChange={objF.handelChange}
                                            />
                                            {objC.alert.filter(input => input.inputName == "name" && input.status === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.message}</span></div>))}
                                        </div>
                                        <div className="w-full mb-6">
                                            <div class="flex items-center justify-center w-full">
                                                <label htmlFor="file" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                        <svg class="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                        </svg>
                                                        {objC.fileNameDocument === ''
                                                            ? <div className="text-center p-5">
                                                                <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Clic para agregar el documento</span></p>
                                                                <p class="text-xs text-gray-500">PDF, XLX o XLXS (Peso: 5MB max)</p>
                                                            </div>
                                                            : <p class="mb-2 text-sm text-gray-500 p-5">
                                                                <span class="font-semibold text-blue-950" title={objC.fileNameDocument}>
                                                                    Nombre del archivo:
                                                                    <br />
                                                                    {objC.fileNameDocument.length > 20
                                                                        ? `${objC.fileNameDocument.substring(0, 20)}...`
                                                                        : objC.fileNameDocument
                                                                    }
                                                                </span>
                                                            </p>
                                                        }
                                                    </div>
                                                    <input
                                                        type="file"
                                                        class="hidden"
                                                        id="file"
                                                        name="file"
                                                        // onChange={
                                                        //     (e) => {
                                                        //         if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpg") {
                                                        //             // handleFileDocument(e)
                                                        //         } else {
                                                        //             // MySwal.fire({
                                                        //             //     position: 'center',
                                                        //             //     icon: 'error',
                                                        //             //     title: 'El archivo debe ser una imagen',
                                                        //             //     showConfirmButton: false,
                                                        //             //     timer: 3500
                                                        //             // });
                                                        //         }
                                                        //     }
                                                        // }
                                                        onChange={objF.handelFileDocument}
                                                    />
                                                </label>
                                            </div>
                                            {objC.alert.filter(input => input.inputName == "file" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span></div>))}
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                            type="submit"
                                        >
                                            Actualizar
                                        </button>
                                        <button
                                            className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                            type="button"
                                            onClick={() => props.setShowModalEditFormatDocument(false)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default ModalEditFormatDocument;