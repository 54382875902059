import { useState, useEffect } from "react";
import { showPopup, getOrDeleteRequest, postOrPutRequest, paginationProps } from '../../../utils/util';
import { validateData } from '../../../utils/validateData';
import Swal from 'sweetalert2';

export const EditStudy = (params) => {
    const { id, setShowModalEditStudy, idEditStudy, dataStudies, getStudies } = params;

    const initDataStudy = {
        'institution': '',
        'type': null,
        'study_degree': '',
        'speciality': '',
        'start_year': '',
        'start_month': '',
        'start_date': '',
        'end_year': '',
        'end_month': '',
        'end_date': '',
        'location': '',
        'current': false
    }

    const initialStateInput = {
        inputName: "",
        message: '',
        status: false
    };

    const typeStudies = [
        { label: 'Académico', value: '1' },
        { label: 'Otros estudios', value: '2' }
    ];

    const months = [
        { label: 'Enero', value: '1' },
        { label: 'Febrero', value: '2' },
        { label: 'Marzo', value: '3' },
        { label: 'Abril', value: '4' },
        { label: 'Mayo', value: '5' },
        { label: 'Junio', value: '6' },
        { label: 'Julio', value: '7' },
        { label: 'Agosto', value: '8' },
        { label: 'Septiembre', value: '9' },
        { label: 'Octubre', value: '10' },
        { label: 'Noviembre', value: '11' },
        { label: 'Diciembre', value: '12' }
    ];

    const [dataSingle, setDataSingle] = useState(initDataStudy);
    const [selectedOption, setSelectedOption] = useState({});
    const [alert, setAlert] = useState([initialStateInput]);

    useEffect(() => {
        console.log(dataStudies, idEditStudy);
        openModalEditStudy();
    }, []);

    // const option = [];


    const openModalEditStudy = () => {
        let study = dataStudies.find(objeto => objeto.id === parseInt(idEditStudy));
        console.log(study);
        // let current = study.current === 1 ? true : false;
        // setSelectedOption(option[study.type]);
        let startMonth = months.find((item) => {
            return item.value == study.start_date.month;
        });

        let endMonth = months.find((item) => {
            return item.value == study.end_date.month;
        });

        let typeStudy = typeStudies.find((item) => {
            return item.value == study.type;
        });

        setDataSingle(prevState => ({
            ...prevState,
            institution: study.institution,
            type: typeStudy,
            study_degree: study.study_degree,
            speciality: study.speciality,
            start_date: study.start_date,
            start_month: startMonth,
            start_year: study.start_date.year,
            end_date: study.end_date,
            end_month: endMonth,
            end_year: study.end_date.year,
            location: study.address,
            current: study.current_study
        }));
    }

    const UpdateStudy = (e) => {
        e.preventDefault();
        console.log(dataSingle);
        if(formatDate(dataSingle)){
            delete dataSingle.speciality;
            dataSingle.current && delete dataSingle.end_date;
            dataSingle.type = dataSingle.type.value;
    
            // setDataSingle({ ...dataSingle, ['type']: selectedOption });
    
            const inputs = Object.entries(dataSingle).map(([key, value]) => {
                return { name: key, value: value };
            });
    
            const resValidate = validateData(inputs);
            console.log(resValidate);
            setAlert(resValidate);
            let validation = resValidate.filter(item => item.status === true);
            validation.length < 1 && SendData();
        }
    }

    const formatDate = (data) =>{
        const start_date = `${data.start_year}-${data.start_month.value}-01`;
        dataSingle.start_date = start_date;
        const end_date = `${data.end_year}-${data.end_month.value}-01`;
        dataSingle.end_date = end_date;
        return true;
    }

    const HandelChange = ({ target: { value, name, checked } }) => {
        setDataSingle({ ...dataSingle, [name]: value });
        name === 'current' && setDataSingle({ ...dataSingle, current: checked });
    }

    const HandelChangeSelect = (e, type) => {
        console.log(e.value);
        setDataSingle({ ...dataSingle, [type]: e });
    }

    const SendData = async () => {
        console.log('enviando...');
        console.log(dataSingle);
        dataSingle.current ? dataSingle.current = 1 : dataSingle.current = 0;
        dataSingle.start_month != '' && delete dataSingle.start_month;
        dataSingle.start_year != '' && delete dataSingle.start_year;
        dataSingle.end_month != '' && delete dataSingle.end_month;
        dataSingle.end_year != '' && delete dataSingle.end_year;
        console.log(dataSingle);
        let response = await postOrPutRequest(
            `student/update-studies/${parseInt(idEditStudy)}`,
            'PUT',
            dataSingle
        );
        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            console.log(response.message);
            return;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        showPopup(false, setShowModalEditStudy);
        getStudies();
        setAlert([initialStateInput]);
        setDataSingle(initDataStudy);
    }

    const objC = {
        dataSingle: dataSingle,
        alert: alert,
        selectedOption: selectedOption,
        typeStudies: typeStudies,
        months: months
    }

    const objF = {
        HandelChange: HandelChange,
        HandelChangeSelect: HandelChangeSelect,
        UpdateStudy: UpdateStudy
    }

    return { objC, objF }
}