import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { getOrDeleteRequest } from "./utils/util";

const DeleteFileButton = (props) => {
    const MySwal = withReactContent(Swal);

    const handleDelete = () => {
        Swal.fire({
            title: "¿Esta seguro de eliminar el perfil psicosocial?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
          }).then(async(result) => {
            if (result.isConfirmed) {
                let response = await getOrDeleteRequest(
                    `student/delete-${props.origin}/${ props.id }`,
                    'DELETE'
                );
                if (response.type === "error") {
                    let errorMessage = response.message ? response.message : "Error al eliminar el archivo";
                    MySwal.fire(
                        'Error',
                        errorMessage,
                        'error'
                    );
                    return;
                }
                props.data();
        
                MySwal.fire(
                    'Archivo eliminado',
                    'El archivo se eliminó correctamente',
                    'success'
                );
            }
          });
    };

    return (
        <button 
            className="btn-file btn-delete"
            type="button" 
            onClick={ handleDelete }
        >
            <FontAwesomeIcon className="btn-icon" icon={ faTrashAlt } />
        </button>
    );
};

export default DeleteFileButton;
