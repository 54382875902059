import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import withReactContent from "sweetalert2-react-content";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import setSeconds from "date-fns/setSeconds/index.js";
import { paginationProps, getOrDeleteRequest, postOrPutRequest, showPopup } from "../utils/util";
import { validateData } from "../utils/validateData";
import Preloader from "../Preloader/Preloader";


const EditProgram = (props) => {

    console.log(" Id de Editar programa desde el modal", props.IdEditProgram)
    const datosFormulario = {
        program: 0,
        alternate_name: "",
        GoogleForm: "",
        attendance_list: "",
        Modalidad: "",
        DateInicio: "",
        DateFinal: "",
        Cooperante: "",
        Campus: "",
        specialty_name: "",
        total_hours: ""
    }

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };
    const MySwal = withReactContent(Swal);
    const tokenAPP = JSON.parse(Cookies.get('Bearer')).token;
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [formulario, setFormulario] = useState(datosFormulario);
    console.log(formulario);
    const [conocimientos, setconocimientos] = useState([]);
    const [especialidades, setespecialidades] = useState([]);
    const [typeProgramCatalog, setTypeProgramCatalog] = useState([]);
    const [modalidad, setmodalidad] = useState([]);
    const [option, setoption] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [cooperante, setcooperante] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [campus, setCampus] = useState([]);
    const [saveknowledge, setsaveknowledge] = useState([]);
    const [saveEspecialidades, setSaveEspecialidades] = useState([]);
    const [saveCooperantes, setSaveCooperantes] = useState([]);
    const [selectedOptionCooperantes, setSelectionTypePrograms] = useState([]);

    const [selectedOptionEditar, setSelectedOptionEditar] = useState();
    const [selectedOptionEspecialidades, setSelectedOptionEspecialidades] = useState();
    const [valueTypeProgram, setValueTypeProgram] = useState([]);
    const [currentCampus, setCurrentCampus] = useState({});

    const ManejarEventoDeInputs = (event) => {
        console.log(event);
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;
        console.log(name)
        console.log(value)

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();
        console.log(formulario, "Form")

        formulario.Cooperante = saveCooperantes;

        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        inputs = inputs.filter(input => input.name !== "GoogleForm");
        inputs = inputs.filter(input => input.name !== "attendance_list");
        inputs = inputs.filter(input => input.name !== "specialty_name");

        const datosValidados = validateData(inputs);

        console.log(datosValidados);

        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.status !== false).map
            ((estado) => { return false });

        console.log('Total de validaciones', totalValidaciones.length);

        console.log(totalValidaciones.length);

        if (totalValidaciones.length < 1) {
            // console.log('Formulario Valido');
            sendData();
        };


    };

    const sendData = async () => {

        const FilterCooperantes = saveCooperantes.map((item) => {
            return item.value
        });

        console.log(valueTypeProgram);

        const FilterTypeProgram = Object.entries(valueTypeProgram).map(([key, value]) => {
            return value
        });

        console.log(valueTypeProgram.value);


        let NewData = {
            program: valueTypeProgram.value,
            alternate_name: formulario.alternate_name,
            modality: formulario.Modalidad,
            start_date: formulario.DateInicio,
            end_date: formulario.DateFinal,
            organizer: FilterCooperantes,
            campus: formulario.Campus,
            specialty_name: formulario.specialty_name,
            total_hours: formulario.total_hours
        }
        console.log(NewData);

        if( formulario.GoogleForm !== '' && formulario.GoogleForm !== undefined && formulario.GoogleForm !== null){
            NewData.google_form = formulario.GoogleForm
        }

        if( formulario.attendance_list !== '' && formulario.attendance_list !== undefined && formulario.attendance_list !== null){
            NewData.attendance_list = formulario.attendance_list
        }

        console.log(NewData, "porfa");

        const response = await postOrPutRequest(
            "program-schedule/update/"+props.IdEditProgram,
            'PUT',
            NewData
        );
        if (response.length < 0) {       
            console.log("Empty response");     
            return;
        }
        if (response.type === "error") { 
            console.log(response);
        } else {
            console.log(response);
            props.setShowModal(false);
            props.loadData();
            MySwal.fire({
                position: 'center',
                icon: 'success',
                title: response.message,
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    const getTrainingCatalog = async () => {
        const response = await getOrDeleteRequest(
            "programs/filter",
            'GET'
        );
        if (response.length < 0) {       
            console.log("Empty response");     
            return;
        }
        if (response.type === "error") {
            setTypeProgramCatalog([]);    
            console.log(response);
        } else {
            // filterTypeProgram(response);
            const filterTypeProgram = response.map((item) => {
                return { value: item.id, label: item.name }
            });
            setTypeProgramCatalog(filterTypeProgram);
            console.log(response);
        }
    };

    const getTraningPrograms = async () => {
        const response = await getOrDeleteRequest(
            "program-schedule/find/"+props.IdEditProgram,
            'GET'
        );
        if (response.length < 0) {       
            console.log("Empty response");     
            return;
        }
        if (response.type === "error") {  
            
            console.log(response);
        } else {

            const filterValueLabelTypePrograms = {
                value: response.uuid, 
                label: response.program
            };

            let campusData = await loadCampus();

            setValueTypeProgram(filterValueLabelTypePrograms);

            let dataForm = {
                program: response.uuid,
                alternate_name: response.alternate_name,
                GoogleForm: response.google_form,
                Modalidad: response.modality_id,
                DateInicio: response.start_date,
                DateFinal: response.end_date,
                Cooperante: response.organizers,
                Campus: response.campus,
                specialty_name: response.specialty_name,
                total_hours: response.total_hours,
                attendance_list: response.attendance_list
            }
            
            let campusSelected = campusData.filter((item) => {
                return item.value == response.campus;
            });

            setFormulario(dataForm);
            setCurrentCampus(campusSelected);
            setSaveCooperantes(response.organizers);
            console.log(response, "responsehsdbfsjfbsjdbfhs");
            setLoaded(true);
        }
    };

    const consultarModalidad = async () => {
        const url = `${process.env.REACT_APP_API_URI}modality/filter`;
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response.data);
                setmodalidad(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const consultarCooperante = async () => {
        const url = `${process.env.REACT_APP_API_URI}contributor/index`;
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response.data, "Holaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
                const resultDataMaps = response.data.map((item) => {
                    return { value: item.id, label: item.name }
                });
                setcooperante(resultDataMaps);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const loadCampus = useCallback(async () => {
        let campus = await getOrDeleteRequest(
            "campus/filter",
            'GET'
        );

        if (campus.type === "error") {
            console.log(campus);
            setCampus([]);

            return;
        }        
        
        let dataCampus = campus.map((item) => {
            return { value: item.id, label: item.campus_name }
        });

        setCampus(dataCampus);

        return dataCampus;
    }, []);

    useEffect(() => {
        if (loaded === false) {
            getTrainingCatalog();
            getTraningPrograms();
            consultarModalidad();
            consultarCooperante();
            loadCampus();
        }
    }, []);

    return (
        <>
            <div
                className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-900">
                                Editar programa de formación
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            // onClick={() => setShowModal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full flex flex-wrap relative" onSubmit={submitForm} >
                                    <div className={`w-full flex flex-wrap py-4 px-6 relative ${loaded ? 'visible' : 'invisible'}`}>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Catálogo formación
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <Select
                                                    className='appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                    // value={formulario.program}
                                                    name="program"
                                                    value={valueTypeProgram}
                                                    options={typeProgramCatalog}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    onChange={setValueTypeProgram}
                                                />
                                                {/* <select
                                                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                    value={formulario.program}
                                                    name="program"
                                                    onChange={ManejarEventoDeInputs}
                                                >
                                                    <option hidden selected> Seleccione una opción </option>
                                                    {typeProgramCatalog.map((item, index) => {
                                                        return (<option key={index} value={item.id}>{item.name}</option>);
                                                    })}
                                                </select> */}
                                                {alerta.filter(input => input.inputName == "program" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">Nombre alternativo</label>
                                            <div className="mb-2 xl:w-100">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="alternate_name" value={formulario.alternate_name} onChange={ManejarEventoDeInputs} type="text" />
                                                { alerta.filter(input => input.inputName == "alternate_name" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-2/3 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">Nombre de la especialización</label>
                                            <div className="mb-2 xl:w-100">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="specialty_name" value={formulario.specialty_name} onChange={ManejarEventoDeInputs} type="text" />
                                                { alerta.filter(input => input.inputName == "specialty_name" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/3 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">Total de horas</label>
                                            <div className="mb-2 xl:w-100">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="total_hours" value={formulario.total_hours} onChange={ManejarEventoDeInputs} type="text" min="1" />
                                                { alerta.filter(input => input.inputName == "total_hours" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Sede
                                            </label>
                                            {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="Campus" value={formulario.Campus} onChange={ManejarEventoDeInputs} type="text" /> */}
                                            <Select
                                                className='appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                // value={formulario.program}
                                                name="Campus"
                                                value={ currentCampus }
                                                options={campus}
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                                onChange={(event) => {
                                                    setFormulario({ ...formulario, Campus: event.value });
                                                }}
                                            />
                                            { alerta.filter(input => input.inputName == "Camous" && input.status === true).map(message => (
                                                <div className=""> <span className="text-red-500">{message.message}</span></div>
                                            )) }
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Modalidad
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.Modalidad} name="Modalidad" onChange={ManejarEventoDeInputs}>
                                                    {modalidad && (
                                                        <>
                                                            <option>Seleccione una modalidad</option>
                                                            {
                                                                modalidad.map((item, index) =>
                                                                    <option
                                                                        key={index}
                                                                        value={item.id}
                                                                    >{item.name}</option>
                                                                )
                                                            }
                                                        </>
                                                    )}
                                                </select>
                                                {
                                                    alerta.filter(input => input.inputName == "Modalidad" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Cooperantes
                                            </label>
                                            <Select
                                                isMulti
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                                value={saveCooperantes}
                                                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                options={cooperante}
                                                name="Cooperante"
                                                onChange={setSaveCooperantes}
                                            />
                                            { alerta.filter(input => input.inputName == "Cooperante" && input.status === true).map(message => (
                                                <div className=""> <span className="text-red-500">{message.message}</span></div>
                                            )) }
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Fecha de Inicio                                    </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="DateInicio" value={formulario.DateInicio} onChange={ManejarEventoDeInputs} type="date" />
                                            {
                                                alerta.filter(input => input.inputName == "DateInicio" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>

                                                ))
                                            }
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Fecha de finalización
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="DateFinal" value={formulario.DateFinal} onChange={ManejarEventoDeInputs} type="date" />
                                            {
                                                alerta.filter(input => input.inputName == "DateFinal" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>

                                                ))
                                            }
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Enlace de Hoja de Cálculo
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="GoogleForm" value={formulario.GoogleForm} onChange={ManejarEventoDeInputs} type="text" />
                                            {
                                                alerta.filter(input => input.inputName == "GoogleForm" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>

                                                ))
                                            }
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold" htmlFor="attendance_list">Asistencia hoja de cálculo</label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="attendance_list" id="attendance_list" value={formulario.attendance_list} onChange={ManejarEventoDeInputs} type="text" placeholder="Ejemplo: https://docs.google.com/spreadsheets/d/1111-aaaa-asdf-14asd/edit#gid=0" />
                                            {alerta.filter(input => input.inputName == "attendance_list" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-center py-4 border-t border-solid border-slate-200 rounded-b">
                                        {
                                            loaded
                                            &&
                                            <button
                                                className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150  "
                                                type="submit"
                                            >
                                                Actualizar
                                            </button>
                                        }
                                        <button
                                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center "
                                            type="button"
                                            onClick={() => props.setShowModal(false)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                    {
                                        !loaded && (
                                            <Preloader />
                                        )
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default EditProgram;
