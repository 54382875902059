import { paginationProps } from '../utils/util';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const DataTableComponent = ({ columns, data }) => {

    // console.log("tableDataComponent", tableData);
    // console.log("columns", columns);
    // console.log("data", data);

    const tableData = {
        columns,
        data
    };

    return (
        <DataTableExtensions
            {...tableData}
            filterPlaceholder={"Buscar"} 
            print={false}
            export={false}
        >
            <DataTable
                columns={columns}
                data={data.length > 0 && data}
                noHeader
                defaultSortField="label"
                defaultSortAsc={false}
                pagination
                highlightOnHover
                paginationComponentOptions={paginationProps}
                responsive
                noDataComponent={<span className="font-bold text-black my-5">No hay documentos cargados</span>}
            />
        </DataTableExtensions>
    )
}

export default DataTableComponent;