import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-2xl font-semibold text-yellow-500">Página no encontrada</h1>
            <p className="text-white">
                La página que buscas no existe, verifica la URL e intenta de nuevo
            </p>
            <br />
            <Link to="/Dashboard" className="btn-base">
                Regresar al Dashboard
            </Link>
        </div>
    );
};

export default NotFound;
