import axios from "axios";
import Brand from './../assets/ticonx-brand.png';
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { postOrPutRequest } from './utils/util'

const MySwal = withReactContent(Swal)
const Home = () => {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [sendData, setSendData] = useState(false);

    const Recuperation = async () => {
        // console.log("Llamar alerta")
        const { value: email } = await MySwal.fire({
            title: '¿Olvidaste tu contraseña?',
            input: 'email',
            inputLabel: 'Ingresa tu correo electrónico vinculado a tu cuenta',
            inputPlaceholder: 'mail@example.com',
            confirmButtonText: 'Confirmar'
        });

        if (email === undefined || email === null) {
            return;
        }

        const urlRecuperacion = `${process.env.REACT_APP_API_URI}auth/forgot-password`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        };

        const dataInsertar = {
            email: email
        };

        axios.post(urlRecuperacion, dataInsertar, config).then(response => {

            // console.log(response)

            MySwal.fire({
                position: 'center',
                icon: 'success',
                title: "Le hemos enviado un correo para recuperar su contraseña",
                showConfirmButton: false,
                timer: 3500
            });

        }).catch(err => {
            console.log(err);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 3500
            });
        });
    }

    const datosFormulario = {
        email: "",
        password: "",
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);
    const [alerta, setAlerta] = useState([initialStateInput]);

    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;
        // console.log(name)
        // console.log(value)
        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const handleLoginsession = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento
        setSendData(true);
        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "email", value: formulario.email },
            { nombre: "password", value: formulario.password },
        ];

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);
        
        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);

        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map((estado) => { return false });

        // Agregamos clase de error al formulario
        // if (totalValidaciones.length < 2) {
        //     e.target.classList.add('invalid-form');

        //     // Agregamos clase de error a los inputs
        //     datosValidados.filter(input => input.estado === true).map((input) => {
        //         document.getElementById(input.valorInput).classList.add('invalid-input');
        //     });
        // } else {
        //     e.target.classList.remove('invalid-form');

        //     // removemos clase de error a los inputs
        //     datosValidados.filter(input => input.estado === true).map((input) => {
        //         document.getElementById(input.valorInput).classList.remove('invalid-input');
        //     });
        // }
        
        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length >= 2) {
            // console.log("Enviar al servidor");
            EnviarDatosServidor();
            return;
        };

        setSendData(false);
        return;
    };

    const EnviarDatosServidor = async () => {
        let data = {
            email: formulario.email,
            password: formulario.password,
        };
        
        let response = await postOrPutRequest(
            'auth/login', 
            'POST', 
            data,
            false
        );

        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3000
            });
            setSendData(false);
            return;
        }
        
        let token = response.access_token;
        let type = response.token_type;
        let expires = response.expires_at;
        let role = response.role;
        let info = response.data;

        let userInformation = JSON.stringify({ token: token, type: type, role: role, data: info });

        Cookies.set(type, userInformation, { path: "/", expires: new Date(expires), secure: true });

        /* if (Cookies.get('prevLocation') !== undefined) {
            let prevLocation = Cookies.get('prevLocation');
            navigate(prevLocation, { replace: true });
        } else {
        } */
        navigate('/Dashboard', { replace: true });

        MySwal.fire({
            position: 'center',
            icon: 'success',
            title: "Bienvenido",
            showConfirmButton: false,
            timer: 3500
        });
        return;
    }

    const ValidarInputs = (data) => {
        // console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'email': {

                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }

                case 'password': {

                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;

                }

                default: {


                    break;
                }
            }

        })

        return errors;
    }

    const handleShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    return (
        <section className="bg-blue-950">
            <div className="mx-auto md:h-screen flex flex-col justify-center items-center pt:mt-0 w-full">
                <div className="flex flex-wrap w-full">
                    <div className="w-full flex justify-center p-2">
                        <img src={Brand} className="w-1/6 my-5" alt="" />
                    </div>
                </div>

                <div className="bg-white shadow rounded-xl md:mt-0 w-full sm:max-w-screen-sm xl:p-0">
                    <div className="p-6 sm:p-8 lg:p-14 space-y-8">
                        <h2 className="text-2xl lg:text-3xl text-center font-bold text-blue-950">
                            Iniciar sesión
                        </h2>
                        <form id="login-form" className="mt-8 space-y-6" onSubmit={handleLoginsession}>
                            <div>
                                <label className="text-sm font-medium text-blue-950 block mb-2">
                                    Ingresa tu correo electrónico
                                </label>
                                <input type="email" name="email" id="email" value={formulario.email} onChange={ManejarEventoDeInputs} className="login-input" placeholder="mail@example.com" disabled={ sendData } />
                                {
                                    alerta.filter(input => input.valorInput == "email" && input.estado === true).map(message => (<div className="alert"> <span className="text-red-500">{message.mensaje}</span>
                                    </div>

                                    ))
                                }
                            </div>
                            <div>
                                <label className="text-sm font-medium text-blue-950 block mb-2">Ingresa tu contraseña</label>
                                <div className="flex relative">
                                    <input type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder="••••••••" value={formulario.password} onChange={ManejarEventoDeInputs} className="login-input" disabled={ sendData } />
                                    <button className="absolute text-xl mt-2 mb-2 right-4" onClick={function(e){
                                        handleShowPassword(e)
                                    }}>
                                        <FontAwesomeIcon className="text-black" icon={!showPassword ? faEye : faEyeSlash} />
                                    </button>
                                </div>
                                {
                                    alerta.filter(input => input.valorInput == "password" && input.estado === true).map(message => (<div className="alert"> <span className="text-red-500">{message.mensaje}</span>
                                    </div>

                                    ))
                                }
                            </div>
                            {/* <div className="flex items-start justify-end">
                                <a onClick={Recuperation} className="text-sm font-medium cursor-pointer text-blue-cnx transition-colors hover:text-blue-950">
                                    ¿Olvidaste tu contraseña?
                                </a>
                            </div> */}
                            <div className='flex justify-center'>
                                <button type="submit" className="btn-login" disabled={ sendData } >
                                    { sendData ? <><FontAwesomeIcon icon={faCircleNotch} className="animate-spin" /> Validando credenciales...</> : 'Ingresar' }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Home;
