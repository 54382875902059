// import { useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

import Brand from "../../assets/conexion-black.png";
import Academy from "../../assets/ticongle_academy_brand_letrasNegras.png";
import RobotoRegular from "./Fonts/Roboto-Regular.ttf"
import RobotoBold from "./Fonts/Roboto-Bold.ttf"
import { useEffect, useRef, useState } from 'react';
import SectionProfile from '../../layouts/SectionProfile';
import PreloaderComponentSectionProfile from '../Estudiantes/Preloaders/PreloaderComponentSectionProfile';

Font.registerHyphenationCallback(word => [word]);

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular
      },
      {
        src: RobotoBold,
        fontWeight: 'bold'
      },
    ]
  })

const EstructuraPDF = ({data}) => {

    const myRef = useRef();

    console.log(data, "dataComponente")

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#fff",
            color: "black",
            fontSize: 12,
            lineHeight: 1.5,
            fontFamily: "Roboto",
            position: "relative",
        },
        container: {
            // flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            // marginBottom: 0,
            // padding: 0,
        },
        section: {
            margin: 15,
            padding: 15,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        date: {
            margin: 15,
            marginTop: 50,
            padding: 15,
            textAlign: "right",
        },
        destinatario: {
            margin: 15,
            marginTop: 20,
            padding: 15,
        },
        parrafo: {
            margin: 15,
            marginTop: 3,
            padding: 15,
            textAlign: "justify",
        },
        atentamente: {
            position: "absolute",
            bottom: 0,
            margin: 15,
            marginTop: 20,
            padding: 15,
        },
        viewer: {
            width: "100%", //the pdf viewer will take up all of the width and height
            height: window.innerHeight - 170,            
        },
      });
    console.log(window.innerHeight);
    //Obtener dia, mes y año con el formato 00/00/0000
    let date = new Date();
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    // let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    // Obtenemos el mes en español
    let month = date.toLocaleString('es-ES', { month: 'long' });
    month = month.charAt(0) + month.slice(1);

    let year = date.getFullYear();

    const [loaded, setLoaded] = useState(false);

    //Cambiar nombre del archivo PDF a descargar
    // let fileName = `Carta de Recomendación - ${data.names + " " + data.lastnames}.pdf`;

    useEffect(() => {
        console.log(myRef.current, "myRef")
        setTimeout(() => {
            setLoaded(true);
        }, 2000);
    }, [myRef])

    return (
        <>
            <SectionProfile
                title="Carta de recomendación"
            >
                {loaded 
                ? 
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="letter" style={styles.page}>
                            <View style={styles.container}>
                                <View style={styles.section}>
                                    <Image src={Brand} style={{
                                        width: "140px",
                                        height: "auto",
                                        marginTop: 20,
                                        marginRight: 20,
                                    }} />
                                    <Image src={Academy} style={{
                                        width: "140px",
                                        height: "auto",
                                        marginTop: 20,
                                    }} />
                                </View>
                                <View style={styles.date}>
                                    <Text>San Salvador, {day} de {month}, {year}</Text>
                                </View>
                            </View>
                            <View style={styles.destinatario}>
                                <Text>A quien interese,</Text>
                            </View>
                            <View style={styles.parrafo}>
                                <Text>
                                    Se hace constar que <Text style={{ fontWeight: "bold" }} >{data.names + " " + data.lastnames}</Text> con Documento Único de Identidad <Text style={{ fontWeight: "bold" }} >{data.dui}</Text>, ha sido estudiante de nuestro programa de becas en Ticongle Academy durante el periodo comprendido de
                                    {
                                        data.letter_start_date.year === data.letter_end_date.year 
                                        ? 
                                        <>
                                            {" " + data.letter_start_date.month} a {data.letter_end_date.month} de {data.letter_end_date.year}.
                                        </>
                                        :
                                        <>
                                            {" " + data.letter_start_date.month} de {data.letter_start_date.year} a {data.letter_end_date.month} de {data.letter_end_date.year}.
                                        </>
                                    }
                                </Text>
                            </View>
                            <View style={styles.parrafo}>
                                <Text ref={myRef}>
                                    Durante el desarrollo del programa de formación y sus prácticas en proyectos de trabajo, <Text style={{ fontWeight: "bold" }} >{data.names + " " + data.lastnames}</Text> demostró ser una persona responsable, respetuosa, con disposición en asumir retos académicos y con deseos constante en aprender y crecer profesionalmente.
                                </Text>
                            </View>
                            <View style={styles.parrafo}>
                                <Text>
                                    Sin más, se extiende la presente para los fines que <Text style={{ fontWeight: "bold" }} >{data.names + " " + data.lastnames}</Text> considere pertinentes.
                                </Text>
                            </View>
                            <View style={styles.atentamente}>
                                <Text>
                                    Atentamente,
                                </Text>
                                <Text style={{marginTop: 20}}>
                                    Gilberto Lara
                                </Text>
                                <Text>
                                    Director General
                                </Text>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer> 
                :
                <PreloaderComponentSectionProfile filas={9} filas_sm={5} filas_md={5} />}
            </SectionProfile>
        </>
      );
}

export default EstructuraPDF;