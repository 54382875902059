import { useState, useEffect } from 'react'
import { showPopup, getOrDeleteRequest, paginationProps, months } from '../../utils/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export const WorkExperience = (params) => {

    const { setShowModalEditWorkExperience, setIdEditWorkExperience, loaded, getWorkExperiences } = params;

    const columns = [
        {
            name: 'Lugar de trabajo',
            width: "200px",
            cell: (row) => loaded && <div>{row.nombre_empresa}</div>
        },
        {
            name: 'Cargo',
            width: "200px",
            cell: (row) => loaded && <div>{row.cargo}</div>
        },
        {
            name: 'Periodo',
            width: "230px",
            cell: (row) => loaded && 
                <div>
                    {row.fecha_inicio} - {row.fecha_fin === null ? 'Actualidad' : row.fecha_fin}
                </div>
        },
        // {
        //     name: 'Lugar de trabajo',
        //     width: "200px",
        //     cell: (row) => loaded && <div>{row.address}</div>
        // },
        // {
        //     name: 'Editar',
        //     width: "100px",
        //     className: "flex justify-center items-center",
        //     cell: (row) => loaded &&
        //         <div className='flex justify-between'>
        //             <button id={row.id} onClick={(e) => {
        //                 console.log(e.currentTarget.id);
        //                 setIdEditWorkExperience(e.currentTarget.id);
        //                 showPopup(true, setShowModalEditWorkExperience);
        //             }} className="text-2xl">
        //                 {loaded && <FontAwesomeIcon icon={faPenToSquare} />}
        //             </button>
        //             <button id={row.id} onClick={(e) => {
        //                 console.log(e.currentTarget.id);
        //                 const idDelete = e.currentTarget.id;
        //                 Swal.fire({
        //                     title: '¿Esta seguro que desea eliminar el registro?',
        //                     text: "",
        //                     icon: 'warning',
        //                     showCancelButton: true,
        //                     confirmButtonColor: '#d33',
        //                     cancelButtonColor: '#c0c0c0',
        //                     confirmButtonText: 'Sí, ¡deseo borrarlo!'
        //                 }).then((result) => {
        //                     if (result.isConfirmed) {
        //                         deleteWorkExperience(idDelete);
        //                     }
        //                 })
        //             }} className="text-2xl text-red-500">
        //                 {loaded && <FontAwesomeIcon icon={faTrashCan} />}
        //             </button>
        //         </div>
        // },
    ];

    const deleteWorkExperience = async (idDelete) => {
        let response = await getOrDeleteRequest(
            `student/delete-experience/${idDelete}`,
            'DELETE'
        );
        Swal.fire(
            '¡Registro borrado!',
            response.message,
            'success'
        )
        getWorkExperiences();
    }

    return { columns, paginationProps, alert }
}