// Icons
import ImgDashboard from '../assets/icono-dashboard/Dashboard.png';
import ImgModalidades from '../assets/icono-dashboard/Modalidades.png';
import ImgEspecialidades from '../assets/icono-dashboard/Especialidades.png';
import ImgConocimientos from '../assets/icono-dashboard/conocimientos.png';
import ImgIdiomas from '../assets/icono-dashboard/idiomas.png';
import ImgProgramas from '../assets/icono-dashboard/learning.png';
import ImgEstudiantes from '../assets/icono-dashboard/estudiante.png';
import ImgTiposdeProgramas from '../assets/icono-dashboard/tipo-de-programas.png';
import ImgDepartamentos from '../assets/icono-dashboard/departamento.png';
import ImgMunicipios from '../assets/icono-dashboard/Municipio.png';
import ImgCooperantes from '../assets/icono-dashboard/enterprise.png';
import ImgDistritos from '../assets/icono-dashboard/Distrito.png';
import ImgUsuario from '../assets/icono-dashboard/usuario.png';
import ImgPaises from '../assets/icono-dashboard/paises.png';
import ImgCampus from '../assets/icono-dashboard/campus.png';
import ImgConfig from '../assets/icono-dashboard/config.png';
import ImgDocFiles from '../assets/icono-dashboard/files.png';
import ImgProfile from '../assets/icono-dashboard/profile.png';

// Components

// Array de objetos que contiene la información de cada menú
const menu = [
    {
        id: 1,
        section: 'general',
        title: 'General',
        default: true,
        icon: ImgDashboard,
        items: [
            {
                id: 1,
                slug: "Dashboard",
                image: ImgDashboard,
                content: "Dashboard",
                roles: [1, 2, 3, 4],
                component: null,
            },
            {
                id: 2,
                slug: "Beneficiarios",
                image: ImgEstudiantes,
                content: "Beneficiarios",
                roles: [1, 2, 3, 4],
                component: null,
            },
        ],
    }, 
    {
        id: 2,
        section: 'training',
        title: 'Formación',
        default: false,
        icon: ImgProgramas,
        items: [
            {
                id: 1,
                slug: "TiposDeFormacion",
                image: ImgDashboard,
                content: "Tipos",
                roles: [1, 2],
                component: null,
            },
            {
                id: 2,
                slug: "CatalagoDeFormacion",
                image: ImgTiposdeProgramas,
                content: "Catálogo",
                roles: [1, 2],
                component: null,
            },
            {
                id: 3,
                slug: "ProgramasDeFormacion",
                image: ImgProgramas,
                content: "Programas de formación",
                roles: [1, 2, 3],
                component: null,
            },
            {
                id: 4,
                slug: "Sedes",
                image: ImgCampus,
                content: "Sedes",
                roles: [1, 2],
                component: null,
            },
            {
                id: 5,
                slug: "Modalidades",
                image: ImgModalidades,
                content: "Modalidades",
                roles: [1, 2],
                component: null,
            },
        ],
    },
    {
        id: 3,
        section: 'contributor',
        title: 'Cooperantes',
        default: false,
        icon: ImgCooperantes,
        items: [
            {
                id: 1,
                slug: "TipoCooperante",
                image: ImgCooperantes,
                content: "Tipos",
                roles: [1, 2],
                component: null,
            },
            {
                id: 2,
                slug: "Cooperantes",
                image: ImgCooperantes,
                content: "Listado de cooperantes",
                roles: [1, 2, 3],
                component: null,
            },
        ],
    },
    {
        id: 4,
        section: 'competences',
        title: 'Competencias',
        default: false,
        icon: ImgEspecialidades,
        items: [
            {
                id: 1,
                slug: "habilidades-blandas",
                image: ImgTiposdeProgramas,
                content: "Habilidades Blandas",
                roles: [1, 2, 3],
                component: null,
            },
            {
                id: 2,
                slug: "Especialidades",
                image: ImgEspecialidades,
                content: "Especialidades",
                roles: [1, 2],
                component: null,
            },
            {
                id: 3,
                slug: "Conocimientos",
                image: ImgConocimientos,
                content: "Conocimientos",
                roles: [1, 2, 3],
                component: null,
            },
            {
                id: 4,
                slug: "Idiomas",
                image: ImgIdiomas,
                content: "Idiomas",
                roles: [1, 2, 3],
                component: null,
            },
        ],
    },
    {
        id: 5,
        section: 'locations',
        title: 'Ubicaciones',
        default: false,
        icon: ImgDepartamentos,
        items: [
            {
                id: 1,
                slug: "Paises",
                image: ImgPaises,
                content: "Países",
                roles: [1, 2],
                component: null,
            },
            {
                id: 2,
                slug: "Departamentos",
                image: ImgDepartamentos,
                content: "Departamentos",
                roles: [1, 2],
                component: null,
            },
            {
                id: 3,
                slug: "Municipios",
                image: ImgMunicipios,
                content: "Municipios",
                roles: [1, 2],
                component: null,
            },
            {
                id: 4,
                slug: "Distritos",
                image: ImgDistritos,
                content: "Distritos",
                roles: [1, 2],
                component: null,
            },
        ],
    },
    {
        id: 6,
        section: 'documentos',
        title: 'Archivos',
        default: false,
        icon: ImgDocFiles,
        items: [
            {
                id: 1,
                slug: "Documentos",
                image: ImgDocFiles,
                content: "Formatos",
                roles: [1, 2, 3, 4],
                component: null,
            }
        ],
    },
    {
        id: 7,
        section: 'administration',
        title: 'Administración',
        default: false,
        icon: ImgConfig,
        items: [
            {
                id: 1,
                slug: "Usuarios",
                image: ImgUsuario,
                content: "Usuarios",
                roles: [1],
                component: null,
            },
            {
                id: 2,
                slug: "AccessLog",
                image: ImgUsuario,
                content: "Historial de acceso",
                roles: [1],
                component: null,
            }
        ],
    },
    {
        id: 8,
        section: 'profile',
        title: 'Perfil',
        default: false,
        icon: ImgProfile,
        items: [
            // {
            //     id: 1,
            //     slug: "Perfil",
            //     image: ImgUsuario,
            //     content: "Mi perfil",
            //     roles: [1],
            //     component: null,
            // },
            // {
            //     id: 2,
            //     slug: "ClaveAcceso",
            //     image: ImgUsuario,
            //     content: "Contraseña",
            //     roles: [1],
            //     component: null,
            // },
            // {
            //     id: 3,
            //     slug: "SesionesPrevias",
            //     image: ImgUsuario,
            //     content: "Sesiones previas",
            //     roles: [1, 2, 3, 4  ],
            //     component: null,
            // },
        ],
    },
];
export default menu;
