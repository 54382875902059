import {useState} from 'react';
import { validateData } from "../../utils/validateData";
import { postOrPutRequest } from "../../utils/util";
import Swal from "sweetalert2";

export const ModalAddFormatDocumentLogic = (param) =>{
    const initDataDocument = {
        name: '',
        description: '',
        file: ''
    }
    const [alert, setAlert] = useState([]);
    const [dataDocument, setDataDocument] = useState(initDataDocument);
    const [fileNameDocument, setFileNameDocument] = useState('');

    const handelChange = ({target: {name, value}}) =>{
        setDataDocument({...dataDocument, [name]: value});
    }

    const handelFileDocument = ({target: {name, files}}) =>{
        if (files.length > 0) {
            setFileNameDocument(files[0].name);
            setDataDocument({...dataDocument, [name]: files[0]});
            return;
        }
        setFileNameDocument('');
        setDataDocument({...dataDocument, [name]: ''});
    }

    const handelSubmit = (e) =>{
        e.preventDefault();
        let inputs = Object.entries(dataDocument).map(([key, value]) => {
            return { name: key, value: value};
        });
        const processData = validateData(inputs);
        setAlert(processData);
        let filterData = processData.filter(input => input.status !== false).map(() => { return false });
        filterData.length === 0 && sendData();
    }

    const sendData = async() =>{
        const response = await postOrPutRequest(
            'document-format/store',
            'POST',
            dataDocument
        );
        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3000
            });
            return;
        } 
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        param.setShowModalAddFormatDocument(false);
        param.getFormatFiles();
    }

    const objC = {
        alert: alert,
        dataDocument: dataDocument,
        fileNameDocument: fileNameDocument
    }

    const objF = {
        handelChange: handelChange,
        handelFileDocument: handelFileDocument,
        handelSubmit: handelSubmit
    }
    return { objC, objF }
}