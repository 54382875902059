import { FiltersStudentsTrainingLogic } from './FiltersStudentsTrainingLogic';
import { SelectStatusProgramStudents, SelectRState, SelectRCity, SelectRDistrict, SelectGender, InputSearch } from '../Components/InputsSystem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
const FiltersStudentsTraining = (props) => {
    const params = {
        dataFilter: props.dataFilter,
        setDataFilter: props.setDataFilter,
        initDataFilter: props.initDataFilter,
        loaded: props.loaded,
        setDataSearch: props.setDataSearch,
        dataStudents: props.dataStudents
    }
    const { objF } = FiltersStudentsTrainingLogic(params);
    return (
        <div className="relative">
            <div className='flex justify-center md:justify-end items-center px-5 text-red-400'>
                <FontAwesomeIcon icon={faTrash} /><h5 className='px-1 cursor-pointer hover:underline' onClick={objF.resetFilter}>Quitar filtros</h5>
            </div>
            <div className="w-full px-4 flex flex-wrap">
                <div className="w-full md:w-1/3 px-2 py-3">
                    <InputSearch dataFilter={props.dataFilter} changeData={objF.changeData} loaded={props.loaded} />
                </div>
                <div className="w-full md:w-1/3 px-2 py-3">
                    <SelectStatusProgramStudents dataFilter={props.dataFilter} loaded={props.loaded} changeData={objF.changeData} />
                </div>
                <div className="w-full md:w-1/3 px-2 py-3">
                    <SelectGender dataFilter={props.dataFilter} loaded={props.loaded} changeData={objF.changeData} />
                </div>
                {/* <div className="w-full md:w-1/3 px-2 py-3">
                    <SelectRState dataFilter={props.dataFilter} loaded={props.loaded} changeDataRSelect={objF.changeDataRSelect} />
                </div>
                <div className="w-full md:w-1/3 px-2 py-3">
                    <SelectRCity dataFilter={props.dataFilter} loaded={props.loaded} changeDataRSelect={objF.changeDataRSelect} />
                </div>
                <div className="w-full md:w-1/3 px-2 py-3">
                    <SelectRDistrict dataFilter={props.dataFilter} loaded={props.loaded} changeDataRSelect={objF.changeDataRSelect} />
                </div> */}
            </div>
        </div>
    )
}
export default FiltersStudentsTraining;