import {useState, useEffect} from 'react';
import {getOrDeleteRequest} from '../utils/util';
export const FiltersCompanyStudentsLogic = (params) =>{
    const { dataStudents, setDataSearch, id, setLoaded } = params;
    console.log(params);
    const initDataFilter = {
        search: '',
        internship: 'default',
        gender: 'default',
        state: null,
        city: null,
        district: null
    }
    let srch;
    const [dataFilter, setDataFilter] = useState(initDataFilter);

    useEffect(() => { getStudentsProgram(dataFilter) }, [dataFilter]);

    const changeData = ({target:{name, value, title}}) => {
        switch (name) {
            case 'search':
                srch = dataStudents.filter((item) => {
                    if ( item.names.toString().toLowerCase().includes(value.toLowerCase()) ||
                         item.lastnames.toString().toLowerCase().includes(value.toLowerCase()) ||
                         item.start_date.toString().toLowerCase().includes(value.toLowerCase())) {
                        return item;
                    }
                });
                setDataSearch(srch);
                setDataFilter({...dataFilter, [name]: value});
                break;
            case 'name':
                setDataFilter({...dataFilter, [title]: value});
                break;
        
            default:
                break;
        }
    }

    const changeDataRSelect = (e) => {
        setDataFilter({ ...dataFilter, [e.name]: e });
    }

    const getStudentsProgram = async (ft) => {
        console.log(ft);
        setLoaded(false);
        const endPoint = `contributor/students/${id}?` +
            `${ft.gender !== 'default' ? 'gender=' + ft.gender + '&' : ''}` +
            `${ft.state !== null ? 'state=' + ft.state.value + '&' : ''}` +
            `${ft.city !== null ? 'city=' + ft.city.value + '&' : ''}` +
            `${ft.district !== null ? 'district=' + ft.district.value + '&' : ''}` +
            `${ft.internship !== 'default' ? 'internship=' + ft.internship + '&' : ''}`;
        console.log(endPoint.slice(0, -1));
        const response = await getOrDeleteRequest(
            endPoint.slice(0, -1),
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            setLoaded(true);
            return;
        }
        if (response.type === "error") {
            setDataSearch([]);
            console.log(response);
            setLoaded(true);
            return;
        }
        setDataSearch(response);
        setLoaded(true);
    }

    const resetFilter = () => {
        setDataFilter(initDataFilter);
    }

    const optionsInternship = [
        {label: 'No', value: 0},
        {label: 'Sí', value: 1}
    ]

    const optionsGender = [
        {label: 'Masculino', value: 1},
        {label: 'Femenino', value: 2},
        {label: 'Prefiero no decirlo', value: 3}
    ]

    const objF = {
        changeData: changeData,
        changeDataRSelect: changeDataRSelect,
        resetFilter: resetFilter
    } 

    const objC = {
        dataFilter: dataFilter,
        optionsInternship: optionsInternship,
        optionsGender: optionsGender
    } 

    return{ objC, objF }
}
