import React from "react";

const ExportTable = React.forwardRef((props, ref) => {
    return (
        <table ref={ref}>
            <thead>
                <tr dangerouslySetInnerHTML={{ __html: props.filters }} />
                <tr dangerouslySetInnerHTML={{ __html: props.headers }} />
            </thead>
            <tbody>
                { props.dataToExport.map((obj, key) => {
                    let inputs = Object.entries(obj).map(([key, value]) => {
                        return {
                            name: key,
                            value: value,
                        };
                    });
                    
                    let html = "";

                    inputs.forEach((item) => {
                        html += `<td>${item.value}</td>`;
                    });
                    return (
                        <tr key={ key } dangerouslySetInnerHTML={{ __html: html }} />
                    );
                }) }
            </tbody>
        </table>
    );
});

export default ExportTable;
