import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { postOrPutRequest } from '../../../utils/util';
import { validateData } from '../../../utils/validateData';

const initDataStudy = {
    'institution': '',
    'type': 0,
    'study_degree': '',
    'speciality': '',
    'start_year': '',
    'start_month': '',
    'start_date': '',
    'end_year': '',
    'end_month': '',
    'end_date': '',
    'location': '',
    'current': false
}

const initialStateInput = {
    inputName: "",
    message: '',
    status: false
};

const typeStudies = [
    { label: 'Académico', value: '1' },
    { label: 'Otros estudios', value: '2' }
];

const months = [
    { label: 'Enero', value: '1' },
    { label: 'Febrero', value: '2' },
    { label: 'Marzo', value: '3' },
    { label: 'Abril', value: '4' },
    { label: 'Mayo', value: '5' },
    { label: 'Junio', value: '6' },
    { label: 'Julio', value: '7' },
    { label: 'Agosto', value: '8' },
    { label: 'Septiembre', value: '9' },
    { label: 'Octubre', value: '10' },
    { label: 'Noviembre', value: '11' },
    { label: 'Diciembre', value: '12' }
];

export const AddStudy = (params) => {

    const { idStudent, getStudies, showPopup, setShowModalStudy} = params;

    const [dataAddStudy, setDataAddStudy] = useState(initDataStudy);
    const [alert, setAlert] = useState([initialStateInput]);

    const AddStudySubmit = (e) => {
        e.preventDefault();
        console.log(dataAddStudy);
        formatDate(dataAddStudy);
        if(formatDate(dataAddStudy)){
            delete dataAddStudy.speciality;
            if(dataAddStudy.current){
                delete dataAddStudy.end_date;
                delete dataAddStudy.end_month;
                delete dataAddStudy.end_year;
            }
            const inputs = Object.entries(dataAddStudy).map(([key, value]) => {
                return { name: key, value: value };
            });
            const resValidate = validateData(inputs);
            console.log(resValidate);
            setAlert(resValidate);
            let validation = resValidate.filter(item => item.status === true);
            validation.length < 1 && SendData();
        }
    }

    const formatDate = (data) =>{
        const start_date = `${data.start_year}-${data.start_month}-01`;
        dataAddStudy.start_date = start_date;
        const end_date = `${data.end_year}-${data.end_month}-01`;
        dataAddStudy.end_date = end_date;
        return true;
    }

    useEffect(() => { console.log(dataAddStudy) }, [dataAddStudy]);
    

    const SendData = async () => {
        console.log('enviando...');
        console.log(dataAddStudy);
        dataAddStudy.current ? dataAddStudy.current = 1 : dataAddStudy.current = 0;
        let response = await postOrPutRequest(
            `student/add-studies/${idStudent}`,
            'POST',
            dataAddStudy
        );
        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            dataAddStudy.current = false
            return;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        showPopup(false, setShowModalStudy);
        getStudies();
        setAlert([initialStateInput]);
        setDataAddStudy(initDataStudy);
    }

    const HandelChange = ({ target: { value, name, checked } }) => {
        setDataAddStudy({ ...dataAddStudy, [name]: value });
        name === 'current' && setDataAddStudy({ ...dataAddStudy, current: checked });
    }

    const HandelChangeSelect = (e, type) => {
        console.log(e, type);
        setDataAddStudy({ ...dataAddStudy, [type]: e.value });
    }

    const objC = {
        alert: alert,
        dataAddStudy: dataAddStudy,
        typeStudies: typeStudies,
        months: months
    }

    const objF = {
        AddStudySubmit: AddStudySubmit,
        HandelChange: HandelChange,
        HandelChangeSelect: HandelChangeSelect
    }

    return { objC, objF }
}
