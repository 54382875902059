import { useState } from "react";

const ModalInternShipsHistory = (props) => {
    // Limite de registros a mostrar
    const [limit, setLimit] = useState(5);
    console.log(props.data);
    console.log(props.data.follow_up);
    // Funcion para aumentar el limite de registros a mostrar
    const showMore = () => {
        setLimit(limit + 5);
    }

    const fullDate = (date) => {
        let dateObj = new Date(date);

        let day = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
        let month = dateObj.toLocaleString('es-ES', { month: 'long' });
        month = month.charAt(0).toUpperCase() + month.slice(1);

        let year = dateObj.getFullYear();

        return `${day} de ${month} del ${year}`;
    };

    return (
        <>
            <div
                className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-2xl font-semibold text-blue-900">
                                Seguimiento de pasantías
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => {
                                    props.showPopup(false, props.setModal);
                            }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <div className="py-4 px-6">
                                    {/* Agregando ultima fecha de actualizacion (De forma representativa) */}
                                    <div className="flex justify-between items-center text-base mb-3">
                                        <span>
                                            Período de pasantía comprendido: <strong>{ fullDate(props.data.start_date) } - { props.data.end_date !== null ? fullDate(props.data.end_date) : 'actualidad' }</strong>
                                        </span>
                                        <button className="btn-card-header disabled:opacity-50 disabled:cursor-not-allowed"
                                            onClick={ showMore }
                                            disabled={ (props.data.follow_up).length <= limit ? true : false }
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                    <div id="list-history" className="flex flex-wrap px-3 overflow-y-auto max-h-[280px]">
                                        { ((props.data.follow_up).length > 0) && (
                                            <>
                                                { (props.data.follow_up).map((item, index) => {
                                                    let initialStatus = null;
                                                    let finalStatus = null;
                                                    let end_date = null;

                                                    switch (item.status) {
                                                        case 1:
                                                        case 2:
                                                        case 3:
                                                        case 4:
                                                        default:
                                                            initialStatus = 'inicio o continuación';
                                                            finalStatus = 'finalización';

                                                            if (item.status === 3 || item.status === 4) {
                                                                end_date = fullDate(item.return_date);
                                                            } else {
                                                                end_date = 'No especificada';
                                                            }

                                                            break;
                                                        
                                                        case 5:
                                                            initialStatus = 'pausa';
                                                            finalStatus = 'retorno';
                                                            end_date = (item.return_date !== null) ? fullDate(item.return_date) : 'No especificada';
                                                            break;
                                                    };

                                                    if (index < limit) {
                                                        return (
                                                            <div className="w-full py-2" key={ index }>
                                                                <details className="group">
                                                                    <summary className="accordion">
                                                                        <span className="text-base">{ item.updated } ({item.status_name})</span>
                                                                        <span className="transition group-open:rotate-180">
                                                                            <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                                                                <path d="M6 9l6 6 6-6"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </summary>
                                                                    <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                                                        <div className="flex flex-wrap justify-center pt-1">
                                                                            <div className="w-full p-2 text-sm text-gray-500 text-center">
                                                                                <span className="text-black font-bold">Ultima actualización</span><br /><span className="italic">{ item.updated }</span>
                                                                            </div>
                                                                            <div className="w-full sm:w-1/2 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">
                                                                                    Fecha de { initialStatus }
                                                                                </span>
                                                                                <br />{ fullDate(item.break_date) }
                                                                            </div>
                                                                            <div className="w-full sm:w-1/2 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">
                                                                                    Fecha de { finalStatus }
                                                                                </span>
                                                                                <br />{ end_date }
                                                                            </div>
                                                                            <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">
                                                                                    Detalles
                                                                                </span>
                                                                                <br />{ item.comment }
                                                                            </div>
                                                                        </div>
                                                                    </p>
                                                                </details>
                                                            </div>
                                                        );
                                                    }

                                                    return null;
                                                }) }
                                            </>
                                        ) }
                                        { (props.data.follow_up).length < 1 && (
                                            <div className="w-full">
                                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                                    Sin registros
                                                </p>
                                            </div>
                                        ) }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center"
                                type="button"
                                onClick={() => {
                                    props.showPopup(false, props.setModal);
                                    setLimit(1);
                                }}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default ModalInternShipsHistory;
