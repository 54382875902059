import Preloader from "../../Preloader/Preloader";

const PreloaderInfoPersonalBeneficiario = () => {
    return (
        <>
            <div className="invisible relative flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="block font-bold text-black">Nombres:</span>
                    {/* <br /> */}
                    <span className="block"> && formulario.names</span>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Apellidos:</span><br /> && formulario.lastnames
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Fecha de nacimiento:</span>
                    <br />
                    {/*  && formulario.birth_date} */}
                    && 
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Edad:</span><br /> &&
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Género:</span><br />
                        && 
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">
                        && 
                    </span>
                    <br />
                    && 
                </div>
                <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Dirección:</span><br /> formulario.address
                </div>
                <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Departamento de residencia:</span><br /> formulario.state.name
                </div>
                <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Municipio de residencia:</span><br /> city.name
                </div>
                <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                    <span className="font-bold text-black">Distrito de residencia:</span><br /> district
                </div>
            </div>
            <Preloader />
        </>
    )
}

export default PreloaderInfoPersonalBeneficiario;