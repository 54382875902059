import { getCampus } from './FunctionsGet';
const CampusRSelect = (props) => {
    const { select: Select } = props;
    const [dataCampus, setDataCampus] = props.useState([]);

    const campus = async () =>{
        let response = await getCampus();
        setDataCampus(props.setFilterDataReactSelect2(response, 'campus'));
    }
    
    props.useEffect(() => { campus();}, []);

    return (<div className="w-full md:w-1/3 px-2">
            <div className="mb-6">
                <label htmlFor="campus" className="block mb-2 text-white">Sede</label>
                <Select
                    name="campus"
                    id="campus"
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                    value={props.dataFilterCampus}
                    options={dataCampus}
                    placeholder={"Selecciona una opción"}
                    noOptionsMessage={() => 'No hay mas opciones'}
                    onChange={props.changeDataRSelect}
                    disabled={props.disabled !== undefined ? props.disabled : false}
                />
            </div>
        </div>)
}
export default CampusRSelect;
