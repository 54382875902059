import React from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useCallback, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import StudentIcon from '../../assets/images/estudiantes-384x384.png';
import ProgramIcon from '../../assets/images/programas-384x384.png';
import ContributorIcon from '../../assets/images/cooperantes-384x384.png';
import { BarChart, Card, DonutChart, Subtitle } from "@tremor/react";

import Navbar from '../navbar/navbar'
import Preloader from '../Preloader/Preloader';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [counter, setCounter] = useState({
        programs: 0,
        students: 0,
        contributors: 0,
    });
    const [table, setTable] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartDataPasantias, setChartDataPasantias] = useState([]);
    const [chartDataProgramas, setChartDataProgramas] = useState([]);
    const MySwal = withReactContent(Swal);

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: '# de registrados',
                data: chartData,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };

    let chartPasantias = [];

    let chartProgramas = [];

    const loadData = useCallback(async () => {
        let url = `${process.env.REACT_APP_API_URI}dashboard`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            if (resp.data !== null && resp.data !== undefined){
                let info = resp.data;

                let tempCounter = {
                    programs: info.counter.programs,
                    students: info.counter.students,
                    contributors: info.counter.contributors,
                };

                let chartTemp = resp.data.chart;

                setCounter(tempCounter);
                setTable(info.table);
                setChartLabels(chartTemp.labels);
                setChartData(chartTemp.data);
                setChartDataPasantias(resp.data.internship_months.data);

                chartPasantias = [];

                chartProgramas = [];

                resp.data.internship_months.data.map((element, index) => {
                    console.log(element, 'element');

                    let temp = {
                        name: element.month + ' ' + element.year,
                        "Pasantías": element.total,
                    };

                    chartPasantias.push(temp);
                    
                });

                for (let i = 0; i < chartTemp.data.length; i++) {
                    let temp = {
                        name: resp.data.chart.labels[i],
                        "Programas": resp.data.chart.data[i],
                    };

                    chartProgramas.push(temp);
                    console.log(temp, 'temp')
                }

                setChartDataPasantias(chartPasantias);
                setChartDataProgramas(chartProgramas);
                
            }

            setLoaded(true);

        } catch (err) {
            console.log(err);
            // MySwal.fire({
            //     position: 'center',
            //     icon: 'error',
            //     title: "¡Error! Vuelva a intentar",
            //     // showConfirmButton: false,
            //     // timer: 3500
            // });
        }
    }, []);

    useEffect(() => {
        if (loaded === false) {
            loadData();
        }
    }, [loadData]);

    // const valueFormatter = (number) => `$ ${new Intl.NumberFormat("us").format(number).toString()}`;
    const [value, setValue] = React.useState(null);

    const [randomNumber, setRandomNumber] = useState(0);

    useEffect(() => {
        let animationInterval;
    
        if (loaded === false) {
          animationInterval = setInterval(() => {
            setRandomNumber(Math.floor(Math.random() * 100));
          }, 100); // Cambia el número cada 100 milisegundos
        } else {
          clearInterval(animationInterval);
        }
    
        return () => {
          clearInterval(animationInterval);
        };
    }, [loaded]);

    // const RedirectToViewProgram = (id) => {
    //     window.location.replace(`/VerProgramaDeFormacion/${id}`);
    // }
   
    return (
        <div className='flex flex-wrap'>
            <div className="w-full">
                <section className="text-gray-600 body-font bg-blue-950">
                    <div className="w-full py-5 px-4 text-xl sm:text-2xl font-semibold text-white" >
                        <h2>Dashboard</h2>
                    </div>
                    <div className="container px-5 pb-12 pt-5 mx-auto bg-blue-950">
                        <div className="flex flex-wrap -m-4">

                            {/* Counters Card */}
                            <div className="xl:w-1/3 md:w-1/2 w-full p-4">
                                <div className="border border-gray-400 bg-white p-6 rounded-lg">
                                    <div className="w-10 h-10 inline-flex items-center justify-center rounded-full mb-4">
                                        <img 
                                            src={ StudentIcon } 
                                            alt="" 
                                        />
                                    </div>
                                    <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                                        Beneficiarios
                                    </h2>
                                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                                        { 
                                            loaded
                                            ?
                                            counter.students
                                            :
                                            <div className='animate-pulse'>{randomNumber}</div>
                                        }
                                    </h2>
                                </div>
                            </div>

                            <div className="xl:w-1/3 md:w-1/2 w-full p-4">
                                <div className="border border-gray-400 bg-white p-6 rounded-lg">
                                    <div className="w-10 h-10 inline-flex items-center justify-center rounded-full mb-4">
                                        <img 
                                            src={ ContributorIcon } 
                                            alt=""
                                        />
                                    </div>
                                    <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                                        Cooperantes
                                    </h2>
                                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                                        { 
                                            loaded
                                            ?
                                            counter.contributors
                                            :
                                            <div className='animate-pulse'>{randomNumber}</div>
                                        }
                                    </h2>
                                </div>
                            </div>

                            <div className="xl:w-1/3 w-full p-4">
                                <div className="border border-gray-400 bg-white p-6 rounded-lg">
                                    <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                                        <img 
                                            src={ ProgramIcon } 
                                            alt="" 
                                        />
                                    </div>
                                    <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                                        Programas de formación
                                    </h2>
                                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-gray-900">
                                        { 
                                            loaded
                                            ?
                                            counter.programs
                                            :
                                            <div className='animate-pulse'>{randomNumber}</div>
                                        }
                                    </h2>

                                </div>
                            </div>
                            {/* Counters Card */}
                            
                            {/* Chart */}
                            <div className="xl:w-2/5 md:w-1/2 w-full p-4">
                                <div className="border border-gray-400 bg-white rounded-lg">
                                    <div className="py-3 px-10 border-b-[1px] border-gray-300">
                                        <h2 className="text-lg text-gray-900 font-bold">
                                            Programas
                                        </h2>
                                    </div>
                                    <div className="py-3 px-10">
                                        {
                                            loaded 
                                            ? (
                                                <DonutChart
                                                    data={loaded && chartDataProgramas.length > 0 ? chartDataProgramas : []}
                                                    category="Programas"
                                                    index='name'
                                                    colors={["blue", "orange", "indigo", "yellow", "emerald", "rose"]}
                                                    onValueChange={(v) => setValue(v)}
                                                    variant='pie'
                                                    noDataText='No hay datos para mostrar'
                                                    showAnimation={false}
                                                    className="w-full h-48 py-1"
                                                />
                                            ) : (
                                                <div className="relative">
                                                    <DonutChart
                                                        data={loaded && chartDataProgramas.length > 0 ? chartDataProgramas : []}
                                                        category="Programas"
                                                        index='name'
                                                        colors={["blue", "orange", "indigo", "yellow", "emerald", "rose"]}
                                                        onValueChange={(v) => setValue(v)}
                                                        variant='pie'
                                                        noDataText='No hay datos para mostrar'
                                                        showAnimation={false}
                                                        className="invisible w-full h-48 py-1"
                                                    />
                                                    <Preloader />
                                                </div>
                                            )
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            
                            {/* Chart */}

                            {/* Table */}
                            <div className="xl:w-3/5 md:w-1/2 w-full p-4">
                                <div className="border border-gray-400 bg-white rounded-lg h-full relative">
                                    <div className="px-10 py-3 border-b-[1px] border-gray-300">
                                        <h2 className="text-lg text-gray-900 font-bold">Programas de formación activos</h2>
                                    </div>
                                    {
                                        loaded
                                        ? (
                                            <section className="text-gray-600 body-font">
                                                <div className="">
                                                    <div className="w-full mx-auto overflow-auto">
                                                        <table className="table-auto w-full text-left whitespace-no-wrap rounded-b overflow-hidden">
                                                            <thead>
                                                                <tr className="text-center bg-gray-300">
                                                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm">
                                                                        <b>#</b>
                                                                    </th>
                                                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm">
                                                                        <b>Programa</b>
                                                                    </th>
                                                                    <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm">
                                                                        <b>Fecha de finalización</b>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { (table.length > 0) && (
                                                                    table.map((item, index) => {
                                                                        // console.log(item, "item")
                                                                        // Darle vuelta a la fecha
                                                                        let date = item.end_date.split('-');
                                                                        return (
                                                                            <tr
                                                                                key={ index } 
                                                                                id={`${item.id}`} 
                                                                                className="bg-gray-100 w-full cursor-pointer transition-colors hover:bg-purple-cnx hover:text-white"
                                                                                onClick={ (e) => {
                                                                                    let id = e.currentTarget.id;
                                                                                    navigate(`/VerProgramaDeFormacion/${id}`);
                                                                                } }
                                                                            >
                                                                                <td className="text-center px-4 py-3">
                                                                                    { item.id }
                                                                                </td>
                                                                                <td className="text-center px-4 py-3">
                                                                                    { item.name }
                                                                                </td>
                                                                                <td className="text-center px-4 py-3">
                                                                                    { date[2] + '/' + date[1] + '/' + date[0]}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) }
                                                                { (table.length < 1) && (
                                                                    <tr>
                                                                        <td className="text-center py-3" colSpan={ 3 }>
                                                                            <strong>Sin programas de formación en curso</strong>
                                                                        </td>
                                                                    </tr>
                                                                ) }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </section>
                                        ) : (
                                            <div className="py-3 px-10">
                                                <section className="text-gray-600 body-font invisible">
                                                    <div className="">

                                                        <div className="w-full mx-auto overflow-auto">
                                                            <table className="table-auto w-full text-left whitespace-no-wrap rounded overflow-hidden">
                                                                <thead>
                                                                    <tr className="text-center bg-gray-300">
                                                                        <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm">
                                                                            <b>#</b>
                                                                        </th>
                                                                        <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm">
                                                                            <b>Programa</b>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    { table && (
                                                                        table.map((item, index) => {
                                                                            return (
                                                                                <tr key={ index } className="bg-gray-100">
                                                                                    <td className="text-center px-4 py-3">
                                                                                        <b>{ item.id }</b>
                                                                                    </td>
                                                                                    <td className="text-center px-4 py-3">{ item.name }</td>
                    
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) }
                                                                    { !table && (
                                                                        <tr>
                                                                            <td className="text-center" colSpan={ 2 }>
                                                                                Sin registros
                                                                            </td>
                                                                        </tr>
                                                                    ) }
                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>
                                                </section>
                                                <Preloader />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {/* Table */}

                            <div className="w-full p-4">
                                <div className="border border-gray-400 bg-white rounded-lg">
                                    <div className="py-3 px-10 border-b-[1px] border-gray-300">
                                        <h2 className="text-lg text-gray-900 font-bold">
                                            Jóvenes en pasantías por mes
                                        </h2>
                                    </div>
                                    <div className="py-3 px-10">  
                                    {
                                            loaded 
                                            ? (
                                                <BarChart
                                                    className='bg-white'
                                                    data={loaded && chartDataPasantias.length > 0 ? chartDataPasantias : []}
                                                    index="name"
                                                    categories={["Pasantías"]}
                                                    colors={["blue", "orange", "indigo", "yellow", "emerald", "rose"]}
                                                    yAxisWidth={18}
                                                    noDataText='No hay datos para mostrar'
                                                    showAnimation={false}
                                                />
                                            ) : (
                                                <div className='relative'>
                                                    <BarChart
                                                        className='invisible'
                                                        data={loaded && chartDataPasantias.length > 0 ? chartDataPasantias : []}
                                                        index="name"
                                                        categories={["Pasantías"]}
                                                        colors={["blue", "orange", "indigo", "yellow", "emerald", "rose"]}
                                                        yAxisWidth={18}
                                                        noDataText='No hay datos para mostrar'
                                                        showAnimation={false}
                                                    />
                                                    <Preloader />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <button className="flex mx-auto mt-16 text-white bg-violet-700 border-0 py-2 px-8 focus:outline-none hover:bg-violet-800 rounded text-lg">Reportes</button> */}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Dashboard