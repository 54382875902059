export const utilObject = (object, deleteItems) => {
    const newObject = [];
    object.forEach((item) => {
        if (!deleteItems.includes(item.name)) {
            newObject.push(item);
        }
    });
    return newObject;
}

export const filterDataReactSelect = (dataFilter, typeData) => {
    let options = [];
    dataFilter.map((item) => {
        options.push({ value: item.id, label: item.name, name: typeData });
    });
    return options;
}

export const filterDataChartDonut = (dataFilter, typeData) => {
    let options = [];
    dataFilter.map((item) => {
        options.push({ name: item.label, value: item.value, type: typeData });
    });
    return options;
}