
export const InputField = ({ label, type, name, value, required = false, onChange }) => {
    return (
        <div className={ `mb-4 ${ required ? 'input-required' : '' }` }>
            <label className="block mb-2 text-sm font-medium text-black" htmlFor={ name }>
                { label } { required && <span className="text-red-600">*</span> }
            </label>
            <input type={ type } name={ name } value={ value } onChange={ onChange } className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 outline-none focus:bg-gray-100 transition-colors border-2 border-gray-300" />
        </div>
    );
}

export const SelectField = ({ label, name, value, onChange, items, required = false, noValue }) => {
    return (
        <div className={ `mb-4 ${ required ? 'input-required' : '' }` }> 
            <label className="block mb-2 text-sm font-medium text-black" htmlFor={ name }>
                { label } { required && <span className="text-red-600">*</span> }
            </label>
            <select 
                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 outline-none focus:bg-gray-100 transition-colors border-2 border-gray-300"
                name={ name } 
                defaultValue={ value }
                onChange={ onChange } 
            >
                <option value={ 0 }>[Seleccione una opción]</option>
                { items.map((item, index) => {
                    return (
                        <option key={ index } value={ item.value }>
                            { item.label }
                        </option>
                    );
                }) }
            </select>
            { required && noValue && (
                <span className="text-red-600 text-sm">Este campo es requerido</span>
            )}
        </div>
    );
};
