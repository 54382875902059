import { useState, useEffect } from 'react';
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import { getOrDeleteRequest } from "../utils/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import CampusRSelect from "../Components/CampusRSelect";
import KnowledgesRSelect from '../Components/KnowledegesRSelect';

const Filters = (props) => {

    const initParamFilters = {
        campus: null,
        modality: null,
        program: null,
        organizer: null,
        knowledge: []
    }

    const [dataOrganizers, setDataOrganizers] = props.useState();
    const [dataModalities, setDataModalities] = props.useState();
    const [dataTrainingCatalog, setDataTrainingCatalog] = props.useState();
    const [textFilters, setTextFilters] = useState(false);
    const [messageDatatable, setMessagesDatatable] = props.useState('Cargando...');
    const [paramFilters, setParamFilters] = useState([initParamFilters]);

    useEffect(() => {
        getOrganizers();
        getModalities();
        getTrainingCatalog();
    }, []);


    const getOrganizers = async () => {
        const response = await getOrDeleteRequest(
            'contributor/filter',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataOrganizers(setFilterDataReactSelect(response, 'organizer'));
            // console.log(response);
        }
    }

    const getModalities = async () => {
        const response = await getOrDeleteRequest(
            'modality/filter',
            'GET'
        );
        if (response.length < 0) {
            // console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            // console.log(response);
        } else {
            setDataModalities(setFilterDataReactSelect(response, 'modality'));
            // console.log(response);
        }
    }

    const getTrainingCatalog = async () => {
        const response = await getOrDeleteRequest(
            'programs/filter',
            'GET'
        );
        if (response.length < 0) {
            // console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            // console.log(response);
        } else {
            setDataTrainingCatalog(setFilterDataReactSelect(response, 'program'));
            // console.log(response);
        }
    }

    const getfiltersPrograms = async () => {
        const endPoint = `program-schedule/index?` +
            `${paramFilters.campus !== undefined ? 'campus=' + paramFilters.campus + '&' : ''}` +
            `${paramFilters.modality !== undefined ? 'modality=' + paramFilters.modality + '&' : ''}` +
            `${paramFilters.program !== undefined ? 'program=' + paramFilters.program + '&' : ''}` +
            `${paramFilters.organizer !== undefined ? 'organizer=' + paramFilters.organizer + '&' : ''}` +
            `${paramFilters.knowledge !== undefined && paramFilters.knowledge.length > 0 ? 'knowledge=' + paramFilters.knowledge + '&' : ''}`;
        // console.log(endPoint.slice(0, -1));
        const response = await props.getOrDeleteRequest(
            endPoint.slice(0, -1),
            'GET'
        );
        if (response.length < 0) {
            // console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            // console.log(response, 'error');
            props.setDataSearch([]);
        } else {
            // console.log(response);
            props.setDataSearch(response);
        }
    }

    const searchFilter = (termSearch, nameInput, dataArray) => {
        // console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = props.data.filter((item) => {
                    if (item.alternate_name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.program.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.modality.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.start_date.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                props.setDataSearch(srch);
                break;
            case 'campus':
                props.dataFilter.campus = termSearch;
                paramFilters.campus = termSearch;
                getfiltersPrograms();
                break;
            case 'organizer':
                props.dataFilter.organizer = termSearch;
                paramFilters.organizer = termSearch;
                getfiltersPrograms();
                break;
            case 'modality':
                props.dataFilter.modality = termSearch;
                paramFilters.modality = termSearch;
                getfiltersPrograms();
                break;
            case 'program':
                props.dataFilter.program = termSearch;
                paramFilters.program = termSearch;
                getfiltersPrograms();
                break;
            case 'knowledge':
                props.dataFilter.knowledge = dataArray;
                paramFilters.knowledge = dataArray;
                if (dataArray.length > 0) {
                    paramFilters.knowledge = addElement(dataArray);
                }
                // console.log(paramFilters.knowledge);
                getfiltersPrograms();
                break;
            default:
                break;
        }
    }

    const addElement = (dataArray) => {
        const arrayIds = dataArray.map(item => {
            return item.value;
        });
        return arrayIds;
    }

    const setFilterDataReactSelect = (dataFilter, typeData) => {
        let options = [];
        dataFilter.map((item) => {
            options.push({ value: item.id, label: item.name, name: typeData });
        });
        // console.log(options);
        return options;
    }

    const setFilterDataReactSelect2 = (dataFilter, typeData) => {
        let options = [];
        dataFilter.map((item) => {
            options.push({ value: item.id, label: item.campus_name, name: typeData });
        });
        // console.log(options);
        return options;
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        // console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        props.setDataFilter({ ...props.dataFilter, [e.target.name]: e.target.value });
    }

    const changeDataRSelect = (e) => {
        // console.log(e);
        searchFilter(e.value, e.name, '');
        props.setDataFilter({ ...props.dataFilter, [e.name]: e });
    }

    const changeDataRSelectMulti = (e) => {
        // console.log(e);
        if (e.length > 0) {
            searchFilter('', e[0].name, e);
        } else {
            props.dataFilter.knowledge = [];
            paramFilters.knowledge = [];
            getfiltersPrograms();
        }
    }

    const resetFilter = () => {
        props.setDataFilter(props.initDataFilter);
        setParamFilters([initParamFilters]);
        props.getTraningPrograms();
    }

    return (<>
        <div className="w-full px-4 flex flex-wrap">
            <div className="w-full md:w-1/3 px-2">
                <div className="relative mb-2">
                    <label htmlFor="search" className="block mb-2 text-white">Búsqueda</label>
                    <input
                        type="text"
                        id="search"
                        name="search"
                        className="text-sm rounded block w-full p-2.5 text-gray-500 bg-white"
                        value={props.dataFilter.search}
                        autoComplete="none"
                        placeholder='Buscar...'
                        onChange={changeData}
                    />
                </div>
            </div>
            <CampusRSelect
                useState={useState}
                useEffect={useEffect}
                select={Select}
                getOrDeleteRequest={getOrDeleteRequest}
                dataFilterCampus={props.dataFilter.campus}
                setFilterDataReactSelect2={setFilterDataReactSelect2}
                changeDataRSelect={changeDataRSelect}
            />
            <div className="w-full md:w-1/3 px-2">
                <div className="mb-6">
                    <label htmlFor="organizer" className="block mb-2 text-white">Cooperantes</label>
                    <Select
                        name="organizer"
                        id="organizer"
                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                        value={props.dataFilter.organizer}
                        options={dataOrganizers}
                        placeholder={"Selecciona una opción"}
                        noOptionsMessage={() => 'No hay mas opciones'}
                        onChange={changeDataRSelect}
                    />
                </div>
            </div>
        </div>
        <div className="relative">
            <div className="absolute right-6 top-0 text-red-400 text-sm hover:underline cursor-pointer" onClick={resetFilter} >
                <FontAwesomeIcon icon={faTrash} /> Quitar filtros
            </div>
            <details className="">
                <summary className="w-1/2 md:w-1/5 lg:w-[12%] pb-2 px-2 flex justify-evenly items-center font-medium cursor-pointer list-none" onClick={() => { setTextFilters(!textFilters) }} >
                    {textFilters
                        ? <div className="text-sm hover:underline text-yellow-500"> <FontAwesomeIcon icon={faCircleMinus} /> Menos filtros</div>
                        : <div className="text-sm hover:underline text-yellow-500"> <FontAwesomeIcon icon={faCirclePlus} /> Más filtros</div>
                    }
                </summary>
                <div className="group-open:animate-fadeIn">
                    <div className="w-full px-4 flex flex-wrap">
                        <div className="w-full md:w-1/2 px-2">
                            <div className="mb-6">
                                <label htmlFor="modality" className="block mb-2 text-white">Modalidades</label>
                                <Select
                                    name="modality"
                                    id="modality"
                                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                    value={props.dataFilter.modality}
                                    options={dataModalities}
                                    placeholder={"Selecciona una opción"}
                                    noOptionsMessage={() => 'No hay mas opciones'}
                                    onChange={changeDataRSelect}
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-2">
                            <div className="mb-6">
                                <label htmlFor="program" className="block mb-2 text-white">Catálogo de formación</label>
                                <Select
                                    name="program"
                                    id="program"
                                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                    value={props.dataFilter.program}
                                    options={dataTrainingCatalog}
                                    placeholder={"Selecciona una opción"}
                                    noOptionsMessage={() => 'No hay mas opciones'}
                                    onChange={changeDataRSelect}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-full px-4 flex flex-wrap">
                        <KnowledgesRSelect
                            useState={useState}
                            useEffect={useEffect}
                            select={Select}
                            getOrDeleteRequest={getOrDeleteRequest}
                            dataFilterKnowledge={props.dataFilter.knowledge}
                            setFilterDataReactSelect={setFilterDataReactSelect}
                            changeDataRSelectMulti={changeDataRSelectMulti}
                        />
                    </div> */}
                </div>
            </details>
        </div>
    </>)
}
export default Filters;