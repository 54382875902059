import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const UserCard = ({ names, lastnames, email, username, role, status }) => {
    return (
        <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4">
            <div className="flex-none sm:flex">
                <div className=" relative h-32 w-32 sm:mb-0 mb-3">
                    <div className="relative inline-flex items-center justify-center order-1 md:order-2">
                        <span className="font-medium flex justify-center items-center w-32 h-32 overflow-hidden rounded-2xl bg-orange-600 text-white uppercase text-2xl">
                            {names.charAt(0)}{lastnames.charAt(0)}
                        </span>
                    </div>
                </div>
                <div className="flex flex-col justify-between ml-4 flex-grow">
                    <div className="flex items-center justify-between sm:mt-2">
                        <div className="flex items-center">
                            <div className="flex flex-col">
                                <div className="w-full flex-none text-lg text-gray-800 font-bold leading-none">
                                    { names } { lastnames }
                                </div>
                                <div className="flex-auto text-gray-500 my-1">
                                    <span className="mr-3 ">{ username }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex pt-2  text-sm text-gray-500">
                        <div className="flex-1 inline-flex items-center">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                            <p className="">
                                { email }
                            </p>
                        </div>
                        <Link to={ `/UserHistory/${username}` } className="btn-card">
                            Ver historial
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserCard;
