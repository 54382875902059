import DataTable from "react-data-table-component";
import Preloader from "../Preloader/Preloader";

const PreloaderTables = () => {

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
    ];
    
    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 3,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 4,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 5,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 6,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 7,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 8,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 9,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 10,
            title: 'Ghostbusters',
            year: '1984',
        },
    ];

    return (
        <div className="w-full relative bg-white">
            <DataTable
                columns={columns}
                className="invisible"
                data={ data }
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                highlightOnHover
                responsive
                noDataComponent={<div className="my-5">No hay registros</div>}
            />
            <Preloader />
            <div className="top-0 absolute w-full h-12 bg-[#1e3a8a]"></div>
        </div>
    );
}

export default PreloaderTables;