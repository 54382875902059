import { ModalAddSoftSkillLogic } from './ModalAddSoftSkillLogic';

const ModalAddSoftSkill = (props) => {

    const params = {
        typeModal: props.typeModal,
        idEdit: props.idEdit,
        setShowModalAddSoftSkill: props.setShowModalAddSoftSkill,
        reloadData: props.reloadData
    }
    const { objC, objF } = ModalAddSoftSkillLogic(params);

    return (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold text-blue-950">{ props.typeModal == 'add' ? 'Agregar' : 'Actualizar' } habilidad blanda </h3>
                        {/* <button
                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={ () => {objF.opeModalSoftSkill()} }
                        >
                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"> × </span>
                        </button> */}
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div className="my-4 text-slate-500 text-lg leading-relaxed">
                            <form className="w-full max-w-sm" onSubmit={objF.handelSubmit}>
                                <div className="my-4">
                                    <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4" htmlFor='name'> Habilidad </label>
                                    <input
                                        type="text"
                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                        id='name'
                                        name="name"
                                        value={ objC.dataSoftSkill.name }
                                        onChange={ objF.handelChange }
                                    />
                                    {objC.alerts.filter(input => input.inputName == "name" && input.status === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))}
                                </div>
                                <div className="my-4">
                                    <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4" htmlFor='description'> Descripción corta </label>
                                    <textarea
                                        type="text"
                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 resize-none"
                                        id='description'
                                        name="description"
                                        rows={ 4 }
                                        value={ objC.dataSoftSkill.description }
                                        onChange={ objF.handelChange }
                                    ></textarea>
                                    {objC.alerts.filter(input => input.inputName == "description" && input.status === true).map(message => (
                                        <span className="text-red-500">{message.message}</span>
                                    ))}
                                </div>
                                <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b justify-center">
                                    <button className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  " type="submit">
                                        { props.typeModal == 'add' ? 'Agregar' : 'Actualizar' }
                                    </button>
                                    <button
                                        className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                        type="button"
                                        onClick={
                                            () => {
                                                objF.setShowModalAddSoftSkill(false);
                                            }
                                        }
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalAddSoftSkill;
