// Filter data by its properties making a new array with the properties that have been selected
export const filterExportDataKeyes = ( keyesExport, initialData ) => {
    return initialData.map(ele => {
        const rest = {};
        keyesExport.forEach(key => { rest[key] = ele[key]; });
        return rest;
    });
}

export const filterWithoutValue = (data) =>{
    let newData = data.map(obj => {
        Object.keys(obj).forEach(key => {
            obj[key] = obj[key] === null || obj[key] === undefined || obj[key] === '' ? 'No especificado' : obj[key];
        });
        return obj;
    });
    return newData;
}