import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "js-cookie";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { paginationProps, getOrDeleteRequest, postOrPutRequest, showPopup } from "../utils/util";

import ImportButton from "../ImportButton";

import AddTraning from "./AddTraning";
import UpdateTraning from "./UpdateTraining";
import SearchInput from "../Components/SearchInput";
import PreloaderTables from "../PreloaderTables/PreloaderTables";

const TrainingCatalog = () => {

    const initDataFilter = {
        search: ''
    }

    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = useState(false);
    const [id, setId] = useState(null);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [data, setData] = useState([]);
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [dataSearch, setDataSearch] = useState([]);
    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');

    const columns = [
        {
            name: '#',
            width: "75px",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre',
            width: "300px",
            cell: (row) => <div>{loaded && row.name}</div>
        },
        {
            name: 'Tipo de programa',
            width: "300px",
            cell: (row) => <div>{loaded && row.type}</div>
        },
        {
            name: 'Estado',
            cell: (row) =>
                <div className="flex items-center">
                    {(row.status == 1)
                        ? (<><div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</>)
                        : (<><div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div> Inactivo</>)
                    }
                </div>
        },
        {
            name: "Activar/Desactivar",
            width: "170px",
            cell: (row) => loaded &&
                <div className="flex justify-center w-full">
                    <label className="inline-flex relative items-center mr-5 cursor-pointer">
                        <input
                            onClick={(e) => { enableOrDisableProgram(e.target.id, row.status); }}
                            id={row.id}
                            type="checkbox"
                            className="sr-only peer"
                            checked={row.status == 1 ? true : row.status == 2 && false}
                            readOnly
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                    </label>
                </div>
        },
        {
            name: 'Editar',
            width: "80px",
            cell: (row) =>
                <div>
                    <button
                        onClick={() => {
                            setId(row.id);
                            showPopup(true, setShowUpdateModal);
                        }}
                        type="button"
                        data-modal-toggle="user-modal"
                        className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center"
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </div>
        },

    ];

    const tableData = {
        columns,
        data
    };

    useEffect(() => {
        if (loaded === false) { loadData(); }
    }, []);

    const loadData = useCallback(async () => {
        const response = await getOrDeleteRequest(
            "programs/index",
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            setData([]);
        } else {
            setData(response);
            setDataSearch(response);
            console.log(response);
        }
        setLoaded(true);
    }, []);

    const enableOrDisableProgram = async (id, status) => {
        let alert = await MySwal.fire({
            icon: "warning",
            title: "¿Estás seguro de realizar esta operación?",
            // text: "No podrás revertir este cambio",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, estoy seguro",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return MySwal.getConfirmButton().click();
            }
        });

        if (!alert.isConfirmed) {
            console.log("Action cancelled");
            return;
        }

        let response = null;

        switch (status) {
            case 1:
            default:
                response = await getOrDeleteRequest(
                    `programs/disable/${id}`,
                    'DELETE'
                );
                break;

            case 2:
                response = await postOrPutRequest(
                    `programs/enable/${id}`,
                    'PUT'
                );
                break;
        }

        if (response.type === "error") {
            MySwal.fire({
                icon: "error",
                title: "Ocurrió un problema",
                text: response.message,
                showConfirmButton: true,
            });

            return;
        }

        MySwal.fire({
            icon: "success",
            title: "Operación exitosa",
            text: response.message,
            showConfirmButton: true,
        });

        loadData();
    };

    const searchFilter = (termSearch, nameInput, dataArray) => {
        console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = data.filter((item) => {
                    if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                setDataSearch(srch);
                break;
            default:
                break;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        setDataFilter({ ...dataFilter, [e.target.name]: e.target.value });
    }

    return (
        <div className="block relative">
            <div className="p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                <div className="mb-1 w-full">
                    <div className="sm:flex">
                        <h1 className="text-xl sm:text-2xl font-semibold text-white">Catálago de Formación</h1>
                        <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                            <button
                                type="button"
                                data-modal-toggle="add-internship-modal"
                                className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                onClick={() => showPopup(true, setShowRegisterModal)}
                            >
                                <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                                </svg>
                                Agregar Formación
                            </button>
                            <ImportButton origin="program" info={loadData} roles={[1, 2]} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-2 px-4 bg-blue-950">
                <SearchInput dataFilterSearch={dataFilter.search} changeData={changeData} />
                <div className="px-2 py-2">
                    {
                        loaded
                        ?
                        <DataTable
                            columns={columns}
                            data={dataSearch}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            responsive
                            paginationComponentOptions={paginationProps}
                            noDataComponent={<div className="my-5">Sin registros disponibles</div>}
                        />
                        :
                        <PreloaderTables />
                    }
                </div>
            </div>
            {showRegisterModal && <AddTraning showPopup={showPopup} modal={setShowRegisterModal} loadData={loadData} />}
            {showUpdateModal && <UpdateTraning showPopup={showPopup} modal={setShowUpdateModal} loadData={loadData} id={id} />}
        </div>
    )
};

export default TrainingCatalog;
