// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Preloader from "../componentes/Preloader/Preloader";

const ProfileCard = ({ names, lastnames, photo, program, email, age, loaded }) => {
    console.log(names, "Nombre");
    console.log(lastnames, "Apellidos");
    return (
        <div className="bg-white border border-white rounded-3xl">
            <div className="flex-none sm:flex">
                { loaded && (
                    <>
                        <div className=" relative h-32 w-32 sm:mb-0 mb-3">
                            <div className="relative inline-flex items-center justify-center order-1 md:order-2">
                                { photo === null && (
                                    <span className="font-medium flex justify-center items-center w-32 h-32 overflow-hidden rounded-2xl bg-orange-600 text-white uppercase text-2xl">
                                        { names !== null && lastnames !== null && (
                                            names.charAt(0) + lastnames.charAt(0)
                                        ) }
                                        { names === null && lastnames === null && (
                                            "NA"
                                        ) }
                                    </span>    
                                ) }
                                { photo !== null && (
                                    <img 
                                        src={ `data:image/jpg;base64,${photo}` }
                                        className=" w-32 h-32 object-cover rounded-2xl"
                                        alt="" 
                                    />   
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col justify-between ml-4 flex-grow">
                            <div className="flex items-center justify-between sm:mt-2">
                                <div className="flex items-center">
                                    <div className="flex flex-col">
                                        <div className="w-full flex-none text-lg text-gray-800 font-bold leading-none">
                                        { names !== null && lastnames !== null && (
                                            names + " " + lastnames
                                        ) }
                                        { names === null && lastnames === null && (
                                            "Cargando..."
                                        ) }
                                        </div>
                                        <div className="flex-auto text-gray-500 my-1">
                                            <span className="mr-3 ">
                                                { age === null ? "Cargando..." : age + " años" }
                                            </span>
                                        </div>
                                        <div className="flex-auto text-gray-500 my-1">
                                            <span className="mr-3 ">Programa: { program }</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex pt-2 text-sm text-gray-500">
                                <div className="flex-1 inline-flex items-center">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                                    <p>
                                        { email === null ? "Correo no registrado" : email }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                ) }
                { !loaded && (
                    <Preloader global={ false } />
                ) }
            </div>
        </div>
    );
};

export default ProfileCard;
