import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CompanyDetailLogic } from "./CompanyDetailLogic";
import { Link, useParams } from "react-router-dom";
import { getOrDeleteRequest } from "../utils/util";
import { set } from "date-fns";

const ModalStateCooperante = (props) => {
    const { id, setCooperantes, cooperantes } = props;
    const [option, setOption] = useState(0);
    const [mostrarBoton, setMostrarBoton] = useState(false);
    const MySwal = withReactContent(Swal);
    const [showModal, setShowModal] = useState(true);

    const params = {
        useState: useState,
        id: useParams("id").id,
        getOrDeleteRequest: getOrDeleteRequest,
        Link: Link
    }

    const { getContributor } = CompanyDetailLogic(params);

    const modificarEstado = async () => {
        if (option == 0) {
            MySwal.fire(
                'Error',
                "Debe seleccionar una opción",
                'error'
            );
            return;
        } else {
            const url = `${process.env.REACT_APP_API_URI}contributor/bulk-status/${id}`;

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            };

            const dataInsertar = {
                students: props.arrayIdChecked,
                status: option
            };

            console.log(props, 'props')

            console.log(dataInsertar, 'dataInsertar')

            axios.post(url, dataInsertar, config).then(response => {
                console.log(response);
                if (response.data.type === "error") {
                    MySwal.fire(
                        'Error',
                        response.data.message,
                        'error'
                    );
                } else {
                    MySwal.fire(
                        'Éxito',
                        response.data.message,
                        'success'
                    ).then(() => {
                        props.show(false);
                        props.function();
                        //Pasar a checked false a todos los checkbox que tengan la clase checkbox-multiple
                        let checkboxes = document.querySelectorAll('.checkbox-multiple');
                        checkboxes.forEach((item) => {
                            item.checked = false;
                        });
                    });
                }
            }).catch(err => {
                console.log(err);
                let message = err.response.data.message;
                MySwal.fire(
                    'Error',
                    message,
                    'error'
                );
            });
        }
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-950">
                                Acción por lote
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.show(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative flex-wrap">
                            <div className="mt-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full flex flex-wrap">
                                    <div className="w-full p-5">
                                        <h1>
                                            Nota: Las acciones retirado y concluida no se encuentran disponibles ya que no se pueden modificar por lotes.
                                        </h1>
                                    </div>
                                    <div className="flex my-6 w-full justify-center items-center">
                                        <label className="block text-blue-950 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                            Acción
                                        </label>
                                        <div className="mb-2 xl:w-100">
                                            <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="option" onChange={
                                                (e) => setOption(e.target.value)
                                            }>
                                                <option value="0" selected>Selecciona una opción</option>
                                                <option value="1">En espera</option>
                                                <option value="2">En proceso</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b justify-center w-full">

                                        <div
                                            className="bg-blue-950 cursor-pointer text-white active:bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modificarEstado}
                                        >
                                            Modificar
                                        </div>

                                        <div
                                            className="bg-red-500 cursor-pointer text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center"
                                            onClick={() => props.show(false)}
                                        >
                                            Cerrar
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                        {/*footer*/}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default ModalStateCooperante;