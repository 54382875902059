import { useState, useEffect } from "react";
import { getOrDeleteRequest } from "../utils/util";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const Filters = (props) => {

    const initParamFilters = {
        country: null,
        program: [],
        internship: null,
        type: null
    }

    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');
    const [textFilters, setTextFilters] = useState(false);
    const [paramFilters, setParamFilters] = useState([initParamFilters]);
    const [dataCountries, setDataCountries] = useState([]);
    const [dataTraningType, setDataTraningType] = useState([]);
    const [dataCooperatorsType, setDataCooperatorsType] = useState([]);

    useEffect(() => {
        getCountries();
        getTrainingType();
        getCooperatorsType();
    }, [])


    const getCountries = async () => {
        const response = await getOrDeleteRequest(
            'country/filter',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataCountries(setFilterDataReactSelect(response, 'country'));
            console.log(response);
        }
    }

    const getTrainingType = async () => {
        const response = await getOrDeleteRequest(
            'course-type/filter',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataTraningType(setFilterDataReactSelect(response, 'program'));
            console.log(response);
        }
    }

    const getCooperatorsType = async () => {
        const response = await getOrDeleteRequest(
            'contributor-type/filter',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataCooperatorsType(setFilterDataReactSelect(response, 'type'));
            console.log(response);
        }
    }

    const setFilterDataReactSelect = (dataFilter, typeData) => {
        let options = [];
        dataFilter.map((item) => {
            options.push({ value: item.id, label: item.name, name: typeData });
        });
        console.log(options);
        return options;
    }

    const getfiltersCooperators = async () => {
        const endPoint = `contributor/index?` +
            `${paramFilters.country !== undefined ? 'country=' + paramFilters.country + '&' : ''}` +
            `${paramFilters.program !== undefined && paramFilters.program.length > 0 ? 'program=' + paramFilters.program + '&' : ''}` +
            `${paramFilters.internship !== undefined ? 'internship=' + paramFilters.internship + '&' : ''}` +
            `${paramFilters.type !== undefined ? 'type=' + paramFilters.type + '&' : ''}`;
        console.log(endPoint.slice(0, -1));
        const response = await getOrDeleteRequest(
            endPoint.slice(0, -1),
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response, 'error');
            props.setDataSearch([]);
        } else {
            console.log(response);
            props.setDataSearch(response);
        }
    }

    const addElement = (dataArray) => {
        const arrayIds = dataArray.map(item => {
            return item.value;
        });
        return arrayIds;
    }

    const searchFilter = (termSearch, nameInput, dataArray) => {
        console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = props.data.filter((item) => {
                    if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.country.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.city.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                        item.email.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                props.setDataSearch(srch);
                break;
            case 'country':
                props.dataFilter.country = termSearch;
                paramFilters.country = termSearch;
                getfiltersCooperators();
                break;
            case 'program':
                props.dataFilter.program = dataArray;
                paramFilters.program = dataArray;
                if (dataArray.length > 0) {
                    paramFilters.program = addElement(dataArray);
                } else {
                    props.dataFilter.program = [];
                    paramFilters.program = [];
                }
                getfiltersCooperators();
                break;
            case 'internship':
                props.dataFilter.internship = termSearch;
                paramFilters.internship = termSearch;
                getfiltersCooperators();
                break;
            case 'type':
                props.dataFilter.type = termSearch;
                paramFilters.type = termSearch;
                getfiltersCooperators();
                break;
            default:
                break;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        props.setDataFilter({...props.dataFilter, [e.target.name]: e.target.value});
    }

    const changeDataRSelect = (e) => {
        console.log(e);
        searchFilter(e.value, e.name, '');
        props.setDataFilter({...props.dataFilter, [e.name]: e});
    }

    const changeDataRSelectMulti = (e) => {
        console.log(e);
        if (e.length > 0) {
            searchFilter('', e[0].name, e);
        } else {
            props.dataFilter.program = [];
            paramFilters.program = [];
            getfiltersCooperators();
        }
    }

    const resetFilter = () =>{
        props.setDataFilter(props.initDataFilter);
        setParamFilters([initParamFilters]);
        props.getInfo();
    }

    return (<>
        <form className="text-sm font-medium">
            <div className="w-full flex flex-wrap">
                <div className="w-full md:w-1/3 px-2">
                    <div className="relative mb-2">
                        <label htmlFor="search" className="block mb-2 text-white">Búsqueda</label>
                        <input type="text"
                            id="search"
                            name="search"
                            className="text-sm rounded block w-full px-2.5 py-2.5 text-gray-500 bg-white"
                            value={ props.dataFilter.search }
                            autoComplete="none"
                            placeholder='Buscar...'
                            onChange={changeData}
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/3 px-2">
                    <div className="mb-6">
                        <label htmlFor="country" className="block mb-2 text-white">País</label>
                        <Select
                            name="country"
                            id="country"
                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                            value={ props.dataFilter.country }
                            options={ dataCountries }
                            placeholder={ "Selecciona una opción" }
                            noOptionsMessage={ () => 'No hay mas opciones' }
                            onChange={ changeDataRSelect }
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/3 px-2">
                    <div className="mb-6">
                        <label htmlFor="type" className="block mb-2 text-white">Tipo de cooperante</label>
                        <Select
                            name="type"
                            id="type"
                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                            value={ props.dataFilter.type }
                            options={dataCooperatorsType}
                            placeholder={"Selecciona una opción"}
                            noOptionsMessage={() => 'No hay mas opciones'}
                            onChange={changeDataRSelect}
                        />
                    </div>
                </div>
            </div>
            <div className="relative">
                <div className="absolute right-6 top-0 text-red-400 text-sm hover:underline cursor-pointer" onClick={resetFilter} >
                    <FontAwesomeIcon icon={faTrash}/> Quitar filtros
                </div>
                <details className="">
                    <summary className="w-1/2 md:w-1/5 lg:w-[12%] pb-2 px-2 text-white text-sm hover:underline flex justify-between items-center font-medium cursor-pointer list-none" onClick={() => { setTextFilters(!textFilters) }} >
                        {textFilters 
                            ? <div className="text-sm hover:underline text-yellow-500"> <FontAwesomeIcon icon={faCircleMinus} /> Menos filtros</div>
                            : <div className="text-sm hover:underline text-yellow-500"> <FontAwesomeIcon icon={faCirclePlus} /> Más filtros</div>
                        }
                    </summary>
                    <div className="group-open:animate-fadeIn">
                        <div className="w-full flex flex-wrap">
                            <div className="w-full md:w-2/3 px-2">
                                <div className="mb-6">
                                    <label htmlFor="knowledges" className="block mb-2 text-white">Tipos de formación</label>
                                    <Select
                                        isMulti
                                        id="program"
                                        name="program"
                                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                        value={ props.dataFilter.program }
                                        options={dataTraningType}
                                        placeholder={"Selecciona una opción"}
                                        noOptionsMessage={() => 'No hay mas opciones'}
                                        onChange={changeDataRSelectMulti}
                                    />
                                </div>
                            </div>
                            <div className="w-full md:w-1/3 px-2">
                                <div className="mb-6">
                                    <label htmlFor="internship" className="block mb-2 text-white">¿Ofrece pasantías?</label>
                                    <select
                                        name="internship"
                                        id="internship"
                                        className="text-sm rounded block w-full px-3 py-3 text-gray-500 bg-white"
                                        value={ props.dataFilter.internship }
                                        onChange={changeData}
                                    >
                                        <option disabled value={'default'}>Seleccione una opción </option>
                                        <option value={0}>Sí</option>
                                        <option value={1}>No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-wrap">
                        </div>
                    </div>
                </details>
            </div>
        </form>
    </>)
}
export default Filters;
