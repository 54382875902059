import { useState, useEffect } from "react";
import { showPopup, getOrDeleteRequest, postOrPutRequest, paginationProps } from '../../../utils/util';
import { validateData } from '../../../utils/validateData';
import Swal from 'sweetalert2';

export const EditWorkExperience = (params) => {
    const { setShowModalEditWorkExperience, idEditWorkExperience, dataWorkExperiences, getWorkExperiences } = params;
    const initDataExperience = {
        'institution': '',
        'job_title': '',
        'start_year': '',
        'start_month': '',
        'start_date': '',
        'end_year': '',
        'end_month': '',
        'end_date': '',
        'address': '',
        'current_job': false,
        'job_placement': ''
    }

    const initialStateInput = {
        inputName: "",
        message: '',
        status: false
    };

    const months = [
        { label: 'Enero', value: '1' },
        { label: 'Febrero', value: '2' },
        { label: 'Marzo', value: '3' },
        { label: 'Abril', value: '4' },
        { label: 'Mayo', value: '5' },
        { label: 'Junio', value: '6' },
        { label: 'Julio', value: '7' },
        { label: 'Agosto', value: '8' },
        { label: 'Septiembre', value: '9' },
        { label: 'Octubre', value: '10' },
        { label: 'Noviembre', value: '11' },
        { label: 'Diciembre', value: '12' }
    ];

    const [dataSingle, setDataSingle] = useState(initDataExperience);
    const [alert, setAlert] = useState([initialStateInput]);
    const [optionsPlacementType, setOptionsPlacementType] = useState([]);

    useEffect(() => {
        getPlacementType();
    }, []);

    const getPlacementType = async() =>{
        let response = await getOrDeleteRequest(
            'job-placement-type/filter',
            'GET'
        );

        if(response.type == 'error'){
            console.error(response.message);
            return;
        }
        console.log(response);
        let selectData = response.map((item) => {
            return {
                value: item.id,
                label: item.type_name,
            }
        });
        console.log(selectData);
        setOptionsPlacementType(selectData);
        openModalEditWorkExperience(selectData);
    }


    const openModalEditWorkExperience = (selectData) => {
        let experience = dataWorkExperiences.find(objeto => objeto.id === parseInt(idEditWorkExperience));
        let current_job = experience.current_job === 1 ? true : false;
        let startMonth = months.find((item) => {
            return item.value == experience.start_date.month;
        });

        let endMonth = months.find((item) => {
            return item.value == experience.end_date.month;
        });

        let placementJob = selectData.find((item) => {
            return item.value == experience.job_placement;
        });

        setDataSingle(prevState => ({
            ...prevState,
            institution: experience.institution,
            job_title: experience.job_title,
            start_date: experience.start_date,
            start_month: startMonth,
            start_year: experience.start_date.year,
            end_date: experience.end_date,
            end_month: endMonth,
            end_year: experience.end_date.year,
            address: experience.address,
            current_job: current_job,
            job_placement: placementJob
        }));
    }

    const UpdateWorkExperience = (e) => {
        e.preventDefault();
        console.log(dataSingle);
        if(formatDate(dataSingle)){
            dataSingle.current_job && delete dataSingle.end_date;
    
            const inputs = Object.entries(dataSingle).map(([key, value]) => {
                return { name: key, value: value };
            });
    
            const resValidate = validateData(inputs);
            console.log(resValidate);
            setAlert(resValidate);
            let validation = resValidate.filter(item => item.status === true);
            validation.length < 1 && SendData();
        }
    }

    const formatDate = (data) =>{
        const start_date = `${data.start_year}-${data.start_month.value}-01`;
        dataSingle.start_date = start_date;
        const end_date = `${data.end_year}-${data.end_month.value}-01`;
        dataSingle.end_date = end_date;
        return true;
    }

    const HandelChange = ({ target: { value, name, checked } }) => {
        setDataSingle({ ...dataSingle, [name]: value });
        name === 'current_job' && setDataSingle({ ...dataSingle, current_job: checked });
    }

    const HandelChangeSelect = (e, type) => {
        console.log(e, type);
        setDataSingle({ ...dataSingle, [type]: e });
    }

    // const HandelChangeSelect2 = (e, type) => {
    //     console.log(e, type);
    //     setDataSingle({ ...dataSingle, [type]: e.value });
    // }

    const SendData = async () => {
        console.log('enviando...');
        console.log(dataSingle);
        dataSingle.current_job ? dataSingle.current_job = 1 : dataSingle.current_job = 0;
        console.log(dataSingle.job_placement);
        console.log(dataSingle.job_placement.value);
        dataSingle.job_placement != '' ? dataSingle.job_placement =  dataSingle.job_placement.value : dataSingle.job_placement =  dataSingle.job_placement;
        let response = await postOrPutRequest(
            `student/update-experience/${parseInt(idEditWorkExperience)}`,
            'PUT',
            dataSingle
        );
        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        showPopup(false, setShowModalEditWorkExperience);
        getWorkExperiences();
        setAlert([initialStateInput]);
        setDataSingle(initDataExperience);
    }

    const objC = {
        dataSingle: dataSingle,
        alert: alert,
        months:months,
        optionsPlacementType: optionsPlacementType
    }

    const objF = {
        HandelChange: HandelChange,
        UpdateWorkExperience: UpdateWorkExperience,
        HandelChangeSelect: HandelChangeSelect,
        // HandelChangeSelect2: HandelChangeSelect2
    }

    return { objC, objF }
}