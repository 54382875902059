import { useState, useEffect } from "react";
import { paginationProps, getOrDeleteRequest } from '../utils/util';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useParams, Link } from 'react-router-dom';
import BackButton from '../BackButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faCheckCircle, faCircleXmark, faEnvelope, faGlobeAmericas, faListCheck, faListUl, faPhoneFlip, faShapes, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { CompanyDetailLogic } from "./CompanyDetailLogic";
import Cookies from "js-cookie";
import SectionProfile from "../../layouts/SectionProfile";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import ModalStateCooperante from "./ModalStateCooperante";
import PreloaderTables from "../PreloaderTables/PreloaderTables";
import PreloaderComponentSectionProfile from "../Estudiantes/Preloaders/PreloaderComponentSectionProfile";
import Filters from './FiltersCompanyStudents';
import {DonutTremor} from '../Components/ChartsTremor';

const CompanyDetail = () => {
    const params = {
        useState: useState,
        id: useParams("id").id,
        getOrDeleteRequest: getOrDeleteRequest,
        Link: Link,
        useEffect: useEffect
    }
    const { objC, objF } = CompanyDetailLogic(params);

    const [showModal, setShowModal] = useState(false);
    const id = useParams("id").id;
    console.log(useParams("id").id);
    return (
        <div className='ContainerOpenClass'>
            <div className="p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                <div className="mb-1 w-full">
                    <SectionProfile title={<div className="flex items-center"><BackButton history={Cookies.get('prevLocation')} />&nbsp;Detalles del cooperante</div>}>
                        {
                            objC.loaded
                                ?
                                <div className="flex flex-wrap py-4">
                                    <div className="w-full md:w-[48%] bg-white rounded p-4 my-2 md:m-1">
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faBuilding} className="text-blue-700 px-4 py-1 w-[8%]" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">Empresa:</div>
                                            <div className="font-medium w-full flex justify-end">
                                                <div className="font-medium w-[80%]">{objC.cooperator.name}</div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faUserAlt} className="text-blue-700 px-4 py-1 w-[8%]" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">Persona de contacto:</div>
                                            <div className="font-medium w-full flex justify-end">
                                                <div className="font-medium w-[80%]">{objC.cooperator.contact_name}</div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faPhoneFlip} className="text-blue-700 px-4 py-1 w-[8%]" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">Teléfono:</div>
                                            <div className="font-medium w-full flex justify-end">
                                                <div className="font-medium w-[80%]">{objC.cooperator.full_phone}</div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faEnvelope} className="text-blue-700 w-[8%] px-4 py-1" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">Email:</div>
                                            <div className="w-full overflow-hidden flex justify-end">
                                                <div className="font-medium w-[80%]">
                                                    <a href={`mailto:${objC.cooperator.email}`} title={objC.cooperator.email} className="hover:border-b-2 hover:border-blue-700">{objC.cooperator.email}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faGlobeAmericas} className="text-blue-700 px-4 py-1 w-[8%]" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">País / Ciudad:</div>
                                            <div className="font-medium w-full flex justify-end">
                                                <div className="font-medium w-[80%]">{objC.cooperator.country_name} / {objC.cooperator.city}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-[48%] bg-white rounded p-4 my-2 md:m-1">
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faListUl} className="text-blue-700 px-4 py-1 w-[8%]" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">Tipo de cooperante:</div>
                                            <div className="font-medium w-full flex justify-end">
                                                <div className="w-[80%]">{objC.cooperator.type_name}</div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                            <FontAwesomeIcon icon={faListCheck} className="text-blue-700 px-4 py-1 w-[8%]" />
                                            <div className="font-semibold pr-2 w-[75%] md:w-[80%]">Ofrece pasantías:</div>
                                            <div className="font-medium w-full flex justify-end">
                                                {objC.cooperator.internships == 1
                                                    ? <div className="font-medium w-[80%]"> <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" /> Sí</div>
                                                    : <div className="font-medium w-[80%]"> <FontAwesomeIcon icon={faCircleXmark} className="text-red-500" />  No</div>
                                                }
                                            </div>
                                        </div>
                                        {objC.cooperator.offer.length > 0 &&
                                            <div className="bg-gray-200 relative flex flex-wrap items-start md:justify-end text-xs md:text-base rounded py-4 px-1 md:px-0.5 mb-4 w-full">
                                                <FontAwesomeIcon icon={faShapes} className="text-blue-700 px-4 py-1 w-[8%]" />
                                                <div className="font-semibold pr-2 w-[80%]"><span className="bg-blue-700 rounded-full px-2 py-1 mx-1 text-xs text-gray-200">{objC.cooperator.offer.length}</span>Tipos de programas que ofrece: </div>
                                                <div className="font-medium w-full flex justify-end">
                                                    <div className="flex flex-wrap w-[80%] py-4">
                                                        {objC.cooperator.offer.map((item, key) => (
                                                            <div key={key} className="ml-1 center relative inline-block select-none whitespace-nowrap rounded-full bg-blue-700 my-1 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                                                                <div className="mt-px">{item}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <PreloaderComponentSectionProfile filas={9} filas_sm={5} filas_md={5} />
                        }
                    </SectionProfile>
                    {objC.cooperator.internships == 1 &&
                        <>
                            <SectionProfile title="Graficos">
                                <div className="w-1/2 my-4">
                                    <DonutTremor data={objC.dataChartStatus} title={'Beneficiarios por estados'} />
                                </div>
                            </SectionProfile>
                            <SectionProfile title={"Beneficiarios en pasantías"} button={
                                objC.mostrarBoton
                                &&
                                <button
                                    className="bg-blue-950 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
                                    onClick={
                                        () => {
                                            setShowModal(true)
                                        }
                                    }
                                >
                                    Acción por lote
                                </button>
                            }>
                                <div className="flex flex-wrap py-2">
                                    <Filters
                                        setDataStudents={objC.setDataStudents}
                                        dataStudents={objC.dataStudents}
                                        loaded={objC.loaded}
                                        setLoaded={objC.setLoaded}
                                        dataFilter={objC.dataFilter}
                                        setDataFilter={objF.setDataFilter}
                                        initDataFilter={objC.initDataFilter}
                                        setDataSearch={objC.setDataSearch}
                                        id={objC.id}
                                    />
                                    {objC.loaded
                                        ? <DataTable
                                            columns={objC.columns}
                                            data={objC.loaded && objC.dataSearch}
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            highlightOnHover
                                            responsive
                                            paginationComponentOptions={paginationProps}
                                            noDataComponent={<div className="my-5">No hay estudiantes</div>}
                                        />
                                        : <PreloaderTables />
                                    }
                                </div>
                            </SectionProfile>
                        </>
                    }
                    {showModal && <ModalStateCooperante show={setShowModal} id={params.id} function={objF.getContributor} arrayIdChecked={objC.arrayIdChecked} setArrayIdChecked={objC.setArrayIdChecked} />}
                </div>
            </div>
        </div>
    )
}
export default CompanyDetail;
