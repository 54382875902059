import { faAddressCard, faEnvelope, faFilePdf, faPhoneAlt, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Preloader from "../../Preloader/Preloader";

const PreloaderCard = () => {
    return (
        <div className="relative">
            <div className="invisible">
                <div className="profile-card-title mt-2">
                    formulario.names
                    <br />
                    formulario.lastnames
                </div>
                {/* <div className="profile-card-subtitle">{loaded && `${edad(formulario.birth_date)} años`}</div> */}
                <div className="profile-card-subtitle">
                    <>
                        <button
                            id="recommend-letter"
                            className="btn-file-card text-black bg-slate-50 p-1.5 rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-blue-cnx hover:text-white transition-colors"
                        >
                            <FontAwesomeIcon className="text-xl" icon={faFilePdf} />
                        </button>
                        <span className="tooltip">
                            Carta de recomendación
                        </span>
                        <button
                            id="commitment-letter"
                            className="btn-file-card text-black bg-slate-50 p-1.5 rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-blue-cnx hover:text-white transition-colors"
                        >
                            <FontAwesomeIcon className="text-xl" icon={faSignature} />
                        </button>
                        <span className="tooltip">
                            Carta de compromiso
                        </span>
                        <button
                            id="curriculum"
                            className="btn-file-card text-black bg-slate-50 p-1.5 rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-blue-cnx hover:text-white transition-colors"
                        >
                            <FontAwesomeIcon className="text-xl" icon={faAddressCard} />
                        </button>
                        <span className="tooltip">
                            Curriculum vitae
                        </span>
                    </>
                </div>
                <div className="flex flex-col w-full h-full px-2 profile-card-info">
                    <div className="w-full">
                        <a
                            className="profile-card-item w-full text-gray-900 bg-white block py-1 px-2 rounded-lg hover:bg-blue-cnx hover:text-white transitions-colors mb-1"
                        >
                            <FontAwesomeIcon className='mr-3' icon={faEnvelope} />
                            ...
                        </a>
                        <span className="tooltip">formulario.email</span>
                    </div>
                    <div className="w-full">
                        <a
                            className="profile-card-item w-full text-gray-900 bg-white block py-1 px-2 rounded-lg hover:bg-blue-cnx hover:text-white transitions-colors"
                        >
                            <FontAwesomeIcon className='mr-3' icon={faPhoneAlt} />
                            formulario.phone
                        </a>
                    </div>
                </div>
            </div>
            <Preloader />
        </div>
    );
}

export default PreloaderCard;