import { useEffect, useState } from "react";
import { DesertionLetterLogic } from "./DesertionLetterLogic";
import { faCloudArrowUp, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "react-drag-drop-files";
const DesertionLetter = (props) => {
    const params = {
        setLetterDesertionStudent: props.setLetterDesertionStudent,
    };

    const [fileName, setFileName] = useState(null);

    useEffect(() => {
        console.log(props.data, "props.data");
        if (props.data !== null) {
            setFileName(props.data);
        } else {
            setFileName(null);
        }
    }, [props.data]);

    const { inputFile, handleFileChange, handleCloudClick } =
        DesertionLetterLogic(params);
    const FontAwesomeIcon = props.FontAwesomeIcon;
    return (
        <div className="w-full mx-4">
            <label className="block text-violet-700 font-bold">
                Carta de deserción
            </label>
            {/* <input
                type="file"
                accept=".pdf,.doc,.docx"
                className="hidden"
                ref={inputFile}
                onChange={(e) => {
                    handleFileChange(e);
                    setFileName(e.target.files[0].name);
                }}
            /> */}
            <FileUploader
                className="w-full h-20 border-2 border-gray-300 border-dashed rounded-lg hover:bg-gray-100"
                types={["pdf", "doc", "docx"]}
                handleChange={(e) => {
                    handleFileChange(e);
                    setFileName(e.name);
                }}
                children={
                    <>
                        <label
                            htmlFor="file-upload"
                            id="file-drag-psychological-profile"
                            // onClick={handleCloudClick}
                            className="cursor-pointer transition-colors flex w-full flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-gray-100 p-6 text-center"
                        >
                            {fileName !== null ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faFileAlt}
                                        className="file-drag-icon h-12 w-12 text-gray-700"
                                    />
                                    <h2 className="file-drag-title mt-4 text-xl font-medium text-gray-700 tracking-wide">
                                        Archivo
                                    </h2>
                                    <p
                                        id="file-drag-title"
                                        className="mt-2 text-gray-500 tracking-wide"
                                    >
                                        {fileName !== null ? fileName : ""}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon
                                        icon={faCloudArrowUp}
                                        className="file-drag-icon h-12 w-12 text-gray-700"
                                    />
                                    <h2
                                        id="file-drag-title"
                                        className="file-drag-title mt-4 text-xl font-medium text-gray-700 tracking-wide"
                                    >
                                        Subir archivo
                                    </h2>
                                    <p className="mt-2 text-gray-500 tracking-wide">
                                        Sube o arrastra tu archivo PDF, DOC, DOCX.{" "}
                                    </p>
                                </>
                            )}
                        </label>
                    </>
                }
            />
            {props.alertSpan && <span className="text-red-500">Campo requerido</span>}
        </div>
    );
};
export default DesertionLetter;
