import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const BackButton = ({ history }) => {
    return (
        <Link
            to={ history } 
            className="text-black bg-slate-50 py-3 px-4 text-sm font-medium text-center rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-gray-300 transition-colors"
        >
            <FontAwesomeIcon icon={faArrowLeft} className="inline-block fill-current -ml-1 mr-2 h-5 w-5" />
            <span className="ml-1">Regresar</span>
        </Link>
    );
};

export default BackButton;
