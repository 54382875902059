// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';    
import  { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import SideBarDropdown from "./SideBarDropdown";

const SidebarNav = ({ menu, role, showMenu, setShowMenu }) => {
    return (
        <>
            { menu.map((item, index) => {
                // Desestructuramos directamente las propiedades de item
                const { items, icon, title, default: active } = item;

                // Verificamos si items es un array válido y si al menos un elemento tiene el rol necesario
                if (!items || !Array.isArray(items) || items.length < 1 || !items.some((element) => element.roles.includes(role))) {
                    return null;
                }

                // Renderizar el componente SideBarDropdown
                return (
                <SideBarDropdown
                    key={ index }
                    icon={ faChevronRight }
                    image={ icon }
                    text={ title }
                    items={ items }
                    role={ role }
                    active={ active }
                    showMenu={ showMenu }
                    setShowMenu={ setShowMenu }
                />);
            }) }
        </>
    );
};

export default SidebarNav;
