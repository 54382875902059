import { useState, useEffect } from 'react';
import { getOrDeleteRequest, downloadFileRequest, postOrPutRequest } from '../utils/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import { faCloudDownload, faDownload } from '@fortawesome/free-solid-svg-icons';

export const FormatFilesLogic = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [showModalAddFormatDocument, setShowModalAddFormatDocument] = useState(false);
    const [showModalEditFormatDocument, setShowModalEditFormatDocument] = useState(false);
    const [id, setId] = useState(0);
    const MySwal = withReactContent(Swal);

    useEffect(() => { getFormatFiles() }, []);

    const getFormatFiles = async () => {
        const response = await getOrDeleteRequest(
            'document-format/index'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === 'error') {
            setData([]);
            return;
        }
        setData(response);
        setLoaded(true);
        console.log(response);
    }

    const downloadDocument = async (idDocument, nameDocument) => {
        const response = await downloadFileRequest(
            `document-format/download-file/${idDocument}`
        );
        console.log(response);
        if (response.type == 'error') {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3500
            })
            return;
        }
        console.log(response);
        const imgDocument = URL.createObjectURL(response);
        hadelDownload(imgDocument, nameDocument);
    }

    const hadelDownload = (imgDocument, nameDocument) => {
        const link = document.createElement('a');
        link.href = imgDocument;
        link.download = nameDocument;
        link.click();
    }

    const disableEnable = (id, status) => {
        MySwal.fire({
            title: "¿Esta seguro de eliminar?",
            text: "¡La acción no se podrá revertir!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false
        }).then(async(result) => {
            if (result.isConfirmed) {
                const endPoint = status === 1 ? `document-format/disable/${id}` : `document-format/enable/${id}`;
                const method = status === 1 ? 'DELETE' : 'PUT';
                const actionResponse = status === 1 ? getOrDeleteRequest : postOrPutRequest;

                const response = await actionResponse(
                    endPoint,
                    method
                )
                if (response.type === 'error') {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: response.message,
                        showConfirmButton: false,
                        timer: 3500
                    })
                    return;
                }
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 3500
                });
                getFormatFiles();
            }
        });
    }

    const columns = [
        {
            name: '#',
            width: "50px",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: '',
            width: "50px",
            cell: (row) => <div>
                <button key={row.id} onClick={() => { downloadDocument(row.id, row.file) }}><FontAwesomeIcon icon={faCloudDownload} className='text-blue-950 hover:text-blue-800 text-lg' /></button>
            </div>
        },
        {
            name: 'Nombre',
            width: "200px",
            cell: (row) => <div>{loaded && row.name}</div>
        },
        {
            name: 'Descripción',
            width: "400px",
            cell: (row) => <div>{loaded && row.description}</div>
        },
        {
            name: 'Estado',
            width: "100px",
            cell: (row) => <div>{loaded && row.status == 1 ? <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</div> : row.status == 2 && <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-400 mr-2"></div> Inactivo</div>}</div>
        },
        {
            name: "Eliminar",
            width: "100px",
            cell: (row) => loaded && <div className="flex">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                        onClick={() => { disableEnable(row.id, row.status) }}
                        id={row.id}
                        type="checkbox"
                        className="sr-only peer"
                        checked={row.status == 1 ? true : row.status == 2 && false}
                        readOnly
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                </label>
            </div>
        },
        {
            name: 'Editar',
            width: "100px",
            className: "flex justify-center items-center",
            cell: (row) => loaded && <button id={row.id}
                onClick={(e) => {
                    setShowModalEditFormatDocument(true);
                    setId(row.id);
                }}
                className="text-2xl">{loaded && <FontAwesomeIcon icon={faPenToSquare} />}</button>
        },
    ];

    const tableData = {
        columns,
        data
    };

    const objC = {
        columns: columns,
        loaded: loaded,
        data: data,
        tableData: tableData,
        showModalAddFormatDocument: showModalAddFormatDocument,
        showModalEditFormatDocument: showModalEditFormatDocument,
        id: id
    }

    const objF = {
        setShowModalAddFormatDocument: setShowModalAddFormatDocument,
        setShowModalEditFormatDocument: setShowModalEditFormatDocument,
        getFormatFiles: getFormatFiles,
        downloadDocument: downloadDocument
    }

    return { objC, objF }
}