import Swal from "sweetalert2";
import { useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from "react-drag-drop-files";
import { faCloudArrowUp, faFile, faTrash } from "@fortawesome/free-solid-svg-icons";

import { showPopup, downloadFileRequest, postOrPutRequest } from '../../utils/util';

import Modal from '../../../layouts/Modal';

const fileTypes = [ "PDF", "DOC", "DOCX", "ODT", "JPG", "JPEG", "PNG" ];

const ModalCommitmentLetter = ({ showModal, setModal, student, info, loadInfo }) => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState(undefined);
    const [check, setCheck] = useState(false);
    const [fileName, setFileName] = useState("");
    const [waiting, setWaiting] = useState(false);
    const [file, setFile] = useState(null);
    const [sending, setSending] = useState(false);

    const loadData = useCallback(async () => {
        console.log('loadData');
        console.log(info);
        if (info.length < 1 || !Array.isArray(info)) {
            setLoaded(true);
            return;
        }

        setData(info);
        setLoaded(true);
    }, []);

    const handleButton = (item) => {
        if (item.commitment_letter === null) {
            setCheck(true);
        }
        console.log(item);
        setCurrent(item);
    };

    const handleCheck = () => {
        setCheck(!check);
    };

    const handleFile = (file) => {
        if (file === null) {
            return;
        }

        let allowed = [ "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.oasis.opendocument.text", "image/jpeg", "image/png", "image/jpg" ];
        
        if (!allowed.includes(file.type)) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Solo se permiten archivos PDF, DOC, DOCX, ODT, JPG, JPEG o PNG',
                showConfirmButton: false,
                timer: 3500
            });

            return;
        }

        if (file.size > 5000000) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'El archivo debe ser menor a 5MB',
                showConfirmButton: false,
                timer: 3500
            });

            return;
        }
        
        setFileName(file.name);
        setFile(file);

        return;
        // setFileCarta(e);
        // setFileNameCarta(e.name);
        // setFileCartaError(false);
        // setFormulario({ ...formulario, letter: e });
        // setFileNames({ ...fileNames, urlLetter: URL.createObjectURL(e), letter: e.name });
    };

    const downloadFile = async () => {
        setWaiting(true);

        waiting && Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'Descargando carta de compromiso...',
            showConfirmButton: false,
        });
        console.log(current, "Current selected");
        let response = await downloadFileRequest(
            `student/download-commintment/${current.id}`,
        );

        if (response.type === "error") {
            let errorMessage = response.message ? response.message : "En este momento no se puede descargar la carta de compromiso";

            Swal.fire({
                position: 'center',
                icon: 'error',
                title: errorMessage,
                showConfirmButton: true,
            });

            setWaiting(false);
            return;
        }

        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement('a');
        
        link.href = url;
        link.setAttribute('download', `Carta_de_compromiso_${ current.program }.pdf`);

        document.body.appendChild(link);

        link.click();

        setWaiting(false);

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Carta de compromiso descargada',
            showConfirmButton: false,
        });

        return;
    };

    const clearFile = () => {
        setFile(null);
        setFileName("");
    };

    const handleSubmit = async () => {
        setSending(true);

        if (file === null) {
            setSending(false);
            return;
        }

        let formData = new FormData();
        formData.append("letter", file);

        let response = await postOrPutRequest(
            `student/upload-commitment-letter/${current.id}?_method=PUT`,
            "POST",
            formData
        );

        if (response.type === "error") {
            let errorMessage = response.message ? response.message : "En este momento no se puede subir la carta de compromiso";

            Swal.fire({
                position: 'center',
                icon: 'error',
                title: errorMessage,
                showConfirmButton: true,
            });

            setSending(false);

            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Carta de compromiso subida con éxito',
            showConfirmButton: false,
            timer: 1500
        });

        setCheck(false);
        setFile(null);
        setFileName("");
        setCurrent(undefined);
        
        // Recargar la información
        setData(await loadInfo());
        setSending(false);
    };

    useEffect(() => {
        if (!loaded) {
            loadData();
        }
    }, [loaded, loadData]);

    return (
        <Modal
            title="Cartas de compromiso"
            expand={ true }
            isOpen={ showModal }
        >
            <div className="relative mb-4 px-5">
                <div className="flex flex-wrap max-h-[400px] h-[400px] overflow-y-auto">
                    <div className="w-full lg:w-2/5 px-4">
                        {  (data.length > 0 && loaded) && (
                            <>
                                { data.map((item, index) => {
                                    return (
                                        <button 
                                            key={ index } 
                                            className={ ` btn-modal ${ current !== undefined && current.id === item.id ? 'btn-modal-select' : '' }` }
                                            onClick={ () => handleButton(item) }
                                        >
                                            { item.program }
                                        </button>
                                    );
                                }) }
                            </>
                        ) }
                        { (data.length > 0 && !loaded) && (
                            <div className="w-full flex items-center justify-center">
                                <div className="w-16 h-16 border-4 border-dashed border-slate-200 rounded-full"></div>
                            </div>
                        ) }
                        { (data.length < 1 && loaded) && (
                            <div className="w-full flex items-center justify-center">
                                El beneficiario no posee cartas de compromiso registradas.
                            </div>
                        ) }
                    </div>
                    <div className={ `w-full lg:w-3/5 px-4 flex ${ current === undefined ? 'items-center' : 'items-start' } justify-center` }>
                        { current === undefined && (
                            <div className="w-full flex items-center justify-center">
                                Seleccione una carta de compromiso para ver o actualizar la información.
                            </div>
                        ) }
                        { current !== undefined && (
                            <div className="w-full flex items-center justify-center flex-col">
                                { current.commitment_letter !== null && (
                                    <div className="w-full flex items-center justify-center mb-3">
                                        <div className="block text-blue-950 font-semibold pr-2">Agregar carta de compromiso</div>
                                        <label className="inline-flex relative items-center cursor-pointer" htmlFor="add-commitment" >
                                            <input
                                                type="checkbox"
                                                id="add-commitment"
                                                name="add-commitment"
                                                className="sr-only peer"
                                                onChange={ handleCheck }
                                                checked={ check }
                                                readOnly
                                            />
                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                        </label>
                                    </div>
                                ) }
                                { check === true  && (
                                    <>
                                        <label className="block mb-2 text-sm font-medium text-slate-700 text-left w-full">
                                            { current.commitment_letter === null ? "Subir carta de compromiso" : "Actualizar carta de compromiso" }
                                            &nbsp;
                                            {/* boton para limpiar */}
                                            { file !== null && (
                                                <FontAwesomeIcon 
                                                    className="text-red-500 cursor-pointer" 
                                                    icon={ faTrash } 
                                                    onClick={ () => clearFile() } 
                                                />
                                            ) }
                                        </label>
                                        <FileUploader 
                                            children={
                                                <label for="letter" class="flex flex-col items-center justify-center w-full h-52 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                    <div class="flex flex-col items-center justify-center p-5">
                                                        {
                                                            fileName === ""
                                                                ?
                                                                <>
                                                                    <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={ faCloudArrowUp } />
                                                                    <p class="text-sm text-gray-500 text-center"><span class="font-semibold">Arrastra o haz clic aquí<br />para subir un archivo</span></p>
                                                                    <p class="text-xs p-5 text-gray-500">PDF, DOC, DOCX, ODT, JPG, JPEG o PNG (Peso: 5MB max)</p>
                                                                </>
                                                                :
                                                                <>
                                                                    <FontAwesomeIcon className="w-8 h-8 mb-4 text-gray-500" icon={ faFile } />
                                                                    <p class="mb-2 text-sm text-gray-500 p-5">
                                                                        <span class="font-semibold">
                                                                            Nombre del archivo:
                                                                            <br />
                                                                            <span className="text-blue-cnx">
                                                                                {
                                                                                    fileName.length > 20
                                                                                    ?
                                                                                    fileName.substring(0, 20) + "..."
                                                                                    :
                                                                                    fileName
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    </p>
                                                                </>
                                                        }
                                                    </div>
                                                </label>
                                            } 
                                            onTypeError={
                                                () => {
                                                    Swal.fire({
                                                        position: 'center',
                                                        icon: 'error',
                                                        title: 'Solo se permiten archivos PDF, DOC, DOCX, ODT, JPG, JPEG o PNG',
                                                        showConfirmButton: false,
                                                        timer: 3500
                                                    });
                                                }
                                            } 
                                            classes="w-full" 
                                            hoverTitle={ "Soltar aquí" } 
                                            handleChange={ handleFile } 
                                            name="letter" 
                                            types={ fileTypes }
                                        />
                                        <div className="w-full flex items-center justify-end mt-2">
                                            <button
                                                type="submit"
                                                className="bg-blue-cnx hover:bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all cursor-pointer disabled:cursor-not-allowed disabled:bg-opacity-50"
                                                disabled={ file === null ? true : false }
                                                onClick={ handleSubmit }
                                            >
                                                { sending ? "Subiendo..." : "Subir carta de compromiso" }
                                            </button>
                                        </div>
                                    </>
                                ) }
                                { check === false && (
                                    <div className="w-full flex items-center justify-center flex-col">
                                        <div className="w-full flex justify-end mb-2">
                                            {/* Boton de descarga */}
                                            <button
                                                className="bg-green-600 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:bg-green-700 outline-none focus:outline-none mr-1 transition-colors content-center disabled:cursor-not-allowed"
                                                type="button"
                                                onClick={ downloadFile }
                                                disabled={ waiting }
                                            >
                                                { waiting ? "Descargando..." : "Descargar" }
                                            </button>
                                        </div>
                                        <div className="w-full h-72">
                                            <embed 
                                                id="commitment-letter-preview"
                                                src={ `${ process.env.REACT_APP_PHOTO_URI }student/preview/comminment-letter/${ current.commitment_letter }` } 
                                                type="application/pdf" 
                                                width="100%" 
                                                height="288px"
                                            />
                                        </div>
                                    </div>
                                
                                ) }
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className="w-full flex items-center justify-center pt-4 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center "
                    type="button"
                    onClick={() => {
                        setCheck(false);
                        showPopup(false, setModal);
                    }}
                >
                    Cerrar
                </button>
            </div>
        </Modal>
    );
};

export default ModalCommitmentLetter;
