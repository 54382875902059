import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { faCheckCircle, faEye, faPowerOff, faStop, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FaBeer } from "react-icons/fa";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import { paginationProps } from "../utils/util";
import ImportButton from "../ImportButton";
import SearchInput from "../Components/SearchInput";
import PreloaderTables from "../PreloaderTables/PreloaderTables";


const Paises = () => {

    const initDataFilter = {
        search: ''
    }

    const initialDatosFormulario = {
        name: "",
        code: "",
        phone_format: "",


    };

    const initialCooperante = {
        name: "",
        code: "",
        phone_format: "",
    };

    const initialCooperanteEditar = {
        name: "",
        code: "",
        phone_format: "",
    };

    const initialTypesPrograms = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [showModal, setShowModal] = React.useState(false);
    const [showModalEditar, setShowModalEditar] = React.useState(false);
    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = useState(false);
    const [typesPrograms, setTypesPrograms] = useState([initialTypesPrograms]);
    const [formulario, setFormulario] = useState([initialDatosFormulario]);
    const [formularioEditar, setFormularioEditar] = useState([initialDatosFormulario]);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [idEditar, setIdEditar] = useState();
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [dataSearch, setDataSearch] = useState([]);
    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');

    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {

        console.log(formulario, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        // console.log(name)

        // if(name == "type2"){
        //     console.log()
        // }



        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const ManejarEventoDeInputsEditar = (event) => {
        console.log(formularioEditar, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(event)

        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormularioEditar({ ...formulario, [name]: value });
    };



    const addPais = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        let verificarInputs = [
            { nombre: "name", value: formulario.name },
            { nombre: "code", value: formulario.code },
            { nombre: "phone_format", value: formulario.phone_format },
        ]
        console.log("Verificar", verificarInputs)

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        // console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        {
            if (totalValidaciones.length == 3) {
                console.log("Enviar al servidor 3");
                RegistrarPais();
            };
        };

    };
    const editarPais = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        formularioEditar.name = e.currentTarget.name.value;
        formularioEditar.code = e.currentTarget.code.value;
        formularioEditar.phone_format = e.currentTarget.phone_format.value;

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "name", value: formularioEditar.name },
            { nombre: "code", value: formularioEditar.code },
            { nombre: "phone_format", value: formularioEditar.phone_format },
        ];

        console.log("Verificar", verificarInputs)

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        // console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length == 3) {
            console.log("Enviar al servidor");
            ActualizarPais();
        };

    };

    function RegistrarPais() {

        const url = `${process.env.REACT_APP_API_URI}country/register`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        const dataInsertar = {
            name: formulario.name,
            code: formulario.code,
            phone_format: formulario.phone_format
        };

        console.log(dataInsertar, "Datainsertar")

        axios.post(url, dataInsertar, config)
            .then(response => {
                console.log(response, "response");

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    setShowModal(false)
                    formulario.name = ""
                    getInfo();

                    // Limpiando el formulario 
                    setFormulario([initialDatosFormulario])
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    function ActualizarPais() {

        const idPais = idEditar;

        const url = `${process.env.REACT_APP_API_URI}country/update/${idPais}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        const dataInsertar = {
            name: formularioEditar.name,
            code: formularioEditar.code,
            phone_format: formularioEditar.phone_format
        };

        console.log(dataInsertar, "Datainsertar")

        axios.put(url, dataInsertar, config)
            .then(response => {
                // console.log(response,"Response");

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    setShowModalEditar(false)
                    getInfo();

                    // Limpiando el formulario
                    setFormularioEditar([initialDatosFormulario])
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        console.log("Data", datosDelFormulario)

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'name': {
                    if (valorInput.value == '' || valorInput.value === null || valorInput.value == undefined) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }
                case 'code': {
                    if (valorInput.value == '' || valorInput.value === null || valorInput.value == undefined) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }

                case 'phone_format': {

                    if (valorInput.value === '' || valorInput.value === null || valorInput.value == undefined || valorInput.value == "0") {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        })
        return errors;
    }

    function desactivarCooperante(event) {
        const idCooperante = event.currentTarget.id;
        console.log(idCooperante)
        Swal.fire({
            title: '¿Seguro que quieres desactivar este pais?',
            showCancelButton: true,
            confirmButtonText: 'Desactivar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmDesactivarCooperante() {
                    const url = `${process.env.REACT_APP_API_URI}country/disable/${idCooperante}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.delete(url, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Conocimientos";
                            setData([initialCooperante])
                            getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "¡Error! Vuelva a intentar",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
                confirmDesactivarCooperante();
            }

        })
    };

    function ReactivarCooperante(event) {
        const idCooperante = event.currentTarget.id;
        console.log(Cookies.get('Bearer').token)
        Swal.fire({
            title: '¿Seguro que quieres activar este pais?',
            showCancelButton: true,
            confirmButtonText: 'Activar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmReactivarCooperante() {
                    const url = `${process.env.REACT_APP_API_URI}country/enable/${idCooperante}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.put(url, {}, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Cooperantes";
                            setData([initialCooperante])
                            getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "Error",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
                confirmReactivarCooperante();
            }

        })
    };

    const [datosServidor, setDatosservidor] = useState([]);

    async function getInfo() {
        setLoaded(false)
        setData([initialCooperante])
        const url = `${process.env.REACT_APP_API_URI}country/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            console.log(resp.data, "----------------Paises------------");
            setData(resp.data);
            setDataSearch(resp.data);
            setLoaded(true)
        } catch (err) {
            console.error(err);
        }
    };

    const options = [];

    useEffect(() => {
        if (!loaded) {
            getInfo();
        }
    }, []);

    typesPrograms.map((item) => {
        //     options.push({ value: item.id, label: item.name })
    })

    const [data, setData] = useState([initialCooperante]);

    const [dataEditar, setDataEditar] = useState([initialCooperanteEditar]);

    const columns = [
        {
            name: 'ID',
            width: "75px",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre',
            width: "250px",
            cell: (row) => <div>{loaded && row.name}</div>
        },
        {
            name: 'Codigo area',
            width: "250px",
            cell: (row) => <div>+{loaded && row.code}</div>
        },
        {
            name: 'Formato',
            width: "250px",
            cell: (row) => <div>{loaded && row.phone_format}</div>
        },
        {
            name: 'Estado',
            width: "200px",
            cell: (row) => <div>{loaded && row.status == 1 ? <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</div> : row.status == 2 && <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-400 mr-2"></div> Inactivo</div>}</div>
        },
        {
            name: "Activar/Desactivar",
            width: "200px",
            cell: (row) => loaded && <div className="flex">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                        onClick={(e) => {
                            row.status == 1 ? desactivarCooperante(e) : row.status == 2 && ReactivarCooperante(e)
                        }}
                        id={row.id}
                        type="checkbox"
                        className="sr-only peer"
                        checked={row.status == 1 ? true : row.status == 2 && false}
                        readOnly
                    />
                    <div
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                </label>
            </div>
        },
        {
            name: 'Editar',
            width: "100px",
            className: "flex justify-center items-center",
            cell: (row) => loaded && <button id={row.id} onClick={(e) => {
                mostrarInfoModalEditar(e)
            }} className="text-2xl">{loaded && <FontAwesomeIcon icon={faPenToSquare} />}</button>
        },
    ];

    const tableData = {
        columns,
        data
    };

    function mostrarInfoModalEditar(e) {

        setFormularioEditar([initialDatosFormulario])
        setShowModalEditar(true)

        setIdEditar(e.currentTarget.id)

        data.map((item) => {
            if (item.id == e.currentTarget.id) {
                const info = {
                    name: item.name,
                    code: item.code,
                    phone_format: item.phone_format
                }
                setFormularioEditar(info)
            }

        })
        setLoaded(true);
    };

    const searchFilter = (termSearch, nameInput, dataArray) => {
        console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = data.filter((item) => {
                    if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                    item.phone_format.toString().toLowerCase().includes(termSearch.toLowerCase()) ||
                    item.code.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                setDataSearch(srch);
                break;
            default:
                break;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        setDataFilter({ ...dataFilter, [e.target.name]: e.target.value });
    }

    return (
        <div className=''>
            <div className='ContainerOpenClass'>

                <div className=" p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">

                        <div className="sm:flex">
                            <div className="">
                                <h1 className="text-xl sm:text-2xl font-semibold text-white">Países</h1>
                            </div>
                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                                <button type="button" data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                    onClick={() => setShowModal(true)}
                                >
                                    <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Agregar país
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 bg-blue-950">
                    <SearchInput dataFilterSearch={ dataFilter.search } changeData={ changeData }/>
                    <div className="p-2">
                        {
                            loaded
                            ?
                            <DataTable
                                columns={columns}
                                data={ dataSearch }
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                responsive
                                paginationComponentOptions={paginationProps}
                                noDataComponent={<div className="my-5">No hay registros</div>}
                            />
                            :
                            <PreloaderTables/>
                        }
                    </div>
                </div>

            </div>

            {/* Modal */}
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-full my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold text-blue-950">
                                        Agregar País
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6">
                                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <form className="w-full flex flex-wrap" onSubmit={addPais}>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Nombre del país
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="name" value={formulario.name} onChange={ManejarEventoDeInputs} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>



                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Código de área
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="code" value={formulario.code} onChange={ManejarEventoDeInputs} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "code" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>

                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Formato teléfonico
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="phone_format" value={formulario.phone_format} onChange={ManejarEventoDeInputs} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "phone_format" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>


                                            <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                <button
                                                    className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                    type="submit"

                                                >
                                                    Agregar
                                                </button>

                                                <button
                                                    className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                    type="button"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            {showModalEditar ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-full my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold text-blue-950">
                                        Editar País
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <form className="w-full flex flex-wrap" onSubmit={editarPais}>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Nombre del país
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="name" value={formularioEditar.name} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>



                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Código de área
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="code" value={formularioEditar.code} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "code" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>

                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Formato teléfonico
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="phone_format" value={formularioEditar.phone_format} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "phone_format" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>

                                            <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                <button
                                                    className="bg-blue-950 text-white active:bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                    type="submit"

                                                >
                                                    Editar
                                                </button>

                                                <button
                                                    className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                    type="button"
                                                    onClick={() => setShowModalEditar(false)}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </div>

    )
}

export default Paises;