import {FormatFilesLogic} from './FormatFilesLogic';
import {Link} from 'react-router-dom';
import PreloaderTables from "../PreloaderTables/PreloaderTables";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { paginationProps } from "../utils/util";
import ModalAddFormatDocument from './modals/ModalAddFormatDocument';
import ModalEditFormatDocument from './modals/ModalEditFormatDocument';

const FormatFiles = () =>{
    const {objC, objF} = FormatFilesLogic();
    return(
        <div className="">
            <div className="ContainerOpenClass">
                <div className=" p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="sm:flex">
                            <div className="w-full md:w-[30%] flex items-center justify-center md:justify-start">
                                <h1 className="text-center md:text-left text-xl sm:text-2xl font-semibold text-white">
                                    Formatos de archivos
                                </h1>
                            </div>
                            <div className="w-full md:w-[70%] flex flex-wrap items-center justify-end px-2">
                                <button
                                    data-modal-toggle="add-user-modal"
                                    className="w-full md:w-1/5 my-2 mx-1 py-2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 text-center sm:w-auto"
                                    onClick={() => objF.setShowModalAddFormatDocument(true)}
                                >
                                    <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path>
                                    </svg>
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-blue-950">
                    <div className="px-6 relative">
                        {objC.loaded ? 
                            <DataTableExtensions {...objC.tableData} filterPlaceholder={"Buscar"} print={false} export={false}>
                                <DataTable
                                    columns={objC.columns}
                                    className="relative"
                                    data={objC.data}
                                    noHeader
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    pagination
                                    highlightOnHover
                                    paginationComponentOptions={paginationProps}
                                    responsive
                                    noDataComponent={<div className="my-5">No hay registros</div>}
                                    // ref={contenedorRef}
                                    // expandableRows
                                    // expandableRowsComponent={expandedComponent}
                                />
                            </DataTableExtensions>
                        : <PreloaderTables />}
                    </div>
                </div>
            </div>
            { objC.showModalAddFormatDocument &&
                <ModalAddFormatDocument setShowModalAddFormatDocument={objF.setShowModalAddFormatDocument} getFormatFiles={objF.getFormatFiles} />
            }
            { objC.showModalEditFormatDocument &&
                <ModalEditFormatDocument setShowModalEditFormatDocument={objF.setShowModalEditFormatDocument} getFormatFiles={objF.getFormatFiles} id={objC.id} downloadDocument= {objF.downloadDocument}/>
            }
        </div>
    )
}
export default FormatFiles;