import { useCallback, useEffect } from "react";
import { useState } from "react";
import Cropper from "react-easy-crop";
import Preloader from "../../Preloader/Preloader";

const ModalResizeImage = ({ setShowModalResizeImage, img, funVaciarFoto, showPopup, setFormulario, setFileNames, formulario, fileNames, name }) => {
    const [image, setImage] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [objectURL, setObjectURL] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [aspectHorizontal, setAspectHorizontal] = useState(11 / 7);
    const [aspectVertical, setAspectVertical] = useState(12 / 19);
    const [vertical, setVertical] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const createImage = (url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
        });
    };

    const handleClose = () => {
        setImage(null);
        setCropper(null);
        setZoom(1);
        setObjectURL(null);
        setCroppedAreaPixels(null);
        setCroppedImage(null);
        setCroppedArea(null);
        setLoading(false);
        setRotation(0);
        setCrop({ x: 0, y: 0 });
        funVaciarFoto();
        showPopup(false, setShowModalResizeImage);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels, "croppedArea, croppedAreaPixels");
        setCroppedArea(croppedArea);
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    async function getCroppedImg(imageSrc, pixelCrop, rotation) {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        canvas.width = safeArea;
        canvas.height = safeArea;

        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.translate(-safeArea / 2, -safeArea / 2);

        ctx.fillStyle = "#fff";
        ctx.fillRect(-100, -100, canvas.width + 100, canvas.height + 100);

        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );

        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(file);
                if (name === "document_front") {
                    setFormulario({ ...formulario, document_front: file });
                } else if (name === "document_back") {
                    setFormulario({ ...formulario, document_back: file });
                }
                console.log(file, "file");
                console.log(URL.createObjectURL(file), "URL.createObjectURL(file)");
            }, "image/jpeg");
        });
    }

    useEffect(() => {
        if (img) {
            setImage(URL.createObjectURL(img));
        }
    }, [img]);

    return (
        <>
            <div
                className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-2xl font-semibold text-blue-900">
                                Recortar imagen
                            </h3>
                            <button
                                className="bg-blue-950 text-white font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center disabled:opacity-50 disabled:cursor-progress"
                                type="button"
                                onClick={() => {
                                    if (vertical) {
                                        setVertical(false);
                                    } else {
                                        setVertical(true);
                                    }
                                }}
                                disabled={loading}
                            >
                                {
                                    vertical ? "Horizontal" : "Vertical"
                                }
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            {/* Input range */}
                            <div className="flex items-center justify-center my-4 p-5">
                                <label className="text-gray-500 text-sm mr-2">Zoom</label>
                                <input
                                    className="w-full"
                                    type="range"
                                    min={"0.5"}
                                    max="3"
                                    step="0.1"
                                    value={zoom}
                                    onChange={(e) => setZoom(e.target.value)}
                                />
                            </div>
                            <div className="my-4 text-slate-500 text-lg leading-relaxed relative">
                                <div className="py-4 px-6" style={{
                                    height: window.innerHeight - 200
                                }}>
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        aspect={vertical ? aspectVertical : aspectHorizontal}
                                        onCropChange={setCrop}
                                        zoom={zoom}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                        rotation={rotation}
                                        onRotationChange={setRotation}
                                    />
                                    {
                                        loading &&
                                        <>
                                            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center sombra">
                                                <Preloader />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {/* Input range */}
                            <div className="flex items-center justify-center my-4 p-5">
                                <label className="text-gray-500 text-sm mr-2">Rotación</label>
                                <input
                                    className="w-full"
                                    type="range"
                                    min="0"
                                    max="360"
                                    step="0.1"
                                    value={rotation}
                                    onChange={(e) => setRotation(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                            <button
                                className="bg-blue-950 text-white font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center disabled:opacity-50 disabled:cursor-progress"
                                type="button"
                                onClick={() => {
                                    setLoading(true);
                                    getCroppedImg(image, croppedAreaPixels, rotation, zoom).then((res) => {
                                        showPopup(false, setShowModalResizeImage);
                                        setLoading(false);
                                    });
                                }}
                                disabled={loading}
                            >
                                Recortar
                            </button>
                            <button
                                className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center disabled:opacity-50 disabled:cursor-progress"
                                type="button"
                                onClick={() => {
                                    handleClose();
                                    if (name === "document_front") {
                                        setFileNames({ ...fileNames, document_front: "", urlDocumentFront: "" });
                                        setFormulario({ ...formulario, document_front: "" });
                                    } else if (name === "document_back") {
                                        setFileNames({ ...fileNames, document_back: "", urlDocumentBack: "" });
                                        setFormulario({ ...formulario, document_back: "" });
                                    }
                                }}
                                disabled={loading}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default ModalResizeImage;