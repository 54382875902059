import { useState, useEffect, useLayoutEffect } from 'react';
import { getOrDeleteRequest } from '../utils/util';
import { filterDataReactSelect } from '../utils/utilObject';
import Select from 'react-select';

export const SelectStatusProgramStudents = (props) => {
    return (
        <div className="relative">
            <label htmlFor="status" className="block mb-2 text-blue-950 font-bold">Estado de los beneficiarios</label>
            <select
                name="status"
                value={props.dataFilter.status}
                className='bg-white appearance-none border-gray-300 border-[2px] ring-[1px] ring-gray-200 focus:ring-blue-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                id="status"
                onChange={props.changeData}
                disabled={props.loaded ? false : true}
            >
                <option disabled value={0} className='text-gray-300'>Selecciona una opción</option>
                <option value={1}>Aplicado </option>
                <option value={2}>Preseleccionado </option>
                <option value={3}>Seleccionado </option>
                <option value={4}>No completado </option>
                <option value={5}>Completado </option>
                <option value={6}>En proceso </option>
                <option value={7}>No iniciado </option>
            </select>
        </div>
    );
}

export const SelectGender = (props) => {
    return (
        <div className="relative">
            <label htmlFor="status" className="block mb-2 text-blue-950 font-bold">Género</label>
            <select
                name="gender"
                value={props.dataFilter.gender}
                className='bg-white appearance-none border-gray-300 border-[2px] ring-[1px] ring-gray-200 focus:ring-blue-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                id="gender"
                onChange={props.changeData}
                disabled={props.loaded ? false : true}
            >
                <option disabled value={0}>Selecciona una opción </option>
                <option value={1}>Masculino</option>
                <option value={2}>Femenino</option>
                <option value={3}>Prefiero no decirlo</option>
            </select>
        </div>
    );
}

export const SelectHTML = (props) => {
    return (
        <div className="">
            <label htmlFor="name" className="block mb-2 text-blue-950 font-bold">{props.label}</label>
            <select
                name="name"
                title={props.title}
                value={props.name}
                className='bg-white appearance-none border-gray-300 border-[2px] ring-[1px] ring-gray-200 focus:ring-blue-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                id="name"
                onChange={props.changeData}
                disabled={props.loaded ? false : true}
            >
                <option disabled value={'default'}>Selecciona una opción</option>
                {props.selectOptions.map((item) =>{
                    return (<option value={item.value}>{item.label}</option>); 
                })}
            </select>
        </div>
    );
}

export const InputSearch = (props) => {
    return (
        <div className="">
            <div className="relative mb-2">
                <label htmlFor="search" className="block mb-2 text-blue-950 font-bold">Búsqueda</label>
                <input
                    type="text"
                    id="search"
                    name="search"
                    className="bg-white appearance-none border-gray-300 border-[2px] ring-[1px] ring-gray-200 focus:ring-blue-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    value={props.dataFilter.search}
                    autoComplete="none"
                    placeholder='Buscar...'
                    onChange={props.changeData}
                    disabled={props.loaded ? false : true}
                />
            </div>
        </div>
    );
}

export const SelectRState = (props) => {
    const [dataDepartments, setDataDepartments] = useState([]);
    useEffect(() => { getDepartments() }, []);

    const getDepartments = async () => {
        const response = await getOrDeleteRequest(
            "state/index",
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            setDataDepartments([]);
            console.error(response);
            return;
        }
        setDataDepartments(filterDataReactSelect(response, 'state'));
    }

    return (
        <div className="">
            <label htmlFor="state" className="block mb-2 text-blue-950 font-bold">Departamentos</label>
            <Select
                name="state"
                id="state"
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                value={props.dataFilter.state}
                options={dataDepartments}
                placeholder={"Selecciona una opción"}
                noOptionsMessage={() => 'No hay mas opciones'}
                onChange={props.changeDataRSelect}
                isDisabled={props.loaded ? false : true}
            />
        </div>
    );
}

export const SelectRCity = (props) => {
    const [dataMunicipalities, setDataMunicipalities] = useState([]);

    useEffect(() => { props.dataFilter.state !== null && getMunicipalities(props.dataFilter.state.value); }, [props.dataFilter.state]);

    const getMunicipalities = async (idState) => {
        const response = await getOrDeleteRequest(
            `city/filter/${idState}`,
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
            return;
        }
        setDataMunicipalities(filterDataReactSelect(response, 'city'));
    }

    return (
        <div className="">
            <label htmlFor="city" className="block mb-2 text-blue-950 font-bold">Municipios</label>
            <Select
                name="city"
                id="city"
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                value={props.dataFilter.city}
                options={dataMunicipalities}
                placeholder={"Selecciona una opción"}
                noOptionsMessage={() => 'Selecciona un departamento'}
                onChange={props.changeDataRSelect}
                isDisabled={props.loaded ? false : true}
            />
        </div>
    );
}

export const SelectRDistrict = (props) => {
    const [dataDistrict, setDataDistrict] = useState([]);

    useEffect(() => { props.dataFilter.city !== null && getDistrict(props.dataFilter.city.value); }, [props.dataFilter.city]);

    const getDistrict = async (idCity) => {
        const response = await getOrDeleteRequest(
            `district/filter/${idCity}`,
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
            return;
        }
        setDataDistrict(filterDataReactSelect(response, 'district'));
        console.log(filterDataReactSelect(response, 'district'));
    }

    return (
        <div className="">
            <label htmlFor="district" className="block mb-2 text-blue-950 font-bold">Distritos</label>
            <Select
                name="district"
                id="district"
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                value={props.dataFilter.district}
                options={dataDistrict}
                placeholder={"Selecciona una opción"}
                noOptionsMessage={() => 'Selecciona un municipio'}
                onChange={props.changeDataRSelect}
                isDisabled={props.loaded ? false : true}
            />
        </div>
    );
}