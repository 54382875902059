// import axios from "axios";
import Swal from "sweetalert2";
// import Cookies from "js-cookie";
import withReactContent from "sweetalert2-react-content";
import { useRef, useState, useEffect, useCallback } from "react";
import { postOrPutRequest, validateSession } from "./utils/util";
import Preloader from "./Preloader/Preloader";
import { set } from "date-fns";

const ImportButton = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [allowed, setAllowed] = useState(false);
    const [key, setKey] = useState(0);

    const fileInputRef = useRef(null);
    const MySwal = withReactContent(Swal);

    const handleInput = () => {
        fileInputRef.current.click();
    };

    const [preloaderExcel, setPreloaderExcel] = useState(false);

    const handleFileChange = async (e) => {
        let file = e.target.files[0];
        
        if (!file) {
            MySwal.fire(
                'Archivo no procesado',
                'El archivo de Excel no pudo ser procesado correctamente, intente nuevamente.',
                'error'
            );

            fileInputRef.current.value = null;
            setKey((prevKey) => prevKey + 1);

            return;
        }

        let alert = await MySwal.fire({
            icon: "warning",
            title: "¿Estás seguro de realizar esta operación?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, estoy seguro",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return MySwal.getConfirmButton().click();
            }
        })

        if (!alert.isConfirmed) {
            return;
        }

        // Muestra la alerta de carga
        Swal.fire({
            title: 'Procesando...',
            html: 'Por favor espera...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        
        let response = await postOrPutRequest(
            `excel/import-${props.origin}`,
            'POST',
            { file: file }
        );

        if (response.type === "error") {

            Swal.close();

            let errorMessage = response.message ? response.message : "Error al importar conocimientos";
            let importedRows = response.rows ? response.rows : 0;

            MySwal.fire(
                errorMessage,
                `Total de registros importados: ${importedRows}`,
                'error'
            );

            fileInputRef.current.value = null;
            setKey((prevKey) => prevKey + 1);

            return
        }

        Swal.close();

        Swal.close();

        MySwal.fire({
            title: response.message,
            icon: 'success',
            html: `Total de registros importados: ${response.rows}<br/>Nuevos registros: ${response.new}<br/>Registros actualizados: ${response.old}`,
        });

        fileInputRef.current.value = null;

        props.info();
        setKey((prevKey) => prevKey + 1);

        return;            
    };

    const handleRole = useCallback(() => {
        let session = validateSession();
        
        if (session !== undefined && session.role !== undefined) {
            if (props.roles.includes(session.role)) {
                setAllowed(true);
            }
        }

        setLoaded(true);
    }, []);

    useEffect(() => {
        if (loaded === false) {
            handleRole();
        }
    }, [handleRole]);

    return (
        <>
            { allowed && (
                <div className="w-[47%] md:w-[30%] mx-1 ">
                    <form className="hidden">
                        <input ref={ fileInputRef } type="file" id="file-excel" className="hidden" onChange={ handleFileChange } />
                    </form>
                    <button 
                        id="upload-file" 
                        type="button" 
                        className="w-full text-white transition-colors bg-green-700 focus:ring-4 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center hover:bg-green-800"
                        onClick={ handleInput }
                    >
                        <svg className="-ml-1 mr-2 h-6 w-6" enableBackground="new 0 0 30 30" id="Layer_1" version="1.1" viewBox="0 0 30 30" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g><path clipRule="evenodd" d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506" fill="currentColor" fillRule="evenodd"/><path d="M20.223,25.382H0/V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943" fill="currentColor"/><polyline fill="currentColor" points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817 8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  "/></g></svg>
                        Importar
                    </button>
                </div>
            ) }
        </>
    );
};

export default ImportButton;

