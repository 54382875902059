import { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useLocation, Link, useParams, redirect, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPenToSquare, faEnvelope, faPhoneAlt, faSignature, faAddressCard, faListCheck, faInfo, faInfoCircle, faEye, faEyeSlash, faCloudArrowDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { differenceInYears, set } from 'date-fns';
import axios, { all } from 'axios';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable2 from "react-data-table-component";
import DataTableExtensions2 from "react-data-table-component-extensions";
import { downloadFileRequest, paginationProps } from '../utils/util';
import BackButton from '../BackButton';
import DownloadFileButton from '../DownloadFileButton';
import DeleteFileButton from '../DeleteFileButton';
import Select from 'react-select';
import { showPopup, getOrDeleteRequest, postOrPutRequest } from '../utils/util';
import ModalPsychologicalProfile from './ModalPsychologicalProfile';

import DataTableComponent from './DataTableComponent';
import SectionProfile from '../../layouts/SectionProfile';
import ReactPDF, { Font, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import EstructuraPDF from '../GenerarPDF/EstructuraPDF';
import UserMale from '../../assets/images/user-male.png';
import UserFemale from '../../assets/images/user-female.png';
import Wallpaper from '../../assets/wallpaper-profile.jpg';
import ModalAddWorkExperience from './workExperience/modals/ModalAddWorkExperience';
import EstructuraCVPDF from '../GenerarPDF/EstructuraCVPDF';
import ModalSocioEconomic from './modals/ModalSocioEconomic';
import withReactContent from 'sweetalert2-react-content';
import ModalAddStudy from './study/modals/ModalAddStudy';
import ModalEditWorkExperience from './workExperience/modals/ModalEditWorkExperience';
import ModalEditStudy from './study/modals/ModalEditStudy';
import ListWorkExperiences from './workExperience/ListWorkExperiences';
import ListStudies from './study/ListStudies';
import ModalInternShipsHistory from './modals/ModalInternShipsHistory';
import Preloader from '../Preloader/Preloader';
import PreloaderCard from './Preloaders/PreloaderCard';
import PreloaderInfoPersonalBeneficiario from './Preloaders/PreloaderInfoPersonalBeneficiario';
import DesertionLetter from './DesertionLetter';
import PreloaderComponentSectionProfile from './Preloaders/PreloaderComponentSectionProfile';
import ModalViewDocument from './modals/ModalViewDocument';
import reactSelect from 'react-select';
import ModalCommitmentLetter from './modals/ModalCommitmentLetter';

import ChambaticonBrand from '../../assets/chambaticon-brand.png';

const VerEstudiante = () => {
    const initialDatosFormulario = {
        id: 0,
        names: "",
        lastnames: '',
        email: '',
        phone: '',
        alternative_phone: "",
        birth_date: '',
        gender: '',
        address: '',
        state: '',
        city_id: '',
        currently_studying: '',
        currently_working: '',
        family_income: '',
        family_members: '',
        contact_info: null,
        dui: '',
        nit: '',
        knowledge: [],
        languages: [],
        socioeconomic: [],
        socioeconomic_history: [],
        psychosocial: [],
        soft_skills: [],
        document_back: '',
        document_front: '',
        complete_external_profile: 0,
    };

    const initialDatosFormularioEditar = {
        program: {},
        program_status: null,
        application_reason: "",
        comment: "",
        internship: {},
        internship_status: null,
        abandoned_at: null,
    };

    const initialDatosFormularioAgregar = {
        program: "",
        program_status: null,
        comment: "",
        internship: "",
        internship_status: null,
        abandoned_at: null,
    };

    const initialDatosFormularioPasantia = {
        organizer: "",
        internal_internship: false,
        specialities: [],
        knowledge: [],
        modality: "",
        internship_status: null,
        start_date: null,
        end_date: "",
        comment: "",
        follow_up: [],
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(initialDatosFormulario);
    const [formularioAgregar, setFormularioAgregar] = useState(initialDatosFormularioAgregar);
    const [formularioEditar, setFormularioEditar] = useState(initialDatosFormularioEditar);
    const [formularioEditarPasantia, setFormularioEditarPasantia] = useState(initialDatosFormularioPasantia);
    const [formularioPasantia, setFormularioPasantia] = useState(initialDatosFormularioPasantia);
    const [city, setCity] = useState([]);
    const [data, setData] = useState([]);
    const [allPrograms, setAllPrograms] = useState([]);
    const [allProgramasEdit, setAllProgramasEdit] = useState([]);
    const [allInternships, setAllInternships] = useState([]);
    const [allInternshipsEdit, setAllInternshipsEdit] = useState([]);
    const [allEmpresas, setAllEmpresas] = useState([]);
    const [allEmpresasEdit, setAllEmpresasEdit] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loadedStudies, setLoadedStudies] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalPasantias, setShowModalPasantias] = useState(false);
    const [idEditar, setIdEditar] = useState();
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [showModalEditarPasantias, setShowModalEditarPasantias] = useState(false);
    const [showModalWorkExperiences, setShowModalWorkExperiences] = useState(false);
    const [showModalStudy, setShowModalStudy] = useState(false);
    const [showModalEditWorkExperience, setShowModalEditWorkExperience] = useState(false);
    const [showModalEditStudy, setShowModalEditStudy] = useState(false);
    const [idEditWorkExperience, setIdEditWorkExperience] = useState(0);
    const [idEditStudy, setIdEditStudy] = useState(0);
    const [dataWorkExperiences, setDataWorkExperiences] = useState([]);
    const [dataStudies, setDataStudies] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [dataConocimientos, setDataConocimientos] = useState([]);
    const [dataPasantiaStudent, setDataPasantiaStudent] = useState([]);
    const [showPauseInternship, setShowPauseInternship] = useState(false);

    const [mostrarComentarioPasantia, setMostrarComentarioPasantia] = useState(false);
    const [mostrarFechaPasantia, setMostrarFechaPasantia] = useState(false);
    const [mostrarFechaPasantiaEditar, setMostrarFechaPasantiaEditar] = useState(false);

    const [showModalPsychological, setShowModalPsychological] = useState(false);
    const [showModalSocioEconomic, setShowModalSocioEconomic] = useState(false);
    const [showModalInternShipsHistory, setShowModalInternShipsHistory] = useState(false);
    const [showModalCommitmentLetter, setShowModalCommitmentLetter] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const [especialidadesEditar, setEspecialidadesEditar] = useState([]);
    const [conocimientosEditar, setConocimientosEditar] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [conocimientos, setConocimientos] = useState([]);
    const [knowledgeValidados, setKnowledgeValidados] = useState([]);

    const [letterDesertionStudent, setLetterDesertionStudent] = useState('');
    const [alertSpan, setAlertSpan] = useState(false);
    const [showModalViewDocument, setShowModalViewDocument] = useState(false);

    const [loadingLetterDesertion, setLoadingLetterDesertion] = useState(false);
    const [shareData, setShareData] = useState(null);
    const [mostrarComentario, setMostrarComentario] = useState(false);
    const [modalidades, setModalidades] = useState([]);

    const [infoCV, setInfoCV] = useState([]);

    const [knowledge, setKnowledge] = useState([]);

    // const loadData = useCallback(async () => {
    //     let getEspecialidades = await getOrDeleteRequest(
    //         "speciality/filter",
    //         'GET'
    //     );

    //     console.log(getEspecialidades, "Especialidades")

    //     let knowledgeEditar = [];

    //     getEspecialidades.map((item) => {
    //         knowledgeEditar.push({
    //             label: item.name,
    //             value: item.id,
    //         });
    //     });

    //     setEspecialidades(getEspecialidades);
    //     setEspecialidadesEditar(knowledgeEditar);

    //     setLoaded(true);
    // });

    // const [estudioAcademico, setEstudioAcademico] = useState(false);
    const [dataEstudioAcademico, setDataEstudioAcademico] = useState([]);
    const [dataOtherStudies, setDataOtherStudies] = useState([]);

    const getStudies = async () => {
        console.log(id);
        let response = await getOrDeleteRequest(
            `student/get-studies/${id}?type=1`,
            'GET'
        );
        if (response.type === 'error') {
            console.error(response.message);
            setDataStudies([]);
            setDataEstudioAcademico([]);
            return;
        }
        console.log(response.data, "Estudios academicos");

        setDataEstudioAcademico(response.data);
        setDataStudies(response.data);
        setLoadedStudies(true);
    }

    const getOtherStudies = async () => {
        console.log(id);
        let response = await getOrDeleteRequest(
            `student/get-studies/${id}?type=2`,
            'GET'
        );
        if (response.type === 'error') {
            console.error(response.message);
            setDataOtherStudies([]);
            return;
        }
        console.log(response.data, "Otros estudios");

        setDataOtherStudies(response.data);
        // setDataStudies(response.data);
        // setLoadedStudies(true);
    }

    const getWorkExperiences = async () => {
        console.log(id);
        let response = await getOrDeleteRequest(
            `student/get-experience/${id}`,
            'GET'
        );
        if (response.type === 'error') {
            setDataWorkExperiences([]);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }
        console.log(response, "Experiencias laborales");    
        setDataWorkExperiences(response.data);
        // setLoaded(true);
    }

    const loadConocimientosEditar = useCallback(async (elements, seleccionado, data = null) => {
        console.log(elements, "elements")
        console.log(seleccionado, "elements")

        let uuids = elements.map((item) => item.value);
        let ids = uuids.join(",");

        console.log(uuids, "uuids")

        if (elements.length < 1) {
            setConocimientosEditar([]);

            // Si no hay nada seleccionado, se limpia los conocimientos
            formularioEditarPasantia.knowledge = [];
            formularioEditarPasantia.specialities = [];

            setFormularioEditarPasantia(formularioEditarPasantia);

            return;
        }

        let getConocimientos = await getOrDeleteRequest(
            `knowledge/filter?type=${ids}`,
            'GET'
        );
        // console.log(uuids);
        // Filtrar conocimientos por especialidades seleccionadas
        let currentKnowledge = formularioEditarPasantia.knowledge;

        let ArrayConocimientos = [];

        if (currentKnowledge !== undefined) {
            console.log(currentKnowledge, "currentKnowledge")
            currentKnowledge = currentKnowledge.filter((item) => {
                if (item.type_id !== undefined || item.type_id !== null) {
                    return uuids.includes(item.type_id);
                }
            });

            // formularioEditarPasantia.knowledge = currentKnowledge;
            // formularioEditarPasantia.specialities = elements;

            // setFormularioEditarPasantia(formularioEditarPasantia);
            if (data !== null) {
                data.knowledge = currentKnowledge;
                data.specialities = elements;

                setFormularioEditarPasantia(data);
            } else {
                setFormularioEditarPasantia({
                    ...formularioEditarPasantia,
                    knowledge: currentKnowledge,
                    specialities: elements,
                });
            }
        }

        let insertarConocimientos = [];

        getConocimientos.map((item) => {
            insertarConocimientos.push({
                label: item.name,
                value: item.id,
            });
        });

        // console.log(insertarConocimientos,"getConocimientos")

        setConocimientosEditar(insertarConocimientos);
    });

    // const loadConocimientos = useCallback(async (elements) => {

    //     let uuids = elements.map((item) => item.id);
    //     let ids = uuids.join(",");

    //     if (elements.length < 1) {
    //         setConocimientos([]);

    //         // Si no hay nada seleccionado, se limpia los conocimientos
    //         formularioPasantia.knowledge = [];
    //         formularioPasantia.specialities = [];

    //         setFormularioPasantia(formularioPasantia);

    //         return;
    //     }

    //     let getConocimientos = await getOrDeleteRequest(
    //         `knowledge/filter?type=${ids}`,
    //         'GET'
    //     );

    //     // Filtrar conocimientos por especialidades seleccionadas
    //     let currentKnowledge = formularioPasantia.knowledge;

    //     if (Array.isArray(currentKnowledge) && currentKnowledge.length) {
    //         currentKnowledge = currentKnowledge.filter((item) => {
    //             return uuids.includes(item.type);
    //         });

    //         formularioPasantia.knowledge = currentKnowledge;
    //         formularioPasantia.specialities = elements;

    //         setFormularioPasantia(formularioPasantia);
    //     }

    //     setConocimientos(getConocimientos);
    // });

    // const [idCity, setIdCity] = useState();

    const { id } = useParams();

    const ValidarInputs = (inputs) => {
        let errors = [];
        let formData = inputs;

        console.log(formData, "formData")

        formData.map((input) => {
            if (input.value === "" || input.value === null || input.value === undefined || input.value === "0") {
                errors.push({
                    valorInput: input.name,
                    mensaje: "Campo requerido",
                    estado: true
                });

                return;
            }

            //Validar fechas de inicio y fin de pasantia que la de inicio no sea mayor a la de fin
            if (showModalPasantias && mostrarFechaPasantia) {
                if (input.name === "start_date" && input.value !== null) {
                    let startDate = new Date(input.value);
                    let endDate = new Date(formularioPasantia.end_date);

                    if (endDate < startDate) {
                        errors.push({
                            valorInput: input.name,
                            mensaje: "La fecha de inicio no puede ser mayor a la de fin",
                            estado: true
                        });
                    }
                }
            }

            if (showModalEditarPasantias && mostrarFechaPasantiaEditar) {
                if (input.name === "start_date" && input.value !== null) {
                    let startDate = new Date(input.value);
                    let endDate = new Date(formularioEditarPasantia.end_date);

                    if (endDate < startDate) {
                        errors.push({
                            valorInput: input.name,
                            mensaje: "La fecha de inicio no puede ser mayor a la de fin",
                            estado: true
                        });
                    }
                }
            }

            //Validar fechas de inicio y fin de pasantia que la de fin no sea menor a la de inicio
            if (mostrarFechaPasantia) {
                if (input.name === "end_date" && input.value !== null) {
                    let startDate = new Date(formularioPasantia.start_date);
                    let endDate = new Date(input.value);

                    if (endDate < startDate) {
                        errors.push({
                            valorInput: input.name,
                            mensaje: "La fecha de fin no puede ser menor a la de inicio",
                            estado: true
                        });
                    }
                }
            }

            if (mostrarFechaPasantiaEditar) {
                if (input.name === "end_date" && input.value !== null) {
                    let startDate = new Date(formularioEditarPasantia.start_date);
                    let endDate = new Date(input.value);

                    if (endDate < startDate) {
                        errors.push({
                            valorInput: input.name,
                            mensaje: "La fecha de fin no puede ser menor a la de inicio",
                            estado: true
                        });
                    }
                }
            }

            errors.push({
                valorInput: input.name,
                mensaje: "",
                estado: false
            });
            return;
        });

        return errors;
    }

    // async function getMunicipios(e) {
    //     const url = `${process.env.REACT_APP_API_URI}city/find/${e}`;

    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //         }
    //     };

    //     if (Cookies.get('Bearer') !== undefined) {
    //         config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
    //     } else {
    //         window.location = "/"
    //     }

    //     try {
    //         const resp = await axios.get(url, config);

    //         setCity(resp.data);

    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    const getProgramsStudent = async () => {
        let response = await getOrDeleteRequest(
            `student/programs/${id}`,
            'GET'
        );
        console.log(response, "Respuesta de programas");
        if (response.type === 'error') {
            console.error(response.message);
            return [];
        }

        setData(response);
        return response;
    };

    async function getPasantiaStudent() {
        console.log(id, "id en pasantias estudiantes")
        const url = `${process.env.REACT_APP_API_URI}student/internships/${id}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            console.log(resp.data, "Respuesta de Pasantias estudiantes")
            setDataPasantiaStudent(resp.data);

        } catch (err) {
            console.error(err, "Respuesta de Pasantias estudiantes");
        }
    };

    const getAllEmpresas = async () => {
        let response = await getOrDeleteRequest(
            'contributor/filter?origin=internships',
            'GET'
        );

        if (response.type === 'error') {
            console.error(response.message);
            return;
        }

        let empresasEdit = response.map((item) => {
            return {
                label: item.name,
                value: item.id,
            };
        });

        setAllEmpresas(empresasEdit);
        setAllEmpresasEdit(empresasEdit);
        return;
    };

    async function getAllPrograms() {
        const url = `${process.env.REACT_APP_API_URI}program-schedule/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setAllPrograms(resp.data);

            let programasEdit = resp.data.map((item) => {
                return {
                    label: item.label,
                    value: item.value,
                };
            });
            console.log(programasEdit, "programasEdit")
            setAllProgramasEdit(programasEdit);

        } catch (err) {
            console.error(err);
        }
    };

    const [speciality, setSpeciality] = useState([]);

    async function getEspecialidades() {
        let response = await getOrDeleteRequest(
            'speciality',
            'GET'
        );

        if (response.type === 'error') {
            console.error(response.message);
            return;
        }
        console.log(response, "Respuesta de especialidades")
        setSpeciality(response);
        return;
    };

    // const [viewKnowledge, setViewKnowledge] = useState();
    const filterKnowledge = [];

    speciality.map((item) => {
        const list = dataConocimientos.filter((conocimiento) => item.id === conocimiento.category_id);

        let data = {
            name: item.name,
            list: list,
        };

        // console.log(data, "data")

        filterKnowledge.push(data);
    });

    console.log(filterKnowledge, "filterKnowledge")

    // const dataKnowledgeFreelance = filterKnowledge.filter((item) => item.name == "");

    // const getInternships = useCallback(async () => {
    //     let response = await getOrDeleteRequest(
    //         'programs/index?status=1&origin=internships',
    //         'GET'
    //     );

    //     // console.log(response,"Respuesta de pasantias")

    //     if (response.type !== 'error') {
    //         // console.log(response, "Respuesta de pasantiasdbcsncscn sdc ds");
    //         setAllInternships(response);

    //         let internshipsEdit = response.map((item) => {
    //             return {
    //                 label: item.name,
    //                 value: item.id,
    //             };
    //         });

    //         setAllInternshipsEdit(internshipsEdit);

    //         console.log(response, "internshipsEdit")
    //     }
    // });

    // const [district, setDistrict] = useState();

    // async function getDistrict(e) {
    //     const url = `${process.env.REACT_APP_API_URI}district/find/${e}`;

    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //         }
    //     };

    //     if (Cookies.get('Bearer') !== undefined) {
    //         config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
    //     } else {
    //         window.location = "/"
    //     }

    //     try {
    //         const resp = await axios.get(url, config);
    //         setDistrict(resp.data.name);

    //         // setFormulario(resp.data)

    //         // getMunicipios(resp.data.city_id)

    //     } catch (err) {
    //         console.error(err);
    //     }
    // };

    async function getKnowledge() {
        let response = await getOrDeleteRequest(
            `student/get-knowledges/${id}`,
            'GET'
        );
        
        if (response.type === 'error') {
            console.error(response.message);
            
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
            });
            setLoaded(true);
            return;
        }

        let respData = response.data;

        console.log(respData, "Respuesta de conocimientos");

        // Recolectar todos los items que sean del mismo categoria y agregarlos a un array
        let conocimientos = [];

        console.log(conocimientos, "conocimientos");

        setDataConocimientos(respData);

        if (respData.length > 0) {
            setMostrarKnoledge(true);
        }

        setLoaded(true);
        setMostrarKnoledge(true);
        return;
    };

    const getInfoEstudiante = async () => { 
        setLoaded(false);
        let response = await getOrDeleteRequest(
            `student/find/${id}`,
            'GET'
        );
        
        if (response.type === 'error') {
            console.error(response.message);
            setLoaded(true);
            return;
        }

        let respData = response.baseData;
        console.log(respData, "Respuesta de estudiante");
        switch (respData.time_availability) {
            case 1:
                respData.time_availability = "Completo";
                break;

            case 2:
                respData.time_availability = "Medio tiempo por la mañana";
                break;

            case 3:
                respData.time_availability = "Medio tiempo por la tarde";
                break;

            case 4:
                respData.time_availability = "Sábado y domingo";
                break;

            case 6:
                respData.time_availability = "Lunes a viernes";
                break;

            case 5:
                respData.time_availability = "Sin disponibilidad";
                break;

            default:
                respData.time_availability = "No ingresado";
                break;
        }

        setFormulario(response.baseData);
        setShareData(response.shareData);

        setLoaded(true);
        return;
    };

    const edad = (fechaNacimiento) => {
        if (typeof (fechaNacimiento) === 'string') {
            const fechaActual = new Date();
            const [anio, mes, dia] = fechaNacimiento.split('-');
            const fechaNacimientoObj = new Date(anio, mes - 1, dia);
            const edad = differenceInYears(fechaActual, fechaNacimientoObj);

            return edad;
        }

    }

    const [mostrarComentarioEditar, setMostrarComentarioEditar] = useState(false);
    const [mostrarComentarioEditarPasantia, setMostrarComentarioEditarPasantia] = useState(false);

    const [desertionLetterEditar, setDesertionLetterEditar] = useState(null);

    function mostrarInfoModalEditar(e) {
        setFormularioEditar()
        setIdEditar(e.currentTarget.id)
        setDesertionLetterEditar(null);

        data.map((item) => {
            if (item.id == e.currentTarget.id) {
                let defaultProgram = allProgramasEdit.filter((program) => program.value == item.program_id).map((program) => program);

                let defaultData = {
                    program: defaultProgram[0],
                    program_status: item.status,
                    application_reason: item.application_reason,
                    comment: "",
                    internship: null,
                    internship_status: null,
                };

                if (item.comment !== null) {
                    defaultData.comment = item.comment;
                }

                if (item.abandoned_at !== null) {
                    defaultData.abandoned_at = item.abandoned_at;
                }

                console.log(item,"Programaaaaaaaaaa")

                // if (item.internship !== null) {
                //     let defaultInternship = allInternshipsEdit.filter((internship) => internship.value == item.internship.internship_id).map((internship) => internship);

                //     defaultData.internship = defaultInternship[0];
                //     defaultData.internship_status = item.internship.status;

                //     setIsChecked(true);
                // }

                setFormularioEditar(defaultData);
                setDesertionLetterEditar(item.file_name);

                if (item.comment === null) {
                    setMostrarComentarioEditar(false);
                    return;
                }

                setMostrarComentarioEditar(true);
                return;
            }
        })
        // console.log(formularioEditar,"formulario editar")        
        showPopup(true, setShowModalEditar);
    }

    function mostrarInfoModalPasantiasEditar(e, origin = 'edit') {
        setFormularioEditarPasantia()
        setIdEditar(e.currentTarget.id)

        let defaultData = {};

        dataPasantiaStudent.forEach((item) => {
            if (item.id == e.currentTarget.id) {
                let empresa = {
                    value: item.organizer.id,
                    label: (item.internal === 1) ? "CONEXION El Salvador" : item.organizer.name,
                };
                
                defaultData = {
                    status: item.status,
                    organizer: (item.internal === 1) ? "" : empresa,
                    internal_internship: (item.internal === 1) ? true : false,
                    specialities: item.specialities,
                    knowledge: item.knowledge,
                    modality: item.modality_id,
                    start_date: item.start_date,
                    end_date: item.end_date,
                    register: item.register,
                    follow_up: item.follow_up,
                };

                if (item.current_follow_up !== null && item.current_follow_up !== undefined) {
                    defaultData.break_date = item.current_follow_up.break_date;
                    defaultData.follow_comment = item.current_follow_up.comment;
                    defaultData.follow_id = item.current_follow_up.id;
                }

                if (item.comment !== null) {
                    defaultData.comment = item.comment;
                    setMostrarComentarioEditarPasantia(true);
                } else {
                    setMostrarComentarioEditarPasantia(false);
                }

                if (item.end_date !== null) {
                    defaultData.end_date = item.end_date;
                    setMostrarFechaPasantiaEditar(true);
                } else {
                    setMostrarFechaPasantiaEditar(false);
                }

                if (item.status == 5) {
                    setShowPauseInternship(true);
                } else {
                    setShowPauseInternship(false);
                }

                setKnowledgeValidados(item.knowledge);
                loadConocimientosEditar(item.specialities, item.knowledge, defaultData);
            }
        });
        
        setFormularioEditarPasantia(defaultData);

        if (origin === 'edit') {
            showPopup(true, setShowModalEditarPasantias);
        }

        if (origin === 'history') {
            showPopup(true, setShowModalInternShipsHistory);
        }
    }

    const RemoveClassContainer = () => {
        let container = document.getElementById('container');

        // Seleccionar el segundo hijo del container
        let secondChild = container.getElementsByTagName('div')[1];

        console.log(secondChild, "secondChild")

        secondChild.classList.remove('mt-5');
        secondChild.classList.remove('mb-5');
    }

    useEffect(() => {
        if (!loaded) {
            getInfoEstudiante();
            getProgramsStudent();
            getAllPrograms();
            getPasantiaStudent();
            getModalidades();
            getEspecialidades();
            getAllEmpresas();
            getStudies();
            getOtherStudies();
            RemoveClassContainer();
        }
    }, []);

    var sexo = "";

    if (loaded && formulario.gender == 1) {
        sexo = "Masculino";
    } else if (loaded && formulario.gender == 2) {
        sexo = "Femenino";
    } else {
        sexo = "Prefiero no decirlo"
    }

    var secondCel = "";

    if (loaded && formulario.alternative_phone == null) {
        secondCel = "No ingresado";
    } else {
        secondCel = loaded && formulario.alternative_phone;
    }

    const downloadDocument = async (idDocument, nameDocument) => {
        setLoadingLetterDesertion(true);
        loadingLetterDesertion && MySwal.fire({
            position: 'center',
            icon: 'info',
            title: 'Descargando documento...',
            showConfirmButton: false,
        })

        await downloadFileRequest(
            `student/download-desertion-letter/${idDocument}`
        ).then((response) => {
            console.log(response);
            if (response.type == 'error') {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: response.message,
                    showConfirmButton: false,
                })
                setLoadingLetterDesertion(false);
                return;
            }
            console.log(response);
            setLoadingLetterDesertion(false);
            MySwal.close();
            MySwal.fire({
                position: 'center',
                icon: 'success',
                title: 'Documento descargado',
                showConfirmButton: false,
            })
            const imgDocument = URL.createObjectURL(response);
            hadelDownload(imgDocument, nameDocument);
        });
    }

    const hadelDownload = (imgDocument, nameDocument) => {
        const link = document.createElement('a');
        link.href = imgDocument;
        link.download = nameDocument;
        link.click();
    }

    const columns = [
        {
            width: "10%",
            cell: (row) => {
                let button = null;
                // console.log(row, "row");
                switch (row.status) {
                    case 4:
                        button = <>
                            <button data-tooltip-target="tooltip-light" data-tooltip-style="light" type="button" className="text-black font-medium rounded-lg text-xl px-5 py-2.5 text-center"
                            onClick={() => {
                                downloadDocument(row.id, 'Carta de deserción');
                            }}>
                                <FontAwesomeIcon icon={faCloudArrowDown} />
                            </button>
                        </>
                        if (row.file_name !== null) {
                            return button;
                        }
                    case 7:
                        button = <>
                            <button data-tooltip-target="tooltip-light" data-tooltip-style="light" type="button" className="text-black font-medium rounded-lg text-xl px-5 py-2.5 text-center"
                            onClick={() => {
                                downloadDocument(row.id, 'Carta de deserción');
                            }}>
                                <FontAwesomeIcon icon={faCloudArrowDown} />
                            </button>
                        </>
                        if (row.file_name !== null) {
                            return button;
                        }
                    default:
                        break;
                }
            }
        },
        {
            name: 'Formación',
            width: "35%",
            cell: (row) => loaded && <div>{row.program}</div>
        },
		{
			name: "Test de rendimiento",
			width: "15%",
			cell: (row) => loaded && (
				<div className="w-full flex justify-center">
					{ (row.status === 5 || row.status === 6) ? (
						<Link
							// to={"/VerTestDeRendimiento/" + row.id}
							to={ `/ranking-test/${row.program_id}/${row.student_id}` }
							className="text-2xl text-white bg-green-600 text-center px-2 py-1 rounded-lg transition-colors hover:bg-green-800"
						>
							{loaded && <FontAwesomeIcon icon={faListCheck} />}
						</Link>
					) : (
					<span className="text-base text-slate-800 text-center px-2 py-1 rounded-lg transition-colors">
						No disponible
					</span>
					)}
				</div>
			),
		},
        {
            name: 'Estado',
            width: "20%",
            cell: (row) => {
                let rowStatus = null;

                switch (row.status) {
                    case 1:
                        rowStatus = "Aplicado";
                        break;

                    case 2:
                        rowStatus = "Pre seleccionado";
                        break;

                    case 3:
                        rowStatus = "Seleccionado";
                        break;

                    case 4:
                        rowStatus = "No completado";
                        break;

                    case 5:
                        rowStatus = "Completado";
                        break;

                    case 6:
                        rowStatus = "En proceso";
                        break;

                    case 7:
                        rowStatus = "No iniciado";
                        break;

                    default:
                        rowStatus = "No ingresado";
                        break;

                }

                return loaded && <div>{rowStatus}</div>
            }
        },
        {
            name: 'Editar',
            width: "10%",
            className: "flex justify-center items-center",
            cell: (row) => loaded && <button id={row.id} onClick={(e) => {
                mostrarInfoModalEditar(e);
                console.log(e, "Event");
            }} className="text-2xl">{loaded && <FontAwesomeIcon icon={faPenToSquare} />}</button>
        },
    ];

    const columnsPasantias = [
        {
            name: 'Pasantía',
            // width: "60%",
            cell: (row) => loaded && <div>{row.organizer.name}</div>
        },
        {
            name: 'Estado',
            width: "20%",
            cell: (row) => {
                let rowStatus = null;

                switch (row.status) {
                    case 1:
                        rowStatus = "En espera";
                        break;

                    case 2:
                        rowStatus = "En proceso";
                        break;

                    case 3:
                        rowStatus = "Retirado";
                        break;

                    case 4:
                        rowStatus = "Concluida";
                        break;

                    case 5:
                        rowStatus = "En pausa";
                        break;

                    default:
                        rowStatus = "No ingresado";
                        break;

                }

                return loaded && <div>{rowStatus}</div>
            }
        },
        {
            name: 'Seguimiento',
            width: "15%",
            className: "flex justify-center items-center",
            cell: (row) => loaded && <button id={row.id} onClick={(e) => {
                mostrarInfoModalPasantiasEditar(e, 'history');
            }} className="text-2xl">{loaded && <FontAwesomeIcon icon={faListCheck} />}</button>
        },
        {
            name: 'Editar',
            width: "10%",
            className: "flex justify-center items-center",
            cell: (row) => loaded && <button id={row.id} onClick={(e) => {
                mostrarInfoModalPasantiasEditar(e);
            }} className="text-2xl">{loaded && <FontAwesomeIcon icon={faPenToSquare} />}</button>
        },
    ];

    // const columnsConocimientos = [
    //     {
    //         name: 'Conocimiento',
    //         // width: "100%",
    //         cell: (row) => {
    //             return (
    //                 <>
    //                     <div>{row.label}</div>
    //                     {/* <div>Mensaje</div> */}
    //                 </>
    //             );
    //         }
    //     },
    // ];

    const tableData = {
        columns,
        data
    };

    const ManejarEventoDeInputsEditar = (event) => {
        console.log(formularioEditar, "Hola editar")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        if (name == "program_status") {
            if (value == 4 || value == 7) {
                setMostrarComentarioEditar(true)
            } else {
                setMostrarComentarioEditar(false)
            }
            console.log(formularioEditar)
        }

        if (name == "comment") {
            console.log(formularioEditar)
        }

        console.log({ ...formularioEditar, [name]: value }, "Prueba")

        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormularioEditar({ ...formularioEditar, [name]: value });
    };

    const RegistrarPasantiaStudent = async (data) => {
        let idsKnowledge = [];

        data.knowledge.map((item) => {
            idsKnowledge.push(item.id);
        });

        let sendData = {
            student: id,
            knowledge: idsKnowledge,
            status: data.internship_status,
            modality: data.modality,
            start_date: data.start_date,
            internal_internship: data.internal_internship ? 1 : 0,
        };

        if (mostrarComentarioPasantia) {
            sendData.comment = data.comment;
        }

        if (mostrarFechaPasantia) {
            sendData.end_date = data.end_date;
        }

        if (!data.internal_internship) {
            sendData.organizer = data.organizer.value;
        }

        let response = await postOrPutRequest(
            `student/add-internship`,
            'POST',
            sendData
        );

        if (response.type === 'error') {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });

        showPopup(false, setShowModalPasantias);
        // setIsChecked(false);
        setMostrarComentarioPasantia(false);
        setMostrarFechaPasantia(false);
        setAlerta([initialStateInput]);
        setFormularioPasantia(initialDatosFormularioPasantia);
        getPasantiaStudent();
    };

    const RegistrarProgramaStudent = async (data) => {
        let sendData = new FormData();

        sendData.append('student', id);
        sendData.append('program', data.program);
        sendData.append('application_reason', data.application_reason);
        sendData.append('status', data.program_status);

        letterDesertionStudent != '' && sendData.append('desertion_letter', letterDesertionStudent);

        if (mostrarComentario) {
            sendData.append('comment', data.comment);
            sendData.append('abandoned', data.abandoned_at);
            // if(letterDesertionStudent == ''){
            //     setAlertSpan(true);
            //     return;
            // }
            // else {
            //     setAlertSpan(false);
            // }
        }

        if (isChecked) {
            sendData.append('internship', data.internship);
            sendData.append('internship_status', data.internship_status);
        }

        console.log(sendData, "SendData");

        let response = await postOrPutRequest(
            `student/add-program`,
            'POST',
            sendData
        );

        if (response.type === 'error') {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });

        showPopup(false, setShowModal);
        setIsChecked(false);
        setMostrarComentario(false);
        setAlerta([initialStateInput]);
        setFormularioAgregar(initialDatosFormularioAgregar);
        setData([])
        getProgramsStudent();
    };

    const addPasantiaStudent = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        let inputs = Object.entries(formularioPasantia).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        // Si el internal_internship es true, se quita del objeto al organizer temporalmente
        if (formularioPasantia.internal_internship) {
            inputs = inputs.filter(input => input.name !== 'organizer');
        }

        //Ordenamos los datos para enviarlos a la validacion
        if (!mostrarComentarioPasantia) {
            inputs = inputs.filter(input => input.name !== 'comment');
        }

        if (!mostrarFechaPasantia) {
            inputs = inputs.filter(input => input.name !== "end_date");
        }

        // if (!isChecked) {
        //     inputs = inputs.filter(input => input.name !== 'internship');
        //     inputs = inputs.filter(input => input.name !== 'internship_status');

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        let datosValidados = ValidarInputs(inputs);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);

        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.estado === true);
        console.log('Total de validaciones', totalValidaciones);

        if (totalValidaciones.length < 1) {
            // console.log("Si")
            RegistrarPasantiaStudent(formularioPasantia);
        }
    };

    const addProgramaStudent = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        let inputs = Object.entries(formularioAgregar).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        //Ordenamos los datos para enviarlos a la validacion
        if (!mostrarComentario) {
            inputs = inputs.filter(input => input.name !== 'comment' && input.name !== "abandoned_at");
        }

        if (!isChecked) {
            inputs = inputs.filter(input => input.name !== 'internship');
            inputs = inputs.filter(input => input.name !== 'internship_status');
        }

        // console.log(inputs,"Inputs")

        if (letterDesertionStudent == '') {
            inputs = inputs.filter(input => input.name !== 'desertion_letter');
        }

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        let datosValidados = ValidarInputs(inputs);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);

        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.estado === true);
        // console.log('Total de validaciones', totalValidaciones);

        if (totalValidaciones.length < 1) {
            // console.log("Si")
            RegistrarProgramaStudent(formularioAgregar);
        }
    };

    const ActualizarPasantiaStudent = async (data) => {
        let idsEspecialities = [];
        let idsKnowledge = [];

        data.specialities.map((item) => {
            idsEspecialities.push(item.id);
        });

        knowledgeValidados.map((item) => {
            idsKnowledge.push(item.value);
        });

        let sendData = {
            student: id,
            internal_internship: data.internal_internship ? 1 : 0,
            specialities: idsEspecialities,
            knowledge: idsKnowledge,
            status: data.status,
            modality: data.modality,
            start_date: data.start_date,
        };

        if (data.follow_id !== undefined && data.follow_id !== null) {
            sendData.follow_id = data.follow_id;
        }

        if (mostrarComentarioEditarPasantia) {
            sendData.comment = data.comment;
        }

        if (mostrarFechaPasantiaEditar) {
            sendData.end_date = data.end_date;
        }

        if (sendData.status == 5) {
            sendData.break_date = data.break_date;
            sendData.follow_comment = data.follow_comment;
        }

        if (!data.internal_internship) {
            sendData.organizer = data.organizer.value;
        }
        // console.log(sendData, "SendData");
        let response = await postOrPutRequest(
            `student/update-internship/${idEditar}`,
            'PUT',
            sendData
        );

        if (response.type === 'error') {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });

        showPopup(false, setShowModalEditarPasantias);
        // setIsChecked(false);
        setMostrarComentarioEditarPasantia(false);
        setAlerta([initialStateInput]);
        setFormularioEditarPasantia(initialDatosFormularioEditar);
        // setDataP([])
        getPasantiaStudent();
    };

    const ActualizarProgramaStudent = async (data) => {
        let sendData = new FormData();

        console.log(data, "Data")

        sendData.append('student', id);
        sendData.append('program', data.program.value);
        sendData.append('application_reason', data.application_reason);
        sendData.append('status', data.program_status);

        if (mostrarComentarioEditar) {
            sendData.append('comment', data.comment);
            sendData.append('abandoned', data.abandoned_at);
            if (letterDesertionStudent !== "") {
                sendData.append('desertion_letter', letterDesertionStudent);
            }
            // if(letterDesertionStudent == ''){
            //     setAlertSpan(true);
            //     return;
            // }
            // else {
            //     setAlertSpan(false);
            // }
        }

        if (isChecked) {
            sendData.append('internship', data.internship.value);
            sendData.append('internship_status', data.internship_status);
        }

        console.log(sendData, "SendData")

        let response = await postOrPutRequest(
            `student/update-program/${idEditar}?_method=PUT`,
            'POST',
            sendData
        );

        if (response.type === 'error') {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });

        showPopup(false, setShowModalEditar);
        setIsChecked(false);
        setMostrarComentarioEditar(false);
        setAlerta([initialStateInput]);
        setFormularioEditar(initialDatosFormularioEditar);
        setData([])
        getProgramsStudent();
    };

    const editarPasantiaStudent = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        if (formularioEditarPasantia.break_date === undefined) {
            formularioEditarPasantia.break_date = null;
            formularioEditarPasantia.follow_comment = null;
        }

        if (formularioEditarPasantia.comment === undefined) {
            formularioEditarPasantia.comment = null;
        }

        let inputs = Object.entries(formularioEditarPasantia).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        // Si el internal_internship es true, se quita del objeto al organizer temporalmente
        if (formularioEditarPasantia.internal_internship) {
            inputs = inputs.filter(input => input.name !== 'organizer');
        }

        if (!mostrarComentarioEditarPasantia) {
            inputs = inputs.filter(input => input.name !== 'comment');
        }

        if (!mostrarFechaPasantiaEditar) {
            inputs = inputs.filter(input => input.name !== 'end_date');
        }

        if (!showPauseInternship) {
            inputs = inputs.filter(input => input.name !== 'break_date');
            inputs = inputs.filter(input => input.name !== 'follow_comment');
        }
        // console.log(inputs, "Inputs");
        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(inputs);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.estado === true);
        // console.log(formularioEditarPasantia, "Formulario pasantia");
        // return;
        if (totalValidaciones.length < 1) {
            ActualizarPasantiaStudent(formularioEditarPasantia);
        }
    };

    const editarProgramaStudent = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        console.log(formularioEditar)
        let inputs = Object.entries(formularioEditar).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        // Removemos el application_reason ya que no es necesario
        inputs = inputs.filter(input => input.name !== 'application_reason');

        if (!mostrarComentarioEditar) {
            inputs = inputs.filter(input => input.name !== 'comment');
        }

        if (!isChecked) {
            inputs = inputs.filter(input => input.name !== 'internship');
            inputs = inputs.filter(input => input.name !== 'internship_status');
        }

        console.log(inputs, "Inputs");

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(inputs);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.estado === true);

        if (totalValidaciones.length < 1) {
            ActualizarProgramaStudent(formularioEditar);
        }
    };

    const addComentario = (e) => {
        if (e.target.value == 4 || e.target.value == 7) {
            setMostrarComentario(true)
        } else {
            setMostrarComentario(false)
        }
    }

    async function getModalidades() {
        const url = `${process.env.REACT_APP_API_URI}modality/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            console.log(resp.data, "Respuesta de modalidades")
            setModalidades(resp.data);

        } catch (err) {
            console.error(err);
        }
    };

    const [loadingLetterComitment, setLoadingLetterComitment] = useState(false);

    const MySwal = withReactContent(Swal);

    const [mostrarPDF, setMostrarPDF] = useState(false);
    const [mostrarPDFCV, setMostrarPDFCV] = useState(false);
    // const [destinatario, setDestinatario] = useState("");

    const formatBirthDate = (date) => {
        if (typeof (date) === 'string') {
            // Formato de fecha dia mes (3 letras) año
            let [year, month, day] = date.split('-');
            let dateObj = new Date(year, month - 1, day);
            let options = { year: 'numeric', month: 'long', day: 'numeric' };

            return dateObj.toLocaleDateString('es-ES', options);
        }

        return date;
    }

    const verificarProgramasTerminados = () => {
        let result = data.map((item) => {
            if (item.status == 5) {
                return true;
            }
            return false;
        })

        if(result.includes(true)){
            setMostrarPDF(true);
        } else {
            MySwal.fire({
                title: 'No se puede generar la carta de recomendación',
                text: 'El beneficiario no ha completado ningún programa',
                icon: 'warning',
                confirmButtonText: 'Aceptar',
            });
        }
    }

    const navigate = useNavigate();

    const verificarCurriculum = () => {
        if (formulario.photo === null) {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: "No se ha generado el CV ya que no se ha subido ninguna foto del beneficiario.",
                showConfirmButton: true,
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#2563EB",
                showDenyButton: true,
                denyButtonText: "Ir a subir foto",
                denyButtonColor: "#0A243F",
            }).then(function (result) {
                if (result.isDenied) {
                    // Redireccionar con react router
                    navigate(`/EditarBeneficiario/${formulario.id}`);
                }
            });
            setLoaded(true);
        } else {
            if(dataEstudioAcademico.length > 0){
                setLoaded(true);
                loaded && setMostrarPDFCV(true)
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "No se ha generado el CV ya que no se ha ingresado ningún estudio académico.",
                    showConfirmButton: true,
                    confirmButtonText: "Cerrar",
                    confirmButtonColor: "#2563EB",
                })
                setLoaded(true);
            }
        }
    }
    const [mostrarKnoledge, setMostrarKnoledge] = useState(false);

    return (
        <>
            {
                <>
                    <div className={`${mostrarPDF || mostrarPDFCV ? "block" : "hidden"} flex flex-wrap-reverse md:flex-wrap w-full md:px-10`}>
                        <div className="flex items-center w-full md:w-1/6 justify-start px-10 md:px-0">
                            <button
                                className="text-black bg-slate-50 py-3 px-4 text-sm font-medium text-center rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-gray-300 transition-colors"
                                onClick={() => {
                                    setMostrarPDF(false);
                                    setMostrarPDFCV(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="inline-block fill-current -ml-1 mr-2 h-5 w-5" />
                                <span className="ml-1">Regresar</span>
                            </button>
                        </div>
                        <div className="flex items-center justify-center w-full md:w-4/6">
                            <h1 className="my-5 text-white text-center font-bold text-4xl">
                                {mostrarPDF === true && mostrarPDFCV === false ? "Carta de recomendación para el beneficiario" : "Curriculum vitae"}
                            </h1>
                        </div>
                        <div className="w-0 md:w-1/6">
                            <br />
                        </div>
                    </div>
                    {mostrarPDF === true && mostrarPDFCV === false
                    &&
                        loaded && <EstructuraPDF data={formulario} />
                    }

                    {mostrarPDF === false && mostrarPDFCV === true
                    &&
                        loaded && <EstructuraCVPDF data={formulario} id={id} />
                    }

                    <div className={`${mostrarPDF || mostrarPDFCV ? "hidden" : "block"} flex items-center justify-center flex-wrap bg-blue-950 px-5 md:px-0`}>
                        <div className="flex flex-wrap-reverse md:flex-wrap w-full md:px-10">
                            <div className="flex items-center w-1/2 md:w-1/4 justify-start px-10 order-1">
                                <BackButton
                                    history={Cookies.get('prevLocation')}
                                />
                            </div>
                            <div className="flex items-center justify-center w-full md:w-2/4 order-2">
                                <h1 className="my-5 text-white text-center font-bold text-4xl">Expediente del beneficiario</h1>
                            </div>
                            <div className="flex items-center w-1/2 md:w-1/4 justify-end px-10 order-1 md:order-3">
                                {loaded
                                    &&
                                    <>
                                        <Link
                                            to={`/EditarBeneficiario/${formulario.id}`}
                                            className="text-black bg-slate-50 py-3 px-4 text-sm font-medium text-center rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-gray-300 transition-colors"
                                        >
                                            <FontAwesomeIcon className="inline-block fill-current -ml-1 mr-2 h-5 w-5" icon={faPenToSquare} />
                                            <span className='ml-1'>Editar</span>
                                        </Link>
                                    </>}
                            </div>
                        </div>

                        {/* Personal info */}
                        <div className="flex flex-wrap w-full md:pl-10 justify-center">
                            <div className="w-full md:w-1/4 px-10 md:px-0 py-5">
                                <div className="profile-card h-full">
                                    <div className="profile-card-image">
                                        <img
                                            src={Wallpaper}
                                            alt=""
                                        />
                                    </div>
                                    <div className="profile-card-avatar">
                                        {(loaded && formulario.photo) && (
                                            <img
                                                src={`data:image/jpeg;base64,${formulario.photo}`}
                                                className=""
                                                alt=""
                                            />
                                        )}

                                        {(loaded && !formulario.photo) && (
                                            <img
                                                // src={formulario.gender !== 1 ? UserFemale : UserMale}
                                                src={ shareData && shareData.gender !== 1 ? UserFemale : UserMale }
                                                alt=""
                                                className=""
                                            />
                                        )}
                                    </div>
                                    {
                                        loaded
                                            ?
                                            <>
                                                <div className="profile-card-title">
                                                    {loaded && formulario.names}
                                                    <br />
                                                    {loaded && formulario.lastnames}
                                                </div>
                                                {/* <div className="profile-card-subtitle">{loaded && `${edad(formulario.birth_date)} años`}</div> */}
                                                <div className="profile-card-subtitle">
                                                    {
                                                        loaded
                                                            ?
                                                            <>
                                                                <button
                                                                    id="recommend-letter"
                                                                    className="btn-file-card text-black bg-slate-50 p-1.5 rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-blue-cnx hover:text-white transition-colors"
                                                                    onClick={verificarProgramasTerminados}
                                                                >
                                                                    <FontAwesomeIcon className="text-xl" icon={faFilePdf} />
                                                                </button>
                                                                <span className="tooltip">
                                                                    Carta de recomendación
                                                                </span>
                                                                <button
                                                                    id="commitment-letter"
                                                                    className="btn-file-card text-black bg-slate-50 p-1.5 rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-blue-cnx hover:text-white transition-colors"
                                                                    onClick={() => showPopup(true, setShowModalCommitmentLetter)}
                                                                >
                                                                    <FontAwesomeIcon className="text-xl" icon={faSignature} />
                                                                </button>
                                                                <span className="tooltip">
                                                                    Carta de compromiso
                                                                </span>
                                                                <button
                                                                    id="curriculum"
                                                                    className="btn-file-card text-black bg-slate-50 p-1.5 rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-blue-cnx hover:text-white transition-colors"
                                                                    onClick={
                                                                        () => {
                                                                            // getInfoEstudiante();
                                                                            verificarCurriculum();
                                                                        }
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon className="text-xl" icon={faAddressCard} />
                                                                </button>
                                                                <span className="tooltip">
                                                                    Curriculum vitae
                                                                </span>
                                                            </>
                                                            :
                                                            <br />
                                                    }

                                                </div>
                                                <div className="flex flex-wrap w-full px-2 profile-card-info">
                                                    <div className="w-full">
                                                        {
                                                        loaded 
                                                        &&
                                                        (
                                                            formulario.email !== null 
                                                            ? 
                                                            (
                                                                <>
                                                                    <a
                                                                        className="profile-card-item w-full text-gray-900 bg-white block py-1 px-2 rounded-lg hover:bg-blue-cnx hover:text-white transitions-colors mb-1"
                                                                        href={`mailto:${loaded && formulario.email !== null ? formulario.email : ''}`}
                                                                    >
                                                                        <FontAwesomeIcon className='mr-3' icon={faEnvelope} />
                                                                        {formulario.email.length > 21 ? formulario.email.substring(0, 21) + "..." : formulario.email}
                                                                    </a>
                                                                    {loaded && formulario.email.length > 18 && (
                                                                        <span className="tooltip">{formulario.email}</span>
                                                                    )} 
                                                                </>
                                                            ) : (
                                                                <div className="text-gray-500 py-1 px-2 mb-1">Correo no registrado</div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="w-full">
                                                    {
                                                        loaded 
                                                        &&
                                                        (
                                                            formulario.email !== null 
                                                            ? 
                                                            (
                                                                <a
                                                                    className="profile-card-item w-full text-gray-900 bg-white block py-1 px-2 rounded-lg hover:bg-blue-cnx hover:text-white transitions-colors"
                                                                    href={`tel:503${formulario.phone}`}
                                                                >
                                                                    <FontAwesomeIcon className='mr-3' icon={faPhoneAlt} />
                                                                    {loaded && formulario.phone}
                                                                </a>
                                                            ) : (
                                                                <div className="text-gray-500 py-1 px-2">Teléfono no registrado</div>
                                                            )
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <PreloaderCard />
                                    }
                                </div>
                            </div>
                            <div className="w-full md:w-3/4 h-full py-5" id='container'>
                                <SectionProfile title="Información personal">
                                    {
                                        loaded
                                            ?
                                            <>
                                                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="block font-bold text-black">Nombres:</span>
                                                    {/* <br /> */}
                                                    <span className="block">{loaded && formulario.names}</span>
                                                </div>
                                                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Apellidos:</span><br />{loaded && formulario.lastnames}
                                                </div>
                                                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Fecha de nacimiento:</span>
                                                    <br />
                                                    {/* {loaded && formulario.birth_date} */}
                                                    {loaded && formatBirthDate(formulario.birth_date)}
                                                </div>
                                                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Edad:</span><br />{loaded && `${edad(formulario.birth_date)} años`}
                                                </div>
                                                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Género:</span><br />
                                                    {loaded && (
                                                        shareData !== null 
                                                        ? (shareData.gender === 1 ? "Masculino" : (shareData.gender === 2 ? "Femenino" : "Prefiero no decirlo"))
                                                        : "Sin registro"
                                                    )}
                                                </div>
                                                <div className="w-full sm:w-1/2 md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <div>
                                                        { formulario.document_front !== null && formulario.document_front !== '' 
                                                            ? <span onClick={() => { setShowModalViewDocument(true) }} ><FontAwesomeIcon icon={faEye} className='px-2 text-blue-900 hover:text-blue-800 cursor-pointer' /></span>
                                                            : <span><FontAwesomeIcon icon={faEyeSlash} className='px-2 cursor-not-allowed'/></span> 
                                                        }
                                                        <span className="font-bold text-black">
                                                            {loaded && edad(formulario.birth_date) >= 18 ? "DUI:" : "NIT:"}
                                                        </span>
                                                    </div>
                                                    {loaded && edad(formulario.birth_date) >= 18 ? formulario.dui : formulario.nit}
                                                </div>
                                                <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Dirección:</span>
                                                    <br />
                                                    {loaded 
                                                    && 
                                                    (
                                                        shareData !== null
                                                        ?
                                                            shareData.direction === null ? "No hay registro" :  shareData.direction
                                                        :
                                                        "No hay registro"
                                                    )
                                                    }
                                                </div>
                                                <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Departamento de residencia:</span><br />
                                                    {loaded 
                                                    && 
                                                    (
                                                        shareData !== null
                                                        ?
                                                            shareData.state.name === null ? "No hay registro" : shareData.state.name
                                                        :
                                                        "No hay registro"
                                                    )
                                                    }
                                                </div>
                                                <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Municipio de residencia:</span><br />
                                                    {
                                                    loaded 
                                                    && 
                                                    (
                                                        shareData !== null
                                                        ?
                                                            shareData.city.name === null ? "No hay registro" : shareData.city.name
                                                        :
                                                        "No hay registro"
                                                    )
                                                    }
                                                </div>
                                                <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                    <span className="font-bold text-black">Distrito de residencia:</span><br />
                                                    {
                                                    loaded 
                                                    && 
                                                    (
                                                        shareData !== null
                                                        ?
                                                            shareData.district.name === null || shareData.district.name === undefined ? "No hay registro" : shareData.district.name
                                                        :
                                                        "No hay registro"
                                                    )
                                                    }
                                                </div>
                                            </>
                                            :
                                            <PreloaderInfoPersonalBeneficiario />
                                    }
                                </SectionProfile>
                            </div>
                        </div>

                        <SectionProfile title="Estado de perfil de Chambaticon">
                            {
                                loaded
                                    ?
                                    <>
                                        <div className="w-full p-2 text-sm text-gray-500 my-5 text-center flex justify-center items-center">
                                            <img
                                                src={ ChambaticonBrand }
                                                className="w-20 h-20 mr-5"
                                                alt=""
                                            />
                                            <div className="inline-block text-center text-base">
                                                { (loaded && formulario.complete_external_profile === 1) && (
                                                    <>
                                                        Perfil de Chambaticon&nbsp;<span className="text-green-700 font-bold uppercase">verificado</span>.
                                                    </>
                                                ) }
                                                { (loaded && formulario.complete_external_profile === 0) && (
                                                    <>
                                                        Perfil de Chambaticon&nbsp;<span className="text-red-700 font-bold uppercase">no completado</span> o <span className="text-orange-500 font-bold uppercase">en proceso de verificación</span>.
                                                    </>
                                                ) }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                            }
                        </SectionProfile>

                        <SectionProfile title="Sobre mi">
                            {
                                loaded
                                    ?
                                    <>
                                        <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                            {
                                            loaded 
                                            && 
                                            (
                                                shareData !== null
                                                ?
                                                    shareData.about_me === null ? "No hay registro" : shareData.about_me
                                                :
                                                "No hay registro"
                                            )
                                            }
                                        </div>
                                    </>
                                    :
                                    <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                            }
                        </SectionProfile>

                        {/* Contact info */}
                        <SectionProfile title="Información de contacto">
                            {
                                loaded
                                    ?
                                    <>
                                        <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Correo electrónico</span><br />
                                            {
                                                loaded 
                                                && 
                                                (
                                                    formulario.email === null
                                                    ?
                                                    "No hay registro"
                                                    :
                                                    formulario.email
                                                )
                                            }
                                        </div>
                                        <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Teléfono</span><br />
                                            {
                                            loaded 
                                            && 
                                            (
                                                formulario.phone === null
                                                ?
                                                "No hay registro"
                                                :
                                                formulario.phone
                                            )    
                                            }
                                        </div>
                                        <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Teléfono alternativo</span><br />{loaded && secondCel}
                                        </div>
                                    </>
                                    :
                                    <PreloaderComponentSectionProfile filas={3} filas_sm={3} filas_md={1} />
                            }
                        </SectionProfile>

                        {/* Emergency info */}
                        <SectionProfile title="Contacto de emergencia">
                            <div className="p-5 w-full flex flex-wrap">
                                {loaded ? (
                                    formulario.contact_info !== null
                                        ? (<>
                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                <span className="font-bold text-black">Nombre</span><br />{loaded && formulario.contact_info.name}
                                            </div>
                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                <span className="font-bold text-black">Apellido</span><br />{loaded && formulario.contact_info.lastname}
                                            </div>
                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                <span className="font-bold text-black">Número de contacto</span><br />{loaded && formulario.contact_info.phone}
                                            </div>
                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                <span className="font-bold text-black">Correo electrónico</span><br />{loaded && formulario.contact_info.email !== null ? loaded && formulario.contact_info.email : "No hay registro"}
                                            </div>
                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                <span className="font-bold text-black">Relación</span><br />{loaded && formulario.contact_info.relationship}
                                            </div>
                                        </>) : (
                                            <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                                <span className="font-bold text-black">No hay registros</span>
                                            </div>
                                        )) : (
                                    <PreloaderComponentSectionProfile filas={5} filas_sm={5} filas_md={2} />
                                )
                                }
                            </div>
                        </SectionProfile>

                        {/* Socioeconomic info */}
                        <SectionProfile
                            title="Situación económica y laboral actual"
                            button={<button
                                onClick={() => {
                                    showPopup(true, setShowModalSocioEconomic)
                                }}
                                className="btn-card-header">
                                Ver registros previos
                            </button>
                            }
                        >
                            {
                                loaded
                                    ?
                                    <>
                                        <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Último grado académico obtenido</span><br />{formulario.socioeconomic.study_level == 1 ? "Educación Básica" : formulario.socioeconomic.study_level == 2 ? "Bachillerato" : formulario.socioeconomic.study_level == 3 ? "Técnico" : formulario.socioeconomic.study_level == 4 ? "Universidad" : formulario.socioeconomic.study_level == 5 ? "Maestría" : formulario.socioeconomic.study_level == 6 ? "Doctorado" : "No especificado"}
                                        </div>
                                        <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">¿Estudia actualmente?</span><br />{loaded && formulario.socioeconomic.currently_studying == 1 ? loaded && "Si" : loaded && "No"}
                                        </div>
                                        <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Disponibilidad de tiempo</span>
                                            <br />
                                            {formulario.socioeconomic.time_availability == 1 ? "Completo" : formulario.socioeconomic.time_availability == 2 ? "Medio tiempo por la mañana" : formulario.socioeconomic.time_availability == 3 ? "Medio tiempo por la tarde" : formulario.socioeconomic.time_availability == 4 ? "Sábado y domingo" : formulario.socioeconomic.time_availability == 5 ? "Sin disponibilidad" : formulario.socioeconomic.time_availability == 6 ? "Lunes a viernes" : "No especificado"}
                                        </div>
                                        <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">¿Trabaja actualmente?</span><br />{loaded && formulario.socioeconomic.currently_working == 1 ? loaded && "Si" : loaded && "No"}
                                        </div>
                                        {
                                            formulario.socioeconomic.currently_working == 1
                                            &&
                                            (
                                                <>
                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                        <span className="font-bold text-black">Puesto</span><br />{formulario.socioeconomic.work_position != null ? formulario.socioeconomic.work_position : "No especificado"}
                                                    </div>
                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                        <span className="font-bold text-black">Área</span><br />{formulario.socioeconomic.work_area != null ? formulario.socioeconomic.work_area : "No especificado"}
                                                    </div>
                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                        <span className="font-bold text-black">Empleador</span><br />{formulario.socioeconomic.employer != null ? formulario.socioeconomic.employer : "No especificado"}
                                                    </div>
                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                        <span className="font-bold text-black">Salario</span><br />{formulario.socioeconomic.work_salary != null ? `$${formulario.socioeconomic.work_salary}` : "No especificado"}
                                                    </div>
                                                </>
                                            )
                                        }
                                        <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Ingreso familiar</span>
                                            <br />
                                            {
                                                loaded && formulario.socioeconomic.family_income == 1 ? loaded && "Menos de $300" : loaded && formulario.socioeconomic.family_income == 2 ? loaded && "$300 - $500" : loaded && formulario.socioeconomic.family_income == 3 ? loaded && "$500 - $1,000" : loaded && formulario.socioeconomic.family_income == 4 ? loaded && "$1,000 - $1,500" : loaded && formulario.socioeconomic.family_income == 5 ? "Mas de $1,500" : "No especificado"
                                            }
                                        </div>

                                        <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Integrantes del grupo familiar</span><br />{loaded && formulario.socioeconomic.family_members}
                                        </div>
                                        <div className="w-full md:w-1/2 p-2 text-sm text-gray-500 my-5 text-center">
                                            <span className="font-bold text-black">Comentarios u observaciones</span><br />
                                            <p>{loaded && formulario.socioeconomic.work_comment}</p>
                                        </div>
                                    </>
                                    :
                                    <PreloaderComponentSectionProfile filas={6} filas_sm={3} filas_md={2} />
                            }
                        </SectionProfile>

                        {/* Study */}
                        <SectionProfile
                            title="Estudios académicos"
                            // button={
                                // <button onClick={() => {
                                //     showPopup(true, setShowModalStudy)
                                // }} className="btn-card-header">Agregar Estudio</button>
                                // <button
                                //     className="btn-card-header"
                                //     onClick={() => {
                                //         getStudies();
                                //         getOtherStudies();
                                //     }}
                                // >
                                //     Consultar
                                // </button>
                            // }
                        >
                            {formulario.id && loaded
                                ?
                                <ListStudies
                                    type="study"
                                    DataTableExtensions={DataTableExtensions}
                                    DataTable={DataTable}
                                    setShowModalEditStudy={setShowModalEditStudy}
                                    setIdEditStudy={setIdEditStudy}
                                    dataStudies={dataStudies}
                                    dataOtherStudies={[]}
                                    loadedStudies={loadedStudies}
                                    getStudies={getStudies}
                                    getOtherStudies={getOtherStudies}
                                />
                                :
                                <PreloaderComponentSectionProfile filas={2} filas_sm={2} filas_md={2} />
                            }
                        </SectionProfile>

                        {/* Other studies */}
                        <SectionProfile
                            title="Otros estudios"
                            // button={
                                // <button onClick={() => {
                                //     showPopup(true, setShowModalStudy)
                                // }} className="btn-card-header">Agregar Estudio</button>
                                // <button
                                //     className="btn-card-header"
                                //     onClick={() => {
                                //         getStudies();
                                //         getOtherStudies();
                                //     }}
                                // >
                                //     Consultar
                                // </button>
                            // }
                        >
                            {formulario.id && loaded
                                ?
                                <ListStudies
                                    type="other"
                                    DataTableExtensions={DataTableExtensions}
                                    DataTable={DataTable}
                                    setShowModalEditStudy={setShowModalEditStudy}
                                    setIdEditStudy={setIdEditStudy}
                                    dataStudies={[]}
                                    dataOtherStudies={dataOtherStudies}
                                    loadedStudies={loadedStudies}
                                    getStudies={getStudies}
                                    getOtherStudies={getOtherStudies}
                                />
                                :
                                <PreloaderComponentSectionProfile filas={2} filas_sm={2} filas_md={2} />
                            }
                        </SectionProfile>

                        {/* Training programs */}
                        <SectionProfile
                            title="Formación"
                            button={<button onClick={() => {
                                showPopup(true, setShowModal)
                            }} className="btn-card-header">Agregar formación</button>}
                        >
                            <div className="bg-white w-full p-5 mb-3">
                                <DataTableExtensions
                                    {...tableData}
                                    filterPlaceholder={"Buscar"}
                                    print={false}
                                    export={false}
                                >
                                    {
                                        loaded
                                            ?
                                            <DataTable
                                                columns={columns}
                                                data={data.length > 0 && data}
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover
                                                paginationComponentOptions={paginationProps}
                                                responsive
                                                noDataComponent={<span className="font-bold text-black my-5">No hay registros</span>}
                                            />
                                            :
                                            <PreloaderComponentSectionProfile filas={2} filas_sm={2} filas_md={2} />

                                    }
                                </DataTableExtensions>
                            </div>
                        </SectionProfile>

                        {/* Languages */}
                        <SectionProfile title="Idiomas">
                            <div className="w-full p-2 text-sm text-gray-500 my-5 text-center">
                                {
                                    loaded
                                    ? 
                                    (
                                        shareData !== null
                                        ?
                                        (shareData.languages.length > 0) ? (
                                            <div className="flex justify-center pt-1">
                                                {shareData.languages.map((item, index) => {
                                                    return (
                                                        <div key={index} className="language-item">
                                                            {item.name}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        ) : (
                                            <span className="font-bold text-black my-5">No hay registros</span>
                                    ) : (
                                        <span className="font-bold text-black my-5">No hay registros</span>
                                    )
                                    ) : (
                                        <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                    )
                                }
                            </div>
                        </SectionProfile>

                        {/* Soft Skills */}
                        {/* <SectionProfile title="Habilidades blandas">
                            <div className="w-full p-2 text-sm text-gray-500 my-5 text-center"> */}
                                {/* <span className="font-bold text-black">Idiomas</span> */}
                                {/* <br /> */}
                                {/* {loaded
                                    ?
                                    (loaded && formulario.soft_skills.length > 0) && (
                                        <div className="flex flex-wrap justify-center pt-1">
                                            {formulario.soft_skills.map((item, index) => {
                                                return (
                                                    <div key={index} className="language-item mb-2">
                                                        {item.label}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                    )}
                            </div>
                        </SectionProfile> */}

                        {/* Knowledge */}
                        <SectionProfile title="Conocimientos" button={
                            <button
                                className="btn-card-header"
                                onClick={() => {
                                    getKnowledge();
                                    console.log(filterKnowledge, "filterKnowledge");
                                }}
                            >
                                Consultar
                            </button>
                        }>
                            <div className="bg-white w-full p-5 mb-3">
                                <div className="flex flex-wrap">
                                    {
                                        mostrarKnoledge ? (
                                            filterKnowledge.filter(data => data.list.length !== 0).map((item, index) => {
                                                return (
                                                    <div key={index} className="w-full md:w-1/2 pb-3 px-3">
                                                        <details className="group">
                                                            <summary className="accordion">
                                                                <span>{item.name}</span>
                                                                <span className="transition group-open:rotate-180">
                                                                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                                                        <path d="M6 9l6 6 6-6"></path>
                                                                    </svg>
                                                                </span>
                                                            </summary>
                                                            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                                                <div className="flex flex-wrap justify-center pt-1">
                                                                    {item.list.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className="language-item">
                                                                                {item.name}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </p>
                                                        </details>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="w-full text-center">
                                                <span className="font-bold text-black my-5">No hay registros</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </SectionProfile>

                        {/* Social */}
                        <SectionProfile
                            title="Perfil psicosocial"
                            button={<button
                                onClick={() => {
                                    showPopup(true, setShowModalPsychological)
                                }}
                                className="btn-card-header">Agregar documento</button>}
                        >
                            <div className="w-full p-5 mb-3">
                                <div className="flex flex-wrap items-center">
                                    {/* Validamos si el usuario tiene un documento cargado */}
                                    {
                                        loaded
                                            ?
                                            loaded && formulario.psychosocial.length > 0 ? (
                                                formulario.psychosocial.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className="w-4/5 flex items-center">
                                                                <span className="text-red-700 bg-red-700 file-icon">.{item.extension}</span>
                                                                <div className="file-details">
                                                                    <h5 className="file-title">{item.file_name}</h5>
                                                                    <p className="file-description">.{item.extension}, {item.mb} MB</p>
                                                                </div>
                                                            </div>
                                                            <div className="w-1/5">
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-1/2 text-center">
                                                                        <DownloadFileButton
                                                                            origin="psychosocial"
                                                                            id={item.id}
                                                                            filename={item.file_name}
                                                                        />
                                                                    </div>
                                                                    <div className="w-1/2 text-center">
                                                                        <DeleteFileButton
                                                                            origin="psychosocial"
                                                                            id={item.id}
                                                                            // data={ formulario }
                                                                            // setData={ setFormulario }
                                                                            data={getInfoEstudiante}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <div className="w-full text-center">
                                                    <span className="font-bold text-black my-5">No hay documentos cargados</span>
                                                </div>
                                            ) : (
                                                <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                            )
                                    }
                                </div>
                            </div>
                        </SectionProfile>

                        {/* Internships */}
                        <SectionProfile
                            title="Pasantías"
                            button={<button onClick={() => {
                                showPopup(true, setShowModalPasantias)
                            }} className="btn-card-header">Agregar pasantía</button>}
                        >
                            <div className="bg-white w-full p-5 mb-3">
                                {
                                    loaded
                                        ?
                                        <DataTableComponent columns={columnsPasantias} data={dataPasantiaStudent} />
                                        :
                                        <PreloaderComponentSectionProfile filas={2} filas_sm={2} filas_md={2} />
                                }
                            </div>
                        </SectionProfile>

                        {/* Work experience */}
                        <SectionProfile
                            title="Experiencia laboral"
                            button={
                                // <button onClick={() => {
                                //     showPopup(true, setShowModalWorkExperiences)
                                // }} className="btn-card-header">Agregar Experiencia</button>
                                <button
                                    className="btn-card-header"
                                    onClick={
                                        () => {
                                            getWorkExperiences();
                                        }
                                    }
                                >
                                    Consultar
                                </button>
                            }
                        >
                            {
                                loaded
                                    ?
                                    formulario.id &&
                                    <ListWorkExperiences
                                        DataTableExtensions={DataTableExtensions}
                                        DataTable={DataTable}
                                        setShowModalEditWorkExperience={setShowModalEditWorkExperience}
                                        showPopup={showPopup}
                                        setIdEditWorkExperience={setIdEditWorkExperience}
                                        dataWorkExperiences={dataWorkExperiences}
                                        loaded={loaded}
                                        getWorkExperiences={getWorkExperiences}
                                    />
                                    :
                                    <PreloaderComponentSectionProfile filas={2} filas_sm={2} filas_md={2} />
                            }
                        </SectionProfile>
                    </div>

                        {/* Modal */}
                        {showModal ? (
                            <>
                                <div
                                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                                        {/*content*/}
                                        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <h3 className="text-3xl font-semibold text-violet-700">
                                                    Agregar formación
                                                </h3>
                                                <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => {
                                                        showPopup(false, setShowModal);
                                                    }}
                                                >
                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6">
                                                <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                                    <form className="w-full flex flex-wrap" onSubmit={addProgramaStudent}>
                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Formación
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    options={allPrograms}
                                                                    getOptionLabel={(option) => option.label}
                                                                    getOptionValue={(option) => option.value}
                                                                    placeholder={"Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    onChange={(e) => {
                                                                        formularioAgregar.program = e.value;
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "program" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Motivo de aplicación
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                {/* Text */}
                                                                <input type="text" name="application_reason" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" placeholder="Motivo de aplicación"
                                                                    onChange={(event) => {
                                                                        formularioAgregar.application_reason = event.target.value;
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Estado del beneficiario en la formación
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="program_status" onChange={(event) => {
                                                                    setFormularioAgregar({ ...formularioAgregar, program_status: event.target.value });
                                                                    addComentario(event);
                                                                }}>
                                                                    <option value={null} disabled selected>Selecciona una opción</option>
                                                                    <option value="1">Aplicado</option>
                                                                    <option value="2">Pre seleccionado</option>
                                                                    <option value="3">Seleccionado</option>
                                                                    <option value="7">No iniciado</option>
                                                                    <option value="6">En proceso</option>
                                                                    <option value="4">No completado</option>
                                                                    <option value="5">Completado</option>
                                                                </select>
                                                                {
                                                                    alerta.filter(input => input.valorInput == "program_status" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        {
                                                            mostrarComentario
                                                            &&
                                                            <>
                                                                <div className="w-full p-3">

                                                                    <label className="block text-violet-700 font-bold">
                                                                        Detalles del retiro del beneficiario de la formación
                                                                    </label>

                                                                    <textarea name="comment" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Escriba un comentario..."
                                                                        onChange={(event) => {
                                                                            formularioAgregar.comment = event.target.value;
                                                                        }}
                                                                    ></textarea>
                                                                    {
                                                                        alerta.filter(input => input.valorInput == "comment" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                        </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="w-full p-3">
                                                                    <div className="mb-6">
                                                                        <label className="block text-violet-700 font-bold">
                                                                            Fecha de deserción
                                                                        </label>
                                                                        <input type="date" name="abandoned_at" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" value={formularioAgregar.abandoned_at} onChange={(event) => {
                                                                            formularioAgregar.abandoned_at = event.target.value;
                                                                        }} />
                                                                        {
                                                                            alerta.filter(input => input.valorInput == "abandoned_at" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                            </div>))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <DesertionLetter
                                                                    FontAwesomeIcon={FontAwesomeIcon}
                                                                    setLetterDesertionStudent={setLetterDesertionStudent}
                                                                    alertSpan={alertSpan}
                                                                    setalertSpan={setAlertSpan}
                                                                    formularioAgregar={formularioAgregar}
                                                                    data={desertionLetterEditar}
                                                                />
                                                            </>
                                                        }

                                                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                            <button
                                                                className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                                type="submit"

                                                            >
                                                                Agregar
                                                            </button>

                                                            <button
                                                                className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                                type="button"
                                                                onClick={() => {
                                                                    showPopup(false, setShowModal);
                                                                    setIsChecked(false);
                                                                    setMostrarComentario(false);
                                                                    setAlerta([initialStateInput]);
                                                                    setFormularioAgregar(initialDatosFormularioAgregar);
                                                                }}
                                                            >
                                                                Cerrar
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/*footer*/}

                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {showModalPasantias ? (
                            <>
                                <div
                                    className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                                        {/*content*/}
                                        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <h3 className="text-3xl font-semibold text-violet-700">
                                                    Agregar pasantía
                                                </h3>
                                                <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => {
                                                        showPopup(false, setShowModalPasantias);
                                                    }}
                                                >
                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6">
                                                <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                                    <form className="w-full flex flex-wrap" onSubmit={addPasantiaStudent}>

                                                        <div className="w-full p-3">
                                                            <div className="flex justify-center items-center w-full">
                                                                <div className="block text-violet-700 font-bold">Pasantía interna</div>
                                                                <label className="inline-flex relative items-center ml-3 cursor-pointer" htmlFor='internal_internship'>
                                                                    <input
                                                                        // onClick={(e) => { enableOrDisableProgram(e.target.id, row.status); }}
                                                                        id='internal_internship'
                                                                        type="checkbox"
                                                                        name='internal_internship'
                                                                        className="sr-only peer"
                                                                        checked={formularioPasantia.internal_internship}
                                                                        onChange={() => {
                                                                            console.log(formularioPasantia.internal_internship);
                                                                            setFormularioPasantia({ ...formularioPasantia, internal_internship: !formularioPasantia.internal_internship, organizer: "" });
                                                                        }}
                                                                        readOnly
                                                                    />
                                                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Empresa o lugar de pasantía
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    name='organizer'
                                                                    value={formularioPasantia.organizer}
                                                                    options={allEmpresas}
                                                                    getOptionLabel={(option) => option.label}
                                                                    getOptionValue={(option) => option.value}
                                                                    placeholder={formularioPasantia.internal_internship ? "CONEXION El Salvador" : "Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    isDisabled={formularioPasantia.internal_internship}
                                                                    onChange={(e) => {
                                                                        setFormularioPasantia({ ...formularioPasantia, organizer: e });
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "organizer" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Modalidad
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="modality" onChange={(event) => {
                                                                    formularioPasantia.modality = event.target.value;
                                                                }}>
                                                                    <option value="0" selected>Selecciona una opción</option>
                                                                    {
                                                                        modalidades.map((i, index) => (
                                                                            <option key={ index } value={i.id}>{i.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    alerta.filter(input => input.valorInput == "modality" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="w-full p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Especialidades
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    isMulti
                                                                    name='specialities'
                                                                    value={formularioPasantia.specialities}
                                                                    options={especialidades}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder={"Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    onChange={(option) => {
                                                                        setFormularioPasantia({ ...formularioPasantia, specialities: option });
                                                                        loadConocimientos(option);
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "specialities" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Conocimientos
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    isMulti
                                                                    name='knowledge'
                                                                    value={formularioPasantia.knowledge}
                                                                    options={conocimientos}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    placeholder={"Selecciona una o más especialidades"}
                                                                    noOptionsMessage={() => 'Selecciona una o más especialidades'}
                                                                    onChange={(option) => {
                                                                        setFormularioPasantia({ ...formularioPasantia, knowledge: option });
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "knowledge" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div> */}

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <div className="mb-6">
                                                                <label className="block text-violet-700 font-bold">
                                                                    Fecha de inicio
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    name="start_date"
                                                                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                                                    // value={ loaded && formularioPasantia.start_date } 
                                                                    onChange={(event) => {
                                                                        formularioPasantia.start_date = event.target.value;
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "start_date" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Estado del beneficiario en la pasantía
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="internship_status" onChange={(event) => {
                                                                    // addComentario(event);
                                                                    formularioPasantia.internship_status = event.target.value;
                                                                    if (event.target.value == "3") {
                                                                        setMostrarComentarioPasantia(true)
                                                                        setMostrarFechaPasantia(true)
                                                                    } else if (event.target.value == "4") {
                                                                        setMostrarComentarioPasantia(false)
                                                                        setMostrarFechaPasantia(true)
                                                                    } else {
                                                                        setMostrarComentarioPasantia(false)
                                                                        setMostrarFechaPasantia(false)
                                                                    }
                                                                }}>
                                                                    <option value="0" selected>Selecciona una opción</option>
                                                                    <option value="1">En espera</option>
                                                                    <option value="2">En proceso</option>
                                                                    <option value="3">Retirado</option>
                                                                    <option value="4">Concluida</option>
                                                                </select>
                                                                {
                                                                    alerta.filter(input => input.valorInput == "internship_status" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        {
                                                            mostrarFechaPasantia
                                                            &&
                                                            <>
                                                                <div className="w-full md:w-1/2 p-3">
                                                                    <div className="mb-6">
                                                                        <label className="block text-violet-700 font-bold">
                                                                            Fecha de finalización
                                                                        </label>
                                                                        <input type="date" name="end_date" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" value={formularioPasantia.end_date} onChange={(event) => {
                                                                            // formularioPasantia.end_date = event.target.value;
                                                                            setFormularioPasantia({ ...formularioPasantia, end_date: event.target.value });
                                                                        }} />
                                                                        {
                                                                            alerta.filter(input => input.valorInput == "end_date" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                            </div>))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {
                                                            mostrarComentarioPasantia
                                                            &&
                                                            <>
                                                                <div className="w-full p-3">

                                                                    <label className="block text-violet-700 font-bold">
                                                                        Detalles del retiro del beneficiario de la pasantía
                                                                    </label>

                                                                    <textarea name="comment" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Escriba un comentario..."
                                                                        onChange={(event) => {
                                                                            formularioPasantia.comment = event.target.value;
                                                                        }}
                                                                    ></textarea>
                                                                    {
                                                                        alerta.filter(input => input.valorInput == "comment" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                        </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </>
                                                        }

                                                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                            <button
                                                                className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                                type="submit"

                                                            >
                                                                Agregar
                                                            </button>

                                                            <button
                                                                className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                                type="button"
                                                                onClick={() => {
                                                                    showPopup(false, setShowModalPasantias);
                                                                    // setIsChecked(false);
                                                                    setMostrarComentarioPasantia(false);
                                                                    setMostrarFechaPasantia(false);
                                                                    setAlerta([initialStateInput]);
                                                                    setFormularioPasantia(initialDatosFormularioPasantia);
                                                                }}
                                                            >
                                                                Cerrar
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/*footer*/}

                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {showModalEditarPasantias ? (
                            <>
                                <div
                                    className="joverflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                                        {/*content*/}
                                        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <h3 className="text-3xl font-semibold text-violet-700">
                                                    Editar pasantía
                                                </h3>
                                                <button
                                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                    onClick={() => {
                                                        showPopup(false, setShowModalEditarPasantias);
                                                    }}
                                                >
                                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                            {/*body*/}
                                            <div className="relative px-6">
                                                <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                                    <form className="w-full flex flex-wrap" onSubmit={editarPasantiaStudent}>
                                                        <div className="w-full p-3">
                                                            {/* Ultima actualizacion del registro */}
                                                            <span className="text-base text-gray-500">
                                                                Ultima actualización: <strong className="text-purple-cnx">{formularioEditarPasantia.register}</strong>
                                                            </span>
                                                        </div>
                                                        <div className="w-full p-3">
                                                            <div className="flex justify-center items-center w-full">
                                                                <div className="block text-violet-700 font-bold">Pasantía interna</div>
                                                                <label className="inline-flex relative items-center ml-3 cursor-pointer" htmlFor="update_internal_internship">
                                                                    <input
                                                                        // onClick={(e) => { enableOrDisableProgram(e.target.id, row.status); }}
                                                                        id="update_internal_internship"
                                                                        type="checkbox"
                                                                        name="update_internal_internship"
                                                                        className="sr-only peer"
                                                                        checked={formularioEditarPasantia.internal_internship}
                                                                        onChange={() => {
                                                                            console.log(formularioEditarPasantia.internal_internship);
                                                                            setFormularioEditarPasantia({ ...formularioEditarPasantia, internal_internship: !formularioEditarPasantia.internal_internship, organizer: "" });
                                                                        }}
                                                                        readOnly
                                                                    />
                                                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Empresa o lugar de pasantía
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    name='organizer'
                                                                    value={formularioEditarPasantia.organizer}
                                                                    options={allEmpresas}
                                                                    placeholder={formularioEditarPasantia.internal_internship ? "CONEXION El Salvador" : "Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    isDisabled={formularioEditarPasantia.internal_internship}
                                                                    onChange={(e) => {
                                                                        setFormularioEditarPasantia({ ...formularioEditarPasantia, organizer: e });
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "organizer" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Modalidad
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <select value={formularioEditarPasantia.modality} className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="modality" onChange={(event) => {
                                                                    setFormularioEditarPasantia({ ...formularioEditarPasantia, modality: event.target.value });
                                                                }}>
                                                                    <option value="0">Selecciona una opción</option>
                                                                    {
                                                                        modalidades.map((i, index) => (
                                                                            <option key={ index } value={i.id}>{i.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    alerta.filter(input => input.valorInput == "modality" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="w-full p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Especialidades
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    isMulti
                                                                    name='specialities'
                                                                    value={formularioEditarPasantia.specialities}
                                                                    options={especialidadesEditar}
                                                                    placeholder={"Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    onChange={(option) => {
                                                                        setFormularioEditarPasantia({ ...formularioEditarPasantia, specialities: option });
                                                                        loadConocimientosEditar(option, formularioEditarPasantia.knowledge);
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "specialities" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="w-full p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Conocimientos
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    isMulti
                                                                    name='knowledge'
                                                                    value={knowledgeValidados}
                                                                    options={conocimientosEditar}
                                                                    getOptionLabel={(option) => option.label}
                                                                    getOptionValue={(option) => option.value}
                                                                    placeholder={"Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    onChange={(option) => {
                                                                        setKnowledgeValidados(option);
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "knowledge" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div> */}

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <div className="mb-6">
                                                                <label className="block text-violet-700 font-bold">
                                                                    Fecha de inicio
                                                                </label>
                                                                <input type="date" name="start_date" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" value={formularioEditarPasantia.start_date} onChange={(event) => {
                                                                    setFormularioEditarPasantia({ ...formularioEditarPasantia, start_date: event.target.value });
                                                                }} />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "start_date" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Estado del beneficiario en la pasantía
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <select value={formularioEditarPasantia.status} className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="internship_status" onChange={(event) => {
                                                                    // addComentario(event);
                                                                    setFormularioEditarPasantia({ ...formularioEditarPasantia, status: event.target.value });
                                                                    if (event.target.value == "3") {
                                                                        setMostrarComentarioEditarPasantia(true)
                                                                        setMostrarFechaPasantiaEditar(true)
                                                                    } else if (event.target.value == "4") {
                                                                        setMostrarComentarioEditarPasantia(false)
                                                                        setMostrarFechaPasantiaEditar(true)
                                                                    } else {
                                                                        setMostrarComentarioEditarPasantia(false)
                                                                        setMostrarFechaPasantiaEditar(false)
                                                                    }

                                                                    if (event.target.value == "5") {
                                                                        setShowPauseInternship(true);
                                                                    } else {
                                                                        setShowPauseInternship(false);
                                                                    }
                                                                }}>
                                                                    <option value="0" selected>Selecciona una opción</option>
                                                                    <option value="1">En espera</option>
                                                                    <option value="2">En proceso</option>
                                                                    <option value="5">En pausa</option>
                                                                    <option value="3">Retirado</option>
                                                                    <option value="4">Concluida</option>
                                                                </select>
                                                                {
                                                                    alerta.filter(input => input.valorInput == "internship_status" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        {
                                                            mostrarFechaPasantiaEditar
                                                            &&
                                                            <>
                                                                <div className="w-full md:w-1/2 p-3">
                                                                    <div className="mb-6">
                                                                        <label className="block text-violet-700 font-bold">
                                                                            Fecha de finalización
                                                                        </label>
                                                                        <input type="date" name="end_date" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" value={formularioEditarPasantia.end_date} onChange={(event) => {
                                                                            setFormularioEditarPasantia({ ...formularioEditarPasantia, end_date: event.target.value });
                                                                        }} />
                                                                        {
                                                                            alerta.filter(input => input.valorInput == "end_date" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                            </div>))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {
                                                            mostrarComentarioEditarPasantia
                                                            &&
                                                            <>
                                                                <div className="w-full p-3">

                                                                    <label className="block text-violet-700 font-bold">
                                                                        Detalles del retiro del beneficiario de la pasantía
                                                                    </label>

                                                                    <textarea value={formularioEditarPasantia.comment} name="comment" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Escriba un comentario..."
                                                                        onChange={(event) => {
                                                                            setFormularioEditarPasantia({ ...formularioEditarPasantia, comment: event.target.value });
                                                                        }}
                                                                    ></textarea>
                                                                    {
                                                                        alerta.filter(input => input.valorInput == "comment" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                        </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </>
                                                        }

                                                        {showPauseInternship && (
                                                            <>
                                                                <div className="w-full px-3 pb-3">
                                                                    <span className="text-base text-justify block" >
                                                                        <strong>NOTA:</strong> Esta información es acumulativa, la cual puede ser consultada en el apartado de <strong>Seguimiento</strong>. Cuando la pasantía se reanude, concluya o se retire definitivamente; la fecha de reanudación se actualizará automáticamente y se verá reflejada en el apartado de <strong>Seguimiento</strong>.
                                                                    </span>
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-3">
                                                                    <div className="mb-6">
                                                                        <label className="block text-violet-700 font-bold">
                                                                            Fecha de pausa
                                                                        </label>
                                                                        <input type="date" name="break_date" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" value={formularioEditarPasantia.break_date} onChange={(event) => {
                                                                            setFormularioEditarPasantia({ ...formularioEditarPasantia, break_date: event.target.value });
                                                                        }} />
                                                                        {
                                                                            alerta.filter(input => input.valorInput == "break_date" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                            </div>))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="w-full p-3">
                                                                    <label className="block text-violet-700 font-bold">
                                                                        Detalles de la pausa de la pasantía
                                                                    </label>
                                                                    <textarea value={formularioEditarPasantia.follow_comment} name="follow_comment" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Escriba un comentario..."
                                                                        onChange={(event) => {
                                                                            setFormularioEditarPasantia({ ...formularioEditarPasantia, follow_comment: event.target.value });
                                                                        }}
                                                                    ></textarea>
                                                                    {
                                                                        alerta.filter(input => input.valorInput == "follow_comment" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                        </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                            <button
                                                                className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                                type="submit"

                                                            >
                                                                Editar
                                                            </button>

                                                            <button
                                                                className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                                type="button"
                                                                onClick={() => {
                                                                    showPopup(false, setShowModalEditarPasantias);
                                                                    // setIsChecked(false);
                                                                    setMostrarComentarioEditarPasantia(false);
                                                                    setMostrarFechaPasantiaEditar(false);
                                                                    // setAlerta([initialStateInput]);
                                                                    setFormularioEditarPasantia(initialDatosFormularioPasantia);
                                                                    setFormularioPasantia(initialDatosFormularioPasantia);
                                                                }}
                                                            >
                                                                Cerrar
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/*footer*/}

                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {/* Modal add Work experiences */}
                        {showModalWorkExperiences &&
                            <ModalAddWorkExperience
                                setShowModalWorkExperiences={setShowModalWorkExperiences}
                                showPopup={showPopup}
                                idStudent={formulario.id}
                                Select={Select}
                                getWorkExperiences={getWorkExperiences}
                            />
                        }
                        {/* Modal edit Work experiences */}
                        {showModalEditWorkExperience &&
                            <ModalEditWorkExperience
                                setShowModalEditWorkExperience={setShowModalEditWorkExperience}
                                showPopup={showPopup}
                                useEffect={useEffect}
                                idEditWorkExperience={idEditWorkExperience}
                                dataWorkExperiences={dataWorkExperiences}
                                Select={Select}
                                getWorkExperiences={getWorkExperiences}
                            />
                        }
                        {/* Modal add study */}
                        {showModalStudy &&
                            <ModalAddStudy
                                setShowModalStudy={setShowModalStudy}
                                showPopup={showPopup}
                                idStudent={formulario.id}
                                Select={Select}
                                getStudies={getStudies}
                            />
                        }
                        {/* Modal edit study */}
                        {showModalEditStudy &&
                            <ModalEditStudy
                                showModalEditStudy={showModalEditStudy}
                                setShowModalEditStudy={setShowModalEditStudy}
                                showPopup={showPopup}
                                useEffect={useEffect}
                                idEditStudy={idEditStudy}
                                dataStudies={dataStudies}
                                Select={Select}
                                getStudies={getStudies}
                            />
                        }

                        {showModalEditar ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-full my-6 mx-auto max-w-3xl"
                                        style={{height: window.innerHeight - 100}}
                                    >
                                        {/*content*/}
                                        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <h3 className="text-3xl font-semibold text-violet-700">
                                                    Editar formación
                                                </h3>
                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6 flex-auto">
                                                <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                                    <form className="w-full flex flex-wrap" onSubmit={editarProgramaStudent}>

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Formación
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <Select
                                                                    options={allProgramasEdit}
                                                                    placeholder={"Selecciona una opción"}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    value={formularioEditar.program}
                                                                    onChange={(e) => {
                                                                        setFormularioEditar({
                                                                            ...formularioEditar,
                                                                            program: e
                                                                        });
                                                                    }}
                                                                />
                                                                {
                                                                    alerta.filter(input => input.valorInput == "program" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>

                                                                    ))
                                                                }
                                                            </div>
                                                        </div>

                                                        {/* Motivo de aplicacion */}
                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Motivo de aplicación
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <input type="text" name="application_reason" className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300" placeholder="Motivo de aplicación"
                                                                    onChange={ManejarEventoDeInputsEditar}
                                                                    value={formularioEditar.application_reason}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="w-full md:w-1/2 p-3">
                                                            <label className="block text-violet-700 font-bold">
                                                                Estado del beneficiario en la formación
                                                            </label>
                                                            <div className="mb-2 xl:w-100">
                                                                <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="program_status" onChange={
                                                                    ManejarEventoDeInputsEditar} value={formularioEditar.program_status}>
                                                                    <option value={null} disabled selected>Selecciona una opción</option>
                                                                    <option value="1">Aplicado</option>
                                                                    <option value="2">Pre seleccionado</option>
                                                                    <option value="3">Seleccionado</option>
                                                                    <option value="7">No iniciado</option>
                                                                    <option value="6">En proceso</option>
                                                                    <option value="4">No completado</option>
                                                                    <option value="5">Completado</option>
                                                                </select>
                                                                {
                                                                    alerta.filter(input => input.valorInput == "program_status" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                    </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            mostrarComentarioEditar
                                                            &&
                                                            <>
                                                                <div className="w-full p-3">

                                                                    <label className="block text-violet-700 font-bold">
                                                                        Detalles del retiro del beneficiario del programa
                                                                    </label>

                                                                    <textarea name="comment" onChange={ManejarEventoDeInputsEditar} value={formularioEditar.comment} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Escriba un comentario..."></textarea>
                                                                    {
                                                                        alerta.filter(input => input.valorInput == "comment" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                        </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="w-full p-3">
                                                                    <div className="mb-6">
                                                                        <label className="block text-violet-700 font-bold">
                                                                            Fecha de deserción
                                                                        </label>
                                                                        <input type="date" name="abandoned_at" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" value={formularioEditar.abandoned_at} onChange={ManejarEventoDeInputsEditar} />
                                                                        {
                                                                            alerta.filter(input => input.valorInput == "abandoned_at" && input.estado === true).map((message, key) => (<div key={key} className=""> <span className="text-red-500">{message.mensaje}</span>
                                                                            </div>))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <DesertionLetter
                                                                    FontAwesomeIcon={FontAwesomeIcon}
                                                                    setLetterDesertionStudent={setLetterDesertionStudent}
                                                                    alertSpan={alertSpan}
                                                                    setalertSpan={setAlertSpan}
                                                                    data={desertionLetterEditar}
                                                                />
                                                            </>
                                                        }
                                                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                                            <button type="submit" className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                                                                Editar
                                                            </button>
                                                            <button
                                                                className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                                type="button"
                                                                onClick={() => {
                                                                    showPopup(false, setShowModalEditar);
                                                                    setIsChecked(false);
                                                                    setMostrarComentario(false);
                                                                    setAlerta([initialStateInput]);
                                                                    setFormularioEditar(initialDatosFormularioEditar);
                                                                    setDesertionLetterEditar(null);
                                                                }}
                                                            >
                                                                Cerrar
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/*footer*/}

                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        {showModalSocioEconomic && <ModalSocioEconomic
                            data={loaded && formulario.socioeconomic_history}
                            lastUpdate={loaded && formulario.socioeconomic.register}
                            setModal={setShowModalSocioEconomic}
                            showModal={showModalSocioEconomic}
                            showPopup={showPopup}
                        />}

                        
                    </>
            }

            {showModalInternShipsHistory && <ModalInternShipsHistory
                data={loaded && formularioEditarPasantia}
                setModal={setShowModalInternShipsHistory}
                showModal={showModalInternShipsHistory}
                showPopup={showPopup}
            />}

            {showModalPsychological && <ModalPsychologicalProfile
                id={id}
                setModal={setShowModalPsychological}
                showModal={showModalPsychological}
                data={getInfoEstudiante}
                showPopup={showPopup}
            />}
            {showModalViewDocument &&
                <ModalViewDocument
                    showPopup={showPopup}
                    setShowModalViewDocument={setShowModalViewDocument}
                    nameDocumentFront={formulario.document_front}
                    nameDocumentBack={formulario.document_back}
                    downloadFileRequest={downloadFileRequest}
                    MySwal={MySwal}
                />
            }

            { showModalCommitmentLetter && <ModalCommitmentLetter
                setModal={ setShowModalCommitmentLetter }
                showModal={ showModalCommitmentLetter }
                student={ id }
                info={ data.length > 0 ? data : [] }
                loadInfo={ getProgramsStudent }
            />}
        </>
    );
};

export default VerEstudiante;
