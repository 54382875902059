import {useState, useEffect} from 'react';
import { validateData } from "../../utils/validateData";
import { postOrPutRequest, getOrDeleteRequest } from "../../utils/util";
import Swal from "sweetalert2";

export const ModalEditFormatDocumentLogic = (param) =>{
    const initDataDocument = {
        name: '',
        description: '',
        file: ''
    }
    const [alert, setAlert] = useState([]);
    const [dataDocument, setDataDocument] = useState(initDataDocument);
    const [fileNameDocument, setFileNameDocument] = useState('');
    const [fileNameDocumentUp, setFileNameDocumentUp] = useState('');
    const [idDocument, setIdDocument] = useState('');

    useEffect(() => { getFormatDocument() }, []);

    const getFormatDocument = async() =>{
        const response = await getOrDeleteRequest(
            `document-format/find/${param.id}`
        );
        if(response.type === 'error'){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }
        const { name, description } = response;
        setDataDocument({...dataDocument, name, description});
        setFileNameDocument(response.file);
        setFileNameDocumentUp(response.file);
        setIdDocument(response.id);
        console.log(response);
    }
    

    const handelChange = ({target: {name, value}}) =>{
        setDataDocument({...dataDocument, [name]: value});
    }

    const handelFileDocument = ({target: {name, files}}) =>{
        if (files.length > 0) {
            setFileNameDocument(files[0].name);
            setDataDocument({...dataDocument, [name]: files[0]});
            return;
        }
        setFileNameDocument('');
        setDataDocument({...dataDocument, [name]: ''});
    }

    const handelSubmit = (e) =>{
        console.log(dataDocument);
        e.preventDefault();
        fileNameDocumentUp !== '' && dataDocument.file === '' && delete dataDocument.file; 
        let inputs = Object.entries(dataDocument).map(([key, value]) => {
            return { name: key, value: value};
        });
        console.log(inputs);
        console.log(fileNameDocumentUp !== '' && dataDocument.file === '');
        const processData = validateData(inputs);
        setAlert(processData);
        let filterData = processData.filter(input => input.status !== false).map(() => { return false });
        console.log(processData);
        filterData.length === 0 && sendData();
    } 

    const sendData = async() =>{
        console.log(dataDocument);
        const response = await postOrPutRequest(
            `document-format/update/${idDocument}?_method=PUT`,
            'POST',
            dataDocument
        );
        console.log(response);
        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3000
            });
            return;
        } 
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        param.setShowModalEditFormatDocument(false);
        param.getFormatFiles();
    }

    const objC = {
        alert: alert,
        dataDocument: dataDocument,
        fileNameDocument: fileNameDocument
    }

    const objF = {
        handelChange: handelChange,
        handelFileDocument: handelFileDocument,
        handelSubmit: handelSubmit
    }
    return { objC, objF }
}