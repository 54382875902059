import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import PreloaderTables from "../PreloaderTables/PreloaderTables";
import { SoftSkillsLogic } from './SoftSkillsLogic';
import ModalAddSoftSkill from './modals/ModalAddSoftSkill';

const SoftSkills = () => {
    const params = {
        Link: Link
    }

    const { objF, objC } = SoftSkillsLogic(params);

    return (
        <div className='ContainerOpenClass'>
            <div className=" p-4 bg-blue-950 flex items-center justify-between border-0">
                <div className="mb-1 w-full">
                    <div className="sm:flex">
                        <h1 className="text-xl sm:text-2xl font-semibold text-white">Habilidades Blandas</h1>
                        <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                            <button onClick={() => { objF.setShowModalAddSoftSkill(true); objF.setTypeModal('add') }} data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto">
                                <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                Agregar Habilidad
                            </button>
                        </div>
                    </div>
                    <div className=" bg-blue-950 my-4">
                        {objC.loaded
                            ? <DataTable
                                columns={ objC.columns }
                                className="relative"
                                data={ objC.dataSearch }
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={ false }
                                pagination
                                highlightOnHover
                                paginationComponentOptions={ objF.paginationProps }
                                responsive
                                noDataComponent={<div className="my-5">No hay registros</div>}
                            />
                            : <PreloaderTables  />}
                    </div>
                </div>
            </div>
            { objC.showModalAddSoftSkill && 
                <ModalAddSoftSkill 
                    showPopup={ objF.showPopup } 
                    setShowModalAddSoftSkill={ objF.setShowModalAddSoftSkill } 
                    typeModal={ objC.typeModal }
                    idEdit = { objC.idEdit }
                    reloadData = { objF.reloadData }
                /> 
            }
        </div>
    )
}
export default SoftSkills;
