import Preloader from "../../Preloader/Preloader"

const PreloaderComponentSectionProfile = (props) => {

    // console.log(props, "props")

    let filas = [];
    let filas_sm = [];
    let filas_md = [];

    if(props.filas !== undefined && props.filas !== null && props.filas > 0){
        for(let i = 0; i < props.filas; i++){
            filas.push(props.filas[i])
        }
        // console.log(filas, "filas")
    }

    if(props.filas_sm !== undefined && props.filas_sm !== null && props.filas_sm > 0){
        for(let i = 0; i < props.filas_sm; i++){
            filas_sm.push(props.filas_sm[i])
        }
        // console.log(filas_sm, "filas_sm")
    }

    if(props.filas_md !== undefined && props.filas_md !== null && props.filas_md > 0){
        for(let i = 0; i < props.filas_md; i++){
            filas_md.push(props.filas_md[i])
        }
        // console.log(filas_md, "filas_md")
    }

    return (
        <div className="w-full flex flex-wrap">
            {
                props.filas !== undefined && props.filas !== null && props.filas > 0 
                &&
                <div className="w-full flex flex-wrap relative sm:invisible">
                    {
                        filas.map((fila) => {
                            return (
                                <div className="w-full sm:hidden p-2 my-5 text-sm invisible">
                                    <span className="block font-bold text-black">Nombres:</span>
                                    {/* <br /> */}
                                    <span className="block">fila</span>
                                </div>
                            )
                        })
                    }
                    <Preloader />
                </div>
            }
            {
                props.filas_sm !== undefined && props.filas_sm !== null && props.filas_sm > 0 
                &&
                <div className="w-full flex flex-wrap relative invisible sm:visible md:invisible">
                    {
                        filas_sm.map((fila) => {
                            return (
                                <div className="hidden sm:block w-full md:hidden p-2 my-5 text-sm invisible">
                                    <span className="block font-bold text-black">Nombres:</span>
                                    {/* <br /> */}
                                    <span className="block">fila</span>
                                </div>
                            )
                        })
                    }
                    <Preloader />
                </div>
            }
            {
                props.filas_md !== undefined && props.filas_md !== null && props.filas_md > 0 
                &&
                <div className="w-full flex flex-wrap relative invisible sm:invisible md:visible">
                    {
                        filas_md.map((fila) => {
                            return (
                                <div className="hidden sm:hidden w-full md:block p-2 my-5 text-sm invisible">
                                    <span className="block font-bold text-black">Nombres:</span>
                                    {/* <br /> */}
                                    <span className="block">fila</span>
                                </div>
                            )
                        })
                    }
                    <Preloader />
                </div>
            }
        </div>
    )
}

export default PreloaderComponentSectionProfile;