import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Icono para cargar el archivo
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

import { postOrPutRequest } from "../utils/util";

const ModalPsychologicalProfile = (props) => {
    const [key, setKey] = useState(0);
    const [uploadedFile, setUploadedFile] = useState(null);

    const inputFile = useRef(null);

    const MySwal = withReactContent(Swal);

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        
        if (!file) {
            inputFile.current.value = null;
            setKey((prevKey) => prevKey + 1);

            return;
        }

        let title = file.name;

        if (title.length > 30) {
            title = title.substring(0, 30) + "...";
        }

        document.getElementById("file-drag-title").innerHTML = title;
        setUploadedFile(file);
    };

    const handleCloudClick = () => {
        inputFile.current.click();
    };

    const uploadFile = async (e) => {
        e.preventDefault();

        if (!uploadedFile) {
            return;
        }

        let formData = new FormData();
        formData.append("file", uploadedFile);

        console.log(formData);

        let response = await postOrPutRequest(
            `student/add-psychosocial/${props.id}`,
            'POST',
            formData
        );

        if (response.type === "error") {
            let errorMessage = response.message ? response.message : "Error al subir el archivo";

            MySwal.fire(
                errorMessage,
                `El archivo no pudo ser subido correctamente.`,
                "error"
            );

            return;
        } 
        
        let successMessage = response.message ? response.message : "Archivo subido correctamente";

        MySwal.fire(
            successMessage,
            `Archivo subido correctamente`,
            "success"
        );
        
        props.data();
        inputFile.current.value = null;
        props.showPopup(false, props.setModal);
    };

    return (
        <>
            <div
                className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-900">
                                Agregar perfil psicosocial
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => {
                                    props.showPopup(false, props.setModal);
                            }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full flex flex-wrap" onSubmit={ uploadFile } >
                                    <div className="w-full flex flex-wrap py-4 px-6">
                                        <div className="w-full">
                                            <label className="block text-blue-950 font-bold mb-4">
                                                Perfil psicosocial del beneficiario
                                            </label>
                                        </div>
                                        <div className="w-full">
                                            <input
                                                type="file"
                                                accept=".pdf,.doc,.docx"
                                                className="hidden"
                                                ref={ inputFile }
                                                onChange={ handleFileChange }
                                            />
                                            <label
                                                htmlFor="file-upload"
                                                id="file-drag-psychological-profile"
                                                onClick={ handleCloudClick }
                                                className="cursor-pointer transition-colors flex w-full flex-col items-center rounded-xl border-2 border-dashed border-gray-400 bg-gray-100 p-6 text-center"
                                            >
                                                <FontAwesomeIcon 
                                                    icon={ faCloudArrowUp } 
                                                    className="file-drag-icon h-12 w-12 text-gray-700"
                                                />
                                                <h2 id="file-drag-title" className="file-drag-title mt-4 text-xl font-medium text-gray-700 tracking-wide">Subir archivo</h2>
                                                <p className="mt-2 text-gray-500 tracking-wide">Sube o arrastra tu archivo PDF, DOC, DOCX. </p>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                            type="submit"

                                        >
                                            Subir
                                        </button>
                                        <button
                                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center"
                                            type="button"
                                            onClick={() => {
                                                props.showPopup(false, props.setModal);
                                                inputFile.current.value = null;
                                                // setIsChecked(false);
                                                // setMostrarComentario(false);
                                                // setAlerta([initialStateInput]);
                                                // setFormularioEditar(initialDatosFormularioEditar);
                                            }}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/*footer*/}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default ModalPsychologicalProfile;