import { InputSearch, SelectHTML, SelectRState, SelectRCity, SelectRDistrict } from '../Components/InputsSystem';
import { FiltersCompanyStudentsLogic } from './FiltersCompanyStudentsLogic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons'
const FiltertsCompanyStudents = (props) => {
    const params = {
        setDataStudents: props.setDataStudents,
        dataStudents: props.dataStudents,
        setDataSearch: props.setDataSearch,
        dataSearch: props.dataSearch,
        id: props.id,
        setLoaded: props.setLoaded
    }
    const { objC, objF } = FiltersCompanyStudentsLogic(params);
    return (
        <div className="w-full flex flex-wrap px-1">
            <div className="w-full">
                <div className='flex justify-center md:justify-end items-center px-5 text-red-400'>
                    <FontAwesomeIcon icon={faTrash} /><h5 className='px-1 cursor-pointer hover:underline' onClick={objF.resetFilter}>Quitar filtros</h5>
                </div>
            </div>
            <div className="w-full md:w-1/3">
                <InputSearch dataFilter={objC.dataFilter} changeData={objF.changeData} loaded={props.loaded} />
            </div>
            <div className="w-full md:w-1/3 px-1">
                <SelectHTML
                    name={objC.dataFilter.internship}
                    changeData={objF.changeData}
                    loaded={props.loaded}
                    selectOptions={objC.optionsInternship}
                    label={'Pasantías'}
                    title={'internship'}
                />
            </div>
            <div className="w-full md:w-1/3 px-1">
                <SelectHTML
                    name={objC.dataFilter.gender}
                    changeData={objF.changeData}
                    loaded={props.loaded}
                    selectOptions={objC.optionsGender}
                    label={'Género'}
                    title={'gender'}
                />
            </div>
            <div className="w-full md:w-1/3 px-1 py-3">
                <SelectRState dataFilter={objC.dataFilter} loaded={props.loaded} changeDataRSelect={objF.changeDataRSelect} />
            </div>
            <div className="w-full md:w-1/3 px-1 py-3">
                <SelectRCity dataFilter={objC.dataFilter} loaded={props.loaded} changeDataRSelect={objF.changeDataRSelect} />
            </div>
            <div className="w-full md:w-1/3 px-1 py-3">
                <SelectRDistrict dataFilter={objC.dataFilter} loaded={props.loaded} changeDataRSelect={objF.changeDataRSelect} />
            </div>
        </div>
    )
}
export default FiltertsCompanyStudents;
