import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { faCheckCircle, faEye, faPowerOff, faStop, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FaBeer } from "react-icons/fa";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { paginationProps } from "../utils/util";
import SearchInput from "../Components/SearchInput";
import PreloaderTables from "../PreloaderTables/PreloaderTables";

const TipoCooperante = () => {

    const initDataFilter = {
        search: ''
    }

    const initialDatosFormulario = {
        name: "",
        type: ''

    };

    const initialIdioma = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    };

    const initialIdiomaEditar = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: '',
        type_id: ""
    };

    const initialSpeciality = {
        id: "",
        name: ""
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [showModal, setShowModal] = React.useState(false);
    const [showModalEditar, setShowModalEditar] = React.useState(false);
    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = React.useState(false);
    const [speciality, setSpeciality] = useState([initialSpeciality]);
    const [formulario, setFormulario] = useState([initialDatosFormulario]);
    const [formularioEditar, setFormularioEditar] = useState([initialDatosFormulario]);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [idEditar, setIdEditar] = useState();
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [dataSearch, setDataSearch] = useState([]);
    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');


    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {
        console.log(formulario, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const ManejarEventoDeInputsEditar = (event) => {
        console.log(formularioEditar, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormularioEditar({ ...formulario, [name]: value });
    };

    const addType = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "name", value: formulario.name }
        ];

        console.log("Verificar", verificarInputs)

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        // console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length == 1) {
            console.log("Enviar al servidor");
            RegistrarType();
        };

    };

    const editarType = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        formularioEditar.name = e.currentTarget.name.value;

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "name", value: formularioEditar.name }
        ];

        console.log("Verificar", verificarInputs)

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        // console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length == 1) {
            console.log("Enviar al servidor");
            ActualizarType();
        };

    };

    const [isChecked, setIsChecked] = useState(false); // Estado para rastrear si el checkbox está marcado o no

    const handleCheckboxChange = () => {
        setIsChecked(prevChecked => !prevChecked); // Cambia el estado del checkbox al contrario del estado actual

    };
    console.log(isChecked, ':))')

    function RegistrarType() {

        const url = `${process.env.REACT_APP_API_URI}contributor-type/register`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        const dataInsertar = {
            name: formulario.name,
            required_contact: isChecked
        };

        console.log(dataInsertar, "Datainsertar")

        axios.post(url, dataInsertar, config)
            .then(response => {
                console.log(response, "response");

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    setShowModal(false)
                    formulario.name = ""
                    getInfo();
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    function ActualizarType() {

        const idType = idEditar;

        const url = `${process.env.REACT_APP_API_URI}contributor-type/update/${idType}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        const dataInsertar = {
            name: formularioEditar.name,
            required_contact: isChecked
        };

        console.log(dataInsertar, "Datainsertar")

        axios.put(url, dataInsertar, config)
            .then(response => {
                // console.log(response,"Response");

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    setShowModalEditar(false)
                    getInfo()
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        console.log("Data", datosDelFormulario)

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'name': {
                    if (valorInput.value == '' || valorInput.value === null || valorInput.value == undefined) {
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });
                    } else {
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })
                    }
                    break;
                }
                default: {
                    break;
                }
            }

        })

        return errors;
    }

    // console.log(alerta)

    function desactivarType(event) {
        const idType = event.currentTarget.id;
        console.log(idType)
        Swal.fire({
            title: '¿Seguro que quieres desactivar este tipo de cooperante?',
            showCancelButton: true,
            confirmButtonText: 'Desactivar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmDesactivarType() {
                    const url = `${process.env.REACT_APP_API_URI}contributor-type/disable/${idType}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.delete(url, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Conocimientos";
                            setData([initialIdioma])
                            getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "¡Error! Vuelva a intentar",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
                confirmDesactivarType();
            }

        })
    };

    function ReactivarType(event) {
        const idIdioma = event.currentTarget.id;
        console.log(Cookies.get('Bearer').token)
        Swal.fire({
            title: '¿Seguro que quieres activar este tipo de programa?',
            showCancelButton: true,
            confirmButtonText: 'Activar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmReactivarType() {
                    const url = `${process.env.REACT_APP_API_URI}contributor-type/enable/${idIdioma}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.put(url, {}, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Conocimientos";
                            setData([initialIdioma])
                            getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "Error",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
                confirmReactivarType();
            }

        })
    };


    const [datosServidor, setDatosservidor] = useState([]);
    // console.log("Listar datos: --- ", datosServidor);

    async function getInfo() {
        setLoaded(false)
        setData([initialIdioma])
        const url = `${process.env.REACT_APP_API_URI}contributor-type/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            setData(resp.data);
            setDataSearch(resp.data);
            console.log(resp.data, "here");
            setLoaded(true);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    const [data, setData] = useState([initialIdioma]);

    const [dataEditar, setDataEditar] = useState([initialIdiomaEditar]);

    const columns = [
        {
            name: '#',
            width: "10%",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre',
            width: "35%",
            cell: (row) => <div>{loaded && row.name}</div>
        },
        {
            name: 'Estado',
            width: "20%",
            cell: (row) => <div>{loaded && row.status == 1 ? <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</div> : row.status == 2 && <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-400 mr-2"></div> Inactivo</div>}</div>
        },
        {
            name: "Activar/Desactivar",
            width: "15%",
            // cell: (row) => <div>{loaded && row.status == 1 ? <button onClick={desactivarConocimiento} id={row.id}><FontAwesomeIcon className="text-2xl" icon={faToggleOn} /></button> : row.status == 2 && <button onClick={ReactivarConocimiento} id={row.id}><FontAwesomeIcon className="text-2xl" icon={faToggleOff} /></button>}</div>
            cell: (row) => loaded && <div className="flex">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                        onClick={(e) => {
                            row.status == 1 ? desactivarType(e) : row.status == 2 && ReactivarType(e)
                        }}
                        id={row.id}
                        type="checkbox"
                        className="sr-only peer"
                        checked={row.status == 1 ? true : row.status == 2 && false}
                        readOnly
                    />
                    <div
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                </label>
            </div>
            // {/* {
            //     // loaded && row.complete_profile_status === 1 
            //     // ?
            //     // setText("Ver")
            //     // :
            //     // setText("")
            // } */}
        },
        {
            name: 'Editar',
            width: "15%",
            className: "flex justify-center items-center",
            cell: (row) => loaded && <button id={row.id} onClick={(e) => {
                mostrarInfoModalEditar(e)
            }} className="text-2xl">{loaded && <FontAwesomeIcon icon={faPenToSquare} />}</button>
        },
    ];

    const tableData = {
        columns,
        data
    };

    function mostrarInfoModalEditar(e) {
        console.log("mensaje")
        setFormularioEditar([initialDatosFormulario])
        setShowModalEditar(true)

        setIdEditar(e.currentTarget.id)

        data.map((item) => {
            if (item.id == e.currentTarget.id) {
                const info = {
                    name: item.name
                }
                setFormularioEditar(info)
                console.log(item.required_contact, "item")


                if (item.required_contact) {
                    setIsChecked(true);
                } else {
                    setIsChecked(false);
                }

            }


        })

        setLoaded(true)

    }

    const searchFilter = (termSearch, nameInput, dataArray) => {
        console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = data.filter((item) => {
                    if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                setDataSearch(srch);
                break;
            default:
                break;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        setDataFilter({...dataFilter, [e.target.name]: e.target.value});
    }

    return (
        <div className=''>
            <div className='ContainerOpenClass'>

                <div className=" p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">

                        <div className="sm:flex">
                            <div className="">
                                <h1 className="text-xl sm:text-2xl font-semibold text-white">Tipos de Cooperantes</h1>
                            </div>
                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto"
                                onClick={() => setShowModal(true)}
                            >
                                <button type="button" data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto">
                                    <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Agregar un Tipo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-4 bg-blue-950">
                    <SearchInput dataFilterSearch={dataFilter.search} changeData={changeData} />
                    <div className="p-2">
                        {
                            loaded
                            ?
                            <DataTable
                                columns={columns}
                                data={ dataSearch }
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                paginationComponentOptions={paginationProps}
                                responsive
                                noDataComponent={<div className="my-5">No hay registros</div>}
                            />
                            :
                            <PreloaderTables />
                        }
                    </div>
                </div>

            </div>

            {/* Modal */}
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold text-blue-950">
                                        Agregar Tipo de Cooperante
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <form className="w-full max-w-sm" onSubmit={addType}>

                                            <div className="flex flex-col mb-6">
                                                <div className="w-full">
                                                    <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                        Tipo de Cooperante
                                                    </label>
                                                </div>

                                                <div className="w-full">
                                                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-900" name="name" value={formulario.name} onChange={ManejarEventoDeInputs} type="text" />
                                                    {
                                                        alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>





                                            <div className="flex items-center pl-6 border border-gray-200 rounded dark:border-gray-700 ml-6 ">
                                                <input id="bordered-checkbox-1" type="checkbox" value="" checked={isChecked} name="bordered-chckbox" onChange={handleCheckboxChange} className="w-4 h-4 text-blue-950 bg-gray-100 border-gray-300 rounded focus:ring-blue-950  " />
                                                <label htmlFor="bordered-checkbox-1" className="w-full py-4 ml-2 text-sm font-medium text-blue-950 ">Requiere persona de contacto</label>
                                            </div>




                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b justify-center">

                                                <button
                                                    className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                    type="submit"

                                                >
                                                    Agregar
                                                </button>

                                                <button
                                                    className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                    type="button"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showModalEditar ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold text-blue-950">
                                        Editar Tipo de Cooperante
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <form className="w-full max-w-sm" onSubmit={editarType}>

                                            <div className="flex flex-col mb-6">
                                                <div className="w-full">
                                                    <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-8">
                                                        Tipo de Cooperante
                                                    </label>
                                                </div>
                                                <div className="w-full">
                                                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="name" value={formularioEditar.name} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                    {
                                                        alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <div className="flex items-center pl-6 border border-gray-200 rounded dark:border-gray-700 ml-6 ">
                                                <input id="bordered-checkbox-1" type="checkbox" value="" checked={isChecked} name="bordered-chckbox" onChange={handleCheckboxChange} className="w-4 h-4 text-blue-950 bg-gray-100 border-gray-300 rounded focus:ring-blue-950  " />
                                                <label htmlFor="bordered-checkbox-1" className="w-full py-4 ml-2 text-sm font-medium text-blue-950 ">Requiere persona de contacto</label>
                                            </div>




                                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b justify-center">

                                                <button
                                                    className="bg-blue-950 text-white active:bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                    type="submit"

                                                >
                                                    Editar
                                                </button>

                                                <button
                                                    className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                    type="button"
                                                    onClick={() => setShowModalEditar(false)}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}



        </div>
    )
}

export default TipoCooperante;