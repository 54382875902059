import Cookies from "js-cookie";
import { useEffect, useState, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { BarChart } from "@tremor/react";
import MyDataTable from "../../components/MyDataTable";

import { getOrDeleteRequest } from '../utils/util';
import Preloader from "../Preloader/Preloader";

import BackButton from "../BackButton";
import UserCard from "../../components/UserCard";

const UserHistory = () => {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [chart, setChart] = useState([]);

    const { username } = useParams();

    const columns = [
        {
            name: 'Fecha de acceso',
            selector: 'date',
            cell: row => <span>{row.login_date}</span>
        },
        {
            name: 'Hora de acceso',
            selector: 'time',
            cell: row => <span>{row.login_time}</span>
        },
        {
            name: 'Método de cierra de sesión',
            cell: row => <span>{row.revoked ? 'Manual' : 'Sesión expirada'}</span>
        },
    ];

    const loadData = useCallback(async () => {
        let response = await getOrDeleteRequest(
            `auth/user-sessions/${username}`, 
            'GET'
        );

        if (response.length < 0) {
            return;
        }

        if (response.type === "error") {
            setData([]);
            return;
        } 
        
        setData(response.data);
        setUser(response.user);
        setChart(response.chart);
        setLoaded(true);

        return;
    }, []);

    useEffect(() => {
        if (!loaded) {
            loadData();
        }
    }, [loaded, loadData]);

    return (
        <>
            <div className=" p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                <div className="mb-1 w-full flex flex-wrap">
                    <div className="flex items-center w-1/2 md:w-1/4 justify-start px-10 order-1">
                        <BackButton
                            history={Cookies.get('prevLocation')}
                        />
                    </div>
                    <div className="flex items-center justify-center w-full md:w-2/4 order-2">
                        <h1 className="my-5 text-white text-center font-bold text-4xl">Historial de acceso</h1>
                    </div>
                </div>
            </div>
            <div className="py-2 px-4 bg-blue-950">
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-1/2 p-4">
                        { loaded ? 
                            <>
                                <UserCard 
                                    names={user.names}
                                    lastnames={user.lastnames}
                                    email={user.email}
                                    username={user.username}
                                    // role={user.role}
                                    // status={user.status}
                                />
                            </> :
                            <div className="w-full p-4">
                                <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4 h-32 flex items-center justify-center">
                                    <Preloader global={ false } />
                                </div>
                            </div>
                        }
                        { loaded ?
                            <>
                                <div className="w-full">
                                    <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4 flex items-center justify-center flex-col">
                                        <h2 className="text-xl font-semibold text-slate-900">Accesos al sistema</h2>
                                        <BarChart
                                            className='bg-white'
                                            data={chart}
                                            index="label"
                                            dataKey="total"
                                            categories={["Accesos al sistema"]}
                                            colors={['indigo']}
                                            yAxisWidth={18}
                                            noDataText='Sin datos para mostrar'
                                            showAnimation={true}
                                        />
                                    </div>
                                </div>
                            </> :
                            <div className="w-full p-4">
                                <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4 h-32 flex items-center justify-center">
                                    <Preloader global={ false } />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="w-full lg:w-1/2 p-4">
                        <MyDataTable
                            loaded={loaded}
                            data={data}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserHistory;
