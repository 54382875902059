import Select from 'react-select';
import Swal from "sweetalert2";
import React, { useState, useEffect, useCallback } from "react";
import { getOrDeleteRequest, postOrPutRequest } from "../utils/util";

const UpdateTraning = (props) => {
    const datosFormulario = {
        nameTraning: "",
        typeProgram: 0,
        // Especialidades: [],
        // Conocimientos: [],
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [typesPrograms, setTypesPrograms] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [conocimientos, setConocimientos] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const validateInputs = (inputs) => {
        let errors = [];
        let formData = inputs;

        formData.filter((input) => input.name !== "Especialidades" && input.name !== "Conocimientos").map((input) => {
            if (input.value === "" || input.value === null || input.value === undefined) {
                errors.push({
                    valorInput: input.name,
                    mensaje: "Campo requerido",
                    estado: true
                });

                return;
            }

            errors.push({
                valorInput: input.name,
                mensaje: "",
                estado: false
            });
            return;
        });

        // formData.filter((input) => input.name === "Especialidades" || input.name === "Conocimientos").map((input) => {
        //     if (input.name === "Especialidades" && especialidades.length < 1) {
        //         errors.push({
        //             valorInput: input.name,
        //             mensaje: "Campo requerido",
        //             estado: true
        //         });

        //         return;
        //     }

        //     if (input.name === "Conocimientos" && conocimientos.length < 1) {
        //         errors.push({
        //             valorInput: input.name,
        //             mensaje: "Campo requerido",
        //             estado: true
        //         });

        //         return;
        //     }

        //     errors.push({
        //         valorInput: input.name,
        //         mensaje: "",
        //         estado: false
        //     });
        //     return;
        // });

        return errors;
    };

    const manejarEventoDeInputs = (event) => {
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        // if (name === "Especialidades") {
        //     consultarConocimientos(value);
        // }

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const verifyData = (event) => {
        event.preventDefault();

        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        let validated = validateInputs(inputs);

        setAlerta(validated);

        let failedValidated = validated.filter((input) => input.estado === true);
        console.log(failedValidated);

        if (failedValidated.length < 1) {
            sendData();

            return;
        }

        return;
    };

    const loadConocimientos = useCallback(async (elements) => {
        let ids = elements.map((item) => item.value);
        let idsList = ids.join(",");

        if (elements.length < 1) {
            formulario.Especialidades = [];
            formulario.Conocimientos = [];

            setConocimientos([]);
            setFormulario(formulario);
            return;
        }

        let getConocimientos = await getOrDeleteRequest(
            `knowledge/filter?type=${idsList}`,
            'GET'
        );

        let dataConocimientos = getConocimientos.map((item) => {
            return {
                value: item.id,
                label: item.name,
                type: item.type,
            };
        });

        let inSpecialities = formulario.Conocimientos.filter((item) => ids.includes(item.type));

        formulario.Especialidades = elements;
        formulario.Conocimientos = inSpecialities;

        setConocimientos(dataConocimientos);

        if (loaded) {
            setFormulario(formulario);
        }
    });

    const loadData = useCallback(async () => {
        let getData = await getOrDeleteRequest(
            `programs/find/${props.id}`,
            'GET'
        );

        let getTypesPrograms = await getOrDeleteRequest(
            "course-type/filter",
            'GET'
        );
        
        let getEspecialidades = await getOrDeleteRequest(
            "speciality/filter",
            'GET'
        );

        let form = {
            nameTraning: getData.name,
            typeProgram: getData.type_id,
            // Especialidades: getData.specialities,
            // Conocimientos: getData.knowledge,
        };
        console.log(form);
        let dataTypesPrograms = getTypesPrograms.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

        // let dataEspecialidades = getEspecialidades.map((item) => {
        //     return {
        //         value: item.id,
        //         label: item.name,
        //     };
        // });

        setTypesPrograms(dataTypesPrograms);
        // setEspecialidades(dataEspecialidades);
        setFormulario(form);

        // loadConocimientos(getData.specialities);

        setLoaded(true);
    });

    const sendData = useCallback(async () => {
        let data = {
            name: formulario.nameTraning,
            type: formulario.typeProgram,
            // knowledge: formulario.Conocimientos.map((item) => item.value),
        };
        console.log(data);
        let response = await postOrPutRequest(
            `programs/update/${props.id}`,
            'PUT',
            data
        );

        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 3000
        });

        props.showPopup(false, props.modal);
        setAlerta([initialStateInput]);
        setFormulario(datosFormulario);
        props.loadData();

        return;
    });

    useEffect(() => {
        if (loaded === false) {
            loadData();
            setLoaded(true);
        }
    }, []);

    return (
        <>
            <div className="block overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-7 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-900">
                                Actualizar Formación
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.showPopup(false, props.modal)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/* Modal Body */}
                        <div className="relative">
                            <div className="text-slate-500 text-lg leading-relaxed">
                                <form
                                    className="w-full flex flex-wrap"
                                    onSubmit={verifyData}
                                >
                                    <div className="w-full flex flex-wrap py-4 px-6">
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Nombre de la formación
                                            </label>
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950"
                                                name="nameTraning"
                                                value={formulario.nameTraning}
                                                onChange={manejarEventoDeInputs}
                                                type="text"
                                                autoComplete="nope"
                                            />
                                            {alerta.filter(input => input.valorInput === "nameTraning" && input.estado === true).map((message, index) => (
                                                <div key={index}> <span className="text-red-500">{message.mensaje}</span></div>
                                            ))}
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Tipo de programa
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <select
                                                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' 
                                                    name="typeProgram" 
                                                    id="typeProgram"
                                                    value={formulario.typeProgram}
                                                    onChange={manejarEventoDeInputs}
                                                >
                                                    {typesPrograms && (
                                                        <>
                                                            <option>Seleccione un Programa</option>
                                                            { typesPrograms.map((item, key) =>
                                                                <option key={key} value={item.value}>{item.label}</option>
                                                            )}
                                                        </>
                                                    )}
                                                    </select>
                                                    { alerta.filter(input => input.valorInput == "typeProgram" && input.estado === true).map(message => (
                                                        <div className=""> <span className="text-red-500">{message.mensaje}</span></div>
                                                    )) }
                                            </div>
                                        </div>
                                        {/* <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Especialidades
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <Select
                                                    isMulti
                                                    options={especialidades}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    value={formulario.Especialidades}
                                                    onChange={(option) => {
                                                        console.log(formulario.Especialidades);
                                                        if (option.length < 1) {
                                                            setFormulario({ ...formulario, Especialidades: [] });
                                                        } else {
                                                            setFormulario({ ...formulario, Especialidades: option });
                                                        }

                                                        loadConocimientos(option);
                                                    }}
                                                />
                                                {
                                                    alerta.filter(input => input.valorInput == "Empresa" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Conocimientos
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <Select
                                                    isMulti
                                                    options={conocimientos}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    value={formulario.Conocimientos}
                                                    onChange={(option) => {
                                                        console.log(option);
                                                        setFormulario({ ...formulario, Conocimientos: option });
                                                    }}
                                                />
                                                {
                                                    alerta.filter(input => input.valorInput == "Empresa" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="w-full flex items-center justify-center border-t border-solid border-slate-200 rounded-b py-4">
                                        <button
                                            className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Actualizar
                                        </button>

                                        <button
                                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center"
                                            type="button"
                                            onClick={() => {
                                                props.showPopup(false, props.modal);
                                                setAlerta([initialStateInput]);
                                                setFormulario(datosFormulario);
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
};

export default UpdateTraning;
