import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import BackButton from "../BackButton";
import SectionProfile from "../../layouts/SectionProfile";
import ProfileCard from "../../components/ProfileCard";
import Preloader from "../Preloader/Preloader";

import { InputField, SelectField } from "../../layouts/InputField";
import { getOrDeleteRequest, postOrPutRequest } from "../utils/util";

const defaultData = [ 1, 2, 3, 4, 5 ];

const RankingTest = () => {
    const studentInit = {
        email: null,
        names: null,
        lastnames: null,
        age: null,
        program_name: null,
        photo: null,
    };

    const [student, setStudent] = useState(studentInit);
    const [errors, setErrors] = useState({});
    const [questionnaire, setQuestionnaire] = useState([]);
    // const [dataValue, setDataValue] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const { id, program } = useParams();

    const getQuestionnaire = useCallback(async () => {
        let response = await getOrDeleteRequest(
            `program-schedule/ranking/${program}/${id}`,
            'GET'
        );
        console.log(response);
        if (response.type === 'error') {
            console.error(response);
            setQuestionnaire([]);
            setLoaded(true);
            return;
        }

        setQuestionnaire(response.data);
        setLoaded(true);
        return;
    }, [id]);

    const getStudent = useCallback(async () => {
        let response = await getOrDeleteRequest(
            `program-schedule/student/${program}/${id}`,
            'GET'
        );
        
        if (response.type === 'error') {
            console.error(response);
            setStudent(studentInit);
            return;
        }

        setStudent(response);
        return;
    }, [id, program, studentInit]);

    const getQuestionnaireResponse = useCallback(async () => {
        let response = await postOrPutRequest(
            `program-schedule/student-ranking/load`,
            'POST',
            { student: id, program: program }
        );
        
        if (response.type === 'error') {
            console.error(response);
            setQuestionnaire([]);

            // Redireccionar a la pagina anterior
            window.location.href = Cookies.get('prevLocation');

            setLoaded(true);
            return;
        }
        // console.log(response);
        setQuestionnaire(response.data);
        setLoaded(true);
        return;
    }, []);

    // Funcion que captura los datos de cada formulario
    const handleData = (originForm) => {
        let form = document.getElementById(originForm);
        let data = new FormData(form);
        let dataToSend = {
            questionnaire_id: originForm.split('-')[1],
            student_id: id,
            program_id: program,
            options: [],
        };
        let requiredItems = {};
        
        for (let [key, value] of data.entries()) {
            // Validar que solo agregue las opciones seleccionadas
            if (value === '0') {
                console.log(key, value);
                // Adjuntamos los errores
                requiredItems[key] = true;
                continue;
            } 

            dataToSend.options.push({
                question_id: key.split('-')[2],
                option_id: value,
            });
        }
        // console.log(requiredItems);
        setErrors(requiredItems);

        return dataToSend;
    };
    
    const sendData = async (event) => {
        let form_id = event.target.dataset.form;

        let data = handleData(form_id);
        // console.log(errors);
        // Validmos que se hayan seleccionado opciones
        if (data.options.length < 1) {
            Swal.fire({
                title: 'No se encontraron opciones seleccionadas',
                text: 'Debe seleccionar al menos una opción',
                icon: 'warning',
                confirmButtonText: 'Aceptar',
            });
            return;
        }
        console.log(data);
        let response = await postOrPutRequest(
            'program-schedule/student-ranking/save',
            'POST',
            data
        );
        console.log(response);
        if (response.type === 'error') {
            Swal.fire({
                title: 'Error al guardar los datos',
                text: response.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
            return;
        }

        Swal.fire({
            title: 'Datos guardados',
            text: response.message,
            icon: 'success',
            confirmButtonText: 'Aceptar',
        });

        return;
    };

    useEffect(() => {
        if (!loaded) {
            getStudent();
            // getQuestionnaire();
            getQuestionnaireResponse();
        }
    }, [getQuestionnaire, getStudent, loaded]);

    return (
        <>
            <div className=" p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                <div className="mb-1 w-full flex flex-wrap">
                    <div className="flex items-center w-1/2 md:w-1/4 justify-start px-10 order-1">
                        <BackButton
                            history={Cookies.get('prevLocation')}
                        />
                    </div>
                    <div className="flex items-center justify-center w-full md:w-2/4 order-2">
                        <h1 className="my-5 text-white text-center font-bold text-4xl">
                            Desempeño y rendimiento del beneficiario
                        </h1>
                    </div>
                    {/* <div className="flex items-center justify-center w-1/2 md:w-1/4 order-3 px-10">
                        <button
                            className="bg-green-600 hover:bg-green-700 text-white py-3 px-4 text-sm font-medium text-center rounded-lg inline-flex items-center justify-center focus:ring-4 transition-colors my-5"
                            onClick={() => {
                                getQuestionnaireResponse();
                            }}
                        >
                            Refrescar
                        </button>
                    </div> */}
                </div>
            </div>
            <div className="py-2 px-4 bg-blue-950">
                <div className="flex justify-center">
                    <SectionProfile 
                        title="Beneficiario y programa" 
                        full={ false }
                    >
                        <div className="bg-white p-5 mb-3 w-full">
                            <ProfileCard
                                names={ student.names }
                                lastnames={ student.lastnames }
                                age={ student.age }s
                                program={ student.program_name }
                                email={ student.email }
                                photo={ student.photo }
                                loaded={ loaded }
                            />
                        </div>
                    </SectionProfile>
                </div>
                {  loaded && questionnaire.length > 0 && (
                    questionnaire.map((item, index) => {
                        return (
                            <SectionProfile 
                                title={ `${item.name} (${item.percentage}%)` } 
                                key={ index }
                                button={
                                    <button
                                        className="bg-blue-700 hover:bg-blue-950 text-white py-3 px-4 text-sm font-medium text-center rounded-lg inline-flex items-center justify-center focus:ring-4 transition-colors"
                                        data-form={ `form-${item.id}` }
                                        onClick={ sendData }
                                    >
                                        Guardar
                                    </button>
                                }
                            >
                                <div className="bg-white w-full p-5 mb-3">
                                    <form id={ `form-${item.id}` } className="w-full">
                                        <div className="flex flex-wrap">
                                            {  (item.questions).map((question, index) => {
                                                // console.log(question);
                                                return (
                                                    <div className="w-full lg:w-1/2 p-4" key={ index }>
                                                        { question.type === 'select' && (
                                                            <SelectField
                                                                label={ question.question }
                                                                name={ `form-${index}-${question.id}` }
                                                                value={ question.answer ? question.answer.option_id : 0 }
                                                                items={ question.options }
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }) }
                                        </div>
                                    </form>
                                </div>
                            </SectionProfile>
                        );
                    })
                ) }
                { !loaded && (
                    defaultData.map((item) => {
                        return (
                            <div className="w-full p-4" key={ item }>
                                <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4 h-32 flex items-center justify-center">
                                    <Preloader global={ false } />
                                </div>
                            </div>
                        );
                    }) 
                ) }
            </div>
        </>
    );
};

export default RankingTest;

