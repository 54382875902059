export const validateData = (data) =>{
    let errors = [];
    console.log(data);
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    data.map((item) => {
        if(item.value === '' || item.value === undefined || item.value === null || item.value === 0){
            errors.push({
                inputName: item.name,
                message: "Campo requerido",
                status: true
            });
            return;
        }

        if(item.name === 'start_year' || item.name === 'end_year'){
            if(item.value > year ){
                console.log("Años validados");
                errors.push({
                    inputName: item.name,
                    message: "El año debe ser menor",
                    status: true
                });
            }
            return;
        }

        if(Array.isArray(item.value)){
            if(item.value.length === 0){
                errors.push({
                    inputName: item.name,
                    message: "Debe seleccionar al menos una opción",
                    status: true
                });
            }
            return;
        }
        
        errors.push({
            inputName: item.name,
            message: "",
            status: false
        });
        return;
    });
    return errors;
}