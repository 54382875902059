import { useEffect, useState, useCallback } from "react";

import UserCard from "../../components/UserCard";
import Preloader from "../Preloader/Preloader";

import { getOrDeleteRequest } from '../utils/util';

const loadElements = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

const AccessLog = () => {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const columns = [];

    const loadData = useCallback(async () => {
        let response = await getOrDeleteRequest(
            'user/index', 
            'GET'
        );

        if (response.length < 0) {
            return;
        }

        if (response.type === "error") {
            setData([]);
            return;
        } 
        
        setData(response);
        setLoaded(true);

        return;
    }, []);

    useEffect(() => {
        if (!loaded) {
            loadData();
        }
    }, [loaded, loadData]);

    return (
        <>
            <div className=" p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                <div className="mb-1 w-full">
                    <div className="sm:flex">
                        <h1 className="text-xl sm:text-2xl font-semibold text-white">Bitácora de Acceso</h1>
                    </div>
                </div>
            </div>
            <div className="py-2 px-4 bg-blue-950">
                <div className="flex flex-wrap">
                    { loaded 
                    ? 
                        data.map((item, index) => {
                            return (
                                <div key={ index } className="w-full lg:w-1/2 p-4">
                                    <UserCard 
                                        names={item.names}
                                        lastnames={item.lastnames}
                                        email={item.email}
                                        username={item.username}
                                        // role={item.role}
                                    />
                                </div>
                            );
                        }) :
                        loadElements.map((item, index) => {
                            return (
                                <div key={ index } className="w-full lg:w-1/2 p-4">
                                    <div className="bg-white border border-white shadow-lg rounded-3xl p-4 m-4 h-32 flex items-center justify-center">
                                        <Preloader global={ false } />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default AccessLog;
