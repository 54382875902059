import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { showPopup, getOrDeleteRequest, postOrPutRequest } from '../../../utils/util';
import { validateData } from '../../../utils/validateData';

const initDataExperience = {
    'institution': '',
    'job_title': '',
    'start_year': '',
    'start_month': '',
    'start_date': '',
    'end_year': '',
    'end_month': '',
    'end_date': '',
    'job_placement': '',
    'address': '',
    'current_job': false
}

const initialStateInput = {
    inputName: "",
    message: '',
    status: false
};

const months = [
    { label: 'Enero', value: '1' },
    { label: 'Febrero', value: '2' },
    { label: 'Marzo', value: '3' },
    { label: 'Abril', value: '4' },
    { label: 'Mayo', value: '5' },
    { label: 'Junio', value: '6' },
    { label: 'Julio', value: '7' },
    { label: 'Agosto', value: '8' },
    { label: 'Septiembre', value: '9' },
    { label: 'Octubre', value: '10' },
    { label: 'Noviembre', value: '11' },
    { label: 'Diciembre', value: '12' }
];

export const WorkExperienceFunctions = (params) => {

    const { id, getWorkExperiences, setShowModalWorkExperiences } = params;

    const [dataAddExperience, setDataAddExperience] = useState(initDataExperience);
    const [alert, setAlert] = useState([initialStateInput]);
    const [optionsPlacementType, setOptionsPlacementType] = useState([]);

    const getPlacementType = async() =>{
        let response = await getOrDeleteRequest(
            'job-placement-type/filter',
            'GET'
        );

        if(response.type == 'error'){
            console.error(response.message);
            return;
        }
        console.log(response);
        let selectData = response.map((item) => {
            return {
                value: item.id,
                label: item.type_name,
            }
        });
        setOptionsPlacementType(selectData);
    }

    const AddWorkExperience = (e) => {
        e.preventDefault();
        console.log(dataAddExperience);
        if(formatDate(dataAddExperience)){
            if(dataAddExperience.current_job){
                delete dataAddExperience.end_date;
                delete dataAddExperience.end_month;
                delete dataAddExperience.end_year;
            }
            const inputs = Object.entries(dataAddExperience).map(([key, value]) => {
                return { name: key, value: value };
            });
            const resValidate = validateData(inputs);
            console.log(resValidate);
            setAlert(resValidate);
            let validation = resValidate.filter(item => item.status === true);
            validation.length < 1 && SendData();

        }
    }

    const formatDate = (data) =>{
        const start_date = `${data.start_year}-${data.start_month}-01`;
        dataAddExperience.start_date = start_date;
        const end_date = `${data.end_year}-${data.end_month}-01`;
        dataAddExperience.end_date = end_date;
        return true;
    }

    const SendData = async () => {
        console.log('enviando...');
        console.log(dataAddExperience);
        dataAddExperience.current_job ? dataAddExperience.current_job = 1 : dataAddExperience.current_job = 0;
        let response = await postOrPutRequest(
            `student/add-experience/${id}`,
            'POST',
            dataAddExperience
        );
        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        getWorkExperiences();
        setAlert([initialStateInput]);
        setDataAddExperience(initDataExperience);
        showPopup(false, setShowModalWorkExperiences);
    }

    const HandelChange = ({ target: { value, name, checked } }) => {
        console.log(value, name, checked);
        setDataAddExperience({ ...dataAddExperience, [name]: value });
        name === 'current_job' && setDataAddExperience({ ...dataAddExperience, current_job: checked });
    }

    const HandelChangeSelect = (e, type) => {
        console.log(e, type);
        setDataAddExperience({ ...dataAddExperience, [type]: e.value });
    }

    useEffect(() => { getPlacementType()}, []);

    const objC = {
        alert: alert,
        dataAddExperience: dataAddExperience,
        months: months,
        optionsPlacementType: optionsPlacementType
    }

    const objF = {
        AddWorkExperience: AddWorkExperience,
        HandelChange: HandelChange,
        HandelChangeSelect: HandelChangeSelect
    }

    return { objC, objF }
}
