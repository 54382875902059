import { useState } from "react";

const ModalSocioEconomic = (props) => {
    // Limite de registros a mostrar
    const [limit, setLimit] = useState(5);

    // Funcion para aumentar el limite de registros a mostrar
    const showMore = () => {
        // let listHistory = document.getElementById("list-history");

        // // Obtemos la altural del contenedor en px
        // let height = listHistory.offsetHeight;
        // console.log(height);

        setLimit(limit + 5);
    }

    return (
        <>
            <div
                className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-2xl font-semibold text-blue-900">
                                Historial socioeconómico del beneficiario
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => {
                                    props.showPopup(false, props.setModal);
                            }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <div className="py-4 px-6">
                                    {/* Agregando ultima fecha de actualizacion (De forma representativa) */}
                                    <div className="flex justify-between items-center text-base mb-3">
                                        <span>
                                            Ultima actualización: <strong className="text-purple-cnx">{ props.lastUpdate }</strong>
                                        </span>
                                        <button className="btn-card-header disabled:opacity-50 disabled:cursor-not-allowed"
                                            onClick={ showMore }
                                            disabled={ props.data.length <= limit ? true : false }
                                        >
                                            Ver más
                                        </button>
                                    </div>
                                    <div id="list-history" className="flex flex-wrap px-3 overflow-y-auto max-h-[280px]">
                                        { ((props.data).length > 0) && (
                                            <>
                                                { (props.data).map((item, index) => {
                                                    if (index < limit) {
                                                        return (
                                                            <div className="w-full py-2" key={ index }>
                                                                <details className="group">
                                                                    <summary className="accordion">
                                                                        <span className="text-base">Registro: { item.register }</span>
                                                                        <span className="transition group-open:rotate-180">
                                                                            <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                                                                <path d="M6 9l6 6 6-6"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </summary>
                                                                    <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                                                        <div className="flex flex-wrap justify-center pt-1">
                                                                            <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">Último grado académico obtenido</span>
                                                                                <br />
                                                                                { 
                                                                                    item.study_level === 1 ? "Educación Básica" : item.study_level === 2 ? "Bachillerato" : item.study_level === 3 ? "Técnico" : item.study_level === 4 ? "Universidad" : item.study_level === 5 ? "Maestría" : item.study_level === 6 ? "Doctorado" : "No especificado"
                                                                                }
                                                                            </div>
                                                                            <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">¿Estudia actualmente?</span><br />{ item.currently_studying == 1 ?  "Si" :  "No"}
                                                                            </div>
                                                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">Disponibilidad de tiempo</span>
                                                                                <br />
                                                                                { 
                                                                                    item.time_availability == 1 ?  "Completo" :  item.time_availability == 2 ?  "Medio tiempo por la mañana" :  item.time_availability == 3 ?  "Medio tiempo por la tarde" :  item.time_availability == 4 ? "Sábado y domingo" : item.time_availability == 5 ? "Sin disponibilidad" : item.time_availability == 6 ? "Lunes a viernes" : "No especificado"
                                                                                }
                                                                            </div>
                                                                            <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">¿Trabaja actualmente?</span><br />{ item.currently_working == 1 ?  "Si" :  "No"}
                                                                            </div>
                                                                            { item.currently_working === 1 && (
                                                                                <>
                                                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                        <span className="font-bold text-black">Empleador</span><br />{ item.employer ? item.employer : "No especificado" }
                                                                                    </div>
                                                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                        <span className="font-bold text-black">Puesto en el que labora</span><br />{ item.work_position ? item.work_position : "No especificado" }
                                                                                    </div>
                                                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                        <span className="font-bold text-black">Área o departamento</span><br />{ item.work_area ? item.work_area : "No especificado" }
                                                                                    </div>
                                                                                    <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                        <span className="font-bold text-black">Salario</span><br />{ item.work_salary ? item.work_salary : "No especificado" }
                                                                                    </div>
                                                                                </>
                                                                            ) }
                                                                            <div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">Ingresos familiares</span>
                                                                                <br />
                                                                                {
                                                                                     item.family_income == 1 ?  "Menos de $300" :  item.family_income == 2 ?  "$300 - $500" :  item.family_income == 3 ?  "$500 - $1,000" :  item.family_income == 4 ?  "$1,000 - $1,500" :  item.family_income == 5 ? "Mas de $1,500" : "No especificado"
                                                                                }
                                                                            </div>

                                                                            <div className="w-full md:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">Cantidad de miembros familiares</span><br />{ item.family_members}
                                                                            </div>
                                                                            <div className="w-full md:w-1/2 p-2 text-sm text-gray-500 my-5 text-center">
                                                                                <span className="font-bold text-black">Comentarios u observaciones</span><br />
                                                                                <p>{ item.comment ? item.comment : "Sin comentarios" }</p>
                                                                            </div>
                                                                        </div>
                                                                    </p>
                                                                </details>
                                                            </div>
                                                        );
                                                    }

                                                    return null;
                                                }) }
                                            </>
                                        ) }
                                        { (props.data).length < 1 && (
                                            <div className="w-full">
                                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                                    Sin registros
                                                </p>
                                            </div>
                                        ) }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center"
                                type="button"
                                onClick={() => {
                                    props.showPopup(false, props.setModal);
                                    setLimit(1);
                                }}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default ModalSocioEconomic;
