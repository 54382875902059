import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { faCircleXmark, faPen, faSliders, faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { paginationProps, deleteRoles, validateSession, calculateAges, getOrDeleteRequest } from "../utils/util";
import { filterExportDataKeyes, filterWithoutValue } from "../utils/utilFiltersArrays";
import ImportButton from "../ImportButton";
import Filters from "./Filters";
import ExportDocument from "../Components/ExportDocument";
import PreloaderTables from "../PreloaderTables/PreloaderTables";

import ChambaticonBrand from "../../assets/chambaticon-brand.png";

const Estudiantes = () => {
    const initStudents = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: "",
    };

    const keyesExport = [
        "No",
        "Nombres",
        "Apellidos",
        "Correo",
        "Telefono",
        "Edad",
        "Departamento",
        "Telefono_Alternativo",
        "Grado_Academico",
        "En_Pasantia",
        "En_Formacion",
        "Ficha_Tecnica",
        "knowledge_filter"
    ];

    const initDataFilter = {
        search: "",
        gender: "default",
        status: "default",
        state: null,
        city: null,
        district: null,
        knowledge: [],
        program: null,
        campus: null,
        program_status: "default",
        language: null,
        internship_status: "default",
        status_order: "default",
    };

    const filtersRef = useRef();
    const contenedorRef = useRef(null);
    const dropdownRef = useRef(null);
    const MySwal = withReactContent(Swal);
    const [role, setRole] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const [dataSearch, setDataSearch] = useState([initStudents]);
    const [data, setData] = useState([initStudents]);
    const [dataToExport, setDataToExport] = useState([]);
    const [myFiltersStorage, setMyFiltersStorage] = useState({});
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [messageFilter, setMessageFilter] = useState(false);
    const [headers, setHeaders] = useState('');

    function eliminarEstudiante(event) {
        const idConocimiento = event.currentTarget.id;
        console.log(idConocimiento);
        Swal.fire({
            title: "¿Seguro que quieres eliminar a este beneficiario?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B",
        }).then((result) => {
            if (result.isConfirmed) {
                async function confirmDesactivarConocimiento() {
                    const url = `${process.env.REACT_APP_API_URI}student/disable/${idConocimiento}`;

                    let config = {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    };

                    if (Cookies.get("Bearer") !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get("Bearer")).token
                            }`;
                    } else {
                        window.location = "/";
                    }

                    try {
                        const resp = await axios.delete(url, config);
                        console.log(resp);
                        MySwal.fire({
                            position: "center",
                            icon: "success",
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500,
                        }).then(function () {
                            // window.location.href = "/Conocimientos";
                            setData([initStudents]);
                            getInfo();
                        });
                    } catch (err) {
                        console.log(err);
                        MySwal.fire({
                            position: "center",
                            icon: "error",
                            title: "¡Error! Vuelva a intentar",
                            showConfirmButton: false,
                            timer: 3500,
                        });
                    }
                }
                confirmDesactivarConocimiento();
            }
        });
    }

    const getInfo = async () => {
        setLoaded(false);

        let response = await getOrDeleteRequest(
            'student/index?internal=1',
            'GET'
        );
        console.log(response);
        if (response.type === "error") {
            setData([]);
            setLoaded(true);
            return;
        }

        let session = validateSession();

        if (session !== undefined && session.role !== undefined) {
            setRole(session.role);
        }

        setDataSearch(response);
        setData(response);

        if (sessionStorage.getItem('MyFilters') !== null) {
            setMyFiltersStorage(JSON.parse(sessionStorage.getItem('MyFilters')));
        }

        setLoaded(true);
    }

    const setDataa = (dataChild) => {
        setData(dataChild);
    };

    const callChildFunction = () => {
        if (filtersRef.current) {
            // filtersRef.current.assignFilterStorage(dataFilter);
            filtersRef.current.getfiltersStudents();
        }
    };

    useEffect(() => {
        if (loaded === false) {
            if (sessionStorage.getItem('MyFilters') === null) {
                console.log('Peticion general de los students');
                getInfo();
                return;
            }
        }
    }, []);

    useEffect(() => {
        setDataToExport(filterExportData());
    }, [dataSearch]);

    const dropDown = (e) => {
        console.log(e.currentTarget.id);
        let contentElement = document.getElementById(`dropdown-menu${e.currentTarget.id}`);
        if (contentElement.classList.contains('block')) {
            contentElement.classList.remove('block');
            contentElement.classList.add('hidden');
        } else {
            contentElement.classList.remove('hidden');
            contentElement.classList.add('block');
        }

        const contenedorElement = contenedorRef.current;
        const dropdownElement = dropdownRef.current;

        console.log(contenedorElement, dropdownElement);
        if (dropdownElement && contenedorElement) {
            const dropdownRect = dropdownElement.getBoundingClientRect();
            const contenedorRect = contenedorElement.getBoundingClientRect();

            // Calcula la posición del dropdown para que se desplace hacia arriba si está cerca de la parte inferior
            console.log(contenedorRect.bottom + 'contenedorRect.bottom');
            console.log(dropdownRect.height + 'dropdownRect.height');
            console.log(window.innerHeight + 'window.innerHeight');
            if (contenedorRect.bottom + dropdownRect.height > window.innerHeight) {
                dropdownElement.style.top = `-${dropdownRect.height}px`;
            } else {
                dropdownElement.style.top = '100%';
            }
        } else {
            console.log('NOT');
        }
    }

    const columns = [
        {
            name: "#",
            width: "80px",
            cell: (row) => <b>{loaded && row.id}</b>,
        },
        {
            name: "Nombres",
            width: "230px",
            cell: (row) => (
                <div className="flex relative">
                    {/* <div className="flex items-center justify-center absolute">
                        <div className="relative inline-block text-left">
                            <button
                                className="flex flex-wrap justify-center w-full text-sm font-medium text-gray-700 rounded-md focus:outline-none"
                                id={row.id}
                                onClick={dropDown}
                            >
                                <FontAwesomeIcon className="w-full hover:rotate-180 transition duration-100 ease-in-out" icon={faGear} />
                                <FontAwesomeIcon className="w-full text-[0.50rem]" icon={faChevronDown} />
                            </button>
                            <div id={`dropdown-menu${row.id}`} ref={dropdownRef} className={`hidden z-[999] origin-top-right absolute left-0 right-0 mt-2 w-48 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5`}>
                                <div className="py-2 p-2" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                                    <Link
                                        to={"/VerBeneficiarios/" + row.id}
                                        className="flex items-center rounded-md px-4 py-2 text-sm text-gray-700 hover:text-blue-900 hover:bg-gray-100 active:bg-blue-100 cursor-pointer"
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faIdCard} /> Expediente
                                    </Link>
                                    <Link
                                        to={"/EditarBeneficiario/" + row.id}
                                        className="flex items-center rounded-md px-4 py-2 text-sm text-gray-700 hover:text-blue-900 hover:bg-gray-100 active:bg-blue-100 cursor-pointer"
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faPen} /> Editar
                                    </Link>
                                    <button
                                        id={row.id}
                                        onClick={(e) => { eliminarEstudiante(e) }}
                                        className="flex w-full items-center rounded-md px-4 py-2 text-sm text-gray-700 hover:text-blue-900 hover:bg-gray-100 active:bg-blue-100 cursor-pointer"
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faTrash} /> Eliminar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <Link
                        to={"/VerBeneficiarios/" + row.id}
                        className="hover:border-b-2 hover:border-blue-950 cursor-pointer"
                    >
                        {loaded && row.names} {loaded && row.lastnames}
                    </Link>
                </div>
            ),
        },
        {
            name: "Email",
            width: "250px",
            cell: (row) => <div>{loaded && row.email}</div>,
        },
        {
            name: "Estudiando / Trabajando",
            width: "250px",
            cell: (row) =>
                loaded && (
                    <div>
                        {/* {row.currently_working} */}
                        <span>
                            {row.currently_studying === 1 && (
                                <span className="p-2 bg-blue-900 mx-0.5 rounded-full text-white text-xs">
                                    Estudiando
                                </span>
                            )}
                        </span>
                        <span>
                            {row.currently_working === 1 && (
                                <span className="p-2 bg-green-600 mx-0.5 rounded-full text-white text-xs">
                                    Trabajando
                                </span>
                            )}
                        </span>
                        {row.currently_studying === 0 && row.currently_working === 0 && (
                            <span className="italic text-gray-400 text-xs">Sin actividades</span>
                        )}
                    </div>
                ),
        },
        {
            name: "Formacion / Pasantia",
            width: "250px",
            cell: (row) =>
                loaded && (
                    <div>
                        <span>
                            {row.active_in_internship === 1 && (
                                <span className="p-2 bg-blue-900 mx-0.5 rounded-full text-white text-xs">
                                    En Pasantía
                                </span>
                            )}
                        </span>
                        <span>
                            {row.active_in_training === 1 && (
                                <span className="p-2 bg-green-600 mx-0.5 rounded-full text-white text-xs">
                                    En Formación
                                </span>
                            )}
                        </span>
                        {row.active_in_internship === 0 && row.active_in_training === 0 && (
                            <span className="italic text-gray-400 text-xs">Sin actividades</span>
                        )}
                    </div>
                ),
        },
        {
            name: "Chambaticon",
            width: "150px",
            cell: (row) => loaded && (
                <div className="w-full">
                    { (row.complete_external_profile === 1) && (
                        <>
                            <img
                                src={ ChambaticonBrand }
                                className="w-10 h-10"
                                alt=""
                            />
                        </>
                    ) }
                    { (row.complete_external_profile === 0) && (
                        <>
                            <FontAwesomeIcon icon={ faCircleXmark } className="text-red-500 text-2xl" />
                        </>
                    ) }
                </div>
            ),
        }
    ];

    const filterHeadings = (dataToFilter) => {
        return dataToFilter.map((key) => {
            const {
                id,
                names,
                lastnames,
                email,
                phone,
                age,
                alternative_phone,
                state,
                study_level,
                active_in_internship,
                active_in_training,
                knowledge_filter,
                ...rest
            } = key;

            const urlDataSheet = `https://registroacademico.ticongle.com/VerBeneficiarios/${id}`;
            const activeInternship = active_in_internship == 1 ? 'Sí' : 'No';
            const activeTraining = active_in_training == 1 ? 'Sí' : 'No';
            return {
                ...rest,
                No: id,
                Nombres: names,
                Apellidos: lastnames,
                Correo: email,
                Telefono: phone,
                Edad: age,
                Departamento: state,
                Telefono_Alternativo: alternative_phone,
                Grado_Academico: study_level,
                En_Pasantia: activeInternship,
                En_Formacion: activeTraining,
                Ficha_Tecnica: urlDataSheet,
                knowledge_filter: knowledge_filter
            };
        });
    };

    const filterExportData = () => {
        const filterHdng = filterHeadings(filterWithoutValue(calculateAges(dataSearch)));

        const processedData = filterExportDataKeyes(
            keyesExport,
            filterHdng
        );
        // Validar la carga de los datos antes de procesar la informacion de la exportacion de datos del estudiante
        if (processedData.length > 0 && processedData[0].Nombres !== undefined) {
            console.log(processedData, 'processedData');
            // Agregar el knowledge_filter que tiene la información de los conocimientos en la raiz del objeto para que liste segun 
            // columna en la exportacion de los datos
            const f = processedData.map(obj => ({
                ...obj,
                ...myF(obj.knowledge_filter)
            }));

            // quitar la propiedad knowledge_filter
            const newArrayDeObjetos = f.map(objeto => {
                const { ['knowledge_filter']: _, ...restoDelObjeto } = objeto;
                return restoDelObjeto;
            });

            // Generar nuevas cabeceras segun se ha la informacion de estudiantes
            const nuevasClaves = Object.keys(newArrayDeObjetos[0]).map(clave => '<th>' + clave + '</th>');
            setHeaders(nuevasClaves);
            console.log(nuevasClaves, 'nuevasClaves');
            // Convertir los valores booleanos de los conocimientos en true o false
            const nuevoArreglo = newArrayDeObjetos.map(objeto => {
                const nuevoObjeto = {};
                for (const clave in objeto) {
                    nuevoObjeto[clave] = convertirBooleano(objeto[clave]);
                }
                return nuevoObjeto;
            });
            return nuevoArreglo;
        }
        return processedData;
    };

    // Cambiar los valores booleanos de los conocimeintos en SI o NO
    const convertirBooleano = (valor) => {
        if (valor === true) {
            return 'Si'
        }
        if (valor === false) {
            return 'No'
        }
        return valor;
    };

    // Extraccion de los conocimientos a la raiz del objeto principal del estudiante
    const myF = (obja) => {
        if (obja === undefined || obja === null || obja.length < 1) { 
            return {};
        }
        const knw = obja.reduce((obj, item) => {
            return { ...obj, ...item };
        }, {});
        return knw;
    }

    const selectValue = (filterValue, filterKey) => {
        switch (filterKey) {
            case "gender":
                let resultGe;
                if (filterValue == 1) {
                    resultGe = "Masculino";
                } else if (filterValue == 2) {
                    resultGe = "Femenino";
                } else {
                    resultGe = "Prefiere no decir";
                }
                return resultGe;
                break;
            case "status":
                let resultSt;
                if (filterValue == 1) {
                    resultSt = "Activo";
                } else if (filterValue == 2) {
                    resultSt = "Inactivo";
                } else {
                    resultSt = "Prefiere no decir";
                }
                return resultSt;
                break;
            case "state":
                let resultSta;
                if (filterValue == 1) {
                    resultSta = "Activo";
                } else if (filterValue == 2) {
                    resultSta = "Inactivo";
                } else {
                    resultSta = "Prefiere no decir";
                }
                return resultSta;
                break;
            case "program_status":
                const value = parseInt(filterValue);
                let resultPs;
                switch (value) {
                    case 1:
                        resultPs = "Aplicado";
                        break;
                    case 2:
                        resultPs = "Preseleccionado";
                        break;
                    case 3:
                        resultPs = "Seleccionado";
                        break;
                    case 4:
                        resultPs = "No seleccionado";
                        break;
                    case 5:
                        resultPs = "Completado";
                        break;
                    case 6:
                        resultPs = "En porceso";
                        break;
                    case 7:
                        resultPs = "No iniciado";
                        break;
                    default:
                        resultPs = "default";
                        break;
                }
                console.log(resultPs);
                return resultPs;
                break;
            case "knowledge":
                const arrayKnowledge = filterValue.map((item) => {
                    return item.label;
                });
                return arrayKnowledge;
                break;
            case "internship_status":
                let resultInternshipStatus;
                if (filterValue == 1) {
                    resultInternshipStatus = "Sí";
                }
                if (filterValue == 2) {
                    resultInternshipStatus = "No";
                }
                return resultInternshipStatus;
                break;
            case "status_order":
                let statusOrder;
                if (filterValue == 1) {
                    statusOrder = "Sí";
                }
                if (filterValue == 0) {
                    statusOrder = "No";
                }
                return statusOrder;
                break;
            default:
                break;
        }
    };

    const filters = `
        ${dataFilter.gender != "default"
            ? "<th> Genero: " +
            selectValue(dataFilter.gender, "gender") +
            "</th>"
            : ""
        }
        ${dataFilter.status != "default"
            ? "<th> Estado: " +
            selectValue(dataFilter.status, "status") +
            "</th>"
            : ""
        }
        ${dataFilter.state != null
            ? "<th> Departamento: " + dataFilter.state["label"] + "</th>"
            : ""
        }
        ${dataFilter.city != null
            ? "<th> Municipio: " + dataFilter.city["label"] + "</th>"
            : ""
        }
        ${dataFilter.district != null
            ? "<th> Distrito: " + dataFilter.district["label"] + "</th>"
            : ""
        }
        ${dataFilter.knowledge.length > 0
            ? "<th> Conocimientos: " +
            selectValue(dataFilter.knowledge, "knowledge") +
            "</th>"
            : ""
        }
        ${dataFilter.program != null
            ? "<th> Programa: " + dataFilter.program["label"] + "</th>"
            : ""
        }
        ${dataFilter.campus != null
            ? "<th> Campus: " + dataFilter.campus["label"] + "</th>"
            : ""
        }
        ${dataFilter.program_status != "default" && dataFilter.program_status != 0
            ? "<th> Estado en el programa: " +
            selectValue(dataFilter.program_status, "program_status") +
            "</th>"
            : ""
        }
        ${dataFilter.language != null
            ? "<th> Lenguaje: " + dataFilter.language["label"] + "</th>"
            : ""
        }
        ${dataFilter.internship_status != "default"
            ? "<th> En pasantía: " + selectValue(dataFilter.internship_status, "internship_status") + "</th>"
            : ""
        }
        ${dataFilter.status_order != "default"
            ? "<th> En formación: " + selectValue(dataFilter.status_order, "status_order") + "</th>"
            : ""
        }
    `;

    useEffect(() => {
        assignFilterStorage2(dataFilter, 'MyFiltersIn');
    }, [dataFilter]);

    const assignFilterStorage2 = (dataObj, nameVarSession) => {
        for (let key in dataObj) {
            if (dataObj.hasOwnProperty(key)) {
                const valueParam = dataObj[key];
                if (valueParam !== 'default' && validateDataStorage(valueParam)) {
                    sessionStorage.setItem(nameVarSession, JSON.stringify(dataObj));
                }
            }
        }
    }

    const validateDataStorage = (valor) => {
        return valor !== null && (!Array.isArray(valor) || valor.length > 0) && valor !== '';
    }

    const expandedComponent = ({ data }) =>
        <div className="flex py-2 px-12" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
            <Link
                to={"/VerBeneficiarios/" + data.id}
                className="flex items-center bg-blue-950 hover:bg-blue-900 rounded-full mx-1 px-4 py-2 text-sm text-white active:bg-blue-100 cursor-pointer"
            >
                <FontAwesomeIcon className="mr-2" icon={faIdCard} /> Expediente
            </Link>
            <Link
                to={"/EditarBeneficiario/" + data.id}
                className="flex items-center bg-blue-950 hover:bg-blue-900 rounded-full mx-1 px-4 py-2 text-sm text-white active:bg-blue-100 cursor-pointer"
            >
                <FontAwesomeIcon className="mr-2" icon={faPen} /> Editar
            </Link>
            <button
                id={data.id}
                onClick={(e) => { eliminarEstudiante(e) }}
                className="flex items-center bg-blue-950 hover:bg-blue-900 rounded-full mx-1 px-4 py-2 text-sm text-white active:bg-blue-100 cursor-pointer"
            >
                <FontAwesomeIcon className="mr-2" icon={faTrash} /> Eliminar
            </button>
        </div>
        ;

    return (
        <div className="">
            <div className="ContainerOpenClass">
                <div className="p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="sm:flex">
                            <div className="w-full md:w-[30%] flex items-center justify-center md:justify-start">
                                <h1 className="text-center md:text-left text-xl sm:text-2xl font-semibold text-white px-2">
                                    Beneficiarios
                                </h1>
                                {messageFilter &&
                                    <span className="flex items-center text-yellow-500 italic text-xs px-4">
                                        <FontAwesomeIcon className="pr-2" icon={faTriangleExclamation} />
                                        filtros
                                    </span>
                                }
                            </div>
                            <div className="w-full md:w-[70%] flex flex-wrap items-center justify-end px-2">
                                <Link
                                    to="/AddBeneficiario"
                                    data-modal-toggle="add-user-modal"
                                    className="w-full md:w-[30%] my-2 mx-1 py-2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 text-center sm:w-auto"
                                >
                                    <svg
                                        className="-ml-1 mr-2 h-6 w-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    Agregar beneficiario
                                </Link>
                                <ImportButton
                                    origin="student"
                                    info={getInfo}
                                    roles={[1, 2, 3]}
                                />
                                {dataSearch.length > 0 && (
                                    <ExportDocument
                                        useState={useState}
                                        useRef={useRef}
                                        useEffect={useEffect}
                                        fileName={"Beneficiarios"}
                                        filters={filters}
                                        headers={headers}
                                        dataToExport={dataToExport}
                                        filterExportData={filterExportData}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-blue-950">
                    <Filters
                        ref={filtersRef}
                        data={data}
                        setDataSearch={setDataSearch}
                        getInfo={getInfo}
                        dataFilter={dataFilter}
                        setDataFilter={setDataFilter}
                        initDataFilter={initDataFilter}
                        disabled={!loaded}
                        setLoaded={setLoaded}
                        myFiltersStorage={myFiltersStorage}
                        setMessageFilter={setMessageFilter}
                    />
                    <div className="px-6 relative">
                        {loaded ? (
                            <DataTable
                                columns={columns}
                                className="relative"
                                data={dataSearch}
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                paginationComponentOptions={paginationProps}
                                responsive
                                noDataComponent={<div className="my-5">No hay registros</div>}
                                ref={contenedorRef}
                                expandableRows
                                expandableRowsComponent={expandedComponent}
                            />
                        ) : (
                            <PreloaderTables />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Estudiantes;
