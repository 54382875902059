import { showPopup, getOrDeleteRequest, paginationProps, months } from '../../utils/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export const Study = (params) => {
    const { setShowModalEditStudy, setIdEditStudy, loadedStudies, getStudies, type } = params;

    const columns = [
        {
            name: 'Centro de estudio',
            width: "200px",
            // cell: (row) => loadedStudies && <div>{row.institution}</div>
            cell: (row) => loadedStudies && <div>{type == 'other' ? row.institution : row.study_degree}</div>
        },
        {
            name: type == 'other' ? 'Estudio' : 'Grado de estudio',
            width: "200px",
            // cell: (row) => loadedStudies && <div>{row.study_degree}</div>
            cell: (row) => loadedStudies && <div>{type == 'other' ? row.study_degree : row.institution}</div>
        },
        {
            name: 'Tipo de estudio',
            width: "200px",
            cell: (row) => loadedStudies && <div>{row.study_level}</div>
        },
        {
            name: 'Fecha de emisión',
            width: "230px",
            cell: (row) => loadedStudies && 
                <div>
                    {row.emision_year}
                </div>
        },
        // {
        //     name: 'Tipo de estudio',
        //     width: "200px",
        //     cell: (row) => {
        //        <div>{row.type_another}</div>
        //     }
        // },
        type !== 'other' && {
            name: 'Pais',
            width: "200px",
            cell: (row) => loadedStudies && <div>{row.country_name}</div>
        },
        // {
        //     name: 'Editar',
        //     width: "200px",
        //     className: "flex justify-center items-center",
        //     cell: (row) => loadedStudies &&
        //         <div className='flex justify-between'>
        //             <button id={row.id} onClick={(e) => {
        //                 console.log(e.currentTarget.id);
        //                 setIdEditStudy(e.currentTarget.id);
        //                 showPopup(true, setShowModalEditStudy);
        //             }} className="text-2xl">
        //                 {loadedStudies && <FontAwesomeIcon icon={faPenToSquare} />}
        //             </button>
        //             <button id={row.id} onClick={(e) => {
        //                 console.log(e.currentTarget.id);
        //                 const idDelete = e.currentTarget.id;
        //                 Swal.fire({
        //                     title: '¿Esta seguro que desea eliminar el registro?',
        //                     text: "",
        //                     icon: 'warning',
        //                     showCancelButton: true,
        //                     confirmButtonColor: '#d33',
        //                     cancelButtonColor: '#c0c0c0',
        //                     confirmButtonText: 'Sí, ¡deseo borrarlo!'
        //                 }).then((result) => {
        //                     if (result.isConfirmed) {
        //                         deleteStudy(idDelete);
        //                     }
        //                 });
        //             }} className="text-2xl text-red-500">
        //                 {loadedStudies && <FontAwesomeIcon icon={faTrashCan} />}
        //             </button>
        //         </div>
        // },
    ];

    const deleteStudy = async (idDelete) => {
        let response = await getOrDeleteRequest(
            `student/delete-studies/${idDelete}`,
            'DELETE'
        );
        console.log(response);
        Swal.fire(
            '¡Registro borrado!',
            response.message,
            'success'
        )
        getStudies();
    }

    return { columns, paginationProps }
}