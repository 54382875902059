import { useState, useEffect } from 'react';
import { validateData } from '../../utils/validateData';
import { postOrPutRequest, getOrDeleteRequest } from '../../utils/util';
import Swal from 'sweetalert2';
export const ModalAddSoftSkillLogic = (params) => {
    const { typeModal, idEdit, setShowModalAddSoftSkill, reloadData } = params;
    const initDataSoftSkill = {
        name: '',
        description: ''
    }

    const initStateInput = {
        inputName: "",
        message: '',
        state: false
    }

    const [dataSoftSkill, setDataSoftSkill] = useState(initDataSoftSkill);
    const [alerts, setAlerts] = useState([initStateInput]);

    useEffect(() => { typeModal === 'edit' && getSoftSkill(idEdit) }, [idEdit]);

    const getSoftSkill = async(id) =>{
        const response = await getOrDeleteRequest(
            `soft-skill/find/${id}`,
            'GET'
        );
        if(response.type == 'error'){
            console.error(response.message);
            return;
        }
        console.log(response);
        response['name'] = response.skill_name;
        delete response.skill_name;
        setDataSoftSkill(response);
    }
    

    const addSoftSkill = () => {
        console.log(dataSoftSkill);
        const inputs = Object.entries(dataSoftSkill).map(([key, value]) => {
            return { name: key, value: value };
        });
        validateData(inputs);
        const resValidate = validateData(inputs);
        console.log(resValidate);
        setAlerts(resValidate);
        let validation = resValidate.filter(item => item.status === true);
        validation.length < 1 && SendData();
    }

    const SendData = async() => {
        const response = await postOrPutRequest(
            'soft-skill/register',
            'POST',
            dataSoftSkill
        );
        if (response.type == 'error') {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 3000
        });

        reloadData();

        //Cerrar modal
        setShowModalAddSoftSkill(false);

        // setTimeout(window.location.reload(), 3000);
        setAlerts([initStateInput]);
        setDataSoftSkill(initDataSoftSkill);
    }

    const updateSoftSkill = async() =>{
        console.log(idEdit);
        const response = await postOrPutRequest(
            `soft-skill/update/${ idEdit }`,
            'PUT',
            dataSoftSkill
        );
        if (response.type == 'error') {
            console.error(response.message);
            return;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 3000
        });

        reloadData();

        //Cerrar modal
        setShowModalAddSoftSkill(false);

        setAlerts([initStateInput]);
        setDataSoftSkill(initDataSoftSkill);
    }

    const handelChange = ({ target: { name, value } }) => {
        setDataSoftSkill({ ...dataSoftSkill, [name]: value });
    }

    const handelSubmit = (e) => {
        e.preventDefault();
        console.log(typeModal);
        typeModal == 'add' ? addSoftSkill() : updateSoftSkill();
    }

    const opeModalSoftSkill = () =>{
        setDataSoftSkill(initDataSoftSkill);
        setShowModalAddSoftSkill(true); 
    }

    const objC = {
        dataSoftSkill: dataSoftSkill,
        alerts: alerts,
        initDataSoftSkill: initDataSoftSkill
    }

    const objF = {
        addSoftSkill: addSoftSkill,
        handelChange: handelChange,
        handelSubmit: handelSubmit,
        setDataSoftSkill: setDataSoftSkill,
        opeModalSoftSkill: opeModalSoftSkill,
        setShowModalAddSoftSkill: setShowModalAddSoftSkill
    }

    return { objC, objF }
}