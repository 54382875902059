const KnowledgesRSelect = (props) => {

    const { select: Select } = props;
    const [dataKnowledges, setDataKnowledges] = props.useState([]);
    
    props.useEffect(() => { getKnowledges() }, []);

    const getKnowledges = async () => {
        const response = await props.getOrDeleteRequest(
            'knowledge/filter',
            'GET'
        );
        if (response.length < 0) {
            console.log("Empty response");
            return;
        }
        if (response.type === "error") {
            console.log(response);
        } else {
            setDataKnowledges(props.setFilterDataReactSelect(response, 'knowledge'));
            // console.log(response);
        }
    }

    return (<>
        <div className="w-full px-2">
            <div className="mb-6">
                <label htmlFor="knowledge" className="block mb-2 text-white">Conocimientos</label>
                <Select
                    isMulti
                    id="knowledge"
                    name="knowledge"
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                    value={ props.dataFilterKnowledge }
                    options={ dataKnowledges }
                    placeholder={"Selecciona una opción"}
                    noOptionsMessage={() => 'No hay mas opciones'}
                    onChange={ props.changeDataRSelectMulti }
                    isDisabled={props.isDisabled !== undefined ? props.isDisabled : false}
                />
            </div>
        </div>
    </>)
}
export default KnowledgesRSelect;