import { ModalViewDocumentLogic } from './ModalViewDocumentLogic';
const ModalViewDocument = (props) => {
    const params = {
        MySwal: props.MySwal,
        downloadFileRequest: props.downloadFileRequest
    }
    const { objF } = ModalViewDocumentLogic(params);
    return (
        <div>
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-violet-700">Imagenes de los documentos</h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => { props.showPopup(false, props.setShowModalViewDocument) }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="flex flex-wrap p-6">
                            <div className="w-full md:w-1/2 flex justify-center hover:bg-gray-100">
                                {props.nameDocumentFront !== '' && props.nameDocumentFront !== null
                                    ?
                                    <div className='relative'>
                                        <button
                                            className="absolute bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                            type="button"
                                            onClick={()=>{objF.downloadDocument(props.nameDocumentFront)}}
                                        >
                                            Descargar
                                        </button>
                                        <img src={`${process.env.REACT_APP_PHOTO_URI}student/document/${props.nameDocumentFront}`} className='h-[400px]' alt="documento lado frontal" />
                                    </div>
                                    : <div>No hay registros de la imagen</div>
                                }
                            </div>
                            <div className="w-full md:w-1/2 flex justify-center items-center hover:bg-gray-100">
                                {props.nameDocumentBack !== '' && props.nameDocumentBack !== null
                                    ? 
                                    <div className='relative'>
                                        <button
                                            className="absolute bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                            type="button"
                                            onClick={()=>{objF.downloadDocument(props.nameDocumentBack)}}
                                        >
                                            Descargar
                                        </button>
                                        <img src={`${process.env.REACT_APP_PHOTO_URI}student/document/${props.nameDocumentBack}`} className='h-[400px]' alt="documento lado reverso" />
                                    </div>
                                    : <div>No hay registros de la imagen</div>
                                }
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                type="button"
                                onClick={() => { props.showPopup(false, props.setShowModalViewDocument) }}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
    )
}
export default ModalViewDocument;