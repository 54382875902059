import { useState, useCallback, useEffect } from "react";

import { showPopup, postOrPutRequest } from "../../utils/util";
import { BarListTremor } from "../../Components/ChartsTremor";

import UserFemale from '../../../assets/images/user-female.png';

import Preloader from "../../Preloader/Preloader";

import Modal from "../../../layouts/Modal";

const RankingModal = ({ showModal, setModal, program }) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    const loadData = useCallback(async () => {
        let response = await postOrPutRequest(
            `program-schedule/ranking/results`,
            "POST",
            { program: program }
        );
        
        if (response.type === 'error') {
            console.error(response.message);
            setError(true);
            setLoaded(true);
            return;
        }
        console.log(response.data);
        let tempData = response.data;

        // Format data
        let formattedData = tempData.map((item, index) => {
            return {
                name: `${(index + 1)}. ${item.names} ${item.lastnames} - (${item.ranking})`,
                value: item.ranking_score,
                href: `${process.env.PUBLIC_URL}/VerBeneficiarios/${item.id}`,
                // icon: () => {
                //     return (
                //         <img
                //             src={ item.photo === null ? UserFemale : `data:image/jpg;base64,${item.photo}` }
                //             alt={`${item.names} ${item.lastnames}`}
                //             className="w-20 h-20 rounded-full mr-1"
                //         />
                //     );
                // }
            };
        });

        setData(formattedData);

        setError(false);
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (!loaded) {
            loadData();
        }
    }, [loaded, loadData]);

    return (
        <Modal 
            title="Ranking de la formación"
            isOpen={ showModal }
        >
            <div className="relative mb-4 px-5">
                {
                    !loaded && <>
                        <div className="py-6">
                            <Preloader />
                        </div>
                    </>
                }
                {
                    loaded && error && (
                        <div className="text-red-500 text-center">
                            Sin datos para mostrar
                        </div>
                    )
                }
                {
                    loaded && !error && (
                        <BarListTremor
                            title="Beneficiarios destacados durante la formación"
                            data={ data }
                        />
                    )
                }
            </div>
            <div className="w-full flex items-center justify-center pt-4 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center "
                    type="button"
                    onClick={() => showPopup(false, setModal)}
                >
                    Cerrar
                </button>
            </div>
        </Modal>
    );
};

export default RankingModal;
