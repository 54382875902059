import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ModalAddConocimiento = (props) => {

    const [showModal, setShowModal] = useState(true);
    const [formulario, setFormulario] = useState({
        name: '',
        type: ''
    })
    const MySwal = withReactContent(Swal);
    const [alerta, setAlerta] = useState([]);
    const [speciality, setSpeciality] = useState([]);
    const [loaded, setLoaded] = useState(false);

    async function getEspecialidades() {
        const url = `${process.env.REACT_APP_API_URI}speciality`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            // console.log(resp.data,"especiality");
            setSpeciality(resp.data);
            // console.log(speciality,"------------speciality----------")
            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (loaded === false) {
            getEspecialidades();
        }
    }, []);

    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        console.log("Data", datosDelFormulario)

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'name': {
                    if (valorInput.value == '' || valorInput.value === null || valorInput.value == undefined) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }

                case 'type': {

                    if (valorInput.value === '' || valorInput.value === null || valorInput.value == undefined || valorInput.value == "0") {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;

                }


                default: {


                    break;
                }
            }

        })

        return errors;
    }

    const ManejarEventoDeInputs = (event) => {
        console.log(formulario, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    }

    function RegistrarConocimiento() {

        const url = `${process.env.REACT_APP_API_URI}knowledge`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        const dataInsertar = {
            name: formulario.name,
            type: formulario.type,
        };

        console.log(dataInsertar, "Datainsertar")

        axios.post(url, dataInsertar, config)
            .then(response => {
                // console.log(response,"response");

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    props.show(false)
                    formulario.name = "";
                    formulario.type = 0;
                    if (props.function !== undefined) {
                        props.function();
                    }
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    }

    const addConocimiento = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "name", value: formulario.name },
            { nombre: "type", value: formulario.type }
        ];

        console.log("Verificar", verificarInputs)

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        // console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length >= 1) {
            console.log("Enviar al servidor");
            RegistrarConocimiento();
        };

    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-950">
                                Agregar conocimiento
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.show(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full max-w-sm">
                                    <div className="md:flex mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-blue-950 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                Conocimiento
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="name" value={formulario.name} onChange={ManejarEventoDeInputs} type="text" />
                                            {
                                                alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="md:flex mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-blue-950 font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                Especialidad
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <div className="mb-2 xl:w-100">
                                                <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.type} name="type" onChange={ManejarEventoDeInputs}>
                                                    <option value="0" selected>Selecciona una opción</option>
                                                    {
                                                        speciality.map((item) =>
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    }

                                                </select>
                                                {
                                                    alerta.filter(input => input.valorInput == "type" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b justify-center">

                                        <div
                                            className="bg-blue-950 cursor-pointer text-white active:bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            onClick={addConocimiento}
                                        >
                                            Agregar
                                        </div>

                                        <div
                                            className="bg-red-500 cursor-pointer text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center"
                                            onClick={() => props.show(false)}
                                        >
                                            Cerrar
                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                        {/*footer*/}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default ModalAddConocimiento;