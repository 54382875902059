import { useState, useRef } from 'react';

export const DesertionLetterLogic = (params) => {
    const { setLetterDesertionStudent } = params;
    const [key, setKey] = useState(0);

    const inputFile = useRef(null);

    const handleFileChange = (e) => {
        let file = e

        if (!file) {
            inputFile.current.value = null;
            setKey((prevKey) => prevKey + 1);

            return;
        }

        let title = file.name;

        if (title.length > 30) {
            title = title.substring(0, 30) + "...";
        }
        document.getElementById("file-drag-title").innerHTML = title;
        setLetterDesertionStudent(file);
    };

    const handleCloudClick = () => {
        inputFile.current.click();
    };

    return { inputFile, handleFileChange, handleCloudClick,  }
}