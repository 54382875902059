import { useState, useEffect } from 'react';
import { paginationProps, getOrDeleteRequest, showPopup } from '../utils/util';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Swal from 'sweetalert2';

export const SoftSkillsLogic = (params) => {

    const { Link } = params;

    const [data, setData] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showModalAddSoftSkill, setShowModalAddSoftSkill] = useState(false);
    const [idEdit, setIdEdit] = useState(0);
    const [typeModal, setTypeModal] = useState('');

    const columns = [
        {
            name: '#',
            width: "15%",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre',
            width: "25%",
            cell: (row) => <div>{loaded && row.skill_name}</div>
        },
        {
            name: 'Descripción',
            width: "30%",
            cell: (row) => <div>{loaded && row.description}</div>
        },
        {
            name: "Activar/Desactivar",
            width: "15%",
            // cell: (row) => <div>{loaded && row.status == 1 ? <button onClick={desactivarConocimiento} id={row.id}><FontAwesomeIcon className="text-2xl" icon={faToggleOn} /></button> : row.status == 2 && <button onClick={ReactivarConocimiento} id={row.id}><FontAwesomeIcon className="text-2xl" icon={faToggleOff} /></button>}</div>
            cell: (row) => loaded &&
                <div className="flex">
                    <label className="inline-flex relative items-center mr-5 cursor-pointer">
                        <input
                            onClick={ (e) => { row.status == 1 ? disableSoftSkill(e) : row.status == 2 && enableSoftSkill(e)} }
                            id={ row.id } 
                            type="checkbox"
                            className="sr-only peer"
                            checked={ row.status == 1 ? true : row.status == 2 && false }
                            readOnly
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                    </label>
                </div>
        },
        {
            name: 'Editar',
            width: "15%",
            className: "flex justify-center items-center",
            cell: (row) => loaded &&
                <button onClick={() => { setIdEdit(row.id); setShowModalAddSoftSkill(true); setTypeModal('edit') }}>
                    {loaded && <FontAwesomeIcon icon={faPenToSquare} />}
                </button>
        },
    ];

    const getSoftSkills = async () => {
        const response = await getOrDeleteRequest(
            'soft-skill/index',
            'GET'
        )
        if (response.type == "error") {
            console.error(response.error);
            return;
        }
        console.log(response);
        setData(response);
        setDataSearch(response);
        setLoaded(true);
    }

    const disableSoftSkill = ({ target: { id } }) => {
        console.log(id);
        Swal.fire({
            title: '¿Seguro que quieres desactivar esta modalidad?',
            showCancelButton: true,
            confirmButtonText: 'Desactivar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then( async (result) => {
            if (result.isConfirmed) {
                const response = await getOrDeleteRequest(
                    `soft-skill/disable/${ id }`,
                    'DELETE'
                );
                if(response.type == 'error'){
                    console.error(response.message);
                    return;
                }
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 3500
                });
                getSoftSkills();
            }
        })
    };

    const enableSoftSkill = ({ target: { id } }) => {
        console.log(id);
        Swal.fire({
            title: '¿Seguro que quieres activar esta modalidad?',
            showCancelButton: true,
            confirmButtonText: 'Activar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then( async (result) => {
            if (result.isConfirmed) {
                const response = await getOrDeleteRequest(
                    `soft-skill/enable/${ id }`,
                    'PUT'
                );
                if(response.type == 'error'){
                    console.error(response.message);
                    return;
                }
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 3500
                });
                getSoftSkills();
            }
        })
    };

    useEffect(() => { getSoftSkills() }, []);

    const objF = {
        paginationProps: paginationProps,
        showPopup: showPopup,
        setShowModalAddSoftSkill: setShowModalAddSoftSkill,
        setTypeModal: setTypeModal,
        reloadData: getSoftSkills
    }

    const objC = {
        columns: columns,
        dataSearch: dataSearch,
        loaded: loaded,
        showModalAddSoftSkill: showModalAddSoftSkill,
        idEdit: idEdit,
        typeModal: typeModal
    }

    return { objF, objC }
}