import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Cookies from "js-cookie";
import { faPenToSquare, faEye } from "@fortawesome/free-regular-svg-icons";
import Select from 'react-select';
import { paginationProps, showPopup, validateSession, deleteRoles, updateRoles, createRoles } from "../utils/util";
import { filterExportDataKeyes } from "../utils/utilFiltersArrays";
import ImportButton from "../ImportButton";
import ReactInputMask from "react-input-mask";
import Filters from './Filters';
import ExportDocument from "../Components/ExportDocument";
import { Link } from "react-router-dom";
import PreloaderTables from "../PreloaderTables/PreloaderTables";

const Cooperantes = () => {
    const [showModal, setShowModal] = React.useState(false);
    const [showModalEditar, setShowModalEditar] = React.useState(false);
    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = useState(false);
    const [role, setRole] = useState(undefined);
    const [dataSearch, setDataSearch] = useState([]);
    const [dataToExport, setDataToExport] = useState([]);

    const initialDatosFormulario = {
        name: "",
        contact_name: "",
        phone: "",
        email: "",
        city: "",
        // description: "",
        // type_document: "",
        // document: "",
        projects: [],
        country: null,
        type1: null,
        type2: [],
        internship: null,
    };

    const initialCooperante = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    };

    const initialCooperanteEditar = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: '',
        type_id: ""
    };

    const initialTypesPrograms = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    };

    const initialPaisSelected = {
        id: 0,
        name: "",
        code: 0,
        phone_format: ""
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const initDataFilter = {
        search: '',
        country: null,
        program: [],
        internship: 'default',
        type: null
    }

    const keyesExport = [
        'No',
        'Nombre',
        'Correo',
        'Pais',
        'Ofrece_pasantias',
        'Ciudad',
        'Contacto',
        'Persona_Contacto'
    ];

    const [typesPrograms, setTypesPrograms] = useState([initialTypesPrograms]);
    const [formulario, setFormulario] = useState(initialDatosFormulario);
    const [formularioEditar, setFormularioEditar] = useState([initialDatosFormulario]);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [idEditar, setIdEditar] = useState();
    const [mostrarCamposEmpresa, setMostrarCamposEmpresa] = useState(false);
    const [mostrarCamposEmpresaEditar, setMostrarCamposEmpresaEditar] = useState(false);
    const [paisSelected, setPaisSelected] = useState([initialPaisSelected]);
    const [paisSelectedEditar, setPaisSelectedEditar] = useState([initialPaisSelected]);
    const [dataFilter, setDataFilter] = useState(initDataFilter);

    let typeProgramRegistrar = [];
    // let typeProgramRegistrarEditar = [];

    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {

        // console.log(formulario,"Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        // console.log(name)

        // if(name == "type2"){
        //     console.log()
        // }

        if (name == "country") {
            paises.map((item) => {
                event.target.value == item.id
                    &&
                    setPaisSelected(item)
            }
            )
        }

        if (name == "type1") {
            contributorType.map((item) => {
                if (event.target.value == item.id) {
                    if (item.required_contact == 1) {
                        setMostrarCamposEmpresa(true)
                        formulario.contact_name = ""
                    } else {
                        setMostrarCamposEmpresa(false)
                        formulario.contact_name = ""
                    }
                }
            })
        }

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const ManejarEventoDeInputsEditar = (event) => {
        console.log(formularioEditar, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        if (name == "country") {
            paises.map((item) => {
                event.target.value == item.id
                    &&
                    setPaisSelectedEditar(item)
            }
            )
        }
        if (name == "type2") {
            console.log(value, "value")
        }

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormularioEditar({ ...formularioEditar, [name]: value });
    };

    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOptionEditar, setSelectedOptionEditar] = useState([]);
    // const [datosCooperante, setDatosCooperante] = useState([]);
    const [datosCooperanteEditar, setDatosCooperanteEditar] = useState([]);

    const addCooperante = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        typeProgramRegistrar = [];
        //Ordenamos los datos para enviarlos a la validacion
        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                nombre: key,
                value: value,
            };
        });

        if (!mostrarCamposEmpresa) {
            inputs = inputs.filter((input) => input.nombre !== "contact_name");
        }
        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(inputs);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);

        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.estado !== false).map(() => { return false });
        console.log(alerta, "alerta");
        if (totalValidaciones.length < 1) {
            setAlerta([initialStateInput]);
            RegistrarCooperante();
        }
    };

    const editarCooperante = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        let inputs = Object.entries(formularioEditar).map(([key, value]) => {
            return {
                nombre: key,
                value: value,
            };
        });

        console.log(mostrarCamposEmpresaEditar, "mostrarCamposEmpresaEditar")

        //Ordenamos los datos para enviarlos a la validacion
        if (!mostrarCamposEmpresaEditar) {
            inputs = inputs.filter((input) => input.name !== "contact_name");
        }

        setTimeout(() => {
            //Enviamos los datos a la funcion de validacion y recibimos las validaciones
            console.log(inputs, "datosCooperanteEditar")

            let datosValidados = ValidarInputs(inputs);
            //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
            setAlerta(datosValidados);
            //Obtenemos el dato de las validaciones
            const totalValidaciones = datosValidados.filter(input => input.estado !== false).map(() => { return false });

            console.log(formularioEditar.contact_name, "totalValidaciones")

            if (totalValidaciones.length < 1 || formularioEditar.contact_name == null) {
                setAlerta([initialStateInput]);
                ActualizarCooperante();
            }
        }, 1000);
    };

    const [paises, setPaises] = useState([]);

    function getPais() {

        const url = `${process.env.REACT_APP_API_URI}country/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        axios.get(url, config)
            .then(response => {

                console.log(response.data, "response");

                setPaises(response.data)


            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    function RegistrarCooperante() {
        setAlerta([initialStateInput]);
        const url = `${process.env.REACT_APP_API_URI}contributor/register`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        selectedOption.map((item) => {
            typeProgramRegistrar.push(item.value);
        })

        let dataInsertar = {
            name: formulario.name,
            type: formulario.type1,
            projects: typeProgramRegistrar,
            phone: formulario.phone,
            city: formulario.city,
            email: formulario.email,
            country: formulario.country,
            internship: formulario.internship
        };;

        if (mostrarCamposEmpresa) {
            dataInsertar.contact_name = formulario.contact_name;
        }
        console.log(dataInsertar, "Datainsertar")
        axios.post(url, dataInsertar, config)
            .then(response => {
                console.log(response, "response");
                setAlerta([initialStateInput]);
                showPopup(false, setShowModal)
                setFormulario([initialDatosFormulario])
                setSelectedOption([]);

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    getInfo();
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
        // setAlerta([initialStateInput]);
    };

    function ActualizarCooperante() {
        setAlerta([initialStateInput]);
        const idCooperante = idEditar;

        const url = `${process.env.REACT_APP_API_URI}contributor/update/${idCooperante}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        let typeProgramRegistrarEditar = [];

        selectedOptionEditar.map((item) => {
            typeProgramRegistrarEditar.push(item.value)
        });

        let dataInsertar = {
            name: formularioEditar.name,
            type: formularioEditar.type,
            projects: typeProgramRegistrarEditar,
            phone: formularioEditar.phone,
            email: formularioEditar.email,
            country: formularioEditar.country,
            city: formularioEditar.city,
            internship: formularioEditar.internship
        };

        if (mostrarCamposEmpresaEditar) {
            dataInsertar.contact_name = formularioEditar.contact_name;
        }

        console.log(dataInsertar, "Datainsertar")

        axios.put(url, dataInsertar, config)
            .then(response => {
                console.log(response, "response");
                setAlerta([initialStateInput]);
                showPopup(false, setShowModalEditar)
                setFormularioEditar([initialDatosFormulario])
                setSelectedOptionEditar([]);

                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(function () {
                    getInfo();
                });

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "¡Error! Vuelva a intentar",
                    showConfirmButton: false,
                    timer: 3500
                });
            });
    };

    var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    const ValidarInputs = (data) => {
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];

        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        //Proceso de validacion
        datosDelFormulario.filter(input => input.nombre !== "type2" && input.nombre !== "email").map((valorInput) => {
            if (valorInput.value === "" || valorInput.value === null || valorInput.value === undefined) {
                errors.push({
                    valorInput: valorInput.nombre,
                    mensaje: 'Campo requerido',
                    estado: true
                });
                return;
            }

            errors.push({
                valorInput: valorInput.nombre,
                mensaje: '',
                estado: false
            });
            return;
        });

        // datosDelFormulario.filter(input => input.nombre === "type2").map((valorInput) => {
        //     if (valorInput.nombre === "type2" && (selectedOption.length < 1 && selectedOptionEditar.length < 1)) {
        //         errors.push({
        //             valorInput: valorInput.nombre,
        //             mensaje: 'Campo requerido',
        //             estado: true
        //         });
        //         return;
        //     }

        //     errors.push({
        //         valorInput: valorInput.nombre,
        //         mensaje: '',
        //         estado: false
        //     });
        //     return;
        // });

        datosDelFormulario.filter(input => input.nombre === "email").map((valorInput) => {
            if (valorInput.nombre === "email" && !validEmail.test(valorInput.value)) {
                errors.push({
                    valorInput: valorInput.nombre,
                    mensaje: 'Ingrese un correo valido',
                    estado: true
                });
                return;
            }

            errors.push({
                valorInput: valorInput.nombre,
                mensaje: '',
                estado: false
            });
            return;
        });

        return errors;
    }

    function desactivarCooperante(event) {
        const idCooperante = event.currentTarget.id;
        console.log(idCooperante)
        Swal.fire({
            title: '¿Seguro que quieres desactivar este cooperante?',
            showCancelButton: true,
            confirmButtonText: 'Desactivar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmDesactivarCooperante() {
                    const url = `${process.env.REACT_APP_API_URI}contributor/disable/${idCooperante}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.delete(url, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Conocimientos";
                            setData([initialCooperante])
                            getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "¡Error! Vuelva a intentar",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
                confirmDesactivarCooperante();
            }

        })
    };

    function ReactivarCooperante(event) {
        const idCooperante = event.currentTarget.id;
        console.log(Cookies.get('Bearer').token)
        Swal.fire({
            title: '¿Seguro que quieres activar este cooperante?',
            showCancelButton: true,
            confirmButtonText: 'Activar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmReactivarCooperante() {
                    const url = `${process.env.REACT_APP_API_URI}contributor/enable/${idCooperante}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.put(url, {}, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Cooperantes";
                            setData([initialCooperante])
                            getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "Error",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
                confirmReactivarCooperante();
            }

        })
    };


    const [datosServidor, setDatosservidor] = useState([]);
    const [contributorType, setContributorType] = useState([]);

    async function getInfo() {
        setLoaded(false)
        setData([initialCooperante])
        const url = `${process.env.REACT_APP_API_URI}contributor/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        let session = validateSession();

        if (session !== undefined && session.role !== undefined) {
            setRole(session.role);
        }

        try {
            const resp = await axios.get(url, config);
            console.log(resp.data, "----------------Cooperantes------------");
            setData(resp.data);
            setDataSearch(resp.data);
            setAlerta([initialStateInput]);
            // filterExportData();
        } catch (err) {
            console.error(err);
            setData([]);
        }
        setLoaded(true);
    };

    async function getTypeCooperante() {
        const url = `${process.env.REACT_APP_API_URI}contributor-type/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            console.log(resp.data, "----------------Types Cooperantes------------");
            setContributorType(resp.data);

        } catch (err) {
            console.error(err);
        }
    };

    const options = [];

    async function getTypesPrograms() {
        const url = `${process.env.REACT_APP_API_URI}course-type/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            // console.log(resp.data,"especiality");
            setTypesPrograms(resp.data);

            // console.log(speciality,"------------speciality----------")
            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (loaded === false) {
            getInfo();
            getTypeCooperante();
            getPais();
            getTypesPrograms();
        }
    }, []);

    useEffect(() => {
        console.log(filterExportData());
        setDataToExport(filterExportData());
    }, [dataSearch]);

    typesPrograms.map((item) => {
        options.push({ value: item.id, label: item.name })
    })

    const [data, setData] = useState([]);

    const [dataEditar, setDataEditar] = useState([initialCooperanteEditar]);

    // console.log(data,"Data")

    let enableOrDisableAction = {
        name: "Activar/Desactivar",
        width: "180px",
        cell: (row) => loaded && <div className="flex">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input
                    onClick={(e) => {
                        row.status == 1 ? desactivarCooperante(e) : row.status == 2 && ReactivarCooperante(e)
                    }}
                    id={row.id}
                    type="checkbox"
                    className="sr-only peer"
                    checked={row.status == 1 ? true : row.status == 2 && false}
                    readOnly
                />
                <div
                    className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                ></div>
            </label>
        </div>
    };

    let columns = [
        {
            name: '#',
            width: "75px",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre',
            width: "250px",
            cell: (row) =>
                <div>
                    <Link to={'/DetalleDeLCooperante/' + row.id} className="hover:border-b-2 hover:border-blue-950">{loaded && row.name}</Link>
                </div>

        },
        {
            name: 'Correo electrónico',
            width: "250px",
            cell: (row) => <div>{loaded && row.email}</div>
        },
        {
            name: 'Ubicación',
            width: "200px",
            cell: (row) => <div>{loaded && (
                <>
                    {row.city === null ? row.country : row.city + ", " + row.country}
                </>
            )}</div>
        },
        {
            name: 'Estado',
            width: "100px",
            cell: (row) => <div>{loaded && row.status == 1 ? <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</div> : row.status == 2 && <div className="flex items-center"><div className="h-2.5 w-2.5 rounded-full bg-red-400 mr-2"></div> Inactivo</div>}</div>
        },
    ];

    if (deleteRoles.includes(role)) {
        columns.push(enableOrDisableAction);
    }

    const tableData = {
        columns,
        data
    };

    function mostrarInfoModalEditar(e) {
        let typeProgramRegistrarEditar = [];

        setFormularioEditar([initialDatosFormulario])
        setSelectedOptionEditar(null);
        setIdEditar(e.currentTarget.id)

        data.map((item) => {
            if (item.id == e.currentTarget.id) {
                console.log(item, "Obtener info edit");
                paises.map((item2) => {
                    item.country_id == item2.id
                        &&
                        setPaisSelectedEditar(item2)
                }
                )

                console.log(item.type, "item.type")

                item.city == null ? item.city = "" : item.city = item.city;

                const info = {
                    name: item.name,
                    contact_name: item.contact_name,
                    country: item.country_id,
                    city: item.city,
                    phone: item.phone,
                    email: item.email,
                    projects: item.projects,
                    type: item.type,
                    internship: item.internships,
                    type2: []
                }

                contributorType.map((element) => {
                    if (item.type == element.id) {
                        console.log(element, "Datainsertar")
                        if (element.required_contact == 1) {
                            setMostrarCamposEmpresaEditar(true);
                        } else {
                            setMostrarCamposEmpresaEditar(false);
                        }
                    }
                });

                console.log(info, "Datainsertar")

                setFormularioEditar(info);

                info.projects.map((item) => {
                    typeProgramRegistrarEditar.push(item)
                });

                setSelectedOptionEditar(typeProgramRegistrarEditar)
            }
        })

        showPopup(true, setShowModalEditar)
        setLoaded(true)
    }

    const selectValue = (filterValue, filterKey) => {
        switch (filterKey) {
            case 'internship':
                let result;
                if (filterValue == 0) {
                    result = 'No';
                } else if (filterValue == 1) {
                    result = 'Sí';
                } else {
                    result = 'No definido';
                }
                return result;
                break;
            case 'program':
                const arrayProgram = filterValue.map(item => {
                    return item.label;
                });
                return arrayProgram;
                break;
            default:
                break;
        }
    }

    const filterHeadings = (dataToFilter) => {
        return dataToFilter.map(key => {
            const { id, name, email, country, city, phone, contact_name, internship, ...rest } = key;
            let intershipYesOrNot = (internship == 0) ? 'No' : 'Si';
            return {
                ...rest,
                No: id,
                Nombre: name,
                Correo: email,
                Pais: country,
                Ofrece_pasantias: intershipYesOrNot,
                Ciudad: city,
                Contacto: phone,
                Persona_Contacto: contact_name
            }
        })
    }

    const filterExportData = () => {
        const processedData = filterExportDataKeyes(keyesExport, filterHeadings(dataSearch));
        console.log(processedData);
        return processedData;
    }

    const filters = `
        ${dataFilter.country != null ? '<th> País: ' + dataFilter.country['label'] + '</th>' : ''}
        ${dataFilter.internship != 'default' ? '<th> Pasantia: ' + selectValue(dataFilter.internship, 'internship') + '</th>' : ''}
        ${dataFilter.type != null ? '<th> Tipo: ' + dataFilter.type['label'] + '</th>' : ''}
        ${dataFilter.program.length > 0 ? '<th> Conocimientos: ' + selectValue(dataFilter.program, 'program') + '</th>' : ''}
    `;

    // Nombre, tipo, ofrece pasantias, pais, ciudad, persona de contacto, programas que ofrece, telefono, correo electronico
    const headers = `
        <th>No</th>
        <th>Nombres</th>
        <th>Tipo</th>
        <th>Correo electrónico</th>
        <th>Ofrece pasantías</th>
        <th>Ciudad</th>
        <th>Persona de contacto</th>
        <th>Programas que ofrece</th>
    `;

    const expandedComponent = ({ data }) =>
        <div className="flex py-2 px-12" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
            {updateRoles.includes(role) &&
                <button
                    id={data.id}
                    onClick={(e) => { mostrarInfoModalEditar(e)}}
                    className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 mr-2 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <FontAwesomeIcon className="pr-2" icon={faPenToSquare} /> Editar
                </button>
            }
            <Link
                to={'/DetalleDeLCooperante/' + data.id}
                className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 mr-2 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center"
            >
                <FontAwesomeIcon className="pr-2" icon={faEye} /> Ver
            </Link>
        </div>
        ;

    return (
        <div className="">
            <div className='ContainerOpenClass'>

                <div className="p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">

                        <div className="sm:flex">
                            <div className="">
                                <h1 className="text-xl sm:text-2xl font-semibold text-white">Cooperantes</h1>
                            </div>
                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                                {([1, 2].includes(role)) && (
                                    <button type="button" data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                        onClick={() => showPopup(true, setShowModal)}
                                    >
                                        <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                        Agregar
                                    </button>
                                )}
                                <ImportButton
                                    origin="contributor"
                                    info={getInfo}
                                    roles={[1, 2]}
                                />
                                {dataSearch.length > 0 &&
                                    <ExportDocument
                                        dataSearch={dataSearch}
                                        useState={useState}
                                        useRef={useRef}
                                        useEffect={useEffect}
                                        fileName={'Cooperantes'}
                                        filters={filters}
                                        headers={headers}
                                        dataToExport={dataToExport}
                                        filterExportData={filterExportData}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-6 bg-blue-950">
                    <Filters
                        data={data}
                        setDataSearch={setDataSearch}
                        getInfo={getInfo}
                        dataFilter={dataFilter}
                        setDataFilter={setDataFilter}
                        initDataFilter={initDataFilter}
                    />
                </div>
                <div className="px-6">
                    {loaded
                        ?
                        <DataTable
                            columns={columns}
                            data={dataSearch}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            responsive
                            paginationComponentOptions={paginationProps}
                            noDataComponent={<div className="my-5">No hay registros</div>}
                            expandableRows
                            expandableRowsComponent={expandedComponent}
                        />
                        :
                        <PreloaderTables />
                    }
                </div>

            </div>

            {/* Modal */}
            {showModal ? (
                <>
                    <div
                        className="block overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-full mx-auto my-6 max-w-4xl">
                            {/*content*/}
                            <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold text-blue-950">
                                        Agregar cooperante
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6">
                                    <div className="text-slate-500 text-lg leading-relaxed">
                                        <form className="w-full flex flex-wrap" onSubmit={addCooperante}>
                                            <div className="w-full p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Nombre del cooperante
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="name" value={formulario.name} onChange={ManejarEventoDeInputs} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Tipo de cooperante
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.type1} name="type1" onChange={ManejarEventoDeInputs}>
                                                        <option hidden disabled selected value={null}>[Seleccione una opción]</option>
                                                        {
                                                            contributorType.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.valorInput == "type1" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    ¿Ofrece pasantías?
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.internship} name="internship" onChange={ManejarEventoDeInputs}>
                                                        <option hidden disabled selected value={null}>[Seleccione una opción]</option>
                                                        <option value="1">Sí</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.valorInput == "internship" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    País
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.country} name="country" onChange={ManejarEventoDeInputs}>
                                                        <option hidden disabled selected value="0">[Seleccione una opción]</option>
                                                        {
                                                            paises.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.valorInput == "country" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>

                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Ciudad
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="city" value={formulario.city} onChange={ManejarEventoDeInputs} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "city" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="w-full p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Persona de contacto
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 disabled:bg-gray-400" name="contact_name" value={formulario.contact_name} onChange={ManejarEventoDeInputs} type="text" disabled={mostrarCamposEmpresa ? false : true} />
                                                {
                                                    mostrarCamposEmpresa
                                                    &&
                                                    alerta.filter(input => input.valorInput == "contact_name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="w-full p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Tipos de programa a ofrecer
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <Select
                                                        // value={formulario.type2}
                                                        isMulti
                                                        name="type2"
                                                        className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                        // classNamePrefix=""
                                                        onChange={setSelectedOption}
                                                        options={options}
                                                        placeholder={"Selecciona una opción"}
                                                        noOptionsMessage={() => 'No hay mas opciones'}
                                                    />
                                                    {/* <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.type2} name="type2" onChange={ManejarEventoDeInputs}>
                                                        <option value="0" selected>Selecciona una opción</option>
                                                            {
                                                                typesPrograms.map((item)=>
                                                                    <option value={item.id}>{item.name}</option>
                                                                )
                                                            }
                                                    </select> */}
                                                    {
                                                        alerta.filter(input => input.valorInput == "type2" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Teléfono
                                                </label>
                                                <div className="flex flex-wrap">
                                                    <div className="w-1/4">
                                                        <input
                                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 w-full"
                                                            name="phone"
                                                            value={(paisSelected.code == undefined ? "" : "+" + paisSelected.code)}
                                                            onChange={ManejarEventoDeInputs}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="w-3/4">
                                                        <ReactInputMask mask={paisSelected.phone_format} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-3/4 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-900 ml-3" name="phone" value={formulario.phone} onChange={ManejarEventoDeInputs} type="text" />
                                                    </div>
                                                </div>
                                                {
                                                    alerta.filter(input => input.valorInput == "phone" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Correo electrónico
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="email" value={formulario.email} onChange={ManejarEventoDeInputs} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "email" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>

                                            <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                <button
                                                    className="bg-blue-950 text-white active:bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                    type="submit"

                                                >
                                                    Agregar
                                                </button>

                                                <button
                                                    className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                    type="button"
                                                    onClick={() => {
                                                        showPopup(false, setShowModal);
                                                        setFormulario(initialDatosFormulario);
                                                        setAlerta([initialStateInput]);
                                                        setSelectedOption([]);
                                                    }}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showModalEditar ? (
                <>
                    <div
                        className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-full mx-auto 2xl:my-6 max-w-4xl">
                            {/*content*/}
                            <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none 2xl:mt-0 2xl:mb-0 sm:mt-14 mt-96 mb-8">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold text-blue-950">
                                        Editar cooperante
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="text-slate-500 text-lg leading-relaxed">
                                        <form className="w-full flex flex-wrap" onSubmit={editarCooperante}>
                                            <div className="w-full p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Nombre
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="name" value={formularioEditar.name} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    País
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formularioEditar.country} name="country" onChange={ManejarEventoDeInputsEditar}>
                                                        {
                                                            paises.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.valorInput == "country" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Ciudad
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="city" value={formularioEditar.city} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "city" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Tipo de cooperante
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formularioEditar.type} name="type"
                                                        onChange={
                                                            (e) => {
                                                                ManejarEventoDeInputsEditar(e);
                                                                contributorType.map((element) => {
                                                                    if (e.target.value == element.id) {
                                                                        if (element.required_contact == 1) {
                                                                            setMostrarCamposEmpresaEditar(true);
                                                                            return;
                                                                        }

                                                                        setMostrarCamposEmpresaEditar(false);
                                                                    }
                                                                });
                                                            }
                                                        }>
                                                        {
                                                            contributorType.map((item) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.valorInput == "internship" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full md:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    ¿Ofrece pasantías?
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formularioEditar.internship} name="internship" onChange={ManejarEventoDeInputsEditar}>
                                                        <option hidden disabled selected value={null}>[Seleccione una opción]</option>
                                                        <option value="1">Sí</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.valorInput == "internship" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            {
                                                mostrarCamposEmpresaEditar
                                                &&
                                                <div className="w-full p-3">
                                                    <label className="block text-blue-950 font-bold">
                                                        Persona de contacto
                                                    </label>
                                                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="contact_name" value={formularioEditar.contact_name} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                    {
                                                        alerta.filter(input => input.valorInput == "contact_name" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>

                                                        ))
                                                    }
                                                </div>
                                            }
                                            <div className="w-full p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Tipos de programa a ofrecer
                                                </label>
                                                <div className="mb-2 xl:w-100">
                                                    <Select
                                                        value={selectedOptionEditar}
                                                        isMulti
                                                        name="type2"
                                                        className='appearance-none border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                        // classNamePrefix=""
                                                        onChange={setSelectedOptionEditar}
                                                        options={options}
                                                        placeholder={"Selecciona una opción"}
                                                        noOptionsMessage={() => 'No hay mas opciones'}
                                                    />
                                                    {/* <select data-te-select-init className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.type2} name="type2" onChange={ManejarEventoDeInputs}>
                                                        <option value="0" selected>Selecciona una opción</option>
                                                            {
                                                                typesPrograms.map((item)=>
                                                                    <option value={item.id}>{item.name}</option>
                                                                )
                                                            }
                                                    </select> */}
                                                    {
                                                        alerta.filter(input => input.valorInput == "type2" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                        </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Teléfono
                                                </label>
                                                <div className="flex">
                                                    <input disabled className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-900 w-1/3" value={(paisSelectedEditar.code == undefined ? "" : "+" + paisSelectedEditar.code)} type="text" />
                                                    <ReactInputMask mask={paisSelectedEditar.phone_format} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="phone" value={formularioEditar.phone} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                </div>
                                                {
                                                    alerta.filter(input => input.valorInput == "phone" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                            <div className="w-full sm:w-1/2 p-3">
                                                <label className="block text-blue-950 font-bold">
                                                    Correo electrónico
                                                </label>
                                                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" name="email" value={formularioEditar.email} onChange={ManejarEventoDeInputsEditar} type="text" />
                                                {
                                                    alerta.filter(input => input.valorInput == "email" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>

                                            <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                                                <button
                                                    className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                    type="submit"

                                                >
                                                    Editar
                                                </button>

                                                <button
                                                    className="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                    type="button"
                                                    onClick={() => {
                                                        showPopup(false, setShowModalEditar);
                                                        setAlerta([initialStateInput]);
                                                        setSelectedOptionEditar([]);
                                                    }}
                                                >
                                                    Cerrar
                                                </button>
                                            </div>


                                        </form>
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

        </div>
    )

}
export default Cooperantes;