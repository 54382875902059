import { Study } from './Study';
const ListStudies = (props) => {
    const params = {
        setShowModalEditStudy: props.setShowModalEditStudy,
        setIdEditStudy: props.setIdEditStudy,
        loadedStudies: props.loadedStudies,
        getStudies: props.getStudies,
        getOtherStudies: props.getOtherStudies,
        type: props.type
    }
    const { columns, paginationProps } = Study(params);
    const DataTable = props.DataTable;
    const DataTableExtensions = props.DataTableExtensions;
    let data = (props.dataStudies.length > 0) ? props.dataStudies : [];
    let dataOther = (props.dataOtherStudies.length > 0) ? props.dataOtherStudies : [];
    data = data.concat(dataOther);
    const tableData = { columns, data };

    return (
        <div className="bg-white w-full p-5 mb-3">
            <DataTableExtensions
                {...tableData}
                filterPlaceholder={"Buscar"}
                print={false}
                export={false}
            >
                <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    paginationComponentOptions={paginationProps}
                    responsive
                    noDataComponent={<span className="font-bold text-black my-5">No hay registros</span>}
                />
            </DataTableExtensions>
        </div>
    )
}
export default ListStudies;