import Swal from "sweetalert2";
import Cookies from 'js-cookie';
// import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ImgCerrarSesion from '../../assets/icono-dashboard/cerrar-sesion-2.png';

import { validateSession } from '../utils/util';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import menu from '../../data/menu';
import ProfileNav from './ProfileNav';

// import SideBarDropdown from '../../layouts/SideBarDropdown';
import SidebarNav from '../../layouts/SideBarNav';

import Brand from '../../assets/ticonx-brand.png';

import { getOrDeleteRequest } from "../utils/util";

const Navbar = (props) => {
	const { width, height } = useWindowDimensions();

	const [loaded, setLoaded] = useState(false);
	const [mostrarMenu, setMostrarMenu] = useState(false);
	const [role, setRole] = useState(undefined);
	// const [windowHeight, setWindowHeight] = useState(height);

	const navigate = useNavigate();
	
	const cerrarSession = () => {
		Swal.fire({
			title: "¿Seguro que quieres cerrar la sesión?",
			showCancelButton: true,
			confirmButtonText: 'Si, cerrar sesión',
			cancelButtonText:"Cancelar",
			confirmButtonColor: "#07285B"
		}).then(async (result) => {
			if (result.isConfirmed) {
				let response = await getOrDeleteRequest(
					`auth/logout`, 
					'GET'
				);

				if (response.type === "error") {
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Sesión cerrada con éxito',
						showConfirmButton: false,
						timer: 1500
					}).then(function () {
						Cookies.remove('Bearer');
						Cookies.remove('prevLocation');
						navigate('/');
					})

					return;
				}


				Swal.fire({
					position: 'center',
					icon: 'success',
					title: 'Sesión cerrada con éxito',
					showConfirmButton: false,
					timer: 1500
				}).then(function () {
					// delete cookies
					Cookies.remove('Bearer');
					Cookies.remove('prevLocation');
					window.location.href = "/";
				});
				
			} else if (result.isDenied) {
				Swal.fire('Changes are not saved', '', 'info')
			}
		})
	};

	const getRole = useCallback(() => {
		let session = validateSession();
		
		if (session !== undefined && session.role !== undefined) {
			props.role(session.role);
			setRole(session.role);
		}

		setLoaded(true);
	}, []);

	// Funcion que se ejecuta cuando se redimensiona la pantalla
	const handleResize = useCallback(() => {
		console.log('resize: ', height);

		// setWindowHeight(window.innerHeight);
	}, [height]);

	useEffect(() => {
		if (loaded === false) {
			getRole();
		}

		window.addEventListener('resize', handleResize);
	}, [getRole]);


	return (
		<>
			<nav className="bg-blue-950 fixed z-50 w-full">
				<div className="px-3 py-3 lg:px-5 lg:pl-3">
				<div className="flex items-center justify-between flex-wrap">
					<div className="flex items-center w-full md:w-1/2">
						<button id="toggleSidebarMobile" onClick={function () {
							(mostrarMenu == false ? setMostrarMenu(true) : setMostrarMenu(false))
						}} aria-expanded="true" aria-controls="sidebar" className="lg:hidden mr-2 text-white cursor-pointer p-2 focus:bg-blue-900 focus:ring-2 focus:ring-blue-900 rounded">
							<svg id="toggleSidebarMobileHamburger" className={`w-6 h-6 ${mostrarMenu == false ? "block" : "hidden"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
							<svg id="toggleSidebarMobileClose" className={`w-6 h-6 ${mostrarMenu == false ? "hidden" : "block"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
						</button>
						<div className="w-full flex justify-center md:justify-start">
							<img src={Brand} className="w-1/3 md:w-1/4" alt="" />
							{/* <a href="" className="text-xl font-bold lg:ml-2.5">
							</a> */}
						</div>
					</div>
					<div className="flex items-center justify-start md:justify-end w-full md:w-1/2 mt-5 md:mt-0">
						<ProfileNav />
					</div>
				</div>
				</div>
			</nav>
			<aside id="sidebar" className={`fixed z-20 h-full top-0 left-0 pt-28 md:pt-20 lg:flex flex-shrink-0 flex-col w-64 transition-width duration-75 ${mostrarMenu == false ? "hidden" : "flex"}`} aria-label="Sidebar">
				<div className="relative flex-1 flex flex-col min-h-0 bg-white pt-0">
					<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-blue-950">
						<div className="flex-1 px-3 divide-y space-y-1 bg-blue-950">
						<ul className="space-y-2 pb-2">
							<li>
								<form action="#" method="GET" className="lg:hidden">
									<label htmlFor="mobile-search" className="sr-only">Search</label>
									<div className="relative">
									<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
										<svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
									</div>
									<input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-10 p-2.5" placeholder="Search" />
									</div>
								</form>
							</li>
							<div className="sidebar-nav">
								<div id="sidebar-container" style={{ height: (width >= 1024) ? `${height - 200}px` : 'auto' }}>
									<SidebarNav 
										menu={ menu } 
										role={ role } 
										showMenu={ mostrarMenu }
										setShowMenu={ setMostrarMenu }
									/>
								</div>
								<li>
									<div className="bg-white h-[2px] my-4" />
								</li>
								<li>
									<a onClick={cerrarSession} className="text-base text-white font-normal rounded-lg flex items-center p-2 group btn-logout">
										<img src={ImgCerrarSesion} className="h-[25px] mr-4" alt="" />
										<span className="ml-3 flex-1 whitespace-nowrap">Cerrar sesión</span>
									</a>
								</li>
							</div>
						</ul>
						</div>
					</div>
				</div>
			</aside>
			<div className={`bg-gray-900 opacity-50 fixed lg:hidden inset-0 z-10 ${mostrarMenu == false ? "hidden-sombra" : "flex-sombra"}`} 
			onClick={function () {
				(mostrarMenu == false ? setMostrarMenu(true) : setMostrarMenu(false))
			}}
			>
				
			</div> 
		</>
	);
}

export default Navbar;
