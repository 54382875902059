import axios from "axios";
import Cookies from "js-cookie";
// import Swal from "sweetalert2";

export const deleteRoles = [1, 2];
export const createRoles = [1, 2, 3];
export const updateRoles = [1, 2];

export const paginationProps = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
};

export const showPopup = (getter, setter) => {
    console.log(getter);
    if (getter) {
        document.body.classList.add('overflow-hidden');
    } else {
        document.body.classList.remove('overflow-hidden');
    }

    setter(getter);
};

export const validateSession = () => {
    let token = Cookies.get('Bearer');

    if (token === undefined || token === null) {
        window.location = "/";

        return null;
    }

    let parsedToken = JSON.parse(token);

    return {
        token: parsedToken.token,
        role: parsedToken.role,
        type: parsedToken.type,
        data: parsedToken.data,
    };
};

export const getOrDeleteRequest = async (action, method, responseType = null, auth = true) => {
    let uri = `${process.env.REACT_APP_API_URI}${action}`;

    let config = {
        method: method,
        url: uri,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };

    if (auth) {
        let session = validateSession();

        config.headers['ApiToken'] = `${session.type} ${session.token}`;

        if (!uri.includes('?')) {
            uri += `?account=${session.data}`;
        } else {
            uri += `&account=${session.data}`;
        }

        config.url = uri;
    }

    if (responseType !== null) {
        config.responseType = responseType;
    }

    try {
        const response = await axios(config);

        if (Array.isArray(response.data)) {
            return response.data;
        }

        return { ...response.data, type: 'success' };
    } catch (error) {
        console.error(error);

        return { ...error.response.data, type: 'error' };
    }
}

export const postOrPutRequest = async (action, method, data, auth = true) => {
    let config = {
        headers: {
            'Content-Type': (method.toLowerCase() === 'post') ? 'multipart/form-data' : 'application/json',
            'Accept': 'application/json',
        }
    };

    let uri = `${process.env.REACT_APP_API_URI}${action}`;

    if (auth) {
        let session = validateSession();

        config.headers['ApiToken'] = `${session.type} ${session.token}`;

        if (!uri.includes('?')) {
            uri += `?account=${session.data}`;
        } else {
            uri += `&account=${session.data}`;
        }
    }

    try {
        const response = await axios({
            method: method,
            url: uri,
            data: data,
            headers: config.headers,
        });

        return { ...response.data, type: 'success' };
    } catch (error) {
        console.error(error);

        // Agregando tipo al objeto error
        // let errorResponse = { ...error.response.data, type: 'error' };

        return { ...error.response.data, type: 'error' };
    }
}

export const downloadFileRequest = async (action) => {
    let session = validateSession();

    let uri = `${process.env.REACT_APP_API_URI}${action}`;

    let config = {
        method: 'GET',
        url: uri,
        headers: {
            //     'Content-Type': 'application/json',
            //     'Accept': 'application/json',
        },
        responseType: 'blob',
    };

    config.headers['Authorization'] = `${session.type} ${session.token}`;

    try {
        const response = await axios(config);

        return response.data;
    } catch (error) {
        console.error(error);

        return { ...error.response.data, type: 'error' };
    }
};

export const months = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre'
}

export const calculateAges = (data) => {
    data.map((key)=>{
        const dataToday = new Date();
        const bDate = new Date(key.birth_date);
        const difference = dataToday - bDate;
        const age = Math.floor(difference / (365.25 * 24 * 60 * 60 * 1000));
        return key.age = age;
    });
    return data;
}
