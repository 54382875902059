import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import PreloaderTables from "../componentes/PreloaderTables/PreloaderTables";

import { paginationProps } from "../componentes/utils/util";

const MyDataTable = ({ data, columns, loaded }) => {
    const tableData = {
        columns,
        data,
    };

    return (
        <DataTableExtensions {...tableData} filterPlaceholder={"Buscar"} print={false} export={false}>
            {
                loaded
                ?
                <DataTable
                    columns={columns}
                    data={data.length > 0 && data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                    responsive
                    paginationComponentOptions={paginationProps}
                    noDataComponent={<div className="my-5">Sin registros</div>}
                />
                :
                <PreloaderTables />
            }
        </DataTableExtensions>
    );    
};

export default MyDataTable;
