import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ExportTable from "./ExportTable";

const ExportDocument = (props) => {
    var date = new Date();
    const tableRef = props.useRef(null);

    return (<div className="w-[47%] md:w-[30%] mx-1">
        <DownloadTableExcel
            filename={ props.fileName+'_'+date.toLocaleDateString()}
            sheet={ props.fileName }
            currentTableRef={tableRef.current}
        >
            <button
                type="button"
                className="w-full mx-1 text-white transition-colors bg-green-700 focus:ring-4 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center hover:bg-green-800"
            >
                <FontAwesomeIcon icon={faFileArrowDown} className="-ml-1 mr-2 h-6 w-6" />
                Exportar
            </button>
        </DownloadTableExcel>
        <div className="hidden">
            <ExportTable 
                ref={ tableRef } 
                useEffect={ props.useEffect }
                useState={ props.useState }
                filters = { props.filters }
                headers = { props.headers }
                dataToExport = { props.dataToExport }
            />
        </div>
    </div>)
}
export default ExportDocument;
