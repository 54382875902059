import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { faCloudArrowUp, faFile, faFileAlt, faFilePdf, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { showPopup, getOrDeleteRequest, downloadFileRequest } from "../utils/util";
import Select from 'react-select';
import { differenceInYears, set } from "date-fns";
import ReactInputMask from "react-input-mask";
import BackButton from "../BackButton";
import SectionProfile from "../../layouts/SectionProfile";
import PreloaderComponentSectionProfile from "./Preloaders/PreloaderComponentSectionProfile";
import ModalAddConocimiento from "../conocimientos/ModalAddConocimiento";
import ModalViewDocument from './modals/ModalViewDocument';
import ModalCropImage from "./modals/ModalCropImage";
import { validateData } from "../utils/validateData";
import { utilObject } from "../utils/utilObject";
import { FileUploader } from "react-drag-drop-files";
import ModalResizeImage from "./modals/ModalResizeImage";
import ModalAddSoftSkill from "./modals/ModalAddSoftSkill";
import { SoftSkillsLogic } from "./SoftSkillsLogic";
import AddLanguageModal from "../Idiomas/AddLanguageModal";
import Wallpaper from '../../assets/wallpaper-profile.jpg';
import UserMale from '../../assets/images/user-male.png';
import UserFemale from '../../assets/images/user-female.png';

const EditarEstudiante = () => {
    const initFileNames = {
        document_front: '',
        document_back: '',
        urlDocumentFront: '',
        urlDocumentBack: '',
        photo: '',
        urlPhoto: '',
        urlLetter: '',
        letter: '',
    }

    const [showModal, setShowModal] = React.useState(false);
    const [showModalEditar, setShowModalEditar] = React.useState(false);
    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = useState(false);
    const [distritos, setDistritos] = useState([]);
    const [fileNameFoto, setFileNameFoto] = useState('');
    const [fileFoto, setFileFoto] = useState(null);
    const [fileFotoError, setFileFotoError] = useState(false);
    const [fileNameCarta, setFileNameCarta] = useState('');
    const [fileCarta, setFileCarta] = useState(null);
    const [fileCartaError, setFileCartaError] = useState(false);
    const [showModalAddConocimientos, setShowModalAddConocimientos] = React.useState(false);
    const [showFormContactEmergency, setShowFormContactEmergency] = useState(false);
    const [fileNames, setFileNames] = useState(initFileNames);
    const [editDocument, setEditDocument] = useState(false);
    const [showModalViewDocument, setShowModalViewDocument] = useState(false);

    const [showModalCropImage, setShowModalCropImage] = useState(false);
    const [showModalResizeImageFront, setShowModalResizeImageFront] = useState(false);
    const [showModalResizeImageBack, setShowModalResizeImageBack] = useState(false);

    const [showModalAddLanguage, setShowModalAddLanguage] = useState(false);

    const navigate = useNavigate();

    const idEditar = useParams("id").id;

    const initialDatosFormulario = {
        names: "",
        lastnames: '',
        email: '',
        phone: '',
        alternative_phone: "",
        birth_date: '',
        gender: '',
        address: '',
        city_id: '',
        dui: '',
        nit: '',
        knowledge: [],
        languages: [],
        currently_studying: '',
        study_level: '',
        currently_working: '',
        time_availability: '',
        family_income: '',
        family_members: '',
        work_area: '',
        work_position: '',
        work_salary: '',
        work_comment: '',
        employer: '',
        photo: '',
        letter: '',
        document_front: '',
        document_back: '',
        authorization_image: '',
    };

    const initialEstudiante = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(initialDatosFormulario);

    const [alerta, setAlerta] = useState([initialStateInput]);

    const [selectedOptionConocimientos, setSelectedOptionConocimientos] = useState([]);
    const [selectedOptionSoftSkills, setSelectedOptionSoftSkills] = useState([]);
    const [selectedOptionLenguajes, setSelectedOptionLenguajes] = useState([]);
    const [authorization, setAuthorization] = useState(false);

    const [mayorDeEdad, setMayorDeEdad] = useState(false);

    const [mostrarDui, setMostrarDui] = useState(false);
    const [mostrarNit, setMostrarNit] = useState(false);

    const [mostrarDuiConNit, setMostrarDuiConNit] = useState(false);

    const [DUI, setDUI] = useState('');
    const [NIT, setNIT] = useState('');

    const [duiExist, setDuiExist] = useState(false);

    const options = [];
    const optionsSoftSkills = [];
    const optionsLanguages = [];

    const [conocimientos, setConocimientos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [languages, setLanguages] = useState([]);

    function esMayorDeEdad(fechaNacimiento) {
        const fechaActual = new Date();
        const [anio, mes, dia] = fechaNacimiento.split('-');
        const fechaNacimientoObj = new Date(anio, mes - 1, dia);

        const edad = differenceInYears(fechaActual, fechaNacimientoObj);

        return edad >= 18;
    }

    const vaciarFileFoto = () => {
        setFileFoto(null);
        setFileNameFoto("");
        setFileNames({ ...fileNames, photo: '' });
        setFormulario({ ...formulario, photo: '' });
    }

    const vaciarFileLetter = () => {
        setFileCarta(null);
        setFileNameCarta("");
        setFileNames({ ...fileNames, letter: '' });
        setFormulario({ ...formulario, letter: '' });
    }

    const vaciarFileDocumentFront = () => {
        setFileNames({ ...fileNames, document_front: '', urlDocumentFront: ''});
        setFormulario({ ...formulario, document_front: '' });
    }

    const vaciarFileDocumentBack = () => {
        setFileNames({ ...fileNames, document_back: '', urlDocumentBack: ''});
        setFormulario({ ...formulario, document_back: '' });
    }

    async function getInfoIdiomas() {
        setLoaded(false)
        const url = `${process.env.REACT_APP_API_URI}language/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setLanguages(resp.data);

        } catch (err) {
            console.error(err);
        }
    };


    async function getConocimientos() {
        const url = `${process.env.REACT_APP_API_URI}knowledge/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setConocimientos(resp.data)

        } catch (err) {
            console.error(err);
        }
    };

    async function getInfoEstudiante() {
        const url = `${process.env.REACT_APP_API_URI}student/find/${idEditar}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            console.log(resp.data.baseData, "res get info estudiante")

            // Cambiamos el valor numerico del authorization a booleano
            resp.data.baseData = { ...resp.data.baseData, authorization: resp.data.baseData.authorization == 1 ? true : false };

            if (resp.data.baseData.contact_info !== null) {
                setShowFormContactEmergency(true);
            }

            if (resp.data.baseData.photo !== null) {
                // setFileNameFoto(resp.data.photo);   
                setFileNames({ ...fileNames, photo: "Imagen actual" });
            }

            if (resp.data.baseData.authorization_image !== null || resp.data.baseData.authorization_image !== undefined) {
                if (resp.data.baseData.authorization_image == 1) {
                    setAuthorization(true);
                } else {
                    setAuthorization(false);
                }
            }

            if (resp.data.baseData.dui !== null) {
                setDUI(resp.data.baseData.dui);
            }

            if (resp.data.baseData.nit !== null) {
                setNIT(resp.data.baseData.nit);
            }

            if (resp.data.baseData.dui !== null && resp.data.baseData.dui !== undefined && resp.data.baseData.dui !== '') {
                setDuiExist(true);
            }

            if (resp.data.baseData.photo !== null) {
                let base64;

                // Transformar una imagen en formato binario a base64 ya sea jpg, jpeg o png
                let image = new Image();
                image.src = `data:image/png;base64,${resp.data.baseData.photo}`;
                image.onload = function () {
                    let canvas = document.createElement('canvas');
                    canvas.width = this.naturalWidth;
                    canvas.height = this.naturalHeight;

                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(this, 0, 0);

                    base64 = canvas.toDataURL('image/jpeg');
                };

                console.log(base64, "base64")
            }

            delete resp.data.baseData.state;
            console.log(resp.data.baseData);
            setFormulario(resp.data.baseData);

            getMunicipios(resp.data.baseData.state_id);
            getDistrict(resp.data.baseData.city_id);

            setSelectedOptionConocimientos(resp.data.baseData.knowledge);
            setSelectedOptionLenguajes(resp.data.baseData.languages);
            setSelectedOptionSoftSkills(resp.data.baseData.soft_skills);

            if (esMayorDeEdad(resp.data.baseData.birth_date) && resp.data.baseData.dui === null && resp.data.baseData.nit !== undefined && resp.data.baseData.nit !== '') {
                setMostrarDuiConNit(true)
            } else if (esMayorDeEdad(resp.data.baseData.birth_date) && resp.data.baseData.dui !== null) {
                setMostrarDui(true)
            } else {
                setMostrarNit(true)
            }

            if (resp.data.baseData.contact_info === null) {
                formulario.contact_info = initialDatosFormulario.contact_info;
            }

            setLoaded(true);
        } catch (err) {
            console.error(err);
        }
    };

    async function getMunicipios(e) {
        // Si el id del departamento es 0 o null, entonces no se ha seleccionado ningun departamento
        if (e == 0 || e == null) {
            setMunicipios([]);
            return;
        }

        const url = `${process.env.REACT_APP_API_URI}city/filter/${e}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setMunicipios(resp.data);

        } catch (err) {
            console.error(err);
        }
    };

    const [departamentos, setDepartamentos] = useState([]);

    async function getDepartamentos() {
        const url = `${process.env.REACT_APP_API_URI}state/index`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setDepartamentos(resp.data)

            // setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    let arrayIdesLanguages = [];
    let arrayIdesKnowledges = [];
    let arraySoftSkills = [];

    async function getDistrict(e) {
        // Si el id del distrito es 0 o null, entonces no se ha seleccionado ningun municipio
        if (e == 0 || e == null) {
            setDistritos([]);
            return;
        }

        const url = `${process.env.REACT_APP_API_URI}district/filter/${e}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            console.log(resp.data, "res get district")

            setDistritos(resp.data);

            // setLoaded(true)

            // setFormulario(resp.data)

            // getMunicipios(resp.data.city_id)

            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    const [softSkills, setSoftSkills] = useState([]);

    async function getSoftSkills() {
        const url = `${process.env.REACT_APP_API_URI}soft-skill/filter`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);

            setSoftSkills(resp.data)

            console.log(resp.data, "Respuesta")

            // setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (loaded == false) {
            getInfoEstudiante();
            getConocimientos();
            getInfoIdiomas();
            getDepartamentos();
            getSoftSkills();
        }
    }, []);

    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {

        // a propiedad target del event nos permitira obtener los valores        
        const name = event.target.name;
        const value = event.target.value;

        if (name == "dui") {
            setDUI(value);
        }

        if (name == "nit") {
            setNIT(value);
        }

        if (name == "department") {
            formulario.state_id = value;
            getMunicipios(value)
        }

        if (name == "city_id") {
            getDistrict(value);
        }

        if (name == "district") {
            formulario.district_id = value;
        }

        // actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const addEstudiante = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento
        // console.log(<span className="text-red-500">Hola</span>);

        if (formulario.dui !== null && formulario.dui !== undefined && formulario.dui !== '') {
            console.log(formulario.dui, "DUI")
            // Si tiene guion se lo quitamos
            let duiTemp = formulario.dui;

            if (duiTemp.includes('-')) {
                duiTemp = duiTemp.replace('-', '');
            }

            //Ahora le ponemos el guion en la posición correcta que seria antes del ultimo digito
            duiTemp = duiTemp.slice(0, -1) + '-' + duiTemp.slice(-1);

            setDUI(duiTemp);
        }

        if (formulario.nit !== null && formulario.nit !== undefined && formulario.nit !== '') {

            // Si tiene guion se lo quitamos
            let nitTemp = formulario.nit;

            if (nitTemp.includes('-')) {
                nitTemp = nitTemp.replace('-', '');
            }

            // console.log(nitTemp.slice(10, 13), "NITdvsdgdfsgfds")

            //Poner guiones en este formato 9999-999999-999-9
            nitTemp = nitTemp.slice(0, 4) + '-' + nitTemp.slice(4, 10) + '-' + nitTemp.slice(10, 13) + '-' + nitTemp.slice(-1);

            setNIT(nitTemp);
        }

        const deleteItems = ["experience", "psychosocial", "active_in_training", "code", "alternative_phone", "currently_working", "currently_studying", "commitment_letter", "active_in_internship", "soft_skills", "programs", "training", "other_training", "letter_start_date", "letter_end_date", "photo_type", "internships", "contact_info", "knowledge", "all_knowledge_list", "all_knowledge_pdf", "knowledge_pdf", "specialities", "authorization_image", "work_comment", "work_salary", "created_at", "updated_at", "register", "socioeconomic_id", "socioeconomic", "socioeconomic_history", "complete_external_profile"];

        // if (selectedOptionLenguajes.length < 1) {
        //     formulario.languages = [];
        // }

        // if (selectedOptionConocimientos.length < 1) {
        //     formulario.knowledge = [];
        // }

        // if (selectedOptionSoftSkills.length < 1) {
        //     formulario.soft_skills = [];
        // }

        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        if (mostrarDuiConNit) {
            deleteItems.push("dui");
        } else if (mostrarDui) {
            deleteItems.push("nit");
        } else if (mostrarNit) {
            deleteItems.push("dui");
        }

        if (showFormContactEmergency) {
            deleteItems.push("contact_email");
        } else {
            deleteItems.push("contact_name");
            deleteItems.push("contact_lastname");
            deleteItems.push("contact_phone");
            deleteItems.push("contact_relationship");
            deleteItems.push("contact_email");
        }

        if (formulario.currently_working == 0) {
            deleteItems.push("work_area");
            deleteItems.push("work_position");
            deleteItems.push("employer");
            deleteItems.push("work_salary");
            // deleteItems.push("work_comment");
        }

        // if (selectedOptionLenguajes.length > 0) {
        //     deleteItems.push("languages");
        // }

        if (formulario.family_income == 0) {
            inputs.filter((input) => {
                if (input.name == "family_income") {
                    input.value = 0;
                }
            });
        }

        if (editarFoto === false && fileFoto !== "") {
            deleteItems.push("photo");
        }

        if (editarCarta === false && fileCarta !== "") {
            deleteItems.push("letter");
        }

        if (editDocument === false) {
            deleteItems.push("document_front");
        }

        if (editDocument === false) {
            deleteItems.push("document_back");
        }

        console.log(fileNames, "fileNames")

        console.log(formulario, "formulario")

        console.log(inputs, "Inputs")

        inputs = utilObject(inputs, deleteItems);
        console.log(inputs, "Inputs de nuevo");
        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = validateData(inputs);

        console.log(datosValidados, "Datos validados");

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario

        setAlerta(datosValidados);

        //Obtenemos el dato de las validaciones
        let totalValidaciones = datosValidados.filter(input => input.status !== false).map(() => { return false });

        console.log('Total de validaciones', totalValidaciones.length);
        // setFormulario(formulario);
        // return;
        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length < 1) {
            console.log("Enviar al servidor");

            // selectedOptionConocimientos.map((item) => {
            //     arrayIdesKnowledges.push(item.value)
            // })

            // selectedOptionLenguajes.map((item) => {
            //     arrayIdesLanguages.push(item.value)
            // })

            // selectedOptionSoftSkills.map((item) => {
            //     arraySoftSkills.push(item.value)
            // })
            // console.log(formulario);
            ActualizarEstudiante();
        };

    };


    function ActualizarEstudiante() {

        const url = `${process.env.REACT_APP_API_URI}student/update/${idEditar}?_method=PUT`;

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        let dataInsertar = {
            id: idEditar,
            names: formulario.names,
            lastnames: formulario.lastnames,
            email: formulario.email,
            phone: formulario.phone,
            alternative_phone: formulario.alternative_phone,
            birth_date: formulario.birth_date,
            // gender: formulario.gender,
            // address: formulario.address,
            // district: formulario.district_id,
            // city: parseInt(formulario.city_id),
            currently_studying: formulario.currently_studying,
            study_level: formulario.study_level,
            currently_working: formulario.currently_working,
            time_availability: formulario.time_availability,
            family_income: formulario.family_income,
            family_members: formulario.family_members,
            // knowledge: arrayIdesKnowledges,
            // languages: arrayIdesLanguages,
            contact_name: formulario.contact_name,
            contact_lastname: formulario.contact_lastname,
            contact_phone: formulario.contact_phone,
            contact_email: formulario.contact_email,
            contact_relationship: formulario.contact_relationship,
            // soft_skills: arraySoftSkills,
            work_comment: formulario.work_comment,
            authorization_image: authorization ? 1 : 0,
        };

        console.log(mostrarDuiConNit && DUI !== '', "Mostrar DUI 1")
        console.log(mostrarDui && DUI !== '' && DUI !== undefined && DUI !== null, "Mostrar DUI 2")

        if (showFormContactEmergency) {
            dataInsertar.contact_name = formulario.contact_name;
            dataInsertar.contact_lastname = formulario.contact_lastname;
            dataInsertar.contact_phone = formulario.contact_phone;
            dataInsertar.contact_email = formulario.contact_email;
            dataInsertar.contact_relationship = formulario.contact_relationship;
        }

        if (mostrarDuiConNit && DUI !== '') {
            dataInsertar.dui = DUI;
        } else {
            dataInsertar.nit = NIT;
        }

        if (mostrarDui && DUI !== '' && DUI !== undefined && DUI !== null) {
            dataInsertar.dui = DUI;
        }

        if (mostrarNit && NIT !== '' && NIT !== undefined && NIT !== null) {
            dataInsertar.nit = formulario.nit;
        }

        if (editarFoto === true && formulario.photo !== null && formulario.photo !== '') {
            dataInsertar.photo = formulario.photo;
        }

        if (editarCarta === true && fileCarta !== null) {
            dataInsertar.letter = fileCarta;
        }

        if (dataInsertar.currently_working == "1") {
            dataInsertar.employer = formulario.employer;
            dataInsertar.work_position = formulario.work_position;
            dataInsertar.work_area = formulario.work_area;
            dataInsertar.work_salary = formulario.work_salary;
        }

        if (editDocument === true && fileNames.document_front !== '') {
            dataInsertar.document_front = formulario.document_front;
        }

        if (editDocument === true && fileNames.document_back !== '') {
            dataInsertar.document_back = formulario.document_back;
        }

        console.log(dataInsertar, "DataActualizar");
        // return;
        axios.post(url, dataInsertar, config)
            .then(response => {
                console.log(response, "response");
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                }).then(() => {
                    setNIT(dataInsertar.nit);
                })
            }).catch(err => {
                console.log(err);
                if (esMayorDeEdad(formulario.birth_date) === false && duiExist) {
                    MySwal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Advertencia',
                        text: 'La fecha de nacimiento no corresponde a un mayor de edad y ya posee un DUI registrado. Por favor, verifique la fecha de nacimiento.',
                        showConfirmButton: false,
                    });
                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 3500
                    });
                }
            });
    };

    function isNumeric(input) {
        return !isNaN(input) && !isNaN(parseFloat(input));
    }

    if (conocimientos.length > 0) {
        conocimientos.map((item) => {
            options.push({ value: item.id, label: item.name })
        })
    }

    if (languages.length > 0) {
        languages.map((item) => {
            optionsLanguages.push({ value: item.id, label: item.name })
        })
    }

    if (softSkills.length > 0) {
        softSkills.map((item) => {
            optionsSoftSkills.push({ value: item.id, label: item.skill_name })
        })
    }

    const handleFileFoto = (e) => {
        setFileFoto(e.target.files[0]);
        setFileNameFoto(e.target.files[0].name);
        setFileFotoError(false);
    }

    const handleFileCarta = (e) => {
        console.log(e, "e")
        // Verificar si el archivo es un pdf, doc, docx, odt, jpg, jpeg o png 
        if (e.type === "application/pdf" || e.type === "application/msword" || e.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || e.type === "application/vnd.oasis.opendocument.text" || e.type === "image/jpeg" || e.type === "image/png" || e.type === "image/jpg") {
            //Verificar que el tamaño del archivo sea menor a 5MB
            if (e.size <= 5000000) {
                setFileCarta(e);
                setFileNameCarta(e.name);
                setFileCartaError(false);
                setFormulario({ ...formulario, letter: e });
                setFileNames({ ...fileNames, urlLetter: URL.createObjectURL(e), letter: e.name });
            } else {
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'El archivo debe ser menor a 5MB',
                    showConfirmButton: false,
                    timer: 3500
                });

                setFormulario({ ...formulario, letter: '' });
                setFileNames({ ...fileNames, letter: '', urlLetter: '' });
            }
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'El archivo debe ser un pdf, doc, docx, odt, jpg, jpeg o png',
                showConfirmButton: false,
                timer: 3500
            });
        }
    }

    const [editarFoto, setEditarFoto] = useState(false);
    const [editarCarta, setEditarCarta] = useState(false);

    const handleSwitchFoto = (e) => {
        if (e.target.checked) {
            setEditarFoto(true);
        } else {
            setEditarFoto(false);
        }
    }

    const handleSwitchDocument = ({ target: { checked } }) => {
        checked ? setEditDocument(true) : setEditDocument(false);
    }

    const handleSwitchCarta = (e) => {
        if (e.target.checked) {
            setEditarCarta(true);
        } else {
            setEditarCarta(false);
        }
    }

    const handleSwitchContactInfo = (e) => {
        if (e.target.checked) {
            setShowFormContactEmergency(true);
            setFormulario({
                ...formulario,
                contact_info: {
                    contact_name: "",
                    contact_lastname: "",
                    contact_phone: "",
                    contact_email: "",
                    contact_relationship: "",
                }
            })
        } else {
            setShowFormContactEmergency(false);
        }
    }

    const handleChangeFoto = (e) => {
        //Verificar si el archivo es una imagen y que la imagen sea mayor a 1000x1000
        if (e.type === "image/jpeg" || e.type === "image/png" || e.type === "image/jpg") {
            //Verificar que el ancho y alto de la imagen sea mayor a 1000x1000 
            const image = new Image();
            image.src = URL.createObjectURL(e);
            image.onload = function () {
                if (this.width >= 1000 && this.height >= 1000) {
                    setFormulario({ ...formulario, photo: e });
                    setFileNames({ ...fileNames, urlPhoto: URL.createObjectURL(e), photo: e.name });
                    showPopup(true, setShowModalCropImage);
                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'La imagen debe ser mayor a 1000x1000',
                        showConfirmButton: false,
                        timer: 3500
                    });

                    setFormulario({ ...formulario, photo: '' });
                    setFileNames({ ...fileNames, photo: '', urlPhoto: '' });
                }
            }
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'El archivo debe ser una imagen',
                showConfirmButton: false,
                timer: 3500
            });
        }
    }

    const handleFileDocumentFront = (e) => {
        //Verificar si el archivo es una imagen y que la imagen sea mayor a 1000x1000
        if (e.type === "image/jpeg" || e.type === "image/png" || e.type === "image/jpg") {
            setFormulario({ ...formulario, document_front: e });
            setFileNames({ ...fileNames, document_front: e.name, urlDocumentFront: URL.createObjectURL(e)});
            showPopup(true, setShowModalResizeImageFront);
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'El archivo debe ser una imagen',
                showConfirmButton: false,
                timer: 3500
            });
            setFormulario({ ...formulario, document_front: '' });
            setFileNames({ ...fileNames, document_front: '', urlDocumentFront: '' });
        }
    }

    const handleFileDocumentBack = (e) => {
        //Verificar si el archivo es una imagen y que la imagen sea mayor a 1000x1000
        if (e.type === "image/jpeg" || e.type === "image/png" || e.type === "image/jpg") {
            setFormulario({ ...formulario, document_back: e });
            setFileNames({ ...fileNames, document_back: e.name });
            showPopup(true, setShowModalResizeImageBack);
        } else {
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: 'El archivo debe ser una imagen',
                showConfirmButton: false,
                timer: 3500
            });
            setFormulario({ ...formulario, document_back: '' });
            setFileNames({ ...fileNames, document_back: '', urlDocumentBack: '' });
        }
    }

    const fileTypes = ["JPG", "PNG", "JPEG"];

    const params = {
        Link: Link
    }

    const { objF, objC } = SoftSkillsLogic(params);

    return (

        <div className=''>

            <div className='ContainerOpenClass'>

                <div className=" p-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="flex flex-wrap-reverse md:flex-wrap w-full md:px-10">
                            <div className="flex items-center w-full md:w-1/4 justify-start ">
                                <BackButton
                                    history={Cookies.get('prevLocation')}
                                />
                            </div>
                            <div className="flex items-center justify-center w-full md:w-2/4">
                                <h1 className="my-5 text-white text-center font-bold text-4xl">Editar beneficiario</h1>
                            </div>
                            <div className="flex items-center w-full md:w-1/4 justify-end ">
                                {loaded
                                    &&
                                    <>
                                        <Link
                                            to={`/VerBeneficiarios/${formulario.id}`}
                                            className="text-black bg-slate-50 py-3 px-4 text-sm font-medium text-center rounded-lg inline-flex items-center justify-center focus:ring-4 hover:bg-gray-300 transition-colors"
                                        >
                                            <FontAwesomeIcon icon={faUser} className="text-xl" />
                                            &nbsp;
                                            Ver beneficiario
                                        </Link>
                                    </>}
                            </div>
                        </div>
                        <form id="update-student" onSubmit={addEstudiante} className="flex flex-wrap rounded-lg mt-5">
                            <div className="w-full p-1">
                                <p className="mb-3 text-white text-center">Los campos marcados con un asterisco (<span className="text-red-600">*</span>) son obligatorios.</p>
                            </div>
                            <SectionProfile title="Foto del beneficiario">
                                {
                                    loaded
                                        ?
                                        <div className="p-5 w-full flex flex-wrap">
                                            <div className="w-full px-0">
                                                <div className="profile-card">
                                                    <div className="w-full h-60">
                                                        <img
                                                            src={Wallpaper}
                                                            className="w-full h-full object-cover rounded-lg"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="absolute w-48 h-48 rounded-full bg-white flex justify-center items-center top-0 left-0 right-0 bottom-0 m-auto">
                                                        {(loaded && formulario.photo) && (
                                                            <img
                                                                src={`data:image/jpeg;base64,${formulario.photo}`}
                                                                className="w-[92%] h-auto rounded-full"
                                                                alt=""
                                                            />
                                                        )}

                                                        {(loaded && !formulario.photo) && (
                                                            <img
                                                                src={formulario.gender !== 1 ? UserFemale : UserMale}
                                                                alt=""
                                                                className="w-[92%] h-auto rounded-full"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <PreloaderComponentSectionProfile filas={3} filas_sm={3} filas_md={3} />
                                }
                            </SectionProfile>
                            <SectionProfile title="Información personal">
                                {
                                    loaded
                                        ?
                                        <div className="p-5 w-full flex flex-wrap">
                                            <div className="w-full sm:w-1/2 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Nombres <span className="text-red-600">*</span></label>
                                                    <input value={formulario.names} onChange={ManejarEventoDeInputs} type="text" name="names" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                    {
                                                        alerta.filter(input => input.inputName == "names" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full sm:w-1/2 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Apellidos <span className="text-red-600">*</span></label>
                                                    <input value={formulario.lastnames} onChange={ManejarEventoDeInputs} type="text" name="lastnames" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                    {
                                                        alerta.filter(input => input.inputName == "lastnames" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full sm:w-1/2 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Fecha de nacimiento <span className="text-red-600">*</span></label>
                                                    <input value={formulario.birth_date} type="date" name="birth_date" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" onChange={function (e) {
                                                        ManejarEventoDeInputs(e)
                                                        if (esMayorDeEdad(e.currentTarget.value) && formulario.dui === null && formulario.nit !== undefined && formulario.nit !== '') {
                                                            setMostrarDuiConNit(true)
                                                            setMostrarDui(false)
                                                            setMostrarNit(false)
                                                        } else if (esMayorDeEdad(e.currentTarget.value)) {
                                                            setMostrarDui(true)
                                                            setMostrarDuiConNit(false)
                                                            setMostrarNit(false)
                                                        } else {
                                                            setMostrarNit(true)
                                                            setMostrarDui(false)
                                                            setMostrarDuiConNit(false)
                                                        }

                                                        if (duiExist) {
                                                            setMostrarDui(true)
                                                            setMostrarDuiConNit(false)
                                                            setMostrarNit(false)
                                                        }
                                                    }} />
                                                    {
                                                        alerta.filter(input => input.inputName == "birth_date" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="w-full sm:w-1/3 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Género <span className="text-red-600">*</span></label>
                                                    <select value={formulario.gender} onChange={ManejarEventoDeInputs} name="gender" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                        <option hidden disabled selected value="0">[Seleccione una opción]</option>
                                                        <option value="1">Masculino</option>
                                                        <option value="2">Femenino</option>
                                                        <option value="3">No especificado</option>
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.inputName == "gender" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div> */}
                                            {
                                                mostrarDui
                                                    ?
                                                    <div className="w-full sm:w-1/2 p-1">
                                                        <div className="mb-6">
                                                            <label className="block mb-2 text-sm font-medium text-black">DUI <span className="text-red-600">*</span></label>
                                                            <ReactInputMask
                                                                mask={"99999999-9"}
                                                                id="dui"
                                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                                name="dui"
                                                                type="text"
                                                                autoComplete="nope"
                                                                onChange={
                                                                    (e) => {
                                                                        ManejarEventoDeInputs(e);
                                                                    }
                                                                }
                                                                value={DUI}
                                                            />
                                                            {
                                                                alerta.filter(input => input.inputName == "dui" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                </div>))
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    mostrarNit
                                                    &&
                                                    <div className="w-full sm:w-1/2 p-1">
                                                        <div className="mb-6">
                                                            <label className="block mb-2 text-sm font-medium text-black">NIT <span className="text-red-600">*</span></label>
                                                            <ReactInputMask
                                                                mask={"9999-999999-999-9"}
                                                                id="nit"
                                                                className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                                name="nit"
                                                                type="text"
                                                                autoComplete="nope"
                                                                onChange={ManejarEventoDeInputs}
                                                                value={NIT}
                                                            />
                                                            {
                                                                alerta.filter(input => input.inputName == "nit" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                </div>))
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                mostrarDuiConNit
                                                &&
                                                <div className="w-full sm:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm text-black">
                                                            <span className="font-medium">
                                                                DUI
                                                            </span>
                                                            <span className="text-xs ml-1 text-gray-500 italic">(NIT: {formulario.nit})</span>
                                                        </label>
                                                        <ReactInputMask
                                                            mask={"99999999-9"}
                                                            id="dui"
                                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            name="dui"
                                                            type="text"
                                                            autoComplete="nope"
                                                            onChange={
                                                                (e) => {
                                                                    ManejarEventoDeInputs(e);
                                                                }
                                                            }
                                                            value={DUI}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "dui" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="w-full p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Dirección <span className="text-red-600">*</span></label>
                                                    <input value={formulario.address} onChange={ManejarEventoDeInputs} type="text" name="address" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                    {
                                                        alerta.filter(input => input.inputName == "address" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div> */}
                                            {/* <div className="w-full md:w-1/3 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Departamento <span className="text-red-600">*</span></label>
                                                    <select name="department" value={formulario.state_id} onChange={ManejarEventoDeInputs} className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                        <option hidden disabled selected value="0">[Seleccione una opción]</option>
                                                        {
                                                            departamentos.map((item) =>
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.inputName == "department" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div> */}
                                            {/* <div className="w-full md:w-1/3 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Municipio <span className="text-red-600">*</span></label>
                                                    <select value={formulario.city_id} onChange={ManejarEventoDeInputs} name="city_id" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                        <option hidden disabled selected value="0">[Seleccione una opción]</option>
                                                        {
                                                            municipios.map((item) =>
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.inputName == "city_id" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div> */}
                                            {/* <div className="w-full md:w-1/3 p-1">
                                                <div className="mb-6">
                                                    <label className="block mb-2 text-sm font-medium text-black">Distrito <span className="text-red-600">*</span></label>
                                                    <select name="district" value={formulario.district_id} onChange={ManejarEventoDeInputs} className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                        <option hidden disabled selected value="0">[Seleccione una opción]</option>
                                                        {
                                                            distritos.map((item) =>
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        alerta.filter(input => input.inputName == "district" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                        </div>))
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                        :
                                        <PreloaderComponentSectionProfile filas={9} filas_sm={7} filas_md={4} />
                                }
                            </SectionProfile>
                            <SectionProfile
                                title="Foto y documentos personales"
                                button={
                                    <button onClick={() => { setShowModalViewDocument(true) }} type="button" className="btn-card-header">
                                        Ver documentos
                                    </button>
                                }
                            >
                                {
                                    loaded
                                        ?
                                        <div className="p-5 w-full flex flex-wrap">
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full md:w-1/2 lg:w-1/3 flex justify-start py-2">
                                                    <label class="relative inline-flex items-center cursor-pointer">
                                                        <input type="checkbox" onChange={
                                                            (e) => {
                                                                handleSwitchFoto(e)
                                                                vaciarFileFoto();
                                                            }
                                                        } class="sr-only peer" />
                                                        <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                                        <span class="ml-3 text-sm font-medium text-black">Editar foto</span>
                                                    </label>
                                                </div>
                                                {/* <div className="w-full md:w-1/2 lg:w-1/3 flex justify-start py-2">
                                                    <label class="relative inline-flex items-center cursor-pointer">
                                                        <input type="checkbox" onChange={
                                                            (e) => {
                                                                handleSwitchCarta(e)
                                                                vaciarFileLetter();
                                                            }
                                                        } class="sr-only peer" />
                                                        <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                                        <span class="ml-3 text-sm font-medium text-black">Editar carta de compromiso</span>
                                                    </label>
                                                </div> */}
                                                <div className="w-full md:w-1/2 lg:w-1/3 flex justify-start py-2">
                                                    <label class="relative inline-flex items-center cursor-pointer">
                                                        <input type="checkbox" onChange={
                                                            (e) => {
                                                                handleSwitchDocument(e)
                                                                setFormulario({ ...formulario, document_front: '', document_back: '' });
                                                                setFileNames({ ...fileNames, document_front: '', document_back: '' });
                                                            }
                                                        } class="sr-only peer" />
                                                        <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                                        <span class="ml-3 text-sm font-medium text-black">Editar documento de identificación</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {editarFoto &&
                                                <div className="w-full sm:w-1/2 p-1 mt-5">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Foto {fileNames.photo !== "" && <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                            () => {
                                                                vaciarFileFoto();
                                                            }
                                                        } />}</label>
                                                        <FileUploader
                                                            name="photo"
                                                            className="flex items-center justify-center w-full"
                                                            hoverTitle={"Soltar aquí"}
                                                            handleChange={handleChangeFoto}
                                                            types={fileTypes}
                                                            children={
                                                                <>
                                                                    <label for="photo" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                                            {
                                                                                fileNames.photo === ''
                                                                                    ?
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faCloudArrowUp} className="w-8 h-8 mb-4 text-gray-500" />
                                                                                        <p class="mb-2 text-sm text-gray-500 text-center"><span class="font-semibold">Arrastra o haz clic aquí<br />para subir un archivo</span></p>
                                                                                        <p class="text-xs text-center text-gray-500">
                                                                                            JPG/JPEG o PNG
                                                                                            <ul>
                                                                                                <li>Peso: 300KB max</li>
                                                                                                <li>Dimensiones mínimas: 1000x1000</li>
                                                                                            </ul>
                                                                                        </p>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <p class="mb-2 text-sm text-gray-500 p-5 text-center">
                                                                                            <center><img src={fileNames.urlPhoto} className="w-1/2 xl:w-1/4 mb-5" alt="" /></center>
                                                                                            <span class="font-semibold">
                                                                                                Nombre del archivo:
                                                                                                <br />
                                                                                                <span className="text-blue-cnx">{
                                                                                                    fileNames.photo.length > 20
                                                                                                        ?
                                                                                                        fileNames.photo.substring(0, 20) + "..."
                                                                                                        :
                                                                                                        fileNames.photo
                                                                                                }</span>
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </label>
                                                                </>
                                                            } onTypeError={
                                                                () => {
                                                                    MySwal.fire({
                                                                        position: 'center',
                                                                        icon: 'error',
                                                                        title: 'El archivo debe ser una imagen',
                                                                        showConfirmButton: false,
                                                                        timer: 3500
                                                                    });
                                                                }
                                                            }
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "photo" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {/* {editarCarta &&
                                                <div className="w-full sm:w-1/2 p-1 mt-5">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Carta de compromiso {
                                                            fileNames.letter !== "" &&
                                                            <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                                () => {
                                                                    vaciarFileLetter();
                                                                }
                                                            } />
                                                        }</label>
                                                        <FileUploader
                                                            name="letter"
                                                            className="flex items-center justify-center w-full"
                                                            hoverTitle={"Soltar aquí"}
                                                            handleChange={handleFileCarta}
                                                            types={["PDF", "DOC", "DOCX", "ODT", "JPG", "JPEG", "PNG"]}
                                                            children={
                                                                <>
                                                                    <label for="letter" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                                            {
                                                                                fileNameCarta === ''
                                                                                    ?
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faCloudArrowUp} className="w-8 h-8 text-gray-500" />
                                                                                        <div className="p-5 text-center">
                                                                                            <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Arrastra o haz clic aquí para<br />subir carta de compromiso</span></p>
                                                                                            <p class="text-xs text-gray-500">PDF, DOC, DOCX, ODT, JPG, JPEG o PNG (Peso: 5MB max)</p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faFile} className="w-8 h-8 text-gray-500" />
                                                                                        <p class="mb-2 text-sm text-gray-500 p-5">
                                                                                            <span class="font-semibold">
                                                                                                Nombre del archivo:
                                                                                                <br />
                                                                                                {
                                                                                                    fileNameCarta.length > 20
                                                                                                        ?
                                                                                                        `${fileNameCarta.substring(0, 20)}...`
                                                                                                        :
                                                                                                        fileNameCarta
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </label>
                                                                </>
                                                            }
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "letter" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            } */}
                                            {editDocument &&
                                                <div className="w-full flex flex-wrap">
                                                    <div className="w-full">
                                                        <img
                                                            src={`data:image/jpeg;base64,${formulario.document_front}`}
                                                            className=""
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="w-full md:w-1/2 p-1 mt-5">
                                                        <div className="mb-6">
                                                            <label className="block mb-2 text-sm font-medium text-black">Parte frontal {
                                                                fileNames.document_front !== "" &&
                                                                <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                                    () => {
                                                                        vaciarFileDocumentFront();
                                                                    }
                                                                } />
                                                            }</label>
                                                            <FileUploader
                                                                name="document_front"
                                                                className="flex items-center justify-center w-full"
                                                                hoverTitle={"Soltar aquí"}
                                                                handleChange={handleFileDocumentFront}
                                                                types={["JPG", "JPEG", "PNG"]}
                                                                children={
                                                                    <>
                                                                        <label for="document_front" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                                                {fileNames.document_front === ''
                                                                                    ?
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faCloudArrowUp} className="w-8 h-8 text-gray-500" />
                                                                                        <div className="text-center p-5">
                                                                                            <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Arrastra o haz clic aquí para subir<br/>la parte frontal del documento</span></p>
                                                                                            <p class="text-xs text-gray-500">JPG, JPEG o PNG (Peso: 5MB max)</p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faFile} className="w-8 h-8 text-gray-500" />
                                                                                        <p class="mb-2 text-sm text-gray-500 p-5">
                                                                                            <span class="font-semibold">
                                                                                                Nombre del archivo:
                                                                                                <br />
                                                                                                {fileNames.document_front.length > 20
                                                                                                    ?
                                                                                                    `${fileNames.document_front.substring(0, 20)}...`
                                                                                                    :
                                                                                                    fileNames.document_front
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </label>
                                                                    </>
                                                                }
                                                            />
                                                            {
                                                                alerta.filter(input => input.inputName == "document_front" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                </div>))
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="w-full md:w-1/2 p-1 mt-5">
                                                        <div className="mb-6">
                                                            <label className="block mb-2 text-sm font-medium text-black">Parte reversa {
                                                                fileNames.document_back !== "" &&
                                                                <FontAwesomeIcon className="text-red-500 cursor-pointer" icon={faTrash} onClick={
                                                                    () => {
                                                                        vaciarFileDocumentBack();
                                                                    }
                                                                } />
                                                            }</label>
                                                            <FileUploader
                                                                name="document_back"
                                                                className="flex items-center justify-center w-full"
                                                                hoverTitle={"Soltar aquí"}
                                                                handleChange={handleFileDocumentBack}
                                                                types={["JPG", "JPEG", "PNG"]}
                                                                children={
                                                                    <>
                                                                        <label for="document_back" class="flex flex-col items-center justify-center w-full h-64 border-2 bg-gray-300 border-dashed rounded-lg cursor-pointer">
                                                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                                                {fileNames.document_back === ''
                                                                                    ?
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faCloudArrowUp} className="w-8 h-8 mb-4 text-gray-500" />
                                                                                        <div className="text-center">
                                                                                            <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Arrastra o haz clic aquí para subir<br/>la parte reversa del documento</span></p>
                                                                                            <p class="text-xs text-gray-500">JPG, JPEG o PNG (Peso: 5MB max)</p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <FontAwesomeIcon icon={faFile} className="w-8 h-8 mb-4 text-gray-500" />
                                                                                        <p class="mb-2 text-sm text-gray-500 p-5">
                                                                                            <span class="font-semibold">
                                                                                                Nombre del archivo:
                                                                                                <br />
                                                                                                {fileNames.document_back.length > 20
                                                                                                    ?
                                                                                                    `${fileNames.document_back.substring(0, 20)}...`
                                                                                                    :
                                                                                                    fileNames.document_back
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </label>
                                                                    </>
                                                                }
                                                            />
                                                            {
                                                                alerta.filter(input => input.inputName == "document_back" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                </div>))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                }
                            </SectionProfile>
                            <SectionProfile title="Información de contacto">
                                {
                                    loaded
                                        ?
                                        <>
                                            <div className="p-5 w-full flex flex-wrap">
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Correo electrónico <span className="text-red-600">*</span></label>
                                                        <input value={formulario.email} onChange={ManejarEventoDeInputs} type="text" name="email" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                        {
                                                            alerta.filter(input => input.inputName == "email" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Teléfono <span className="text-red-600">*</span></label>
                                                        <ReactInputMask
                                                            mask={"9999-9999"}
                                                            id="phone"
                                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            name="phone"
                                                            type="text"
                                                            autoComplete="nope"
                                                            onChange={ManejarEventoDeInputs}
                                                            value={formulario.phone}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "phone" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Teléfono alternativo</label>
                                                        <ReactInputMask
                                                            mask={"9999-9999"}
                                                            id="alternative_phone"
                                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            name="alternative_phone"
                                                            type="text"
                                                            autoComplete="nope"
                                                            onChange={ManejarEventoDeInputs}
                                                            value={formulario.alternative_phone}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "alternative_phone" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <PreloaderComponentSectionProfile filas={3} filas_sm={3} filas_md={3} />
                                }
                            </SectionProfile> 
                            <SectionProfile title="Situación economica y laboral actual">
                                {
                                    loaded
                                        ?
                                        <>
                                            <div className="p-5 w-full flex flex-wrap">
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Último grado académico obtenido <span className="text-red-600">*</span></label>
                                                        <select name="study_level" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" value={
                                                            formulario.study_level
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    study_level: e.target.value
                                                                })
                                                            }
                                                        } >
                                                            <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                            <option value="1">Educación Básica</option>
                                                            <option value="2">Bachillerato</option>
                                                            <option value="3">Técnico</option>
                                                            <option value="4">Universidad</option>
                                                            <option value="5">Maestría</option>
                                                            <option value="6">Doctorado</option>
                                                        </select>
                                                        {
                                                            alerta.filter(input => input.inputName == "study_level" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">¿Estudia actualmente? <span className="text-red-600">*</span></label>
                                                        <select value={
                                                            formulario.currently_studying
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    currently_studying: e.target.value
                                                                })
                                                            }
                                                        } name="currently_studying" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                            <option selected value="1">Si</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                        {
                                                            alerta.filter(input => input.inputName == "currently_studying" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">¿Disponibilidad de tiempo? <span className="text-red-600">*</span></label>
                                                        <select
                                                            name="time_availability"
                                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                            value={
                                                                formulario.time_availability
                                                            }
                                                            onChange={
                                                                (e) => {
                                                                    setFormulario({
                                                                        ...formulario,
                                                                        time_availability: e.target.value
                                                                    })
                                                                }
                                                            }
                                                        >
                                                            <option hidden disabled selected value="null">[Seleccione una opción]</option>
                                                            <option value="1">Completo</option>
                                                            <option value="6">Lunes a viernes</option>
                                                            <option value="2">Medio tiempo por la mañana</option>
                                                            <option value="3">Medio tiempo por la tarde</option>
                                                            <option value="4">Sábado y domingo</option>
                                                            <option value="5">Sin disponibilidad</option>
                                                        </select>
                                                        {
                                                            alerta.filter(input => input.inputName == "time_availability" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">¿Trabaja actualmente? <span className="text-red-600">*</span></label>
                                                        <select value={
                                                            formulario.currently_working
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    currently_working: e.target.value
                                                                })
                                                            }
                                                        } name="currently_working" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                            <option selected value="1">Si</option>
                                                            <option value="0">No</option>
                                                        </select>
                                                        {
                                                            alerta.filter(input => input.inputName == "currently_working" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Empleador<span className={`text-red-600 ${formulario.currently_working == 1 ? 'inline' : 'hidden'}`}>&nbsp;*</span></label>
                                                        <input value={
                                                            formulario.employer
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    employer: e.target.value
                                                                })
                                                            }
                                                        } type="text" name="employer" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true} />
                                                        {
                                                            formulario.currently_working == 1 && alerta.filter(input => input.inputName == "employer" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Posición laboral<span className={`text-red-600 ${formulario.currently_working == 1 ? 'inline' : 'hidden'}`}>&nbsp;*</span></label>
                                                        <input value={
                                                            formulario.work_position
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    work_position: e.target.value
                                                                })
                                                            }
                                                        } type="text" name="work_position" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true} />
                                                        {
                                                            formulario.currently_working == 1 && alerta.filter(input => input.inputName == "work_position" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/2 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Área laboral<span className={`text-red-600 ${formulario.currently_working == 1 ? 'inline' : 'hidden'}`}>&nbsp;*</span></label>
                                                        <input value={
                                                            formulario.work_area
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    work_area: e.target.value
                                                                })
                                                            }
                                                        } type="text" name="work_area" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true} />
                                                        {
                                                            formulario.currently_working == 1 && alerta.filter(input => input.inputName == "work_area" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full md:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Salario</label>
                                                        <input type="text" name="work_salary" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 disabled:opacity-50" disabled={formulario.currently_working == 1 ? false : true}
                                                            value={formulario.work_salary} onChange={
                                                                (e) => {
                                                                    // Verificar que el valor ingresado sea un número y que no sea negativo
                                                                    if (isNaN(e.target.value) || e.target.value < 0) {
                                                                        setFormulario({
                                                                            ...formulario,
                                                                            [e.target.name]: 1
                                                                        })
                                                                    } else {
                                                                        setFormulario({
                                                                            ...formulario,
                                                                            [e.target.name]: e.target.value
                                                                        })
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        {/* {
                                                            formulario.currently_working == 1 && alerta.filter(input => input.inputName == "work_salary" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        } */}
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Ingreso familiar <span className="text-red-600">*</span></label>
                                                        <select value={
                                                            formulario.family_income
                                                        } onChange={
                                                            (e) => {
                                                                setFormulario({
                                                                    ...formulario,
                                                                    family_income: e.target.value
                                                                })
                                                            }
                                                        } name="family_income" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300">
                                                            <option value="0">Seleccione una opción</option>
                                                            <option value="1">Menos de $300</option>
                                                            <option value="2">$300 - $500</option>
                                                            <option value="3">$500 - $1,000</option>
                                                            <option value="4">$1,000 - $1,500</option>
                                                            <option value="5">Mas de $1,500</option>
                                                        </select>
                                                        {
                                                            alerta.filter(input => input.inputName == "family_income" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/3 p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Miembros familiares <span className="text-red-600">*</span></label>
                                                        <input value={
                                                            formulario.family_members
                                                        } onChange={
                                                            (e) => {
                                                                // Verificar que el valor ingresado sea un número y que no sea negativo
                                                                if (isNaN(e.target.value) || e.target.value < 0) {
                                                                    setFormulario({
                                                                        ...formulario,
                                                                        [e.target.name]: 1
                                                                    })
                                                                } else {
                                                                    setFormulario({
                                                                        ...formulario,
                                                                        [e.target.name]: e.target.value
                                                                    })
                                                                }
                                                            }
                                                        } type="text" name="family_members" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" min={1} />
                                                        {
                                                            alerta.filter(input => input.inputName == "family_members" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="w-full p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Observaciones o comentarios adicionales</label>
                                                        <textarea name="work_comment" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300 h-32 resize-none" 
                                                            value={formulario.work_comment}
                                                            onChange={
                                                                (e) => {
                                                                    setFormulario({
                                                                        ...formulario,
                                                                        work_comment: e.target.value
                                                                    })
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <PreloaderComponentSectionProfile filas={9} filas_sm={7} filas_md={4} />
                                }
                            </SectionProfile>
                            <SectionProfile title="Contacto de emergencia" button={
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" checked={showFormContactEmergency ? true : false} onChange={handleSwitchContactInfo} class="sr-only peer" />
                                    <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                    {/* <span class="ml-3 text-sm font-medium text-black">Mostrar</span> */}
                                </label>}>
                                {
                                    loaded
                                        ?
                                        <div className="p-5 w-full flex flex-wrap">
                                            {
                                                showFormContactEmergency
                                                    ?
                                                    <>
                                                        <div className="w-full sm:w-1/2 p-1">
                                                            <div className="mb-6">
                                                                <label className="block mb-2 text-sm font-medium text-black">Nombres<span className="text-red-600">&nbsp;*</span></label>
                                                                <input value={
                                                                    formulario.contact_name
                                                                } onChange={
                                                                    (e) => {
                                                                        setFormulario({
                                                                            ...formulario,
                                                                            contact_name: e.target.value
                                                                        })
                                                                    }
                                                                } type="text" name="contact_name" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                                {
                                                                    alerta.filter(input => input.inputName == "contact_name" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full sm:w-1/2 p-1">
                                                            <div className="mb-6">
                                                                <label className="block mb-2 text-sm font-medium text-black">Apellidos<span className="text-red-600">&nbsp;*</span></label>
                                                                <input value={
                                                                    formulario.contact_lastname
                                                                } onChange={
                                                                    (e) => {
                                                                        setFormulario({
                                                                            ...formulario,
                                                                            contact_lastname: e.target.value
                                                                        })
                                                                    }
                                                                } type="text" name="contact_lastname" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                                {
                                                                    alerta.filter(input => input.inputName == "contact_lastname" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full sm:w-1/3 p-1">
                                                            <div className="mb-6">
                                                                <label className="block mb-2 text-sm font-medium text-black">Teléfono de contacto<span className="text-red-600">&nbsp;*</span></label>
                                                                <ReactInputMask
                                                                    mask={"9999-9999"}
                                                                    id="contact_phone"
                                                                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-300"
                                                                    name="contact_phone"
                                                                    type="text"
                                                                    autoComplete="nope"
                                                                    value={
                                                                        formulario.contact_phone
                                                                    }
                                                                    onChange={
                                                                        (e) => {
                                                                            setFormulario({
                                                                                ...formulario,
                                                                                contact_phone: e.target.value
                                                                            })
                                                                        }
                                                                    }
                                                                />
                                                                {
                                                                    alerta.filter(input => input.inputName == "contact_phone" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full sm:w-1/3 p-1">
                                                            <div className="mb-6">
                                                                <label className="block mb-2 text-sm font-medium text-black">Correo electrónico</label>
                                                                <input value={
                                                                    formulario.contact_email
                                                                } onChange={
                                                                    (e) => {
                                                                        setFormulario({
                                                                            ...formulario,
                                                                            contact_email: e.target.value
                                                                        })
                                                                    }
                                                                } type="text" name="contact_email" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                                {
                                                                    alerta.filter(input => input.inputName == "contact_email" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="w-full sm:w-1/3 p-1">
                                                            <div className="mb-6">
                                                                <label className="block mb-2 text-sm font-medium text-black">Relación<span className="text-red-600">&nbsp;*</span></label>
                                                                <input value={
                                                                    formulario.contact_relationship
                                                                } onChange={
                                                                    (e) => {
                                                                        setFormulario({
                                                                            ...formulario,
                                                                            contact_relationship: e.target.value
                                                                        })
                                                                    }
                                                                } type="text" name="contact_relationship" className="text-sm rounded-lg block w-full p-2.5 bg-gray-300" />
                                                                {
                                                                    alerta.filter(input => input.inputName == "contact_relationship" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                                    </div>))
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="w-full text-center">
                                                        <p className="text-sm font-medium text-black">
                                                            <span className="text-red-600">*</span> Para mostrar el formulario de contacto de emergencia, active el interruptor.
                                                        </p>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <PreloaderComponentSectionProfile filas={5} filas_sm={5} filas_md={5} />
                                }
                            </SectionProfile>
                            {/* <SectionProfile title="Idiomas" button={
                                <button
                                    onClick={() => {
                                        setShowModalAddLanguage(true)
                                    }}
                                    type="button"
                                    className="btn-card-header">
                                    Agregar idioma
                                </button>
                            }>
                                {
                                    loaded
                                        ?
                                        <>
                                            <div className="p-5 w-full flex flex-wrap">
                                                <div className="w-full p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Idiomas <span className="text-red-600">*</span></label>
                                                        <Select
                                                            value={selectedOptionLenguajes}
                                                            isMulti
                                                            name="languages"
                                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                            onChange={setSelectedOptionLenguajes}
                                                            options={optionsLanguages}
                                                            placeholder={"Selecciona una opción"}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "languages" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                }
                            </SectionProfile>
                            <SectionProfile title="Conocimientos" button={<button
                                onClick={() => {
                                    setShowModalAddConocimientos(true)
                                }}
                                type="button"
                                className="btn-card-header">
                                Agregar conocimiento
                            </button>}>
                                {
                                    loaded
                                        ?
                                        <>
                                            <div className="p-5 w-full flex flex-wrap">
                                                <div className="w-full p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Conocimientos</label>
                                                        <Select
                                                            value={selectedOptionConocimientos}
                                                            isMulti
                                                            name="knowledge"
                                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                            onChange={setSelectedOptionConocimientos}
                                                            options={options}
                                                            placeholder={"Selecciona una opción"}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "knowledge" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                }
                            </SectionProfile>
                            <SectionProfile title="Habilidades blandas" button={
                                <button
                                    onClick={() => {
                                        objF.setShowModalAddSoftSkill(true);
                                        objF.setTypeModal("add");
                                    }}
                                    type="button"
                                    className="btn-card-header">
                                    Agregar habilidad
                                </button>
                            }>
                                {
                                    loaded
                                        ?
                                        <>
                                            <div className="p-5 w-full flex flex-wrap">
                                                <div className="w-full p-1">
                                                    <div className="mb-6">
                                                        <label className="block mb-2 text-sm font-medium text-black">Habilidades blandas</label>
                                                        <Select
                                                            value={selectedOptionSoftSkills}
                                                            isMulti
                                                            name="soft_skills"
                                                            className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                            onChange={setSelectedOptionSoftSkills}
                                                            options={optionsSoftSkills}
                                                            placeholder={"Selecciona una opción"}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                        />
                                                        {
                                                            alerta.filter(input => input.inputName == "soft_skills" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                            </div>))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <PreloaderComponentSectionProfile filas={1} filas_sm={1} filas_md={1} />
                                }
                            </SectionProfile> */}
                            <SectionProfile title="Uso de información e imagen">
                                <div className="w-full">
                                    {
                                        authorization
                                            ?
                                            <h2 className="p-5 pb-0">Autorizo a CONEXION para que utilice mi información personal y mi imagen para fines de difusión, publicidad y promoción de sus programas y servicios.</h2>
                                            :
                                            <h2 className="p-5 pb-0">No autorizo a CONEXION para que utilice mi información personal y mi imagen para fines de difusión, publicidad y promoción de sus programas y servicios.</h2>
                                    }
                                </div>
                                <div className="w-full flex justify-center p-5">
                                    <label class="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" onChange={
                                            (e) => {
                                                setAuthorization(e.target.checked)
                                            }
                                        }
                                            checked={authorization}
                                            class="sr-only peer" />
                                        <div class="w-11 h-6 peer-focus:outline-none rounded-full peer bg-gray-500 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-950"></div>
                                        <span class="ml-3 text-sm font-medium text-black">
                                            {
                                                authorization
                                                    ?
                                                    <>
                                                        Si
                                                    </>
                                                    :
                                                    <>
                                                        No
                                                    </>
                                            }
                                        </span>
                                    </label>
                                </div>
                            </SectionProfile>
                            {
                                showModalAddConocimientos
                                    ?
                                    <ModalAddConocimiento show={setShowModalAddConocimientos} function={getConocimientos} />
                                    :
                                    null
                            }
                            {showModalViewDocument &&
                                <ModalViewDocument
                                    showPopup={showPopup}
                                    setShowModalViewDocument={setShowModalViewDocument}
                                    nameDocumentFront={formulario.document_front}
                                    nameDocumentBack={formulario.document_back}
                                    downloadFileRequest={downloadFileRequest}
                                    MySwal={MySwal}
                                />
                            }
                            <div className="w-full text-center">
                                <button
                                    type="submit"
                                    className="bg-blue-cnx hover:bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all cursor-pointer"
                                    disabled={!loaded}
                                >Actualizar</button>
                            </div>
                        </form>
                        {
                            showModalCropImage
                            &&
                            <ModalCropImage
                                img={formulario.photo}
                                setShowModalCropImage={setShowModalCropImage}
                                showModalCropImage={showModalCropImage}
                                funVaciarFoto={vaciarFileFoto}
                                showPopup={showPopup}
                                setFormulario={setFormulario}
                                setFileNames={setFileNames}
                                formulario={formulario}
                                fileNames={fileNames}
                            />
                        }
                        {
                            showModalResizeImageFront
                            &&
                            <ModalResizeImage
                                img={formulario.document_front}
                                setShowModalResizeImage={setShowModalResizeImageFront}
                                showModalResizeImage={showModalResizeImageFront}
                                funVaciarFoto={vaciarFileDocumentFront}
                                showPopup={showPopup}
                                setFormulario={setFormulario}
                                setFileNames={setFileNames}
                                formulario={formulario}
                                fileNames={fileNames}
                                name="document_front"
                            />
                        }
                        {
                            showModalResizeImageBack
                            &&
                            <ModalResizeImage
                                img={formulario.document_back}
                                setShowModalResizeImage={setShowModalResizeImageBack}
                                showModalResizeImage={showModalResizeImageBack}
                                funVaciarFoto={vaciarFileDocumentBack}
                                showPopup={showPopup}
                                setFormulario={setFormulario}
                                setFileNames={setFileNames}
                                formulario={formulario}
                                fileNames={fileNames}
                                name="document_back"
                            />
                        }
                        { 
                            objC.showModalAddSoftSkill && 
                            <ModalAddSoftSkill
                                showPopup={ objF.showPopup } 
                                setShowModalAddSoftSkill={ objF.setShowModalAddSoftSkill } 
                                typeModal={ objC.typeModal }
                                reloadData={ getSoftSkills }
                            /> 
                        }
                        {/* Modal agregar idiomas */}
                        <AddLanguageModal
                            showModal={showModalAddLanguage}
                            setShowModal={setShowModalAddLanguage}
                            getInfo={getInfoIdiomas}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}
export default EditarEstudiante;