import { WorkExperience } from './WorkExperience';
const ListWorkExperiences = (props) => {
    const params = {
        showPopup: props.showPopup,
        setShowModalEditWorkExperience: props.setShowModalEditWorkExperience,
        setIdEditWorkExperience: props.setIdEditWorkExperience,
        loaded: props.loaded,
        getWorkExperiences: props.getWorkExperiences
    }
    const { columns, paginationProps } = WorkExperience(params);
    const DataTable = props.DataTable;
    const DataTableExtensions = props.DataTableExtensions;
    const data = props.dataWorkExperiences;
    const tableData ={
        columns,
        data
    }
    return (
        <div className="bg-white w-full p-5 mb-3">
            <DataTableExtensions
                {...tableData}
                filterPlaceholder={ "Buscar" }
                print={false}
                export={false}
            >
                <DataTable
                    columns={ columns }
                    data={ data }
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={ false }
                    pagination
                    highlightOnHover
                    paginationComponentOptions={ paginationProps }
                    responsive
                    noDataComponent={ <span className="font-bold text-black my-5">No hay documentos cargados</span> }
                />
            </DataTableExtensions>
        </div>
    )
}
export default ListWorkExperiences;