import React, { useCallback, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditProgram from "./EditPrograma";
import AggProgram from "./AgregarPrograma";
import Cookies from "js-cookie";
import { useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { paginationProps, postOrPutRequest, getOrDeleteRequest, showPopup } from "../utils/util";
import { filterExportDataKeyes } from "../utils/utilFiltersArrays";
import { getCampus } from "../Components/FunctionsGet";

import ImportButton from "../ImportButton";
import Filters from "./Filters";
import ExportDocument from "../Components/ExportDocument";
import { Link } from "react-router-dom";
import PreloaderTables from "../PreloaderTables/PreloaderTables";
import Educacion from "../../assets/educacion.png";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';

const Programas = () => {

    const initialListarDatos = {
        id: 0,
        names: "",
    }

    const initDataFilter = {
        search: '',
        campus: null,
        modality: null,
        program: null,
        organizer: null,
        knowledge: []
    }

    const keyesExport = [
        'No',
        'Catalago_de_formacion',
        'Nombre_alternativo',
        'Sede',
        'Modalidad',
        'Cooperante',
        'Fecha_inicio',
        'Fecha_finalizacion'
    ];

    const [showModal, setShowModal] = React.useState(false);
    const [ShowModalAgregar, setShowModalAgregar] = React.useState(false);
    const [IdEditProgram, setIdEditProgram] = React.useState();
    const MySwal = withReactContent(Swal);
    const [ListarDatos, setListar] = useState();
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([initialListarDatos]);
    const [dataSearch, setDataSearch] = useState([initialListarDatos]);
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [dataToExport, setDataToExport] = useState([]);
    const [campusN, setCampusN] = useState('No definido');
    const [campusArr, setCampusArr] = useState([]);
    const [dataCalendar, setDataCalendar] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(10);

    const getContrastColor = () => {
        let hexColor = Math.floor(Math.random()*16777215).toString(16);
        
        // Ver si el color es oscuro o claro y asi poner el texto de un color que contraste
        let r = parseInt(hexColor.substr(0,2),16);
        let g = parseInt(hexColor.substr(2,2),16);
        let b = parseInt(hexColor.substr(4,2),16);
        let luminance = (0.299*r + 0.587*g + 0.114*b);

        return luminance <= 128 ? {bgColor: '#'+hexColor, color:'#ffffff'} : {bgColor: '#'+hexColor, color:'#000000'};
    }

    const getTraningPrograms = async () => {
        let response = await getOrDeleteRequest(
            // `program-schedule/index?page=${page}&rowsPerPage=${rowsPerPage}`,
            `program-schedule/index`,
            'GET'
        );

        if (response.length < 0) {
            console.log("Empty response");
            return;
        }

        if (response.type === "error") {
            setData([]);
            setDataSearch([]);
            console.log(response);
            setLoaded(true);
            return;
        }

        setData(response);
        setDataSearch(response);
        console.log(response)
        let data = [];
        (response).map((item) => {
            let colors = getContrastColor();
            data.push({
                start: item.start_date, 
                end: item.end_date, 
                title: item.alternate_name, 
                id: item.id,
                backgroundColor: colors.bgColor,
                textColor: colors.color
            });
        });
        setDataCalendar(data);
        // setTotalRows(response.total);
        // console.log(data, "data");
        setLoaded(true);
        return;
    }

    useEffect(() => {
        if (loaded === false) {
            getTraningPrograms();
            getCampuss();
        }
    }, []);

    useEffect(() => { setDataToExport(filterExportData()); }, [dataSearch]);

    const getCampuss = async () => {
        let response = await getCampus();
        setCampusArr(response);
    }

    const buttonEditar = (estadomodal, IdPrograma) => {
        setIdEditProgram(IdPrograma)

        setShowModal(estadomodal)
    }

    const reactivarPrograna = async (id, status) => {
        let alert = await MySwal.fire({
            icon: "warning",
            title: "¿Estás seguro de realizar esta operación?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, estoy seguro",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return MySwal.getConfirmButton().click();
            }
        });

        if (!alert.isConfirmed) {
            console.log("Action cancelled");
            return;
        }

        let response = null;

        switch (status) {
            case 1:
            default:
                response = await getOrDeleteRequest(
                    `programs/disable/${id}`,
                    'DELETE'
                );
                break;

            case 2:
                response = await postOrPutRequest(
                    `programs/enable/${id}`,
                    'PUT'
                );
                break;
        }

        if (response.type === "error") {
            MySwal.fire({
                icon: "error",
                title: "Ocurrió un problema",
                text: response.message,
                showConfirmButton: true,
            });

            return;
        }
        MySwal.fire({
            icon: "success",
            title: "Operación exitosa",
            text: response.message,
            showConfirmButton: true,
        });
        getTraningPrograms();
    };

    const syncGoogleSheet = useCallback(async (id) => {
        let uuid = id;

        if (uuid === null || uuid === undefined) {
            return;
        }

        let alert = await MySwal.fire({
            icon: "warning",
            title: "¿Estás seguro de realizar la sincronización?",
            text: "Todos los beneficiarios que se encuentren en la hoja de cálculo de Google se sincronizarán con el programa de formación dentro de la plataforma, esta operación puede tardar varios minutos.",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sí, estoy seguro",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return MySwal.getConfirmButton().click();
            }
        });

        if (!alert.isConfirmed) {
            return;
        }

        const response = await getOrDeleteRequest(
            `programs/google-form/${uuid}`,
            'GET'
        );

        if (response.type === "error") {
            MySwal.fire({
                icon: "error",
                title: "Ocurrió un problema",
                text: response.message,
                showConfirmButton: true,
            });

            return;
        }

        MySwal.fire({
            icon: "success",
            title: "Operación exitosa",
            text: "La sincronización se ha realizado con éxito, los nuevos beneficiarios se encuentran en la plataforma.",
            showConfirmButton: true
        });

        getTraningPrograms();
    }, []);


    const columns = [
        {
            name: '#',
            width: "75px",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre alternativo',
            width: "250px",
            cell: (row) =>
                <Link to={`/VerProgramaDeFormacion/${row.id}`} className="hover:border-b-2 hover:border-blue-950">{loaded && row.alternate_name}</Link>
        },
        {
            name: 'Catálogo de formación',
            width: "300px",
            cell: (row) => <div>{loaded && row.program}</div>
        },
        {
            name: 'Modalidad',
            width: "150px",
            cell: (row) => <div>{loaded && row.modality}</div>
        },
        {
            name: 'Fecha de Inicio',
            width: "150px",
            cell: (row) => <div>{loaded && row.start_date}</div>
        },
        {
            name: 'Estado',
            width: "130px",
            cell: (row) =>
                loaded && <div className="flex items-center">
                    {(row.status == 1) ? (
                        <>
                            <div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo</>
                    ) : (
                        <>
                            <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div> Inactivo
                        </>
                    )}
                </div>
        },
        {
            name: "Activar/Desactivar",
            width: "170px",
            // cell: (row) => <div>{loaded && row.status == 1 ? <button onClick={desactivarConocimiento} id={row.id}><FontAwesomeIcon className="text-2xl" icon={faToggleOn} /></button> : row.status == 2 && <button onClick={ReactivarConocimiento} id={row.id}><FontAwesomeIcon className="text-2xl" icon={faToggleOff} /></button>}</div>
            cell: (row) => loaded && <div className="flex justify-center w-full">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                        onClick={() => {
                            reactivarPrograna(row.id, row.status)
                        }}
                        id={row.id}
                        type="checkbox"
                        className="sr-only peer"
                        checked={row.status == 1 ? true : row.status == 2 && false}
                        readOnly
                    />
                    <div
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                </label>
            </div>
        }
    ];

    function desactivarCooperante(event) {
        const idPrograma = event.currentTarget.id;
        // console.log(idPrograma)
        Swal.fire({
            title: '¿Seguro que quieres desactivar este programa?',
            showCancelButton: true,
            confirmButtonText: 'Desactivar',
            cancelButtonText: "Cancelar",
            confirmButtonColor: "#07285B"
        }).then((result) => {

            if (result.isConfirmed) {
                async function confirmDesactivarCooperante() {
                    const url = `${process.env.REACT_APP_API_URI}programs/disable/${idPrograma}`;

                    let config = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    };

                    if (Cookies.get('Bearer') !== undefined) {
                        config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
                    } else {
                        window.location = "/"
                    }

                    try {
                        const resp = await axios.delete(url, config);
                        console.log(resp)
                        MySwal.fire({
                            position: 'center',
                            icon: 'success',
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 3500
                        }).then(function () {
                            // window.location.href = "/Conocimientos";
                            //setData([initialCooperante])
                            // getInfo();
                        });

                    } catch (err) {
                        console.log(err)
                        MySwal.fire({
                            position: 'center',
                            icon: 'error',
                            title: "¡Error! Vuelva a intentar",
                            showConfirmButton: false,
                            timer: 3500
                        });
                    }
                }
            }
        })
    };

    const selectValue = (filterValue, filterKey) => {
        switch (filterKey) {
            case 'internship':
                let result;
                if (filterValue == 0) {
                    result = 'No';
                } else if (filterValue == 1) {
                    result = 'Sí';
                } else {
                    result = 'No definido';
                }
                return result;
                break;
            case 'program':
                const arrayProgram = filterValue.map(item => {
                    return item.label;
                });
                return arrayProgram;
                break;
            case 'organizers':
                if (filterValue) {
                    const arrayOrganizers = filterValue.map(item => {
                        return item.label;
                    });
                    return arrayOrganizers;
                }
                break;
            case 'modality':
                if (filterValue) {
                    const arrayModality = filterValue.map(item => {
                        return item.label;
                    });
                    return arrayModality;
                }
                break;
            case 'knowledge':
                const arrayKnowledge = filterValue.map(item => {
                    return item.label;
                });
                return arrayKnowledge;
                break;
            default:
                break;
        }
    }

    const filterHeadings = (dataToFilter) => {
        return dataToFilter.map((key) => {
            const { id, program, alternate_name, campus, modality, start_date, end_date, organizers, ...rest } = key;
            return {
                ...rest,
                No: id,
                Catalago_de_formacion: program,
                Nombre_alternativo: alternate_name,
                Sede: campusArr.find(item => item.id === campus)?.campus_name,
                Modalidad: modality,
                Cooperante: selectValue(organizers, 'organizers'),
                Fecha_inicio: start_date,
                Fecha_finalizacion: end_date
            };
        });
    }

    const filterExportData = () => {
        return filterExportDataKeyes(keyesExport, filterHeadings(dataSearch));
    }

    const filters = `
        ${dataFilter.campus != null ? '<th> Sede: ' + dataFilter.campus['label'] + '</th>' : ''}
        ${dataFilter.modality != null ? '<th> Modalidad: ' + dataFilter.modality['label'] + '</th>' : ''}
        ${dataFilter.program != null ? '<th> Programa: ' + dataFilter.program['label'] + '</th>' : ''}
        ${dataFilter.organizer != null ? '<th> Organizador: ' + dataFilter.organizer['label'] + '</th>' : ''}
        ${dataFilter.knowledge.length > 0 ? '<th> Conocimientos: ' + selectValue(dataFilter.knowledge, 'knowledge') + '</th>' : ''}
    `;

    const headers = `
        <th>N°</th>
        <th>Catálogo de programación</th>
        <th>Nombre alternativo</th>
        <th>Sede</th>
        <th>Modality</th>
        <th>Cooperantes</th>
        <th>Fecha de inicio</th>
        <th>Fecha de finalización</th>
    `;

    const [switchTableCalendar, setSwitchTableCalendar] = useState(false);

    // const handlePageChange = (page) => {
    //     // setCurrentPage(page);
    // };

    const expandedComponent = ({ data }) =>
        <div className="flex py-2 px-12" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
            <button
                className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 mr-2 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center"
                onClick={() => buttonEditar(true, data.id)}
                type="button" 
                data-modal-toggle="user-modal"
            > 
                <FontAwesomeIcon className="pr-2" icon={faPenToSquare} /> Editar
            </button>
            <Link 
                to={`/VerProgramaDeFormacion/${data.id}`}
                className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 mr-2 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center"
            > 
                <FontAwesomeIcon className="pr-2" icon={faEye} /> Ver
            </Link>
            {/* <button
                class="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 mr-2 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => syncGoogleSheet(data.id)}
                type="button"
                disabled={data.google_form === null ? true : false}
            > 
                <FontAwesomeIcon className="pr-2" icon={faGoogle} /> Sincronizar
            </button> */}
        </div>;

    return (
        <div className=''>
            <div className='ContainerOpenClass'>
                <div className="p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="sm:flex">
                            <h1 className="text-xl sm:text-2xl font-semibold text-white">Programas de formación</h1>
                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                                <button type="button" data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                    onClick={() => setShowModalAgregar(true)}>
                                    <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                    Agregar
                                </button>
                                <ImportButton
                                    origin="schedule"
                                    info={getTraningPrograms}
                                    roles={[1, 2]}
                                />
                                {dataSearch.length > 0 &&
                                    <ExportDocument
                                        dataSearch={dataSearch}
                                        useState={useState}
                                        useRef={useRef}
                                        useEffect={useEffect}
                                        fileName={'Programas'}
                                        filters={filters}
                                        headers={headers}
                                        dataToExport={dataToExport}
                                        filterExportData={filterExportData}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-2 bg-blue-950">
                    {!switchTableCalendar && <Filters
                        data={data}
                        setDataSearch={setDataSearch}
                        getTraningPrograms={getTraningPrograms}
                        useState={useState}
                        getOrDeleteRequest={getOrDeleteRequest}
                        dataFilter={dataFilter}
                        setDataFilter={setDataFilter}
                        initDataFilter={initDataFilter}
                    />}
                    <div className="w-full flex justify-center">
                        {/* switch para mostrar tabla o calendario */}
                        <div className="flex items-center justify-center w-full mb-8 mt-8 md:mt-0">
                            <span className="text-xs mx-5 text-white">Tabla</span>
                            <label for="toggle" className="flex items-center cursor-pointer">
                                <input type="checkbox" id="toggle" disabled={loaded ? false : true} className="sr-only peer" onChange={
                                    () => {
                                        setSwitchTableCalendar(!switchTableCalendar);
                                    }
                                }/>
                                <div className="block relative bg-white w-14 h-7 p-1 rounded-full before:absolute before:bg-blue-600 before:w-5 before:h-5 before:p-1 before:rounded-full before:transition-all before:duration-500 before:left-1 peer-checked:before:left-8 peer-checked:before:bg-blue-600"></div>
                            </label>
                            <span className="text-xs mx-5 text-white">Calendario</span>
                        </div>
                    </div>
                    <div className="px-6 relative">
                        {
                            loaded
                                ?
                                    switchTableCalendar
                                    ?
                                    <div className="bg-white p-5 rounded-lg">
                                        <FullCalendar 
                                            events={dataCalendar}
                                            plugins={[dayGridPlugin]}
                                            locale={"es"}
                                            height={"auto"}
                                            initialView="dayGridMonth"
                                            // Cambiar texto de botones
                                            buttonText={{
                                                today: 'Hoy',
                                                month: 'Mes',
                                                week: 'Semana',
                                                day: 'Día',
                                                list: 'Lista'
                                            }}
                                            eventClick={(info) => {
                                                //Fechas en español
                                                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                                                const start = new Date(info.event.start).toLocaleDateString('es-ES', options);
                                                const end = new Date(info.event.end).toLocaleDateString('es-ES', options);

                                                MySwal.fire({
                                                    //Logo de alerta
                                                    imageUrl: Educacion,
                                                    imageWidth: 100,
                                                    imageHeight: 100,
                                                    imageAlt: 'Educación',
                                                    title: info.event.title,
                                                    html: `
                                                        <p>Fecha de inicio: ${start}</p>
                                                        <p>Fecha de finalización: ${end}</p>
                                                    `,
                                                    showCloseButton: true,
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Ver programa',
                                                    confirmButtonColor: '#07285B',
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Cerrar',
                                                    cancelButtonColor: '#FF0000',
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        window.open(`/VerProgramaDeFormacion/${info.event.id}`, '_blank');
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                    :
                                    <DataTable
                                        columns={columns}
                                        data={dataSearch}
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        pagination
                                        paginationServer
                                        highlightOnHover
                                        responsive
                                        paginationComponentOptions={paginationProps}
                                        noDataComponent={<div className="my-5">No hay registros</div>}
                                        expandableRows
                                        expandableRowsComponent={expandedComponent}
                                        // onChangePage={ handlePageChange }
                                        // paginationTotalRows={totalRows}
                                        // paginationDefaultPage={currentPage}
                                        // paginationRowsPerPageOptions={[10, 15, 20]}
                                    />
                                :
                                <PreloaderTables />
                        }
                    </div>
                </div>
            </div>
            {showModal ? (<EditProgram setShowModal={setShowModal} loadData={getTraningPrograms} IdEditProgram={IdEditProgram}></EditProgram>) : null}
            {ShowModalAgregar ? (<AggProgram modal={setShowModalAgregar} loadData={getTraningPrograms} showPopup={showPopup}></AggProgram>) : null}
        </div>
    )
}
export default Programas;
