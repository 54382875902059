
const SectionProfile = ({ children, title, button, full = true, header = true }) => {
    return (
        <div className={ `px-10 ${ full ? 'w-full' : 'w-3/5' }` }>
            <div className="relative w-full rounded-lg shadow-md bg-white mt-5 mb-5">
                { header && (
                    <div className="flex flex-wrap items-center py-3 px-10 border-b-[2px] border-gray-300">
                        <div className="w-1/2">
                            <h3 className="text-xl font-bold">{ title }</h3>
                        </div>
                        { button && (
                            <div className="w-1/2">
                                <div className="flex justify-end">
                                    {button}
                                </div>
                            </div>
                        ) }
                    </div>
                ) }
                <div className="flex flex-wrap items-center px-2">
                    { children }
                </div>
            </div>
        </div>
    );
};

export default SectionProfile;
