import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AllowedRoute = ({ role, roles }) => {
    const isAllowed = roles.includes(role);

    if (!isAllowed) {
        return <Navigate to="/access-denied" />;
    }
    
    return <Outlet />;
};

export const AccessDenied = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-2xl font-semibold text-red-500">Acceso denegado</h1>
            <p className="text-white">
                No tienes permisos para ver esta página  
            </p>
        </div>
    );
};
export default AllowedRoute;

