import { getOrDeleteRequest } from "../utils/util";

export const getCampus = async () => {
    let response = await getOrDeleteRequest(
        'campus/index',
        'GET'
    );
    if (response.length < 0) {
        return;
    }
    if (response.type === "error") {
        return response;
    }
    return response;
}
