import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import withReactContent from "sweetalert2-react-content";
import Select from 'react-select';
import { paginationProps, getOrDeleteRequest, postOrPutRequest, showPopup } from "../utils/util";
import { validateData } from "../utils/validateData";
import { set } from "date-fns";

const AggPrograma = (props) => {
    const datosFormulario = {
        program: "",
        alternate_name: "",
        GoogleForm: "",
        attendance_list: "",
        Modalidad: "",
        DateInicio: "",
        DateFinal: "",
        Cooperante: [],
        Campus: "",
        specialization_name: "",
        total_hours: ""
    }

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const MySwal = withReactContent(Swal);
    const tokenAPP = JSON.parse(Cookies.get('Bearer')).token;
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [formulario, setFormulario] = useState(datosFormulario);
    const [conocimientos, setconocimientos] = useState([]);
    const [campus, setCampus] = useState([]);
    const [especialidades, setespecialidades] = useState([]);
    const [tipoprograma, settipoprograma] = useState([]);
    const [modalidad, setmodalidad] = useState([]);
    const [cooperante,setcooperante] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [saveknowledge, setsaveknowledge] = useState([]);
    const [option, setoption] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOptionCooperantes, setSelectedOptionCooperantes] = useState([]);
    const [selectedOptionEspecialidades, setSelectedOptionEspecialidades] = useState([]);

    const [campusEnabled, setCampusEnabled] = useState(true);
    
    const campusRef = useRef(null);
    
    const ManejarEventoDeInputs = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name, value);
        if (name === "Especialidades") {
            consultarConocimientos(value);
        }
        setFormulario({ ...formulario, [name]: value });
    };

    const submitForm = (e) => {
        e.preventDefault();

        formulario.Cooperante = selectedOptionCooperantes.map((item) => {
            return item.value
        });

        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });

        inputs = inputs.filter(input => input.name !== "GoogleForm");
        inputs = inputs.filter(input => input.name !== "attendance_list");
        inputs = inputs.filter(input => input.name !== "specialization_name");
        
        const datosValidados = validateData(inputs);

        setAlerta(datosValidados);
        const totalValidaciones = datosValidados.filter(input => input.status !== false).map
        (() => { return false });
        if (totalValidaciones.length < 1) {
            sendData();
        } 
    }

    const sendData = async () => {
        const idsCooperantes = selectedOptionCooperantes.map((item) => {
            return item.value
        });

        let newdata = {
            program: formulario.program,
            alternate_name: formulario.alternate_name,
            modality: formulario.Modalidad,
            start_date: formulario.DateInicio,
            end_date: formulario.DateFinal,
            organizer: idsCooperantes,
            campus: formulario.Campus,
            specialty_name: formulario.specialization_name,
            total_hours: formulario.total_hours
        }

        if( formulario.GoogleForm !== '' && formulario.GoogleForm !== undefined){
            newdata.google_form = formulario.GoogleForm
        }

        if( formulario.attendance_list !== '' && formulario.attendance_list !== undefined){
            newdata.attendance_list = formulario.attendance_list
        }

        console.log("Data de envio: ", newdata);
        const response = await postOrPutRequest(
            "program-schedule/register",
            'POST',
            newdata
        );
        if (response.length < 0) {       
            console.log("Empty response");     
            return;
        }
        if (response.type === "error") {  
            console.log(response);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }
        props.showPopup(false, props.modal);
        props.loadData();
        MySwal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 3000
        });
        setLoaded(true);
    };

    const consultarConocimientos = async () => {
        const idsEspecialidades = selectedOptionEspecialidades.map((item) => {
            return item.value
        });

        const idsParaConsultaApi = idsEspecialidades.join(',');

        const url = `${process.env.REACT_APP_API_URI}knowledge/filter?type=${idsParaConsultaApi}`;
        console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response.data,"aqui listado de conocimientos");
                setconocimientos(response.data);
                const resultDataMaps = response.data.map((item) => {
                    return { value: item.id, label: item.name }
                });
                console.log(resultDataMaps, "holi");
                setSelectedOption(resultDataMaps);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const onClickConocimientos = () => {
        if(selectedOptionEspecialidades.length !== 0){
            consultarConocimientos();
        }else{
            setSelectedOption([]);
        }
    }

    const options = [];

    const getTrainingCatalog = async () => {
        const response = await getOrDeleteRequest(
            "programs/filter",
            'GET'
        );
        if (response.length < 0) {       
            console.log("Empty response");     
            return;
        }
        if (response.type === "error") {
            settipoprograma([]);    
            console.log(response);
        } else {
            let dataCatalog = response.map((item) => {
                return { value: item.id, label: item.name }
            });

            settipoprograma(dataCatalog);
        }

        setLoaded(true);
    };

    const consultarModalidad = async () => {
        const url = `${process.env.REACT_APP_API_URI}modality/filter`;
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response.data);
                setmodalidad(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const consultarPrograma = async () => {
        const url = `${process.env.REACT_APP_API_URI}programs/find/3`;
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response.data);

            })
            .catch(error => {
                console.log(error);
            });
    };

    const consultarCooperante = async () => {
        const url = `${process.env.REACT_APP_API_URI}contributor/index`;
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
        .then(response => {
            console.log(response.data);
            setcooperante(response.data);
        })
        .catch(error => {
            console.log(error);
        });
    };

    const loadCampus = useCallback(async () => {
        let campus = await getOrDeleteRequest(
            "campus/filter",
            'GET'
        );

        if (campus.type === "error") {
            console.log(campus);
            setCampus([]);

            return;
        }        
        
        let dataCampus = campus.map((item) => {
            return { value: item.id, label: item.campus_name }
        });

        setCampus(dataCampus);
    }, []);

    useEffect(() => {
        if (loaded === false) {
            loadCampus();
            consultarCooperante();
            consultarModalidad();
            getTrainingCatalog();
        }
    }, []);

    const optionsCooperantes = [];
    const optionsEspecialidades = [];

    cooperante.map((item) => {
        optionsCooperantes.push({ value: item.id, label: item.name })
    })

    especialidades.map((item) => {
        optionsEspecialidades.push({ value: item.id, label: item.name })
    })

    return (
        <>
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-900">
                                Agregar programa de formación
                            </h3>
                            <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                // onClick={() => setShowModal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full flex flex-wrap" onSubmit={submitForm} >
                                    <div className="w-full flex flex-wrap py-4 px-6">
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Catálogo formación
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                {/* <select 
                                                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' 
                                                    value={ formulario.program } 
                                                    name="program" 
                                                    onChange={ManejarEventoDeInputs}
                                                >
                                                    <option hidden selected> Seleccione una opción </option>
                                                    { tipoprograma.map((item, index) => {
                                                        return( <option key={index} value={item.id}>{item.name}</option> );
                                                    })}
                                                </select> */}
                                                <Select
                                                    name="program"
                                                    className="appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white"
                                                    onChange={(event) => {
                                                        setFormulario({ ...formulario, program: event.value });
                                                    }}
                                                    options={tipoprograma}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                />
                                                { alerta.filter(input => input.inputName == "program" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">Nombre alternativo</label>
                                            <div className="mb-2 xl:w-100">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="alternate_name" value={formulario.alternate_name} onChange={ManejarEventoDeInputs} type="text" />
                                                { alerta.filter(input => input.inputName == "alternate_name" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-2/3 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">Nombre de la especialización</label>
                                            <div className="mb-2 xl:w-100">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="specialization_name" value={formulario.specialization_name} onChange={ManejarEventoDeInputs} type="text" />
                                                { alerta.filter(input => input.inputName == "specialization_name" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/3 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">Total de horas</label>
                                            <div className="mb-2 xl:w-100">
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="total_hours" value={formulario.total_hours} onChange={
                                                (event) => {
                                                    // Verificamos que el valor ingresado sea un numero y sea mayor a 0
                                                    if (event.target.value > 0 && !isNaN(event.target.value)) {
                                                        setFormulario({ ...formulario, total_hours: event.target.value });
                                                    }else{
                                                        setFormulario({ ...formulario, total_hours: "" });
                                                    }
                                                }
                                            } type="text" min="1" />
                                                { alerta.filter(input => input.inputName == "total_hours" && input.status === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.message}</span></div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Sede
                                            </label>
                                            <Select
                                                name="Campus"
                                                className="appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white"
                                                // classNamePrefix=""
                                                onChange={(event) => {
                                                    setFormulario({ ...formulario, Campus: event.value });
                                                }}
                                                options={campus}
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                            />

                                            {/* <input ref={ campusRef } className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="Campus" value={formulario.Campus} onChange={ManejarEventoDeInputs} type="text" /> */}
                                            {
                                                alerta.filter(input => input.inputName == "Campus" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>

                                                ))
                                            }
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Modalidad
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' value={formulario.Modalidad} name="Modalidad" onChange={ManejarEventoDeInputs}>
                                                    <option hidden selected value="0">Seleccione una modalidad</option>
                                                    {modalidad.map((item, index) =>
                                                        <option
                                                            key={index}
                                                            value={item.id}
                                                        >{item.name}</option>
                                                    )}
                                                </select>
                                                {
                                                    alerta.filter(input => input.inputName == "Modalidad" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                    </div>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Cooperantes
                                            </label>
                                            <Select
                                                isMulti
                                                name="Cooperante"
                                                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white'
                                                onChange={
                                                    (event) => {
                                                        setSelectedOptionCooperantes(event);
                                                        setFormulario({ ...formulario, Cooperante: event.value });
                                                    }
                                                }
                                                options={optionsCooperantes}
                                                placeholder={"Selecciona una opción"}
                                                noOptionsMessage={() => 'No hay mas opciones'}
                                            />
                                            {alerta.filter(input => input.inputName == "Cooperante" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>
                                                ))
                                            }
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Fecha de Inicio                                    </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="DateInicio" value={formulario.DateInicio} onChange={ManejarEventoDeInputs} type="date" />
                                            {alerta.filter(input => input.inputName == "DateInicio" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>
                                                ))
                                            }
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Fecha de finalización
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="DateFinal" value={formulario.DateFinal} onChange={ManejarEventoDeInputs} type="date" />
                                            {alerta.filter(input => input.inputName == "DateFinal" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>
                                                ))
                                            }
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold" htmlFor="GoogleForm">Convocatoria hoja de cálculo</label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="GoogleForm" id="GoogleForm" value={formulario.GoogleForm} onChange={ManejarEventoDeInputs} type="text" placeholder="Ejemplo: https://docs.google.com/spreadsheets/d/1111-aaaa-asdf-14asd/edit#gid=0"/>
                                            {alerta.filter(input => input.inputName == "GoogleForm" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>
                                                 ))
                                            }
                                        </div>
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold" htmlFor="attendance_list">Asistencia hoja de cálculo</label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="attendance_list" id="attendance_list" value={formulario.attendance_list} onChange={ManejarEventoDeInputs} type="text" placeholder="Ejemplo: https://docs.google.com/spreadsheets/d/1111-aaaa-asdf-14asd/edit#gid=0" />
                                            {alerta.filter(input => input.inputName == "attendance_list" && input.status === true).map(message => (<div className=""> <span className="text-red-500">{message.message}</span>
                                                </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-center py-4 border-t border-solid border-slate-200 rounded-b">

                                        <button
                                            className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150  "
                                            type="submit"

                                        >
                                            Registrar
                                        </button>

                                        <button
                                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center "
                                            type="button"
                                            onClick={() => props.showPopup(false, props.modal)}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default AggPrograma;