import { filterDataChartDonut } from "../utils/utilObject";
export const CompanyDetailLogic = (params) => {

    const { useState, id, getOrDeleteRequest, Link, useEffect } = params;
    const [loaded, setLoaded] = useState(false);
    const [dataStudents, setDataStudents] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [cooperator, setCooperator] = useState([]);
    const [dataChartStatus, setDataChartStatus] = useState([]);
    const [mostrarBoton, setMostrarBoton] = useState(false);
    const [arrayIdChecked, setArrayIdChecked] = useState([]);
    const [tableData, setTableData] = useState({});

    useEffect(() => { 
        getContributor(); 
        getStudentsOfContributor();
    }, []);
    
    const handleButton = (e) => {
        let id = e.target.id;
        let isChecked = e.target.checked;

        console.log(id, isChecked);
        
        if (isChecked) {
            arrayIdChecked.push(id);
        } else {
            let index = arrayIdChecked.indexOf(id);
            arrayIdChecked.splice(index, 1);
        }

        setArrayIdChecked(arrayIdChecked);
        
        if (arrayIdChecked.length > 0) {
            setMostrarBoton(true);
        } else {
            setMostrarBoton(false);
        }
        console.log(arrayIdChecked);
    }

    let columns = [
        {
            name: '',
            width: "10%",
            cell: (row) => loaded && <div class="flex items-center justify-center">
                <input id={row.student_id} type="checkbox" className="w-4 h-4 bg-blue-900 checkbox-multiple" onClick={handleButton}/>
            </div>
        },
        {
            name: '#',
            width: "10%",
            cell: (row) => <b>
                {loaded && row.student_id}
            </b>
        },
        {
            name: 'Nombres',
            width: "25%",
            cell: (row) =>
                <div>
                    <Link to={"/VerBeneficiarios/" + row.student_id} className='hover:border-b-2 hover:border-blue-900'>
                        {loaded && row.names}
                    </Link>
                </div>

        },
        {
            name: 'Apellidos',
            width: "25%",
            cell: (row) => <div>{loaded && row.lastnames}</div>
        },
        {
            name: 'Estado',
            width: "30%",
            cell: (row) => row.internship_status == 1 ? <div>En espera</div> : row.internship_status == 2 ? <div>{loaded && row.start_date} - En proceso</div> : row.internship_status == 3 ? <div>Retirado</div> : <div>Concluida</div>
        }
    ];

    const getContributor = async() =>{
        const response = await getOrDeleteRequest(
            `contributor/find/${id}`,
            'GET'
        );
        if(response.type == 'error'){
            console.error(response.message);
            return;
        }
        console.log(response);
        setMostrarBoton(false);
        setCooperator(response);
        setDataChartStatus(filterDataChartDonut(response.chart, 'status'));
        setLoaded(true);
    }

    const getStudentsOfContributor = async() =>{
        setLoaded(false);
        const response = await getOrDeleteRequest(
            `contributor/students/${id}`,
            'GET'
        );
        if(response.type == 'error'){
            console.error(response.message);
            return;
        }
        console.log(response);
        setMostrarBoton(false);
        setDataStudents(response);
        setDataSearch(response);
        setLoaded(true);
    }

    const objF = {
        getContributor: getContributor, 
        getStudentsOfContributor: getStudentsOfContributor
    }

    const objC = {
        columns: columns,
        dataStudents: dataStudents, 
        cooperator: cooperator, 
        loaded: loaded, 
        setLoaded: setLoaded, 
        mostrarBoton: mostrarBoton, 
        arrayIdChecked: arrayIdChecked, 
        setArrayIdChecked: setArrayIdChecked,
        setDataStudents: setDataStudents,
        setDataSearch: setDataSearch,
        dataSearch: dataSearch,
        id: id,
        dataChartStatus: dataChartStatus
    }

    return {  objC, objF  }
}