import { useCallback, useEffect } from "react";
import { useState } from "react";
import Cropper from "react-easy-crop";
import Preloader from "../../Preloader/Preloader";

const ModalCropImage = ({ setShowModalCropImage, setFile, setPreview, img, funVaciarFoto, showPopup, setFormulario, setFileNames, formulario, fileNames }) => {
    const [image, setImage] = useState(null);
    const [cropper, setCropper] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [objectURL, setObjectURL] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedArea, setCroppedArea] = useState(null);
    const [loading, setLoading] = useState(false);

    // Quiero que el area de cortar sea un cuadrado de 1000 x 1000
    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const createImage = (url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
            image.src = url;
        });
    };

    const handleClose = () => {
        setImage(null);
        setCropper(null);
        setZoom(1);
        setObjectURL(null);
        setCroppedAreaPixels(null);
        setCroppedImage(null);
        setCroppedArea(null);
        setCrop({ x: 0, y: 0 });
        funVaciarFoto();
        setFormulario({ ...setFormulario, photo: "" });
        showPopup(false, setShowModalCropImage);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels, "croppedArea, croppedAreaPixels");
        setCroppedArea(croppedArea);
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    // const dataURLToBlob = (dataURL) => {
    //     const BASE64_MARKER = ";base64,";
    //     if (dataURL.indexOf(BASE64_MARKER) === -1) {
    //         const parts = dataURL.split(",");
    //         const contentType = parts[0].split(":")[1];
    //         const raw = parts[1];

    //         return new Blob([raw], { type: contentType });
    //     }

    //     const parts = dataURL.split(BASE64_MARKER);
    //     const contentType = parts[0].split(":")[1];
    //     const raw = window.atob(parts[1]);
    //     const rawLength = raw.length;
    //     const uInt8Array = new Uint8Array(rawLength);

    //     for (let i = 0; i < rawLength; ++i) {
    //         uInt8Array[i] = raw.charCodeAt(i);
    //     }

    //     return new Blob([uInt8Array], { type: contentType });
    // };

    async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea;
        canvas.height = safeArea;

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate((rotation * Math.PI) / 180);
        ctx.translate(-safeArea / 2, -safeArea / 2);

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        );
        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        );

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file));
                console.log(file, "file");
                console.log(URL.createObjectURL(file), "URL.createObjectURL(file)");

                // Reducir los pixeles a 1000 x 1000 del file
                const image = new Image();
                image.src = URL.createObjectURL(file);
                image.onload = function () {
                    const elem = document.createElement('canvas');
                    elem.width = 1000;
                    elem.height = 1000;
                    const ctx = elem.getContext('2d');
                    // img.width and img.height will contain the original dimensions
                    ctx.drawImage(image, 0, 0, 1000, 1000);
                    ctx.canvas.toBlob((blob) => {
                        const file = new File([blob], fileNames.photo, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });
                        console.log(file, "file");

                        setFileNames({ ...fileNames, photo: file.name, urlPhoto: URL.createObjectURL(file) });

                        setFormulario({ ...formulario, photo: file });

                        // Url de la imagen
                        console.log(URL.createObjectURL(file), "URL.createObjectURL(file)");
                    }, "image/jpeg");
                }
                setLoading(false);
            }, "image/jpeg");
        });
    }


    useEffect(() => {
        if (img) {
            setImage(URL.createObjectURL(img));
        }
    }, [img]);

    return (
        <>
            <div
                className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-full my-6 mx-auto max-w-4xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-2xl font-semibold text-blue-900">
                                Recortar imagen
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed relative">
                                <div className="py-4 px-6" style={{
                                    height: window.innerHeight - 250
                                }}>
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        aspect={1 / 1}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                    />
                                    {
                                        loading &&
                                        <>
                                            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center sombra">
                                                <Preloader />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                            <button
                                className="bg-blue-950 text-white font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center disabled:opacity-50 disabled:cursor-progress"
                                type="button"
                                onClick={() => {
                                    setLoading(true);
                                    getCroppedImg(image, croppedAreaPixels).then((res) => {
                                        showPopup(false, setShowModalCropImage);
                                        setLoading(false);
                                    });
                                }}
                                disabled={loading}
                            >
                                Recortar
                            </button>
                            <button
                                className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center disabled:opacity-50 disabled:cursor-progress"
                                type="button"
                                onClick={() => {
                                    handleClose();
                                    setFileNames({ ...fileNames, photo: "" });
                                }}
                                disabled={loading}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default ModalCropImage;