import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from 'js-cookie';
import withReactContent from 'sweetalert2-react-content';

import { getOrDeleteRequest } from "../../utils/util";

const ModalAgregar = (props) => {
    const MySwal = withReactContent(Swal);

    const tokenAPP = JSON.parse(Cookies.get('Bearer')).token;

    const datosFormulario = {
        nombre: "",
        apellido: "",
        correoelectronico: "",
        rol: "",
        contraseña: "",
        confirmarpassword: "",
        usuario: "",
        estado: ''
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [loaded, setLoaded] = useState(false);
    const [formulario, setFormulario] = useState(datosFormulario);
    const [contrasena, setcontrasena] = useState(true);
    const [validacion, setvalidacion] = useState("La contraseña debe contener 8 caracteres")
    const [userType, setUserType] = useState([]);
    const [alerta, setAlerta] = useState([initialStateInput]);
    
    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name)
        console.log(value)
        if (name == "contraseña"){
            setcontrasena(false)
          //console.log((/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)),"Contra") 

        if (value.length >= 8){

            setcontrasena(true)
            
            if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)) {
                setcontrasena(true)
              }else{
                setvalidacion("Debe contener caracteres especiales (/*%#!)")

                setcontrasena(false) 
              }
        }else{
            setcontrasena(false)
            setvalidacion("La contraseña debe contener 8 caracteres")
        }
           }else{
            setcontrasena(true)
           }
           


        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const handleLoginsession = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "nombre", value: formulario.nombre },
            { nombre: "apellido", value: formulario.apellido },
            { nombre: "correoelectronico", value: formulario.correoelectronico },
            { nombre: "rol", value: formulario.rol },
            { nombre: "contraseña", value: formulario.contraseña },
            { nombre: "confirmarpassword", value: formulario.confirmarpassword },
            { nombre: "usuario", value: formulario.usuario },
            { nombre: "estado", value: formulario.estado }
        ];
        console.log(formulario);
        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        console.log(datosValidados);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length >= 5) {

            if (formulario.contraseña == formulario.confirmarpassword) {
                console.log("Enviar al servidor");
                RegistroUsuario()
            } else {
                if (formulario.contraseña.length == 8 && formulario.confirmarpassword.length == 8) {

                } else {
                    MySwal.fire({
                        position: 'center',
                        icon: 'error',
                        title: ("La contraseña debe contener 8 caracteres minimo"),
                        showConfirmButton: false,
                        timer: 3000
                    });
                }

                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: ("Verifique que sus contraseñas sean iguales!"),
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        };

    };

    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;

        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {

            switch (valorInput.nombre) {
                case 'nombre': {
                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }

                case 'apellido': {
                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;
                }

                case 'correoelectronico': {

                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;

                }

                case 'rol': {

                    if (valorInput.value === '' || valorInput.value === null || valorInput.value === "null" || valorInput.value === undefined) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;

                }


                case 'contraseña': {

                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;

                }
                case 'confirmarpassword': {

                    if (valorInput.value === '' || valorInput.value === null) {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });

                    } else {

                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })

                    }

                    break;

                }




                default: {


                    break;
                }
            }

        })

        return errors;
    }

    const RegistroUsuario = async () => {


        const url = `${process.env.REACT_APP_API_URI}user/register`;
        console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        const newdata = {
            names: formulario.nombre,
            lastnames: formulario.apellido,
            email: formulario.correoelectronico,
            type: formulario.rol,
            password: formulario.contraseña,
            password_confirmation: formulario.confirmarpassword

        }
        console.log(newdata);
        // console.log(url, login, config)
        axios.post(url, newdata, config)
            .then(response => {
                console.log(response);
                if (response.data.message) {
                    MySwal.fire({
                        position: 'center',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setFormulario(datosFormulario)

                    setTimeout(() => {
                        props.setShowModalAgregar(false)
                    }, "4000")
                    setTimeout(() => {
                        props.data();
                    }, "4500");

                }

            }).catch(err => {
                console.log(err);
                MySwal.fire({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });

            });
    };

    const loadData = useCallback(async () => {
        let response = await getOrDeleteRequest(
            "user-type/filter",
            'GET'
        );
        
        if (response.length < 0) {       
            console.log("Empty response");     
            return;
        }
        console.log(response);
        if (response.type === "error") {
            setUserType([]);    
        } else {
            setUserType(response);
        }
        
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (!loaded) {
            loadData();
        }
    }, [loadData]);

    return (
        <>
            <div
                className="block overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative w-full flex flex-col  bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-950">
                                Agregar Usuario
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-gray-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none transition-colors hover:text-gray-700"
                                onClick={() => props.setShowModalAgregar(false)}
                            >
                                <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full" onSubmit={handleLoginsession}>
                                    <div className="flex flex-wrap py-4 px-6">

                                        <div className="w-full md:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4"htmlFor="inline-full-name">
                                                Nombre
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="nombre" id="nombre" type="text" value={formulario.nombre} onChange={ManejarEventoDeInputs} />
                                            {
                                                alerta.filter(input => input.valorInput == "nombre" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4"htmlFor="inline-full-name">
                                                Apellido
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="apellido" id="apellido" type="text" value={formulario.apellido} onChange={ManejarEventoDeInputs} />
                                            {
                                                alerta.filter(input => input.valorInput == "apellido" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4"htmlFor="inline-full-name">
                                                Correo electrónico
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="correoelectronico" id="correo electronico" value={formulario.correoelectronico} onChange={ManejarEventoDeInputs} type="text" />
                                            {
                                                alerta.filter(input => input.valorInput == "correoelectronico" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4"htmlFor="inline-full-name">
                                                Rol
                                            </label>
                                            <select className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:bg-white focus:border-blue-950" name="rol" id="rol" value={formulario.rol} onChange={ManejarEventoDeInputs}>
                                                <option hidden selected value="null">Seleccione un rol</option>
                                                {userType.map((userType) => (
                                                    <option value={userType.id}>{userType.name}</option>
                                                ))}
                                            </select>
                                            {
                                                alerta.filter(input => input.valorInput == "rol" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4"htmlFor="inline-full-name">
                                                Contraseña
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="contraseña" id="contraseña" value={formulario.contraseña} onChange={ManejarEventoDeInputs} type="password" />
                                            {!contrasena ? (
                                            <div className="text-red-500"> {validacion}</div>
                                            ) : (
                                                <div> </div>
                                            )}
                                            {
                                                alerta.filter(input => input.valorInput == "contraseña" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>

                                        <div className="w-full md:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold mb-1 md:mb-0 pr-4"htmlFor="inline-full-name">
                                                Confirmar Contraseña
                                            </label>
                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="confirmarpassword" id="confirmarpassword" value={formulario.confirmarpassword} disabled ={!contrasena} onChange={ManejarEventoDeInputs} type="password" />
                                            {
                                                alerta.filter(input => input.valorInput == "confirmarpassword" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b justify-center">
                                            <button
                                                className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                                type="submit"

                                            >
                                                Guardar
                                            </button>

                                            <button
                                                className="bg-red-500 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                                type="button"
                                                onClick={() => props.setShowModalAgregar(false)}
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/*footer*/}

                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default ModalAgregar;
