const SearchInput = (props) => {
    return (<>
        <div className="w-full md:w-1/3 px-2">
            <div className="relative mb-2">
                <label htmlFor="search" className="block mb-2 text-white">Búsqueda</label>
                <input
                    type="text"
                    id="search"
                    name="search"
                    className="text-sm rounded block w-full p-2.5 text-gray-500 bg-white"
                    value={props.dataFilterSearch}
                    autoComplete="none"
                    placeholder='Buscar...'
                    onChange={props.changeData}
                    disabled={props.disabled !== undefined ? props.disabled : false}
                />
            </div>
        </div>
    </>)
}
export default SearchInput;