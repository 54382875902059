import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Cookies from "js-cookie";
import { useEffect } from "react";





const EditMunicipio = (props) => {

    const MySwal = withReactContent(Swal);
    const tokenAPP = JSON.parse(Cookies.get('Bearer')).token;
    const [departamentos, setDepartamentos] = useState(undefined);

    const datosFormulario = {
        municipio: "",
        departamento: ''
    };

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);
    const [alerta, setAlerta] = useState([initialStateInput]);


    const ManejarEventoDeInputs = (event) => {


        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name)
        console.log(value)


        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const handleLoginsession = (e) => {
        e.preventDefault(); //previene el comportamiento por defecto que trae consigo el evento

        //Ordenamos los datos para enviarlos a la validacion
        let verificarInputs = [
            { nombre: "municipio", value: formulario.municipio},
            { nombre: "departamento", value: formulario.departamento}

        ];

        //Enviamos los datos a la funcion de validacion y recibimos las validaciones
        const datosValidados = ValidarInputs(verificarInputs);

        // console.log(datosValidados);

        //Enviamos las validaciones al estado que se va encargar de mostrarlas en el formulario
        setAlerta(datosValidados);
        //Obtenemos el dato de las validaciones
        const totalValidaciones = datosValidados.filter(input => input.estado === false).map
            ((estado) => { return false });

        //console.log('Total de validaciones', totalValidaciones.length);

        //Validacion para enviar los datos al servidor
        if (totalValidaciones.length >= 2) {
            //console.log("Enviar al servidor");
            EnviarDatos();
        };

    };
 
    const ValidarInputs = (data) => {
        console.log(data);
        //Declaramos el arreglo el cual se va encargar de guardar las valudaciones
        let errors = [];
        //Recibimos los datos a Validar
        const datosDelFormulario = data;
      
        //Proceso de validacion
        datosDelFormulario.map((valorInput) => {
      
            switch (valorInput.nombre) {
                case 'municipio': {
                    if (valorInput.value === '' || valorInput.value === null) {
      
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });
      
                    } else {
      
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })
      
                    }
      
                    break;
                }
    
                case 'departamento': {
                    if (valorInput.value === '' || valorInput.value === null) {
      
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: 'Campo requerido',
                            estado: true
                        });
      
                    } else {
      
                        errors.push({
                            valorInput: valorInput.nombre,
                            mensaje: '',
                            estado: false
                        })
      
                    }
      
                    break;
                }
      
                default: {
      
      
                    break;
                }
            }
      
        })
      
        return errors;
      }


      const EnviarDatos = async () => {
        // console.log(formulario)

        const NewData = {
            name: formulario.municipio,
            state: formulario.departamento


        }
        // console.log(NewData);
        // return;
        const IdMunicipio = props.EditarMunicipio
        const url = `${process.env.REACT_APP_API_URI}city/update/${IdMunicipio}`;
        console.log(NewData, url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }
        console.log(config)
        try {

            const response = await axios.put(url, NewData, config);

            if (response.data.message) {
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });
                setFormulario(datosFormulario)

                setTimeout(() => {
                    props.setShowModalEditar(false)
                }, "4000")
                setTimeout(() => {
                    window.location.replace('/Municipios');
                }, "4500")

            }



            console.log(response.data);







        } catch (err) {
            console.error(err);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    useEffect(() => {

        // List info Experience Freelancer
        const MunicipioInformation = async () => {
        
        const IdMunicipio = props.EditarMunicipio 
         console.log(IdMunicipio, "Aqui esta")
            const urlCountry = `${process.env.REACT_APP_API_URI}city/update/${IdMunicipio}`;
              
              let config = {
                 headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${tokenAPP}`
                 }
              };
              
              try {
  
                 const response = await axios.put(urlCountry, config);
  
                 console.log(response.data, "holi");
  
                 
                 const datosFormulario = {
                    nombre:response.data.name,
                   
                };
                 setFormulario(datosFormulario);
                 
  
  
              } catch (err) {
                 console.error(err);
              }
  
           
        }
        MunicipioInformation();
  
     }, []);


     const consultarDepartamentos = async () => {
        const url = `${process.env.REACT_APP_API_URI}state/filter/1`; // Siempre consultando El Salvador
        // console.log(url);
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${tokenAPP}`
            }
        }

        axios.get(url, config)
        .then(response => {
            console.log(response.data);
            formulario.departamento= props.IdDepartamento
            setDepartamentos(response.data);
            setTimeout(() => {
                formulario.departamento= props.IdDepartamento 
            }, "1500")
        })
        .catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        consultarDepartamentos();
    }, []);

   const consultarMunicipios = async () => {
    const IdMunicipio = props.EditarMunicipio 
    const url = `${process.env.REACT_APP_API_URI}city/find/${IdMunicipio}`;

    let config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${tokenAPP}`
        }
    }

    axios.get(url, config)
    .then(response => {
        console.log(response.data.name);
        formulario.municipio= response.data.name
       // setDepartamentos(response.data);
    })
    .catch(error => {
        console.log(error);
    });


   };

   useEffect(() => {
    consultarMunicipios();
}, []);


console.log(formulario, "sssssssss");






    return(
        <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="w-[127%] border-0 rounded-lg shadow-lg relative w-full flex flex-col  bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold text-blue-950">
                        Editar Departamento
                    </h3>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => props.setShowModalEditar(false)}
                    >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                    </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    <div className="my-4 text-slate-500 text-lg leading-relaxed">
                        <form className="w-full max-w-sm" onSubmit={handleLoginsession}>
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                <label className="block text-blue-950 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                  Municipio
                                                </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950" name="municipio" id="nombre" type="text" value={formulario.municipio} onChange={ManejarEventoDeInputs} />
                                    {
                                        alerta.filter(input => input.valorInput == "nombre" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                        </div>

                                        ))
                                    }

                                </div>
                            </div>

                            <div className="md:flex md:items-center ">
                                        <div className="md:w-1/3">
                                            <label className="block text-blue-950 font-bold  mb-1 md:mb-0 pr-4">
                                                Departamento
                                            </label>


                                        </div>
                                        <div className="md:w-2/3 m-2 ">
                                            <select className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white' name="departamento" id="type" value={formulario.departamento}
                                                onChange={ ManejarEventoDeInputs }
                                            >
                                                { departamentos && (
                                                    <>
                                                        <option>Seleccione un Departamento</option>
                                                        {
                                                            departamentos.map((item, index)=>
                                                                <option 
                                                                    key={ index }
                                                                    value={item.id}
                                                                >{item.name}</option>
                                                            )
                                                        }
                                                    </>
                                                ) }
                                            </select>

                                            {
                                                alerta.filter(input => input.valorInput == "Departamento" && input.estado === true).map(message => (<div className=""> <span className="text-red-500">{message.mensaje}</span>
                                                </div>

                                                ))
                                            }


                                        </div>
                                    </div>






                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b justify-center">

                                <button
                                    className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                    type="submit"

                                >
                                    Guardar
                                </button>

                                <button
                                    className="bg-red-500 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                    type="button"
                                    onClick={() => props.setShowModalEditar(false)}
                                >
                                    Cerrar
                                </button>
                            </div>


                        </form>
                    </div>
                </div>
                {/*footer*/}

            </div>
        </div>
    </div>
    )
}

export default EditMunicipio;