export const FiltersStudentsTrainingLogic = (params) =>{

    const { setDataFilter, dataFilter, initDataFilter, setDataSearch, dataStudents } = params;

    const changeData = ({target:{ name, value }}) =>{
        switch (name) {
            case 'search':
                let srch;
                srch = dataStudents.filter((item) => {
                    if ( item.names.toString().toLowerCase().includes(value.toLowerCase()) ||
                         item.lastnames.toString().toLowerCase().includes(value.toLowerCase()) ||
                         item.email.toString().toLowerCase().includes(value.toLowerCase())) {
                        return item;
                    }
                });
                setDataSearch(srch);
                dataFilter.search = value;
                break;
        
            default:
                setDataFilter({...dataFilter, [name]: value});
                break;
        }
    }

    const changeDataRSelect = (e) => {
        setDataFilter({ ...dataFilter, [e.name]: e });
    }

    const resetFilter = () => {
        setDataFilter(initDataFilter);
    }

    const objF = {
        changeData: changeData,
        changeDataRSelect: changeDataRSelect,
        resetFilter: resetFilter
    }
    
    return { objF }
}