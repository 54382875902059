import React, { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalEditar from "./modals/modaleditar";
import ModalAgregar from "./modals/modalagregar";
import Cookies from "js-cookie";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { paginationProps, getOrDeleteRequest, postOrPutRequest } from "../utils/util";
import PreloaderTables from "../PreloaderTables/PreloaderTables";

const Usuarios = () => {
    const initialListarDatos = {
        id: 0,
        names: "",
    }

    const [showModal, setShowModal] = React.useState(false);
    const [ShowModalAgregar, setShowModalAgregar] = React.useState(false);
    const [EditarUsuario, setEditarUsuarioId] = React.useState();
    const MySwal = withReactContent(Swal);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([initialListarDatos]);

    const DeleteUser = async (IdUsuario) => {
        const url = `${process.env.REACT_APP_API_URI}user/disable/${IdUsuario}`;
        
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        }else{
            window.location = "/"
        }
        console.log(url, config)
        try {

            const response = await axios.delete(url, config);

            if (response.data.message) {
                MySwal.fire({
                    position: 'center',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });


                setTimeout(() => {
                    setShowModal(false)
                }, "4000")
                setTimeout (() => {
                    window.location.replace('/Usuarios');
                },"4500") 

            }

            console.log(response.data);
        } catch (err) {
            console.error(err);
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    const listarUsuarios = async () => {
        let response = await getOrDeleteRequest(
            'user/index', 
            'GET'
        );

        if (response.length < 0) {
            return;
        }

        if (response.type === "error") {
            setData([]);
            return;
        } 
        
        let filterData = response.filter(item => item.status == 1);

        setData(filterData);
        setLoaded(true);

        return;
    }

    useEffect(() => {
        if (!loaded) {
            listarUsuarios();
        }
    }, []);

    const buttonEditar = (estadomodal, IdUsuario) => {
        console.log(estadomodal, IdUsuario);
        setEditarUsuarioId(IdUsuario)
        setShowModal(estadomodal)
    }

    const columns = [
        {
            name: '#',
            width: "75px",
            cell: (row,index) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Nombre',
            width: "130px",
            cell: (row) => <div>{loaded && row.names}</div>
        },
        {
            name: 'Apellido',
            width: "130px",
            cell: (row) => <div>{loaded && row.lastnames}</div>
        },
        {
            name: 'Correo electrónico',
             width: "300px",
            cell: (row) => <div>{loaded && row.email}</div>
        },
        {
            name: 'Registrado',
            width: "210px",
            cell: (row) => <div className="font-bold">{loaded && row.registered}</div>
        },
        {
            name: 'Estado',
            width: "130px",
            cell: (row) =>    
            <div className="flex items-center">
                {(row.status == 1) ? (
                    <>
                        <div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> Activo
                    </>
                ) : (
                    <>
                        <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div> Inactivo
                    </>
                )}

            </div>
        },
        {
            name: 'Editar',
             width: "90px",
            cell: (row) => <div> <button onClick={() => buttonEditar(true, row.id)} type="button" data-modal-toggle="user-modal" className="text-white bg-blue-950 hover:bg-blue-900 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center">
            <FontAwesomeIcon icon={faPenToSquare} />
        
        </button></div>
        },
        {
            name: 'Eliminar',
            width: "90px",
            
            cell: (row) => <div><button onClick={() => DeleteUser(row.id)} type="button" data-modal-toggle="delete-user-modal" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center">
            <svg className=" h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
             </button> </div>
        },
    ];

    const tableData = {
        columns,
        data
    };

    return (
        <div className="">
            <div className='ContainerOpenClass'>
                <div className=" p-4 bg-blue-950 block sm:flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="sm:flex">
                            <h1 className="text-xl sm:text-2xl font-semibold text-white">Usuarios</h1>
                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                                <button type="button" data-modal-toggle="add-user-modal" className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                    onClick={() => setShowModalAgregar(true)}>


                                    <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>

                                    Agregar Usuario
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-2 px-4 bg-blue-950">
                    <DataTableExtensions {...tableData} filterPlaceholder={"Buscar"} print={false}
                        export={false}>
                        {
                            loaded
                            ?
                            <DataTable
                                columns={columns}
                                data={data.length > 0 && data}
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                responsive
                                paginationComponentOptions={paginationProps}
                                noDataComponent={<div className="my-5">No hay registros</div>}
                            />
                            :
                            <PreloaderTables />
                        }
                    </DataTableExtensions>
                </div>
            </div>

            {/* Modal */}
            {showModal ? (<ModalEditar setShowModal={setShowModal} EditarUsuario={EditarUsuario} data={ listarUsuarios }></ModalEditar>) : null}

            {ShowModalAgregar ? (<ModalAgregar setShowModalAgregar={setShowModalAgregar} data={ listarUsuarios } ></ModalAgregar>) : null}


        </div>

    )
}

export default Usuarios;
