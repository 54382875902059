import { Card, DonutChart, Title, BarList } from "@tremor/react";

export const DonutTremor = (props) => {
    const valueFormatter = (number) => `${new Intl.NumberFormat("us").format(number).toString()}`;
    return (
        <Card className="w-full">
            <Title>{props.title}</Title>
            <DonutChart
                className="mt-6"
                data={props.data}
                category="value"
                index="name"
                valueFormatter={valueFormatter}
                colors={["slate", "indigo", "teal", "rose", "cyan", "amber", "yellow"]}
            />
        </Card>
    )
};

export const BarListTremor = ({ title, data }) => {
    const valueFormatter = (number) => `${new Intl.NumberFormat("us").format(number).toString()}`;
    return (
        <Card className="w-full">
            <Title>{title}</Title>
            <BarList
                className="mt-6"
                data={data}
                category="value"
                index="name"
                valueFormatter={valueFormatter}
                showAnimation={true}
                colors={["slate", "indigo", "teal", "rose", "cyan", "amber", "yellow"]}
            />
        </Card>
    )
};
