import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

const SideBarDropdown = ({ icon, image, text, items, role, active, showMenu, setShowMenu }) => {
    const dropdownRef = useRef(null);
    const contentRef = useRef(null);
    const iconRef = useRef(null);

    const { pathname } = useLocation();
    // console.log(routes);
    // console.log(active);
    const handleClick = () => {
        if (dropdownRef.current.classList.contains('active')) {
            dropdownRef.current.classList.remove('active');
            contentRef.current.classList.toggle('hidden');
            iconRef.current.classList.remove('rotate-90');
            return;
        }

        // Cerramos todos los dropdowns excepto el que se está abriendo
        let dropdowns = document.querySelectorAll('.sidebar-dropdown');
        dropdowns.forEach((dropdown) => {
            if (dropdown !== dropdownRef.current) {
                dropdown.classList.remove('active');
                dropdown.querySelector('.sidebar-content').classList.remove('active');
                dropdown.querySelector('.sidebar-icon').classList.remove('rotate-90');
                dropdown.querySelector('.sidebar-content').nextSibling.classList.add('hidden');
            }
        });

        dropdownRef.current.classList.toggle('active');
        contentRef.current.classList.remove('hidden');
        iconRef.current.classList.toggle('rotate-90');
        return;
    };

    return (
        <div className="sidebar-dropdown">
            <div className={ `sidebar-content ${ active ? 'active' : '' }` } ref={ dropdownRef } onClick={ handleClick }>
                <img src={ image } className="h-[25px] mr-6" alt="" />
                <div className="inline-flex items-center justify-between w-full">
                    <span className="sidebar-title">{ text }</span>
                    <FontAwesomeIcon icon={ icon } className={ `sidebar-icon ${ active ? 'rotate-90' : '' }` } ref={ iconRef } />
                </div>
            </div>
            <div className={ `flex w-full flex-col px-2 ${ active ? '' : 'hidden' }` } ref={ contentRef }>
                { items.map((item, index) => {
                    if (role !== undefined && item.roles.includes(role) === false) {
                        return null;
                    }
                    
                    return (
                        <Link 
                            key={ index }
                            to={ `/${item.slug}` } 
                            onClick={() => {
                                (!showMenu ? setShowMenu(true) : setShowMenu(false))
                            }} 
                            className={ `sidebar-menu ${ pathname === `/${item.slug}` ? 'current' : '' }` }
                        >
                            <div className="ml-3 flex items-center justify-between w-full">
                                <span>{ item.content }</span>
                                <span className="ping relative flex h-3 w-3">
                                    <span className="animate-ping absolute inline-flex rounded-full bg-sky-400 opacity-75 h-full w-full"></span>
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                </span>
                            </div>
                        </Link>
                    );
                }) }
            </div>
        </div>
    );
};

export default SideBarDropdown;
