import { useEffect, useState } from "react";
import SectionProfile from "../../layouts/SectionProfile";
import { getOrDeleteRequest, paginationProps, showPopup } from "../utils/util";
import { filterDataChartDonut } from "../utils/utilObject";
import { Link, useParams } from "react-router-dom";
import BackButton from "../BackButton";
import PreloaderComponentSectionProfile from "../Estudiantes/Preloaders/PreloaderComponentSectionProfile";
import PreloaderTables from "../PreloaderTables/PreloaderTables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faListCheck } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import Filters from "./FiltersStudentsTraning";
import { DonutTremor } from "../Components/ChartsTremor";

import RankingModal from "./modals/RankingModal";
import ChambaticonBrand from "../../assets/chambaticon-brand.png";
// import { google } from 'googleapis';

const VerProgramaDeFormacion = () => {
	const initDataFilter = {
		search: "",
		status: 0,
		state: null,
		city: null,
		district: null,
		gender: 0,
	};

//   let id = useParams().id;
  	let { id } = useParams();
	const [data, setData] = useState({
		organizers: [],
		knowledge: [],
		program: "",
		alternate_name: "",
		campus_name: "",
		modality: "",
		start: "",
		end: "",
		specialty_name: null,
	});

	// Modals
	const [showModal, setShowModal] = useState(false);
	const [showModalRanking, setShowModalRanking] = useState(false);
	
	const [attendanceList, setAttendanceList] = useState("");
	const [dataChartStatus, setDataChartStatus] = useState([]);
	const [dataStudents, setDataStudents] = useState([]);
	const [dataSearch, setDataSearch] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [arrayIdChecked, setArrayIdChecked] = useState([]);
	const [mostrarBoton, setMostrarBoton] = useState(false);
	const [option, setOption] = useState(0);
	const [dataFilter, setDataFilter] = useState(initDataFilter);


	const MySwal = withReactContent(Swal);
	const documentURL = `https://docs.google.com/spreadsheets/d/${attendanceList}/edit#gid=0`;

  const getTraningProgram = async () => {
    const response = await getOrDeleteRequest(
      `program-schedule/find/${id}`,
      "GET"
    );
    if (response.length < 0) {
      console.log("Empty response");
      return;
    }
    if (response.type === "error") {
      setData([]);
      console.log(response);
    } else {
      setData(response);
      setDataChartStatus(filterDataChartDonut(response.chart, "status"));
      console.log(response, "response");
    }
    if (response.attendance_list) {
      const extractIdSheets = response.attendance_list.split("d/");
      const idSheets = extractIdSheets[1].split("/");
      console.log(extractIdSheets);
      setAttendanceList(idSheets[0]);
    }
    setLoaded(true);
  };

	const getStudentsProgram = async (ft) => {
	setLoaded(false);
	const endPoint =
		`program-schedule/students/${id}?` +
		`${ft.gender !== 0 ? "gender=" + ft.gender + "&" : ""}` +
		`${ft.state !== null ? "state=" + ft.state.value + "&" : ""}` +
		`${ft.city !== null ? "city=" + ft.city.value + "&" : ""}` +
		`${ft.district !== null ? "district=" + ft.district.value + "&" : ""}` +
		`${ft.status !== 0 ? "status=" + ft.status + "&" : ""}`;
	const response = await getOrDeleteRequest(endPoint.slice(0, -1), "GET");
	if (response.length < 0) {
		console.log("Empty response");
		setLoaded(true);
		return;
	}
	if (response.type === "error") {
		setDataStudents([]);
		setDataSearch([]);
		console.log(response);
		setLoaded(true);
		return;
	}
	setDataStudents(response.students);
	setDataSearch(response.students);
	// console.log(response.students);
	setLoaded(true);
	};

	const getFeaturedStudents = async () => {
		
	};


	useEffect(() => {
		if (loaded === false) {
		getTraningProgram();
		getStudentsProgram(dataFilter);
		}
	}, []);

	useEffect(() => {
		getStudentsProgram(dataFilter);
	}, [dataFilter]);

	const handleButton = (e) => {
		let id = e.target.id;
		let isChecked = e.target.checked;

		if (isChecked) {
		arrayIdChecked.push(id);
		} else {
		let index = arrayIdChecked.indexOf(id);
		arrayIdChecked.splice(index, 1);
		}

		setArrayIdChecked(arrayIdChecked);

		if (arrayIdChecked.length > 0) {
		setMostrarBoton(true);
		} else {
		setMostrarBoton(false);
		}
	};

	const columns = [
		{
			name: "",
			width: "10%",
			cell: (row) => loaded && (
				<div class="flex items-center justify-center">
					<input
					id={row.id}
					type="checkbox"
					className="w-4 h-4 bg-blue-900 checkbox-multiple"
					onClick={handleButton}
					/>
				</div>
			),
		},
		{
			name: "Nombres",
			width: "20%",
			cell: (row) => (
				<div className="flex">
					<Link
						to={"/VerBeneficiarios/" + row.id}
						className="hover:border-b-2 hover:border-blue-950"
					>
						{loaded && row.names} {loaded && row.lastnames}
					</Link>
				</div>
			),
		},
		{
			name: "Email",
			width: "25%",
			cell: (row) => loaded && <div className={ `font-bold ${ row.email === null ? "text-red-500" : "text-blue-700" }` }>
				{row.email ? row.email : "No registrado"}
			</div>,
		},
		{
			name: "Estado",
			width: "10%",
			cell: (row) => {
				let rowStatus = null;

				switch (row.course_status) {
					case 1:
						rowStatus = "Aplicado";
						break;

					case 2:
						rowStatus = "Preseleccionado";
						break;

					case 3:
						rowStatus = "Seleccionado";
						break;

					case 4:
						rowStatus = "No completado";
						break;

					case 5:
						rowStatus = "Completado";
						break;

					case 6:
						rowStatus = "En proceso";
						break;

					case 7:
						rowStatus = "No iniciado";
						break;

					default:
						rowStatus = "No especificado";
						break;
				}
				return loaded && <div>{rowStatus}</div>;
			},
		},
		{
			name: "Test de rendimiento",
			width: "15%",
			cell: (row) => loaded && (
				<div className="w-full flex justify-center">
					{ (row.course_status === 5 || row.course_status === 6) ? (
						<Link
							// to={"/VerTestDeRendimiento/" + row.id}
							to={ `/ranking-test/${id}/${row.id}` }
							className="text-2xl text-white bg-green-600 text-center px-2 py-1 rounded-lg transition-colors hover:bg-green-800"
						>
							{loaded && <FontAwesomeIcon icon={faListCheck} />}
						</Link>
					) : (
					<span className="text-base text-slate-800 text-center px-2 py-1 rounded-lg transition-colors">
						No disponible
					</span>
					)}
				</div>
			),
		},
		{
			name: "Chambaticon",
			width: "20%",
			cell: (row) => loaded && (
				<div className="w-full">
					{ (row.complete_external_profile === 1) && (
						<>
							<img
								src={ ChambaticonBrand }
								className="w-10 h-10"
								alt=""
							/>
						</>
					) }
					{ (row.complete_external_profile === 0) && (
						<>
							<FontAwesomeIcon icon={ faCircleXmark } className="text-red-500 text-2xl" />
						</>
					) }
				</div>
			),
		},
	];

	const modificarEstado = async () => {
		if (option == 0) {
		MySwal.fire("Error", "Debe seleccionar una opción", "error");
		return;
		} else {
		const url = `${process.env.REACT_APP_API_URI}program-schedule/bulk-status/${id}`;

		let config = {
			headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			},
		};

		const dataInsertar = {
			students: arrayIdChecked,
			status: option,
		};

		console.log(dataInsertar, "dataInsertar");

		axios
			.post(url, dataInsertar, config)
			.then((response) => {
			console.log(response);
			if (response.data.type === "error") {
				MySwal.fire("Error", response.data.message, "error");
			} else {
				MySwal.fire("Éxito", response.data.message, "success").then(() => {
				setShowModal(false);
				getTraningProgram();
				setArrayIdChecked([]);
				//Pasar a checked false a todos los checkbox que tengan la clase checkbox-multiple
				let checkboxes = document.querySelectorAll(".checkbox-multiple");
				checkboxes.forEach((item) => {
					item.checked = false;
				});
				// Obtenemos el listado de beneficiarios nuevamente
				getStudentsProgram(dataFilter);
				setMostrarBoton(false);
				});
			}
			})
			.catch((err) => {
			console.log(err);
			let message = err.response.data.message;
			MySwal.fire("Error", message, "error");
			});
		}
	};

	return (
		<>
			<div className="flex flex-wrap">
				<div className="flex flex-wrap-reverse md:flex-wrap w-full md:px-10">
				<div className="flex items-center w-full md:w-1/4 justify-start ">
					<BackButton history={Cookies.get("prevLocation")} />
				</div>
				<div className="flex items-center justify-center w-full md:w-2/4">
					<h1 className="my-5 text-white text-center font-bold text-4xl">
					Programa de formación
					</h1>
				</div>
				<div className="flex items-center w-full md:w-1/4 justify-end ">
					<br />
				</div>
				</div>
			</div>
			<SectionProfile title="Información">
				{loaded ? (
				<>
					<div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
					<span className="block font-bold text-black">
						Nombre del programa
					</span>
					{/* <br /> */}
					<span className="block">{loaded && data.program}</span>
					</div>
					<div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
					<span className="block font-bold text-black">
						Nombre alternativo del programa
					</span>
					{/* <br /> */}
					<span className="block">{loaded && data.alternate_name}</span>
					</div>
					{
						loaded && data.specialty_name !== null && (
							<div className="w-full sm:w-1/3 p-2 text-sm text-gray-500 my-5 text-center">
							<span className="block font-bold text-black">
								Nombre del certificado
							</span>
							{/* <br /> */}
							<span className="block">{loaded && data.specialty_name}</span>
							</div>
						)
					}
					<div className={`w-full sm:w-1/${loaded && data.specialty_name !== null ? "2" : "3"} p-2 text-sm text-gray-500 my-5 text-center`}>
					<span className="block font-bold text-black">Campus</span>
					{/* <br /> */}
					<span className="block">{loaded && data.campus_name}</span>
					</div>
					<div className={`w-full sm:w-1/${loaded && data.specialty_name !== null ? "2" : "3"} p-2 text-sm text-gray-500 my-5 text-center`}>
					<span className="block font-bold text-black">Modalidad</span>
					{/* <br /> */}
					<span className="block">{loaded && data.modality}</span>
					</div>
					<div className={`w-full sm:w-1/${loaded && data.specialty_name !== null ? "2" : "3"} p-2 text-sm text-gray-500 my-5 text-center`}>
					<span className="block font-bold text-black">
						Fecha de inicio
					</span>
					{/* <br /> */}
					<span className="block">{loaded && data.start}</span>
					</div>
					<div className={`w-full sm:w-1/${loaded && data.specialty_name !== null ? "2" : "3"} p-2 text-sm text-gray-500 my-5 text-center`}>
					<span className="block font-bold text-black">
						Fecha de finalización
					</span>
					{/* <br /> */}
					<span className="block">{loaded && data.end}</span>
					</div>
				</>
				) : (
				<PreloaderComponentSectionProfile
					filas={6}
					filas_sm={2}
					filas_md={2}
				/>
				)}
			</SectionProfile>
			<SectionProfile title="Cooperantes">
				{loaded ? (
				<div className="w-full p-2 text-sm text-gray-500 my-5 text-center relative">
					{/* <span className="font-bold text-black">Idiomas</span> */}
					{/* <br /> */}
					{data.organizers.length > 0 && (
					<div className="flex justify-center pt-1">
						{data.organizers.map((item, index) => {
						return (
							<Link
							to={`/DetalleDelCooperante/${item.value}`}
							key={index}
							className="language-item-blue"
							>
							{item.label}
							</Link>
						);
						})}
					</div>
					)}
				</div>
				) : (
				<PreloaderComponentSectionProfile
					filas={1}
					filas_sm={1}
					filas_md={1}
				/>
				)}
			</SectionProfile>
			{/* <SectionProfile title="Conocimientos">
				{loaded ? (
				<div className="w-full p-2 text-sm text-gray-500 my-5 text-center relative">
					{data.knowledge.length > 0 && (
					<div className="flex flex-wrap justify-center pt-1">
						{data.knowledge.map((item, index) => {
						return (
							<div
							key={index}
							className="language-item-blue w-full m-3 md:w-1/3"
							>
							{item.label}
							</div>
						);
						})}
					</div>
					)}
				</div>
				) : (
				<PreloaderComponentSectionProfile
					filas={1}
					filas_sm={1}
					filas_md={1}
				/>
				)}
			</SectionProfile> */}
				{/* BOTON PARA MODAL DE RANKING DE ESTUDIANTES */}
			<SectionProfile 
				title="Estadísticas"
				button={
					<button 
						className="btn-card-header disabled:opacity-50"
						onClick={() => showPopup(true, setShowModalRanking)}
					> 
						Ranking de Beneficiarios
					</button>
				}
			>
				<div className="bg-white p-5 mb-3 flex flex-wrap w-full">
				<div className="w-1/2 my-4">
					<DonutTremor
					data={dataChartStatus}
					title={"Beneficiarios por estados"}
					/>
				</div>
				{/* <div className="w-full my-4">
					<BarListTremor
					data={dataChartStatus}
					title={"Beneficiarios por destacados"}
					/>
				</div> */}
				</div>
			</SectionProfile>
			<SectionProfile
				title={`Beneficiarios ${
				loaded && dataStudents.length > 0
					? "(" + dataStudents.length + ")"
					: ""
				}`}
				button={
				<button
					onClick={() => {
					setShowModal(true);
					}}
					className="btn-card-header disabled:opacity-50"
					disabled={!mostrarBoton}
				>
					Acción por lote
				</button>
				}
			>
				<div className="w-full">
				<div className="rounded">
					<Filters
						dataFilter={dataFilter}
						setDataFilter={setDataFilter}
						initDataFilter={initDataFilter}
						getStudentsProgram={getStudentsProgram}
						loaded={loaded}
						setDataSearch={setDataSearch}
						dataStudents={dataStudents}
					/>
				</div>
				<div className="p-5 w-full">
					{loaded ? (
					<DataTable
						columns={columns}
						data={dataSearch}
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						responsive
						paginationComponentOptions={paginationProps}
						noDataComponent={<div className="my-5">No hay registros</div>}
					/>
					) : (
					<PreloaderTables />
					)}
				</div>
				</div>
			</SectionProfile>
			<SectionProfile title="Asistencia">
				<div className="w-full my-4 flex justify-center">
				{attendanceList == "" ? (
					<span className="italic text-xs text-gray-500">
					No se encontro hoja de cálculo
					</span>
				) : (
					<iframe src={documentURL} width="100%" height="600px" />
				)}
				</div>
			</SectionProfile>
			{showModal && (
				<>
				<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
					<div className="relative w-full my-6 mx-auto max-w-3xl">
					{/*content*/}
					<div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/*header*/}
						<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
						<h3 className="text-3xl font-semibold text-blue-950">
							Acción por lote
						</h3>
						<button
							className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
							onClick={() => setShowModal(false)}
						>
							<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
							×
							</span>
						</button>
						</div>
						{/*body*/}
						<div className="relative flex-wrap">
						<div className="mt-4 text-slate-500 text-lg leading-relaxed">
							<form className="w-full flex flex-wrap">
							<div className="w-full p-5">
								<h1 className="text-justify">
								<strong className="text-red-700">NOTA:</strong> En este apartado, los estados de beneficiario <strong>No iniciado</strong> y <strong>No completado</strong> no se encuentran disponibles debido a la necesidad de contar con datos precisos.
								</h1>
							</div>
							<div className="flex my-6 w-full justify-center items-center">
								<label className="block text-blue-950 font-bold md:text-right mb-1 md:mb-0 pr-4">
								Acción
								</label>
								<div className="mb-2 xl:w-100">
								<select
									data-te-select-init
									className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
									name="option"
									onChange={(e) => setOption(e.target.value)}
								>
									<option value="0" selected>
									Selecciona una opción
									</option>
									<option value="1">Aplicado</option>
									<option value="2">Pre seleccionado</option>
									<option value="3">Seleccionado</option>
									<option value="6">En proceso</option>
									<option value="5">Completado</option>
								</select>
								</div>
							</div>
							<div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b justify-center w-full">
								<div
								className="bg-blue-950 cursor-pointer text-white active:bg-blue-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
								onClick={modificarEstado}
								>
								Modificar
								</div>

								<div
								className="bg-red-500 cursor-pointer text-white active:bg-red-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center"
								onClick={() => setShowModal(false)}
								>
								Cerrar
								</div>
							</div>
							</form>
						</div>
						</div>
						{/*footer*/}
					</div>
					</div>
				</div>
				<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
			{ showModalRanking && (
				<RankingModal
					setModal={ setShowModalRanking }
					showModal={ showModalRanking }
					program={ id }
				/>
			) }
		</>
	);
};
export default VerProgramaDeFormacion;
