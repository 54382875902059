import { AddStudy } from './AddStudy';
const ModalAddStudy = (props) => {
    const params = {
        idStudent: props.idStudent,
        getStudies: props.getStudies,
        setShowModalStudy: props.setShowModalStudy,
        showPopup: props.showPopup
    }
    const { objC, objF } = AddStudy(params);
    const Select = props.Select;

    return (
        <>
            <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-violet-700">Agregar estudio</h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => { props.showPopup(false, props.setShowModalStudy); }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full flex flex-wrap" onSubmit={objF.AddStudySubmit}>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="institution">Centro de estudio</label>
                                        <input
                                            type="text"
                                            id='institution'
                                            name="institution"
                                            value={objC.dataAddStudy.institution}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {objC.alert.filter(input => input.inputName == "institution" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="type">Tipo de estudio</label>
                                        <Select
                                            options={objC.typeStudies}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            placeholder={"Selecciona una opción"}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            onChange={(e)=>{objF.HandelChangeSelect(e, 'type')}}
                                        />
                                        {objC.alert.filter(input => input.inputName == "institution" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="study_degree">Grado de estudio</label>
                                        <input
                                            type="text"
                                            id='study_degree'
                                            name="study_degree"
                                            value={objC.dataAddStudy.study_degree}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {objC.alert.filter(input => input.inputName == "study_degree" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="speciality">Especialidad de estudio</label>
                                        <input
                                            type="text"
                                            id='speciality'
                                            name="speciality"
                                            value={objC.dataAddStudy.speciality}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {/* {objC.alert.filter(input => input.inputName == "speciality" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))} */}
                                    </div>
                                    <div className="w-full p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="location">Lugar de estudio</label>
                                        <input
                                            type="text"
                                            id='location'
                                            name="location"
                                            value={objC.dataAddStudy.location}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {objC.alert.filter(input => input.inputName == "location" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full px-3 pb-5 pt-3">
                                        <div className="w-full flex items-center justify-center">
                                            <label className="inline-flex relative items-center mr-5 cursor-pointer" htmlFor='current'>
                                                <input
                                                    id='current'
                                                    type="checkbox"
                                                    name='current'
                                                    className="sr-only peer"
                                                    checked={objC.dataAddStudy.current}
                                                    onChange={objF.HandelChange}
                                                    readOnly
                                                />
                                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                            <div className="block text-violet-700 font-bold">Estudiando actualmente</div>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-wrap">
                                        <div className='w-full md:w-1/2 flex flex-wrap'>
                                            <h5 className='w-full px-3 block text-violet-700 font-bold'>Fecha de inicio</h5>
                                            <div className="w-1/2 p-2.5">
                                                <Select
                                                    options={objC.months}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                    placeholder={"Mes"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    onChange={(e)=>{objF.HandelChangeSelect(e, 'start_month')}}
                                                />
                                                {objC.alert.filter(input => input.inputName == "start_month" && input.status === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                            <div className="w-1/2 p-3">
                                                <input
                                                    type="text"
                                                    id='start_year'
                                                    name="start_year"
                                                    placeholder='aaaa'
                                                    value={objC.dataAddStudy.start_year}
                                                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                                    onChange={objF.HandelChange}
                                                />
                                                {objC.alert.filter(input => input.inputName == "start_year" && input.status === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                        </div>
                                        {!objC.dataAddStudy.current &&
                                            <div className='w-full md:w-1/2 flex flex-wrap'>
                                                <h5 className='w-full px-3 block text-violet-700 font-bold'>Fecha de Finalización</h5>
                                                <div className="w-full md:w-1/2 p-2.5">
                                                    <Select
                                                        options={objC.months}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        placeholder={"Mes"}
                                                        noOptionsMessage={() => 'No hay mas opciones'}
                                                        onChange={(e)=>{objF.HandelChangeSelect(e, 'end_month')}}
                                                    />
                                                    {objC.alert.filter(input => input.inputName == "end_month" && input.status === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                                <div className="w-full md:w-1/2 p-3">
                                                    <input
                                                        type="text"
                                                        id='end_year'
                                                        name="end_year"
                                                        placeholder='aaaa'
                                                        value={objC.dataAddStudy.end_year}
                                                        className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                                        onChange={objF.HandelChange}
                                                    />
                                                    {objC.alert.filter(input => input.inputName == "end_year" && input.status === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button type="submit" className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                                            Agregar
                                        </button>
                                        <button
                                            type="button"
                                            className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                            onClick={() => { props.showPopup(false, props.setShowModalStudy) }}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default ModalAddStudy;