export const ModalViewDocumentLogic = (params) =>{
    const { MySwal, downloadFileRequest } = params;
    
    const downloadDocument = async(nameDocument) =>{
        const response = await downloadFileRequest(
            `student/download-document/${nameDocument}`
        );
        console.log(response);
        if(response.type == 'error'){
            MySwal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 3500
            })
            return;
        }
        console.log(response);
        const imgDocument = URL.createObjectURL(response);
        hadelDownload(imgDocument, nameDocument);
    }

    const hadelDownload = (imgDocument, nameDocument) =>{
        const link = document.createElement('a');
        link.href = imgDocument;
        link.download = nameDocument;
        link.click();
    }

    const objF = {
        downloadDocument: downloadDocument
    }

    return { objF }
}