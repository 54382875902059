import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { paginationProps, getOrDeleteRequest, validateSession, deleteRoles } from "../utils/util";

import ImportButton from "../ImportButton";
import SearchInput from "../Components/SearchInput";
import PreloaderTables from "../PreloaderTables/PreloaderTables";
import ModalAddConocimiento from "./ModalAddConocimiento";

const Conocimientos = () => {
    const initDataFilter = {
        search: ''
    }

    const initialDatosFormulario = {
        name: "",
        type: ''
    }

    const initialConocimientos = {
        created_at: "",
        icon: "",
        id: "",
        name: "",
        status: ''
    }

    const initialSpeciality = {
        id: "",
        name: ""
    }

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    }


    const [role, setRole] = useState(undefined);
    const [loaded, setLoaded] = useState(false);
    const [dataFilter, setDataFilter] = useState(initDataFilter);
    const [dataSearch, setDataSearch] = useState([]);
    const [messageDatatable, setMessagesDatatable] = useState('Cargando...');
    const [speciality, setSpeciality] = useState([initialSpeciality]);
    const [formulario, setFormulario] = useState([initialDatosFormulario]);

    //funcion para obtener los de los inputs//
    const ManejarEventoDeInputs = (event) => {
        console.log(formulario, "Hola")

        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        console.log(name, "----------")
        console.log(value, "----------")

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    }

    async function getInfoPorEspecialidad(e) {
        setLoaded(false)
        setData([initialConocimientos])
        const url = `${process.env.REACT_APP_API_URI}knowledge?speciality=${e.target.value}`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        try {
            const resp = await axios.get(url, config);
            // console.log(resp.data,"----------------Conocimiento------------");
            setData(resp.data);
            setDataSearch(resp.data);
            setLoaded(true)

        } catch (err) {
            console.error(err);
        }
    }

    async function getInfo() {
        setLoaded(false)
        setData([initialConocimientos])
        const url = `${process.env.REACT_APP_API_URI}knowledge`;

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        if (Cookies.get('Bearer') !== undefined) {
            config.headers.Authorization = `Bearer ${JSON.parse(Cookies.get('Bearer')).token}`;
        } else {
            window.location = "/"
        }

        let session = validateSession();

        if (session !== undefined && session.role !== undefined) {
            setRole(session.role);
        }

        try {
            const resp = await axios.get(url, config);
            setData(resp.data);
            setDataSearch(resp.data);
            setLoaded(true);
        } catch (err) {
            console.error(err);
        }
    };

    async function getEspecialidades() {
        let response = await getOrDeleteRequest(
            'speciality', 
            'GET'
        );

        if (response.length < 0) {
            console.log("Empty response");
            return;
        }

        if (response.type === "error") {
            console.log(response.message);
            return;
        }

        setSpeciality(response);
        setLoaded(true);
        return;
    };

    useEffect(() => {
        if (loaded === false) {
            getInfo();
            getEspecialidades();
        }
    }, []);

    const [data, setData] = useState([initialConocimientos]);

    const columns = [
        {
            name: '#',
            width: "10%",
            cell: (row) => <b>
                {loaded && row.id}
            </b>
        },
        {
            name: 'Conocimiento',
            width: "35%",
            cell: (row) => <div>{loaded && row.name}</div>
        },
        {
            name: 'Especialidad',
            width: "35%",
            cell: (row) => <div>{loaded && row.speciality.name}</div>
        },
        {
            name: 'Origen',
            width: "20%",
            cell: () => <div>Chambaticon</div>
        },
    ];

    const searchFilter = (termSearch, nameInput, dataArray) => {
        console.log(termSearch, nameInput);
        let srch, sizeData;
        switch (nameInput) {
            case 'search':
                srch = data.filter((item) => {
                    if (item.name.toString().toLowerCase().includes(termSearch.toLowerCase())) {
                        return item;
                    }
                });
                sizeData = srch.length;
                changeDatatableMessages(sizeData);
                setDataSearch(srch);
                break;
            default:
                break;
        }
    }

    const changeDatatableMessages = (sizeData) => {
        if (sizeData < 1) {
            setMessagesDatatable('No se encontraron registros');
        }
    }

    const changeData = (e) => {
        console.log(e.target.value, e.target.name);
        searchFilter(e.target.value, e.target.name);
        setDataFilter({ ...dataFilter, [e.target.name]: e.target.value });
    }

    return (
        <div className=''>
            <div className='ContainerOpenClass'>
                <div className="px-4 pt-4 bg-blue-950 flex items-center justify-between border-0">
                    <div className="mb-1 w-full">
                        <div className="sm:flex">
                            <div className="">
                                <h1 className="text-xl sm:text-2xl font-semibold text-white">Conocimientos</h1>
                            </div>
                            <div className="flex items-center space-x-2 sm:space-x-3 ml-auto">
                                <button
                                    type="button"
                                    data-modal-toggle="add-user-modal"
                                    className="w-1/2 text-black bg-slate-50  focus:ring-4 focus:bg-blue-950 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center sm:w-auto"
                                    // onClick={() => setShowModal(true)}
                                >
                                    {/* <svg className="-ml-1 mr-2 h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg> */}
                                    Consultar
                                </button>
                                {/* <ImportButton
                                    origin="knowledge"
                                    info={getInfo}
                                    roles={[1, 2]}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-2 bg-blue-950">
                    <div className="flex flex-wrap p-2">
                        <SearchInput dataFilterSearch={dataFilter.search} changeData={changeData} />
                        <div className="w-full md:w-1/3 ">
                            <label htmlFor="filterDataTable" className="text-white">Especialidades</label>
                            <select
                                className='text-sm rounded block w-full px-3 py-3 my-2 text-gray-500 bg-white' value={formulario.type} name="type"
                                onChange={(e) => {
                                    ManejarEventoDeInputs(e);
                                    getInfoPorEspecialidad(e);
                                }}
                            >
                                <option value="0" selected>Seleccione una especialidad</option>
                                {speciality.map((item) =>
                                    <option value={item.id}>{item.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="px-4">
                        {
                            loaded
                            ?
                            <DataTable
                                columns={columns}
                                data={ dataSearch }
                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                                paginationComponentOptions={paginationProps}
                                responsive
                                noDataComponent={<div className="my-5">No hay registros</div>}
                            />
                            :
                            <PreloaderTables />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Conocimientos;