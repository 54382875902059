import Swal from "sweetalert2";
import ReactInputMask from 'react-input-mask';
import React, { useState, useCallback, useEffect } from "react";
import { getOrDeleteRequest, postOrPutRequest } from "../utils/util";

const UpdateSede = (props) => {
    const datosFormulario = {
        name: null,
        address: null,
        phone: null,
        // Conocimientos: [],
        // Especialidades: []
    }

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [loaded, setLoaded] = useState(false);

    const validateInputs = (inputs) => {
        let errors = [];
        let formData = inputs;

        formData.map((input) => {
            if (input.value === "" || input.value === null || input.value === undefined) {
                errors.push({
                    valorInput: input.name,
                    mensaje: "Campo requerido",
                    estado: true
                });

                return;
            }

            errors.push({
                valorInput: input.name,
                mensaje: "",
                estado: false
            });
            return;
        });
        
        return errors;
    };

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setFormulario({
            ...formulario,
            [name]: value
        });
    };

    const verifyData = (event) => {
        event.preventDefault();
        
        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });
        
        let validated = validateInputs(inputs);
        setAlerta(validated);
        
        let failedValidated = validated.filter(input => input.estado === true);

        if (failedValidated.length < 1) {
            sendData(formulario);
        }
    };

    const sendData = useCallback(async (info) => {
        let data = {
            name: info.name,
            address: info.address,
            phone: info.phone,
        };
        
        let response = await postOrPutRequest(
            `campus/update/${props.id}`,
            'PUT',
            data
        );

        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 3000
        });

        props.showPopup(false, props.modal);
        setAlerta([initialStateInput]);
        setFormulario(datosFormulario);
        props.loadData();
        return;
    }, []);

    const loadData = useCallback(async () => {
        let getData = await getOrDeleteRequest(
            `campus/find/${props.id}`,
            'GET'
        );
        
        let form = {
            name: getData.campus_name,
            address: getData.address,
            phone: getData.phone,
        };

        setFormulario(form);
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (loaded === false) {
            loadData();
            setLoaded(true);
        }
    }, []);

    return (
        <>
            <div className="block overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-7 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-900">
                                Actualizar Sede
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.showPopup(false, props.modal)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/* Modal Body */}
                        <div className="relative">
                            <div className="text-slate-500 text-lg leading-relaxed">
                                <form
                                    className="w-full flex flex-wrap"
                                    onSubmit={verifyData}
                                >
                                    <div className="w-full md:w-1/2 flex flex-wrap py-4 px-6">
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Nombre de la sede
                                            </label>
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950"
                                                name="name"
                                                value={ formulario.name }
                                                onChange={ handleChange }
                                                type="text"
                                                autoComplete="nope"
                                            />
                                            {alerta.filter(input => input.valorInput === "name" && input.estado === true).map((message, index) => (
                                                <div key={index}> <span className="text-red-500">{message.mensaje}</span></div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 flex flex-wrap py-4 px-6">
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Teléfono
                                            </label>
                                            <ReactInputMask
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950"
                                                name="phone"
                                                value={ formulario.phone }
                                                onChange={ handleChange }
                                                type="text"
                                                autoComplete="nope"
                                                mask="9999-9999"
                                            />
                                            {alerta.filter(input => input.valorInput === "phone" && input.estado === true).map((message, index) => (
                                                <div key={index}> <span className="text-red-500">{message.mensaje}</span></div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-wrap py-4 px-6">
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Ubicación de la sede
                                            </label>
                                            <textarea
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950 resize-none h-24"
                                                name="address"
                                                value={ formulario.address }
                                                onChange={ handleChange }
                                            ></textarea>
                                            {alerta.filter(input => input.valorInput === "address" && input.estado === true).map((message, index) => (
                                                <div key={index}> <span className="text-red-500">{message.mensaje}</span></div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-center border-t border-solid border-slate-200 rounded-b py-4">
                                        <button
                                            className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Registrar
                                        </button>

                                        <button
                                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center"
                                            type="button"
                                            onClick={() => {
                                                props.showPopup(false, props.modal);
                                                setAlerta([initialStateInput]);
                                                setFormulario(datosFormulario);
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default UpdateSede;
