const Preloader = ({ global = true }) => {
  return (
    <div className={ `w-full flex justify-center items-center ${ global ? 'absolute' : 'relative' } top-0 left-0 right-0 bottom-0 m-auto z-40` }>
        <div className="bg-blue-cnx p-2 w-4 h-4 rounded-full animate-bounce blue-circle m-1"></div>
        <div className="bg-dark-yellow-cnx p-2 w-4 h-4 rounded-full animate-bounce green-circle m-1"></div>
        <div className="bg-purple-cnx p-2 w-4 h-4 rounded-full animate-bounce red-circle m-1"></div>
    </div>
  );
}	

export default Preloader;