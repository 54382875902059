import Select from 'react-select';
import Swal from "sweetalert2";
import React, { useState, useEffect, useCallback } from "react";
import { getOrDeleteRequest, postOrPutRequest } from "../utils/util";

const AddTraning = (props) => {
    const datosFormulario = {
        nameTraining: "",
        typesProgram: null,
        Conocimientos: [],
        Especialidades: []
    }

    const initialStateInput = {
        valorInput: "",
        mensaje: '',
        estado: false
    };

    const [formulario, setFormulario] = useState(datosFormulario);
    const [alerta, setAlerta] = useState([initialStateInput]);
    const [modalidades, setModalidades] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [conocimientos, setConocimientos] = useState([]);
    const [typesPrograms, setTypesPrograms] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const validateInputs = (inputs) => {
        let errors = [];
        let formData = inputs;

        formData.filter((input) => input.name !== "Especialidades" && input.name !== "Conocimientos").map((input) => {
            if (input.value === "" || input.value === null || input.value === undefined) {
                errors.push({
                    valorInput: input.name,
                    mensaje: "Campo requerido",
                    estado: true
                });

                return;
            }

            errors.push({
                valorInput: input.name,
                mensaje: "",
                estado: false
            });
            return;
        });

        // formData.filter((input) => input.name === "Especialidades" || input.name === "Conocimientos").map((input) => {
        //     console.log(input)
        //     if (input.name === "Especialidades" && input.value.length < 1) {
        //         errors.push({
        //             valorInput: input.name,
        //             mensaje: "Campo requerido",
        //             estado: true
        //         });

        //         return;
        //     }

        //     if (input.name === "Conocimientos" && input.value.length < 1) {
        //         errors.push({
        //             valorInput: input.name,
        //             mensaje: "Campo requerido",
        //             estado: true
        //         });

        //         return;
        //     }

        //     errors.push({
        //         valorInput: input.name,
        //         mensaje: "",
        //         estado: false
        //     });
        //     return;
        // });

        return errors;
    };

    const manejarEventoDeInputs = (event) => {
        //la propiedad target del event nos permitira obtener los valores//
        const name = event.target.name;
        const value = event.target.value;

        // if (name === "Especialidades") {
        //     consultarConocimientos(value);
        // }

        //Actualizamos los valores capturados a nuestro estado de formulario
        setFormulario({ ...formulario, [name]: value });
    };

    const verifyData = (event) => {
        event.preventDefault();

        let inputs = Object.entries(formulario).map(([key, value]) => {
            return {
                name: key,
                value: value,
            };
        });
        // console.log(formulario);
        let validated = validateInputs(inputs);
        // console.log(validated);
        // return;
        setAlerta(validated);

        let failedValidated = validated.filter((input) => input.estado === true);

        if (failedValidated.length < 1) {
            sendData();
            // return;
        }
        // return;
    };

    const loadConocimientos = useCallback(async (elements) => {
        let selectedIds = elements.map((item) => item.id);
        let ids = selectedIds.join(",");
        let dataForm = formulario.Conocimientos.map((item) => {
            if (selectedIds.includes(item.type)) {
                return item;
            }
        });

        dataForm = dataForm.filter((item) => item !== undefined);

        if (elements.length === 0) {
            setFormulario({
                ...formulario, Conocimientos: [], Especialidades: []
            });
            setConocimientos([]);
            return;
        }

        let getConocimientos = await getOrDeleteRequest(
            `knowledge/filter?type=${ids}`,
            'GET'
        );

        setFormulario({
            ...formulario, Conocimientos: dataForm, Especialidades: elements
        });
        
        setConocimientos(getConocimientos);
    });

    const loadData = useCallback(async () => {
        let getTypesPrograms = await getOrDeleteRequest(
            "course-type/filter",
            'GET'
        );
        let getEspecialidades = await getOrDeleteRequest(
            "speciality/filter",
            'GET'
        );
        setEspecialidades(getEspecialidades);
        setTypesPrograms(getTypesPrograms);
        setLoaded(true);
    });

    const sendData = useCallback(async () => {
        let data = {
            name: formulario.nameTraining,
            type: formulario.typesProgram,
            knowledge: formulario.Conocimientos.map((item) => item.id)
        };

        console.log(data);

        let response = await postOrPutRequest(
            "programs/register",
            'POST',
            data
        );

        if (response.type === "error") {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.message,
                showConfirmButton: true,
                timer: 3000
            });
            return;
        }

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: response.message,
            showConfirmButton: true,
            timer: 3000
        });

        props.showPopup(false, props.modal);
        setAlerta([initialStateInput]);
        setFormulario(datosFormulario);
        props.loadData();
        return;
    });

    useEffect(() => {
        if (loaded === false) {
            loadData();
            setLoaded(true);
        }
    }, []);

    return (
        <>
            <div className="block overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-7 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-blue-900">
                                Agregar Formación
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => props.showPopup(false, props.modal)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/* Modal Body */}
                        <div className="relative">
                            <div className="text-slate-500 text-lg leading-relaxed">
                                <form
                                    className="w-full flex flex-wrap"
                                    onSubmit={verifyData}
                                >
                                    <div className="w-full flex flex-wrap py-4 px-6">
                                        <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Nombre de la Formación
                                            </label>
                                            <input
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-950"
                                                name="nameTraining"
                                                value={formulario.nameTraining}
                                                onChange={manejarEventoDeInputs}
                                                type="text"
                                                autoComplete="nope"
                                            />
                                            {alerta.filter(input => input.valorInput === "nameTraining" && input.estado === true).map((message, index) => (
                                                <div key={index}> <span className="text-red-500">{message.mensaje}</span></div>
                                            ))}
                                        </div>
                                        <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Tipo de Programa
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <Select
                                                    options={typesPrograms}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    onChange={(option) => {
                                                        setFormulario({ ...formulario, typesProgram: option.id });
                                                    }}
                                                />
                                                {alerta.filter(input => input.valorInput == "typesProgram" && input.estado === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.mensaje}</span></div>
                                                ))}
                                            </div>
                                        </div>
                                        {/* <div className="w-full sm:w-1/2 pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Especialidades
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <Select
                                                    isMulti
                                                    options={especialidades}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    onChange={(option) => {
                                                        setFormulario({
                                                            ...formulario, Especialidades: option
                                                        });
                                                        loadConocimientos(option);
                                                    }}
                                                />
                                                {alerta.filter(input => input.valorInput == "Especialidades" && input.estado === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.mensaje}</span></div>
                                                ))}
                                            </div>
                                        </div> */}
                                        {/* <div className="w-full pb-3 px-1">
                                            <label className="block text-blue-950 font-bold">
                                                Conocimientos
                                            </label>
                                            <div className="mb-2 xl:w-100">
                                                <Select
                                                    isMulti
                                                    options={conocimientos}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder={"Selecciona una opción"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    value={formulario.Conocimientos}
                                                    onChange={(option) => {
                                                        setFormulario({ ...formulario, Conocimientos: option });
                                                    }}
                                                />
                                                {alerta.filter(input => input.valorInput == "Conocimientos" && input.estado === true).map(message => (
                                                    <div className=""> <span className="text-red-500">{message.mensaje}</span></div>
                                                ))}
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="w-full flex items-center justify-center border-t border-solid border-slate-200 rounded-b py-4">
                                        <button
                                            className="bg-blue-950 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Registrar
                                        </button>

                                        <button
                                            className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 content-center"
                                            type="button"
                                            onClick={() => {
                                                props.showPopup(false, props.modal);
                                                setAlerta([initialStateInput]);
                                                setFormulario(datosFormulario);
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default AddTraning;
