import { WorkExperienceFunctions } from './AddWorkExperience';
const ModalAddWorkExperience = (props) => {
    const params = {
        id: props.idStudent,
        getWorkExperiences: props.getWorkExperiences,
        setShowModalWorkExperiences: props.setShowModalWorkExperiences
    }
    const Select = props.Select
    const { objC, objF } = WorkExperienceFunctions(params);
    return (
        <>
            <div className="joverflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold text-violet-700">Agregar experiencia laboral</h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => { props.showPopup(false, props.setShowModalWorkExperiences); }}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6">
                            <div className="my-4 text-slate-500 text-lg leading-relaxed">
                                <form className="w-full flex flex-wrap" onSubmit={objF.AddWorkExperience}>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="institution">Lugar de trabajo</label>
                                        <input
                                            type="text"
                                            id='institution'
                                            name="institution"
                                            value={objC.dataAddExperience.institution}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {objC.alert.filter(input => input.inputName == "institution" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="job_title">Cargo</label>
                                        <input
                                            type="text"
                                            id='job_title'
                                            name="job_title"
                                            value={objC.dataAddExperience.job_title}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {objC.alert.filter(input => input.inputName == "job_title" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full flex flex-wrap">
                                        <div className='w-full md:w-1/2 flex flex-wrap'>
                                            <h5 className='w-full px-3 block text-violet-700 font-bold'>Fecha de inicio</h5>
                                            <div className="w-1/2 p-2.5">
                                                <Select
                                                    options={objC.months}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                    placeholder={"Mes"}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    onChange={(e) => { objF.HandelChangeSelect(e, 'start_month') }}
                                                />
                                                {objC.alert.filter(input => input.inputName == "start_month" && input.status === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                            <div className="w-1/2 p-3">
                                                <input
                                                    type="text"
                                                    id='start_year'
                                                    name="start_year"
                                                    placeholder='aaaa'
                                                    value={objC.dataAddExperience.start_year}
                                                    className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                                    onChange={objF.HandelChange}
                                                />
                                                {objC.alert.filter(input => input.inputName == "start_year" && input.status === true).map((message, key) => (
                                                    <span key={key} className="text-red-500">{message.message}</span>
                                                ))}
                                            </div>
                                        </div>
                                        {!objC.dataAddExperience.current_job &&
                                            <div className='w-full md:w-1/2 flex flex-wrap'>
                                                <h5 className='w-full px-3 block text-violet-700 font-bold'>Fecha de Finalización</h5>
                                                <div className="w-full md:w-1/2 p-2.5">
                                                    <Select
                                                        options={objC.months}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        placeholder={"Mes"}
                                                        noOptionsMessage={() => 'No hay mas opciones'}
                                                        onChange={(e) => { objF.HandelChangeSelect(e, 'end_month') }}
                                                    />
                                                    {objC.alert.filter(input => input.inputName == "end_month" && input.status === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                                <div className="w-full md:w-1/2 p-3">
                                                    <input
                                                        type="text"
                                                        id='end_year'
                                                        name="end_year"
                                                        placeholder='aaaa'
                                                        value={objC.dataAddExperience.end_year}
                                                        className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                                        onChange={objF.HandelChange}
                                                    />
                                                    {objC.alert.filter(input => input.inputName == "end_year" && input.status === true).map((message, key) => (
                                                        <span key={key} className="text-red-500">{message.message}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="address">Ubicación</label>
                                        <input
                                            type="text"
                                            id='address'
                                            name="address"
                                            value={objC.dataAddExperience.address}
                                            className="text-sm rounded-lg block w-full p-2.5 bg-gray-50 border border-gray-300"
                                            onChange={objF.HandelChange}
                                        />
                                        {objC.alert.filter(input => input.inputName == "address" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <label className="block text-violet-700 font-bold" htmlFor="job_placement">Medio de inserción</label>
                                        <Select
                                            options={objC.optionsPlacementType}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.value}
                                            placeholder={"Seleccione una opción"}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            onChange={(e) => { objF.HandelChangeSelect(e, 'job_placement') }}
                                        />
                                        {objC.alert.filter(input => input.inputName == "job_placement" && input.status === true).map((message, key) => (
                                            <span key={key} className="text-red-500">{message.message}</span>
                                        ))}
                                    </div>
                                    <div className="w-full md:w-1/2 p-3">
                                        <div className="block justify-center w-full">
                                            <div className="block text-violet-700 font-bold">Laborando actualmente</div>
                                            <label className="inline-flex relative items-center mr-5 mt-3 cursor-pointer" htmlFor='current_job'>
                                                <input
                                                    // onClick={(e) => { enableOrDisableProgram(e.target.id, row.status); }}
                                                    id='current_job'
                                                    type="checkbox"
                                                    name='current_job'
                                                    className="sr-only peer"
                                                    checked={objC.dataAddExperience.current_job}
                                                    onChange={objF.HandelChange}
                                                    readOnly
                                                />
                                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="bg-violet-700 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150  "
                                            type="submit"
                                        >
                                            Agregar
                                        </button>

                                        <button
                                            className="bg-red-500 text-white active:bg-violet-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 content-center "
                                            type="button"
                                            onClick={() => {
                                                props.showPopup(false, props.setShowModalWorkExperiences);
                                            }}
                                        >
                                            Cerrar
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/*footer*/}
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}
export default ModalAddWorkExperience;